import { createAction } from "@reduxjs/toolkit";
import { actionRequest, actionSuccess } from "setup/redux/loading.toolkit";
import {
  ActionTypes,
  IArticleDetailResponse,
  IArticleResponse,
  IGetConfigResponse,
  IGetMitraDetailResponse,
  IGetMitraResponse,
  IGetProductDetailResponse,
  IGetProductResponse,
  IGetSummaryResponse,
  IInputPayload,
  IMitraDetailV1Response,
  IMitraListV1Response,
  IProductV1Response,
} from "./landing.model";

// summary
export const getSummary = createAction(actionRequest(ActionTypes.GET_SUMMARY));
export const setSummary = createAction<IGetSummaryResponse>(
  actionSuccess(ActionTypes.GET_SUMMARY)
);
//config
export const getConfig = createAction(actionRequest(ActionTypes.GET_CONFIG));
export const setConfig = createAction<IGetConfigResponse>(
  actionSuccess(ActionTypes.GET_CONFIG)
);
// article
export const getArticle = createAction(actionRequest(ActionTypes.GET_ARTICLE));
export const setArticle = createAction<IArticleResponse>(
  actionSuccess(ActionTypes.GET_ARTICLE)
);
export const getArticleDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ARTICLE_DETAIL)
);
export const setArticleDetail = createAction<IArticleDetailResponse>(
  actionSuccess(ActionTypes.GET_ARTICLE_DETAIL)
);
// product
export const getProduct = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT)
);
export const setProduct = createAction<IGetProductResponse>(
  actionSuccess(ActionTypes.GET_PRODUCT)
);
export const getProductDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_DETAIL)
);
export const setProductDetail = createAction<IGetProductDetailResponse>(
  actionSuccess(ActionTypes.GET_PRODUCT_DETAIL)
);
// mitra
export const getMitra = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA)
);
export const setMitra = createAction<IGetMitraResponse>(
  actionSuccess(ActionTypes.GET_MITRA)
);
export const getMitraDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_DETAIL)
);
export const setMitraDetail = createAction<IGetMitraDetailResponse>(
  actionSuccess(ActionTypes.GET_MITRA_DETAIL)
);
export const getProductV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_V1)
);
export const setProductV1 = createAction<IProductV1Response>(
  actionSuccess(ActionTypes.GET_PRODUCT_V1)
);
export const getMitraV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_V1)
);
export const setMitraV1 = createAction<IMitraListV1Response>(
  actionSuccess(ActionTypes.GET_MITRA_V1)
);
export const getMitraDetailV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_DETAIL_V1)
);
export const setMitraDetailV1 = createAction<IMitraDetailV1Response>(
  actionSuccess(ActionTypes.GET_MITRA_DETAIL_V1)
);
