import { Button, Dropdown, Icon, MainLayout } from "components";
import { LineChart, MultiBarChart } from "./components";
import { SelectField } from "components/SelectField/SelectField";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  getAnalytics,
  getAnalyticsV1,
  getCategory,
  getCity,
  getDistrict,
  getVillage,
} from "pages/admin/redux";
import { RootState } from "setup";
import { PATH } from "config";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const analytics = useSelector((state: RootState) => state.admin.analyticsv1);

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_ANALYTICS)
  );

  const mitraProduk = useMemo(() => {
    const mitraData = analytics.mitra;
    const produkData = analytics.products;
    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const mitraProdukData = months.map((date, index) => {
      return {
        date,
        produk: produkData[index],
        usaha: mitraData[index],
      };
    });

    return mitraProdukData;
  }, [analytics]);

  const omset = useMemo(() => {
    const mitraData = analytics.omset;

    const months = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    const mitraOmset: any = months.map((date, index) => {
      return {
        date,
        omset: mitraData[index],
      };
    });

    return mitraOmset;
  }, [analytics]);

  useEffect(() => {
    dispatch(
      getAnalyticsV1({
        params: {
          tahun: new Date().getFullYear().toString(),
          kecamatan: null,
          kota: null,
        },
      })
    );
  }, []);

  function formatCurrency(number: number): string {
    const suffixes = ["", "Rb", "Jt", "M", "Tr"];
    const suffixNum = Math.floor((("" + number).length - 1) / 3);

    let shortValue: number | string = parseFloat(
      (number / Math.pow(1000, suffixNum)).toFixed(1)
    );

    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }

    return `Rp ${shortValue} ${suffixes[suffixNum]}`;
  }

  const { city, district } = useSelector((state: RootState) => state.admin);

  const yearList = useMemo<any[]>(() => {
    const currentYear = new Date().getFullYear();
    const yearOptions: any[] = [];

    // Generate year options
    for (let i = 0; i < 10; i++) {
      const year = currentYear - i;
      yearOptions.push({ label: year.toString(), value: year.toString() });
    }

    return yearOptions;
  }, []);

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      tahun: 0,
      kota: 0,
      kecamatan: 0,
    },
    onSubmit(values) {
      dispatch(
        getAnalyticsV1({
          params: values,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getCategory({}));
    dispatch(getCity());
  }, []);

  useEffect(() => {
    if (formHandler.values.kota !== 0) {
      dispatch(
        getDistrict({
          params: {
            regencyId: formHandler.values.kota,
          },
        })
      );
    }
  }, [formHandler.values.kota]);

  return (
    <MainLayout>
      <>
        <div className="p-2 w-full flex justify-between items-center">
          <div className="text-gray-900 text-2xl font-medium">
            Data Bulan Mei
          </div>
          <Dropdown>
            <button className="shadow rounded-lg bg-white flex items-center px-4 py-2 cursor-pointer">
              <Icon name="Filter2" type="light" size="x-small" />
              <span className="ml-2">Filter</span>
            </button>
            <div className="w-72 p-4">
              <div className="px-4 py-2">
                <div className="mb-4">Filter Analisis</div>
                <SelectField
                  className="!bg-gray-100"
                  variant="solid"
                  onChange={(e) => {
                    formHandler.setFieldTouched("kota");
                    formHandler.setFieldValue("kota", e.target.value ?? "");
                  }}
                  options={city.map((e) => ({ label: e.name, value: e.id }))}
                  placeholder="Pilih Kab/Kota"
                  padingBottom={4}
                />
                <SelectField
                  className="!bg-gray-100"
                  variant="solid"
                  onChange={(e) => {
                    formHandler.setFieldTouched("kecamatan");
                    formHandler.setFieldValue(
                      "kecamatan",
                      e.target.value ?? ""
                    );
                  }}
                  options={district.map((e) => ({
                    label: e.name,
                    value: e.id,
                  }))}
                  placeholder="Pilih Kecamatan"
                  padingBottom={4}
                />
                <SelectField
                  className="!bg-gray-100"
                  variant="solid"
                  onChange={(e) => {
                    formHandler.setFieldTouched("tahun");
                    formHandler.setFieldValue("tahun", +e.target.value);
                  }}
                  options={yearList}
                  placeholder="Pilih Tahun"
                  padingBottom={4}
                />
                <Button
                  full
                  color="primary"
                  className="h-14 w-full"
                  onClick={() => formHandler.submitForm()}
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Filter
                </Button>
              </div>
            </div>
          </Dropdown>
        </div>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon name="People" type="bold" size="xlarge" color="#CB043F" />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Jumlah Pengguna</div>
                <div className="text-2xl font-bold text-gray-800">
                  {analytics.summary.user ?? 0}
                </div>
              </div>
              <div>
                {/* <span className="text-blue-500 cursor-pointer">
                  <span
                    className="text-xs mr-2"
                    onClick={() => navigate(PATH.DASHBOARD_USER)}
                  >
                    Detail
                  </span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon
                  name="ShieldDone"
                  type="bold"
                  size="xlarge"
                  color="#CB043F"
                />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Usaha Terdaftar</div>
                <div className="text-2xl font-bold text-gray-800">
                  {analytics.summary.mitra ?? 0}
                </div>
              </div>
              <div>
                {/* <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate(PATH.DASHBOARD_PARTNER)}
                >
                  <span className="text-xs mr-2">Detail</span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon name="Bag" type="bold" size="xlarge" color="#CB043F" />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Jumlah Produk</div>
                <div className="text-2xl font-bold text-gray-800">
                  {analytics.summary.produk ?? 0}
                </div>
              </div>
              <div>
                {/* <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate(PATH.DASHBOARD_PARTNER)}
                >
                  <span className="text-xs mr-2">Detail</span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon name="Wallet" type="bold" size="xlarge" color="#CB043F" />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Rata - rata omset</div>
                <div className="text-2xl font-bold text-gray-800">
                  {formatCurrency(+analytics.summary.omset.toFixed() ?? 0)}
                </div>
              </div>
              <div>
                {/* <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate(PATH.DASHBOARD_PARTNER)}
                >
                  <span className="text-xs mr-2">Detail</span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon
                  name="Document"
                  type="bold"
                  size="xlarge"
                  color="#CB043F"
                />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Mitra dengan NIB</div>
                <div className="text-2xl font-bold text-gray-800">
                  {analytics.summary.nib.exist}
                </div>
              </div>
              <div>
                {/* <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate(PATH.DASHBOARD_PARTNER)}
                >
                  <span className="text-xs mr-2">Detail</span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 p-2">
            <div className="p-4 shadow rounded-lg bg-white mb-2 md:mb-4 flex">
              <div>
                <Icon
                  name="Document"
                  type="bold"
                  size="xlarge"
                  color="#CB043F"
                />
              </div>
              <div className="pl-4 flex-1">
                <div className="text-xs text-gray-600">Mitra tanpa NIB</div>
                <div className="text-2xl font-bold text-gray-800">
                  {analytics.summary.nib.nonExist}
                </div>
              </div>
              <div>
                {/* <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate(PATH.DASHBOARD_PARTNER)}
                >
                  <span className="text-xs mr-2">Detail</span>
                  <i className="fa fa-arrow-right text-xs"></i>
                </span> */}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-2">
          <div className="p-4 shadow rounded-lg bg-white mb-4">
            <MultiBarChart data={mitraProduk ?? []} />
          </div>
          <div className="p-4 shadow rounded-lg bg-white mb-4">
            <LineChart data={omset} />
          </div>
        </div>
      </>
    </MainLayout>
  );
};
export { Dashboard };
