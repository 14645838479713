import { PATH } from "config";
import { getProduct, getProductV1 } from "pages/landing/redux";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import { RootState } from "setup";

const Header: FC<{
  isLanding?: boolean;
  handleScroll: (to: number | null) => void;
  handleClick: () => void;
  setOpenSide: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isLanding, handleScroll, handleClick, setOpenSide }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authInfo = useSelector((state: RootState) => state.auth.authInfo);

  const handleSearch = useCallback((e: any) => {
    if (e.keyCode === 13) {
      navigate(`${PATH.LANDING_PAGE_PRODUCT}?search=${e.target.value}`);
    }
  }, []);

  return (
    <nav className="fixed top-0 w-full left-0 shadow-lg shadow-gray-100 bg-white md:px-12 px-4 py-4 flex flex-row items-center z-50">
      <img
        src="/assets/icon/logo-ct.png"
        alt="logo"
        className="w-12 cursor-pointer"
        onClick={() => {
          if (authInfo.role === "") {
            navigate("/");
          } else if (authInfo.role === "user") {
            // navigate(PATH.DASHBOARD);
          } else if (authInfo.role === "adminRegional") {
            navigate(PATH.DASHBOARD_REGIONAL);
          } else if (authInfo.role === "root") {
            navigate(PATH.DASHBOARD_PIMPINAN);
          } else {
            navigate(PATH.DASHBOARD);
          }
        }}
      />
      {isLanding !== undefined ? (
        <>
          <div className="flex-1"></div>
          <button
            onClick={() => {
              handleScroll(0);
            }}
            className="text-[#CB043F] hidden md:block font-semibold px-4"
          >
            Beranda
          </button>
          <button
            onClick={() => {
              handleScroll(934);
            }}
            className="text-[#3D3D3D] hidden md:block font-medium px-4"
          >
            Produk
          </button>
          <button
            onClick={() => {
              handleScroll(1823);
            }}
            className="text-[#3D3D3D] hidden md:block font-medium px-4"
          >
            UMKM & Ekraf
          </button>
          <button
            onClick={() => {
              handleScroll(null);
            }}
            className="text-[#3D3D3D] hidden md:block font-medium px-4"
          >
            Kontak Kami
          </button>
        </>
      ) : (
        <>
          <div className="flex-1 flex justify-center px-4">
            <div className="w-full max-w-xl relative">
              <input
                className="w-full border rounded-lg py-3 px-4"
                placeholder="Cari Produk"
                onKeyUp={handleSearch}
              />
              <i className="fa fa-search absolute top-0 right-0 text-gray-500 m-4 cursor-pointer"></i>
            </div>
          </div>
        </>
      )}
      {authInfo.role === "" ? (
        <button
          onClick={() => navigate("/register")}
          className="ml-8 bg-[#CB043F] text-white px-8 py-3 hidden md:table rounded-lg whitespace-nowrap"
        >
          Gabung Sekarang
        </button>
      ) : null}
      <i
        className="fa fa-bars text-2xl text-gray-600 md:hidden"
        onClick={() => setOpenSide(true)}
      ></i>
    </nav>
  );
};
export { Header };
