import sagaRequest from "setup/saga/sagaRequest";
import {
  ActionTypes,
  IInputPayload,
  IInputPayloadCallback,
  addAspiration,
  addFavorite,
  addMitra,
  addProduct,
  applyFunding,
  applyTraining,
  changePassword,
  deleteMitra,
  editMitra,
  editProduct,
  getCategory,
  getFavorite,
  getFunding,
  getFundingDetail,
  getFundingDetailHistory,
  getFundingHistory,
  getMitra,
  getMitraDetail,
  getNotification,
  getProduct,
  getProductDetail,
  getProductDetailV1,
  getProductV1,
  getProfile,
  getTraining,
  getTrainingDetail,
  getTrainingDetailHistory,
  getTrainingHistory,
  readNotification,
  removeFavorite,
  updateProfile,
} from ".";
import { takeLatest } from "redux-saga/effects";
import { IActionWithPayload } from "setup/redux/redux.model";

const prefixMitra = "/mitra/user";
const prefixCategory = "/classification";
const prefixProduct = "/product/user";
const prefixFavorit = "/favorite";
const prefixTraining = "/course/v1/user";
const prefixFunding = "/funding/v1/user";
function* getMitraSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/v1/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_MITRA,
  });
}
function* getMitraDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/v1/details?mitraId=${action.payload.params.businessId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_MITRA_DETAIL,
  });
}
function* addMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/create`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_MITRA,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* editMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.EDIT_MITRA,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* deleteMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/delete`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.DELETE_MITRA,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* getCategorySaga() {
  yield sagaRequest({
    requestPayload: {
      // url: `${prefixCategory}/v1/list`,
      url: `${prefixCategory}/general`,
      method: "GET",
    },
    actionType: ActionTypes.GET_CATEGORY,
  });
}
function* addProductSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/create`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_PRODUCT,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* editProductSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.EDIT_PRODUCT,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
// product user
function* getProductSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/product/v1/user/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_PRODUCT,
  });
}
function* getProductDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/product/v1/user/details?productId=${action.payload.params.productId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PRODUCT_DETAIL,
  });
}

function* addFavoritSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFavorit}/user/add`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_FAVORITE,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* removeFavoritSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFavorit}/user/remove`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.REMOVE_FAVORITE,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* getFavoriteSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFavorit}/user/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_FAVORITE,
  });
}

// training
function* getTrainingSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixTraining}/programs`,
      method: "GET",
    },
    actionType: ActionTypes.GET_TRAINING,
  });
}
function* getTrainingHistorySaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixTraining}/history`,
      method: "GET",
    },
    actionType: ActionTypes.GET_TRAINING_HISTORY,
  });
}
function* getTrainingDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixTraining}/details/${action.payload.params.trainingId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_TRAINING_DETAIL,
  });
}
function* getTrainingDetailHistorySaga(
  action: IActionWithPayload<IInputPayload>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixTraining}/history/${action.payload.params.trainingId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_TRAINING_DETAIL_HISTORY,
  });
}
function* applyTrainingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixTraining}/join`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.APPLY_TRAINING,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}

// funding
function* getFundingSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_FUNDING,
  });
}
function* getFundingHistorySaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/history`,
      method: "GET",
    },
    actionType: ActionTypes.GET_FUNDING_HISTORY,
  });
}
function* getFundingDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/details/${action.payload.params.fundingId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_FUNDING_DETAIL,
  });
}
function* getFundingDetailHistorySaga(
  action: IActionWithPayload<IInputPayload>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/history/${action.payload.params.fundingId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_FUNDING_DETAIL_HISTORY,
  });
}
function* applyFundingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/join`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.APPLY_FUNDING,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}

function* getProfileSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `/user/data`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PROFILE,
  });
}

const prefixData = "/user/data";
function* updateProfileSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixData}/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.UPDATE_PROFILE,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* changePasswordSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixData}/change-password`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.CHANGE_PASSWORD,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}

const prefixNotification = "/notification/user";
function* getNotificationSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixNotification}/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_NOTIFICATION,
  });
}
function* readNotificationSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixNotification}/read`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.READ_NOTIFICATION,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* addAspirationSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `/aspiration/user`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_ASPIRATION,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}

//v1
function* getProductV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/product/v1/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_PRODUCT_V1,
  });
}

function* getProductDetailV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/product/v1/details/${action.payload.params.productId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PRODUCT_DETAIL_V1,
  });
}

export default function* userSaga() {
  yield takeLatest(getMitra.type, getMitraSaga);
  yield takeLatest(getMitraDetail.type, getMitraDetailSaga);
  yield takeLatest(getCategory.type, getCategorySaga);
  yield takeLatest(addMitra.type, addMitraSaga);
  yield takeLatest(editMitra.type, editMitraSaga);
  yield takeLatest(deleteMitra.type, deleteMitraSaga);
  yield takeLatest(getFavorite.type, getFavoriteSaga);
  yield takeLatest(addFavorite.type, addFavoritSaga);
  yield takeLatest(removeFavorite.type, removeFavoritSaga);

  yield takeLatest(getProduct.type, getProductSaga);
  yield takeLatest(addProduct.type, addProductSaga);
  yield takeLatest(editProduct.type, editProductSaga);
  yield takeLatest(getProductDetail.type, getProductDetailSaga);

  // general
  yield takeLatest(getProductV1.type, getProductV1Saga);
  yield takeLatest(getProductDetailV1.type, getProductDetailV1Saga);

  yield takeLatest(getTraining.type, getTrainingSaga);
  yield takeLatest(getTrainingHistory.type, getTrainingHistorySaga);
  yield takeLatest(getTrainingDetail.type, getTrainingDetailSaga);
  yield takeLatest(getTrainingDetailHistory.type, getTrainingDetailHistorySaga);
  yield takeLatest(applyTraining.type, applyTrainingSaga);
  yield takeLatest(getFunding.type, getFundingSaga);
  yield takeLatest(getFundingHistory.type, getFundingHistorySaga);
  yield takeLatest(getFundingDetail.type, getFundingDetailSaga);
  yield takeLatest(getFundingDetailHistory.type, getFundingDetailHistorySaga);
  yield takeLatest(applyFunding.type, applyFundingSaga);
  yield takeLatest(getProfile.type, getProfileSaga);
  yield takeLatest(updateProfile.type, updateProfileSaga);
  yield takeLatest(changePassword.type, changePasswordSaga);
  yield takeLatest(getNotification.type, getNotificationSaga);
  yield takeLatest(readNotification.type, readNotificationSaga);
  yield takeLatest(addAspiration.type, addAspirationSaga);
}
