import { Icon, MainLayout } from "components";
import { BottomNav } from "../bottom-nav/BottomNav";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { getInfo } from "../auth/redux";
import { convertToWhatsAppURL } from "utils/convertWhatsappUrl";
import { PATH } from "config";
import { getProductDetail } from "pages/landing/redux";
import {
  addFavorite,
  getProductDetailV1,
  removeFavorite,
} from "pages/users/redux";
import { toast } from "react-hot-toast";

const DashboardProductDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    productId: string;
  }>();

  const authInfo = useSelector((state: RootState) => state.auth.authInfo);
  const product = useSelector((state: RootState) => state.user.productDetailV1);

  const handleFavorite = useCallback(() => {
    if (params.productId) {
      if (product.isFavorite) {
        dispatch(
          removeFavorite({
            params: {
              productId: params.productId,
            },
            onSuccess(e) {
              toast.success("Success to remove from favorit");
              dispatch(
                getProductDetailV1({
                  params: {
                    productId: params.productId,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Failed to remove from favorit");
            },
          })
        );
      } else {
        dispatch(
          addFavorite({
            params: {
              productId: params.productId,
            },
            onSuccess(e) {
              toast.success("Success to add favorit");
              dispatch(
                getProductDetailV1({
                  params: {
                    productId: params.productId,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Failed to add favorit");
            },
          })
        );
      }
    }
  }, [product]);

  useEffect(() => {
    if (params.productId) {
      // dispatch(
      //   getProductDetail({
      //     params: {
      //       productId: params.productId,
      //     },
      //   })
      // );
      dispatch(
        getProductDetailV1({
          params: {
            productId: params.productId,
          },
        })
      );
    }
    dispatch(getInfo());
  }, [params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative pb-10">
          <HeaderNav title="Detail" />
          <Swiper
            className="w-full footer-slider"
            pagination
            slidesPerView={1}
            centerInsufficientSlides={true}
            spaceBetween={0}
          >
            {product.fotoProduk.map((e, index) => (
              <SwiperSlide key={index}>
                <img
                  src={e.url || "/assets/images/placeholder-image.png"}
                  alt="cover product"
                  className="w-full h-72 object-cover"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="p-6 transform -translate-y-16 absolute z-10 w-full">
            <div className="bg-white rounded-lg p-6">
              <div className="flex">
                <div className="flex-1">
                  <div className="text-xl font-semibold text-[#3D3D3D]">
                    {product.namaProduk}
                  </div>
                  <div className="flex items-center my-3">
                    <div className="text-[#CB043F] text-2xl font-bold">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(product.harga)}
                    </div>
                    <div className="text-gray-500 ml-2 capitalize text-sm pt-3">
                      / {product.nominalSatuan} {product.beratSatuan}
                    </div>
                  </div>
                </div>
                {authInfo.role !== "" ? (
                  <Icon
                    onClick={handleFavorite}
                    name="Heart2"
                    type={product.isFavorite ? "bold" : "light"}
                    size="small"
                    color={product.isFavorite ? "#CB043F" : "transparent"}
                    className="cursor-pointer"
                  />
                ) : null}
              </div>
              <div className="text-[#3D3D3D] mt-4 text-sm">
                <p>{product.deskripsi}</p>
              </div>
            </div>
            <div className="bg-white rounded-lg p-6 mt-4">
              <div className="flex items-start">
                <img
                  src={
                    product.mitra.foto || "/assets/images/placeholder-image.png"
                  }
                  alt="avatar"
                  className="w-12 h-12 object-cover rounded-full flex-shrink-0"
                />
                <div className="ml-4 flex-1">
                  <div
                    className="font-semibold cursor-pointer"
                    onClick={() => {
                      navigate(`${PATH.USER_USAHA}/${product.mitra.id}`);
                    }}
                  >
                    {product.mitra.namaMitra}
                  </div>
                  <div className="text-xs">
                    {product.mitra.kecamatan},{product.mitra.kota}
                  </div>
                </div>
                <img
                  src="/assets/icon/shop.svg"
                  alt="shop icon"
                  className="w-5"
                />
              </div>
              <div className="flex flex-wrap md:justify-start mt-4">
                <div className="flex-1">
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${product.mitra.location.coordinates[1]}%2C${product.mitra.location?.coordinates[0]}`}
                    target="_blank"
                    className="whitespace-nowrap items-center border border-[#CB043F] text-[#CB043F] text-sm py-2 px-4 rounded flex"
                    rel="noreferrer"
                  >
                    <Icon
                      name="Send"
                      type="bold"
                      size="small"
                      color="#CB043F"
                      className="h-5 md:h-8"
                    />
                    <span className="ml-0 md:ml-2 text-sm md:text-md">
                      Menuju Lokasi
                    </span>
                  </a>
                </div>
                <div className="flex-1">
                  <a
                    href={convertToWhatsAppURL(product.mitra.phoneNumber ?? "")}
                    target="_blank"
                    className="whitespace-nowrap items-center ml-1 md:ml-3 bg-[#CB043F] text-white text-sm py-2 px-4 rounded flex border border-[#CB043F]"
                    rel="noreferrer"
                  >
                    <Icon
                      name="Chat"
                      type="bold"
                      size="small"
                      color="#FFFFFF"
                      className="h-5 md:h-8"
                    />
                    <span className="ml-0 md:ml-2 text-sm md:text-md">
                      Chat Penjual
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardProductDetail };
