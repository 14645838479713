import axios from "axios";
import { IApiRequest } from "./requestPublic";

const requestPrivate = ({
  url,
  method,
  data,
  isDataPayload = false,
}: IApiRequest) => {
  const baseURL = process.env.REACT_APP_API_URL || "";

  const dataOrParams =
    !isDataPayload && ["get", "delete"].includes(method.toLowerCase())
      ? "params"
      : "data";

  return axios.request({
    url,
    baseURL: baseURL,
    method,
    headers: {
      Authorization: localStorage.getItem(
        process.env.REACT_APP_TOKEN_NAME ?? ""
      ),
      "Content-Type": "application/json",
    },
    [dataOrParams]: data,
  });
};
export { requestPrivate };
