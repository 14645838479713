import { useFormik } from "formik";
import * as Yup from "yup";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes, LoginFormValues, login } from "../redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "components";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const Auth: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const loading = useSelector(createLoadingSelector(ActionTypes.LOGIN));

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
    password: Yup.string().required("Password is Required"),
  });

  const formHandler = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      dispatch(
        login({
          data: values,
          onSuccess() {
            toast.success("login Successfully!");
            navigate("/admin");
          },
          onFailure() {
            toast.error("Login Failed!");
          },
        })
      );
    },
  });

  return (
    <div className="flex flex-row">
      <div className="w-3/5 relative hidden md:block">
        <div className="absolute w-full h-screen bg-black bg-opacity-80 flex justify-start items-end z-10">
          <div className="p-12">
            <div className="text-white text-5xl text-opacity-90">Tabe</div>
            <div className="text-white text-2xl font-light mt-4 text-opacity-50">
              Bring change and innovation
            </div>
          </div>
        </div>
        <img
          src="/assets/images/background.jpg"
          alt="footage foto by unsplash"
          className="w-full h-screen object-cover"
        />
      </div>
      <div className="w-full h-screen md:w-2/5 flex flex-col px-8 md:px-16 py-8 items-center justify-center">
        <img src="/assets/logo/logo.png" alt="logo tabe" className="w-32" />
        <div className="text-xl mt-2 mb-8 font-bold">Login</div>
        <TextField
          size="medium"
          placeholder="Email Address"
          propsInput={{ ...formHandler.getFieldProps("email") }}
        />
        <TextField
          type="password"
          size="medium"
          placeholder="Password"
          propsInput={{ ...formHandler.getFieldProps("password") }}
        />
        <Button
          color="failure"
          full
          className="text-md p-2"
          onClick={() => formHandler.submitForm()}
          loading={loading}
          disabled={loading}
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default Auth;
