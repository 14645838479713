import { Button } from "components";
import { toast } from "react-hot-toast";

export default function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data?.err === "Sesi telah habis"
      ) {
        const overlay = document.createElement("div");
        overlay.className =
          "fixed top-0 left-0 w-full h-screen bg-black bg-opacity-10 z-10";
        document.body.append(overlay);
        toast(
          (t) => (
            <div className="w-64 flex flex-col items-center z-50">
              <div>Sesi anda telah habis</div>
              <div>Anda perlu login ulang</div>
              <Button
                className="mt-2"
                color="light"
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Home
              </Button>
            </div>
          ),
          {
            duration: 60000,
          }
        );
      }
      return Promise.reject(error);
    }
  );
}
