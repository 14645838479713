import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../landing-page/componets";
import { ActionTypes, getProductDetail } from "pages/landing/redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { getInfo } from "pages/admin/components/auth/redux";
import { Icon } from "components";
import { convertToWhatsAppURL } from "utils/convertWhatsappUrl";

const DetailProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    productId: string;
  }>();

  const loading = useSelector(
    createLoadingSelector(ActionTypes.GET_PRODUCT_DETAIL)
  );

  const authInfo = useSelector((state: RootState) => state.auth.authInfo);
  const product = useSelector((state: RootState) => state.landing.product);

  useEffect(() => {
    dispatch(
      getProductDetail({
        params: {
          productId: params.productId,
        },
      })
    );
    dispatch(getInfo());
  }, [params]);

  return (
    <>
      <div className="bg-[#F6F7FB] min-h-screen">
        <Header
          handleScroll={() => {}}
          handleClick={() => {}}
          setOpenSide={() => {}}
        />
        {!loading && (
          <div className="mx-auto max-w-5xl pt-24 md:px-4">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/3">
                <img
                  src={
                    product.fotoProduk[0] ||
                    "/assets/images/placeholder-image.png"
                  }
                  alt="foto"
                  className="w-full rounded"
                />
              </div>
              <div className="w-full md:w-2/3 -mt-12 md:mt-0 md:pl-8 px-6 pb-12">
                <div className="bg-white rounded-lg p-6 md:p-8">
                  <div className="flex">
                    <div className="flex-1">
                      <div className="text-xl font-semibold text-[#3D3D3D]">
                        {product.namaProduct}
                      </div>
                      <div className="flex items-end my-3">
                        <div className="text-[#CB043F] text-3xl font-bold">
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(product.harga)}
                        </div>
                        <div className="text-gray-500 ml-2 capitalize">
                          / {product.nominalSatuan} {product.beratSatuan}
                        </div>
                      </div>
                    </div>
                    {authInfo.role !== "" ? (
                      <Icon
                        onClick={() => {
                          // favoriteMutation.mutate({
                          //     isFavorited: data.isFavorite,
                          //     productId: data._id,
                          //   })
                        }}
                        name="Heart2"
                        type={product.isFavorite ? "bold" : "light"}
                        size="medium"
                        color={product.isFavorite ? "#CB043F" : "transparent"}
                        className="cursor-pointer"
                      />
                    ) : null}
                  </div>
                  <div className="text-[#3D3D3D] mt-4">
                    <p>{product.deskripsi}</p>
                  </div>
                </div>
                <div className="bg-white rounded-lg p-6 md:p-8 mt-6">
                  <div>
                    <div className="w-full">
                      <div className="flex items-center">
                        <img
                          src={
                            product.mitra.foto ||
                            "/assets/images/placeholder-image.png"
                          }
                          alt="avatar"
                          className="w-16 h-16 rounded-full object-cover"
                        />
                        <div className="ml-4 flex-1">
                          <div className="flex">
                            <div
                              onClick={() =>
                                navigate("/mitra/" + product.mitra.id)
                              }
                              className="cursor-pointer text-xl font-semibold text-[#3D3D3D] capitalize"
                            >
                              {product.mitra.namaMitra}
                            </div>
                            {product.mitra.isVerified && (
                              <img
                                src="/assets/icon/verify.svg"
                                alt="verift"
                                className="w-4 ml-2"
                              />
                            )}
                          </div>
                          <div className="text-md text-[#3D3D3D] capitalize">
                            {[product.mitra.kecamatan, product.mitra.kota]
                              .map((x) => x.toLowerCase())
                              .join(", ")}
                          </div>
                        </div>
                        <i className="fa fa-shop text-[#CB043F]"></i>
                      </div>
                    </div>
                    <div className="flex flex-wrap md:justify-start mt-4">
                      <div className="pb-2">
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${product.mitra.location.coordinates[1]}%2C${product.mitra.location?.coordinates[0]}`}
                          target="_blank"
                          className="whitespace-nowrap items-center border border-[#CB043F] text-[#CB043F] py-4 md:py-2 px-2 md:px-4 rounded flex"
                          rel="noreferrer"
                        >
                          <Icon
                            name="Send"
                            type="bold"
                            size="medium"
                            color="#CB043F"
                            className="h-5 md:h-8"
                          />
                          <span className="ml-0 md:ml-2 text-sm md:text-md">
                            Menuju Lokasi
                          </span>
                        </a>
                      </div>
                      <div className="pb-2">
                        <a
                          href={convertToWhatsAppURL(
                            product.mitra.phoneNumber ?? ""
                          )}
                          target="_blank"
                          className="whitespace-nowrap items-center ml-1 md:ml-3 bg-[#CB043F] text-white py-4 md:py-2 px-2 md:px-4 rounded flex border border-[#CB043F]"
                          rel="noreferrer"
                        >
                          <Icon
                            name="Chat"
                            type="bold"
                            size="medium"
                            color="#FFFFFF"
                            className="h-5 md:h-8"
                          />
                          <span className="ml-0 md:ml-2 text-sm md:text-md">
                            Chat Penjual
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export { DetailProduct };
