import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { route } from "config";
import { Guard } from "middleware";
import { LandingPage, NotFound } from "pages/landing/components";

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<LandingPage />} />
        {route.public.map((e: any, i: number) => (
          <Route key={i} path={e.path} element={<e.element />} />
        ))}

        {route.private.map((e: any, i: number) => (
          <Route
            key={i}
            path={e.path}
            element={<Guard role={e.role} element={<e.element />} />}
          />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
