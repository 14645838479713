import { Button, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { ActionTypes, changePassword, getProfile } from "pages/users/redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardChangePassword = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.CHANGE_PASSWORD)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string().required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit(values) {
      dispatch(
        changePassword({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil ganti password");
          },
          onFailure(e) {
            toast.error("Gagal ganti password");
          },
        })
      );
    },
  });
  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Ganti Password" />
          <div className="p-6 pb-4">
            <TextField
              className="rounded-lg p-4"
              type="password"
              toggle
              variant="solid"
              placeholder="Password Lama"
              propsInput={{ ...formHandler.getFieldProps("oldPassword") }}
              error={
                !!formHandler.touched.oldPassword &&
                !!formHandler.errors.oldPassword
              }
              errorText={`${formHandler.errors.oldPassword}`}
            />
            <TextField
              className="rounded-lg p-4"
              type="password"
              toggle
              variant="solid"
              placeholder="Password Baru"
              propsInput={{ ...formHandler.getFieldProps("newPassword") }}
              error={
                !!formHandler.touched.newPassword &&
                !!formHandler.errors.newPassword
              }
              errorText={`${formHandler.errors.newPassword}`}
            />
            <TextField
              className="rounded-lg p-4"
              type="password"
              toggle
              variant="solid"
              placeholder="Ulangi Password Baru"
              propsInput={{ ...formHandler.getFieldProps("confirmPassword") }}
              error={
                !!formHandler.touched.confirmPassword &&
                !!formHandler.errors.confirmPassword
              }
              errorText={`${formHandler.errors.confirmPassword}`}
            />
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoading}
              disabled={isLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardChangePassword };
