import {
  Button,
  FileSelect,
  MainLayout,
  TextField,
  SelectField,
} from "components";
import { useFormik } from "formik";
import moment from "moment";
import {
  ActionTypes,
  addUser,
  editUser,
  getUser,
  getUserDetail,
} from "pages/admin/redux";
import { FC, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

export const DashboardUserInput: FC<{
  isEdit: boolean;
}> = ({ isEdit }) => {
  const dispatch = useDispatch();

  const isLoadingAdd = useSelector(createLoadingSelector(ActionTypes.ADD_USER));
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_USER)
  );

  const params = useParams<{
    userId: string;
  }>();

  const user = useSelector((state: RootState) => state.admin.userDetail);

  const initialValues = useMemo(() => {
    return {
      nama: isEdit ? user.nama : "",
      email: isEdit ? user.email : "",
      password: "",
      nik: isEdit ? user.nik : "",
      gender: isEdit ? user.gender : "",
      tempatLahir: isEdit ? user.tempatLahir : "",
      tanggalLahir: isEdit
        ? moment(user.tanggalLahir ?? "0000-00-00").format("YYYY-MM-DD")
        : "",
      noHandphone: isEdit ? user.noHandphone : "",
      foto: isEdit ? user.foto : "",
      status: isEdit ? user.statusVerify : "S",
    };
  }, [isEdit, user]);

  const validationSchema = Yup.object({
    nama: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Nama should only contain letters")
      .required("Nama is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: isEdit
      ? Yup.string().optional().nullable()
      : Yup.string().min(6, "Password minimum 6 Characters").required(),
    nik: Yup.string()
      .test(
        "len",
        "NIK must be 16 digits",
        (val: any) => val.toString().length === 16
      )
      .required(),
    gender: Yup.string().required("Jenis Kelamin is required").matches(/(M|F)/),
    tempatLahir: Yup.string().required("Tempat Lahir is required"),
    tanggalLahir: Yup.date().required("Tanggal Lahir is required"),
    noHandphone: Yup.string()
      .matches(/^[0-9]+$/, "Can only numbers")
      .min(9, "Phone number at least 9 digits")
      .max(20, "Phone number up to 20 digits")
      .required(),
    foto: Yup.string().optional().nullable(),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      let updateData: any = {
        userId: params.userId,
        nama: values.nama,
        email: values.email,
        nik: values.nik,
        gender: values.gender,
        tempatLahir: values.tempatLahir,
        tanggalLahir: values.tanggalLahir,
        noHandphone: values.noHandphone,
        foto: values.foto,
        status: values.status,
      };

      if (values.password !== "") {
        updateData.password = values.password;
      }

      if (isEdit) {
        dispatch(
          editUser({
            params: updateData,
            onSuccess(e) {
              toast.success("Berhasil edit user");
              dispatch(
                getUserDetail({
                  params: {
                    userId: params.userId,
                  },
                })
              );
            },
            onFailure(e) {
              if (e?.context?.nama) {
                toast.error(e?.context?.nama);
              } else {
                toast.error("Gagal edit user");
              }
            },
          })
        );
      } else {
        dispatch(
          addUser({
            params: {
              ...values,
              gambarProfil: values.foto,
            },
            onSuccess(e) {
              toast.success("Berhasil tambah user");
            },
            onFailure(e) {
              if (e?.context?.nama) {
                toast.error(e?.context?.nama);
              } else {
                toast.error("Gagal tambah user");
              }
            },
          })
        );
      }
    },
  });

  useEffect(() => {
    dispatch(
      getUserDetail({
        params: {
          userId: params.userId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Foto Profil</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Foto akan di tampilkan di dashboard akun pengguna.
              </div>
            </div>
            <div className="w-full max-w-[300px]">
              <FileSelect
                imageUrl={formHandler.values.foto}
                onChange={(value) => {
                  formHandler.setFieldValue("foto", value);
                  formHandler.setFieldTouched("foto");
                }}
                error={formHandler.touched.foto && !!formHandler.errors.foto}
                errorText={`${formHandler.errors.foto ?? ""}`}
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Akun Login</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan data nama, email dan password untuk akun login
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                label="Nama"
                propsInput={{ ...formHandler.getFieldProps("nama") }}
                error={formHandler.touched.nama && !!formHandler.errors.nama}
                errorText={formHandler.errors.nama}
              />
              <TextField
                type="email"
                label="Email"
                propsInput={{ ...formHandler.getFieldProps("email") }}
                error={formHandler.touched.email && !!formHandler.errors.email}
                errorText={formHandler.errors.email}
              />
              <TextField
                type="password"
                label="Password"
                propsInput={{ ...formHandler.getFieldProps("password") }}
                error={
                  formHandler.touched.password && !!formHandler.errors.password
                }
                errorText={formHandler.errors.password}
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Data Tambahan</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan data tempat lahir dan tanggal lahir.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <SelectField
                label="Jenis Kelamin"
                value={formHandler.values.gender}
                placeholder="Pilih Jenis kelamin"
                options={[
                  { label: "Laki - laki", value: "M" },
                  { label: "Perempuan", value: "F" },
                ]}
                onChange={(e) => {
                  formHandler.setFieldValue("gender", e?.target.value ?? "");
                  formHandler.setFieldTouched("gender");
                }}
                error={
                  formHandler.touched.gender && !!formHandler.errors.gender
                }
                errorText={formHandler.errors.gender}
              />
              <TextField
                label="NIK"
                propsInput={{ ...formHandler.getFieldProps("nik") }}
                error={formHandler.touched.nik && !!formHandler.errors.nik}
                errorText={formHandler.errors.nik}
              />
              <TextField
                label="Tempat Lahir"
                propsInput={{ ...formHandler.getFieldProps("tempatLahir") }}
                error={
                  formHandler.touched.tempatLahir &&
                  !!formHandler.errors.tempatLahir
                }
                errorText={formHandler.errors.tempatLahir}
              />
              <TextField
                type="date"
                label="Tanggal Lahir"
                propsInput={{ ...formHandler.getFieldProps("tanggalLahir") }}
                error={
                  formHandler.touched.tanggalLahir &&
                  !!formHandler.errors.tanggalLahir
                }
                errorText={formHandler.errors.tanggalLahir}
              />
              <TextField
                type="tel"
                label="Nomor Handphone"
                propsInput={{ ...formHandler.getFieldProps("noHandphone") }}
                error={
                  formHandler.touched.noHandphone &&
                  !!formHandler.errors.noHandphone
                }
                errorText={`${formHandler.errors.noHandphone ?? ""}`}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <Button
                color="failure"
                loading={isLoadingAdd || isLoadingEdit}
                disabled={isLoadingAdd || isLoadingEdit}
                onClick={() => formHandler.submitForm()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardUserInput;
