import { FC, forwardRef, useEffect, useState } from "react";
import { ISelectFieldProps } from "./SelectField.model";

const SelectField: FC<ISelectFieldProps> = ({
  required,
  className,
  value,
  options,
  onChange,
  placeholder,
  error,
  errorText,
  variant,
  label,
  size,
  padingBottom,
  propsInput,
}) => {
  const sizeObject = {
    small: "rounded p-2 placeholder:text-sm text-sm",
    medium: "rounded p-3 placeholder:text-md text-md",
    large: "rounded-xl p-4 placeholder:text-lg text-lg",
  };

  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue(value ?? "");
  }, [value]);

  const handleValueChange = (event: any) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onChange && onChange(event);
  };

  return (
    <>
      {!!label && (
        <div className="text-sm text-gray-700 font-medium mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </div>
      )}
      <div
        className={[
          "w-full",
          padingBottom != undefined ? `mb-${padingBottom}` : "mb-6",
        ].join(" ")}
      >
        <select
          required={required}
          className={[
            "w-full",
            sizeObject[size ?? "small"],
            variant === "light"
              ? "border"
              : variant === "solid"
              ? "border-0 bg-white"
              : "border border-gray-200",
            error && "!border !border-red-500 !bg-red-50",
            className,
          ].join(" ")}
          {...(propsInput !== undefined ? propsInput : {})}
          value={selectedValue}
          onChange={handleValueChange}
        >
          {placeholder && <option value="">{placeholder ?? ""}</option>}
          {options.map((e, i) => (
            <option
              key={i}
              value={e?.value}
              // selected={e?.value === selectedValue}
            >
              {e?.label}
            </option>
          ))}
        </select>
        {error && <div className="text-sm mt-2 text-red-500">{errorText}</div>}
      </div>
    </>
  );
};

export { SelectField };
