import { createReducer } from "@reduxjs/toolkit";
import {
  IUserState,
  setCategory,
  setFavorite,
  setFunding,
  setFundingDetail,
  setFundingDetailHistory,
  setFundingHistory,
  setMitra,
  setMitraDetail,
  setNotification,
  setProduct,
  setProductDetail,
  setProductDetailV1,
  setProductV1,
  setProfile,
  setTraining,
  setTrainingDetail,
  setTrainingDetailHistory,
  setTrainingHistory,
} from ".";

export const initialState: IUserState = {
  categoryList: [],
  mitraList: [],
  mitra: {
    _id: "",
    userId: "",
    namaMitra: "",
    namaBadanUsaha: "",
    links: {},
    waktuOperasional: {},
    foto: "",
    alamat: "",
    kota: "",
    kecamatan: "",
    kelurahan: "",
    npwp: "",
    nib: "",
    pengizinUsaha: "",
    berkasIzinUsaha: "",
    deskripsi: "",
    omset: 0,
    klasifikasiEkraf: [],
    status: "",
    location: {
      type: "",
      coordinates: [],
    },
    rejectReason: "",
    addressName: {
      kota: "",
      kecamatan: "",
      kelurahan: "",
      _id: "",
    },
    createdAt: "",
    kotaId: 0,
    kecamatanId: 0,
    kelurahanId: 0,
  },
  favorite: [],
  trainings: [],
  profile: {
    _id: "",
    nama: "",
    nik: "",
    tempatLahir: "",
    tanggalLahir: "",
    foto: "",
    email: "",
    noHandphone: "",
    statusVerify: "",
    gender: "",
  },
  productDetail: {
    id: "",
    mitraId: "",
    harga: 0,
    nominalSatuan: 0,
    beratSatuan: "",
    deskripsi: "",
    category: [],
    status: "",
    namaProduk: "",
    fotoProduk: [],
  },
  productListV1: [],
  trainingDetail: {
    program: {
      title: "",
      penyelenggara: "",
      waktu: "",
      alamat: "",
      kuota: 0,
      id: "",
      image: "",
    },
    available: [],
  },
  trainingHistory: [],
  trainingDetailHistory: {
    id: "",
    message: "",
    image: "",
    title: "",
    penyelenggara: "",
    waktu: "",
    alamat: "",
    kuota: 0,
    mitra: "",
    statusBooking: "",
    statusProgram: "",
    status: "",
  },
  fundings: [],
  fundingHistory: [],
  fundingDetail: {
    program: {
      id: "",
      title: "",
      penyelenggara: "",
      deadline: "",
      kriteria: "",
      persyaratan: [],
      status: "",
      banner: "",
    },
    available: [],
  },
  fundingDetailHistory: {
    id: "",
    fundingId: "",
    title: "",
    sektorUsaha: [],
    karyawan: {
      pria: 0,
      wanita: 0,
    },
    asset: 0,
    omset: 0,
    catatan: "",
    kriteria: "",
    banner: "",
    penyelenggara: "",
    deadline: "",
    persyaratan: [],
    namaMitra: "",
    nib: "",
    statusBook: "",
    statusProgram: "",
    status: "",
  },
  productDetailV1: {
    harga: 0,
    nominalSatuan: 0,
    beratSatuan: "",
    deskripsi: "",
    mitra: {
      addressName: {
        _id: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
      },
      id: "",
      namaMitra: "",
      foto: "",
      kota: 0,
      kecamatan: 0,
      kelurahan: 0,
      location: {
        type: "Point",
        coordinates: [],
      },
      phoneNumber: "",
      userId: "",
      isVerified: false,
    },
    fotoProduk: [],
    id: "",
    namaProduk: "",
    isFavorite: false,
  },
  products: [],
  notification: [],
};

const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setMitra, (state, action) => {
      state.mitraList = action.payload.payload.mitra;
    })
    .addCase(setMitraDetail, (state, action) => {
      state.mitra = action.payload.payload.mitra;
    })
    .addCase(setCategory, (state, action) => {
      state.categoryList = action.payload.payload.classifications;
    })
    .addCase(setFavorite, (state, action) => {
      state.favorite = action.payload.payload;
    })
    .addCase(setProductDetail, (state, action) => {
      state.productDetail = action.payload.payload.product;
    })
    .addCase(setProductDetailV1, (state, action) => {
      state.productDetailV1 = action.payload.payload.product;
    })
    .addCase(setTraining, (state, action) => {
      state.trainings = action.payload.payload.courses;
    })
    .addCase(setTrainingHistory, (state, action) => {
      state.trainingHistory = action.payload.payload.histories;
    })
    .addCase(setTrainingDetail, (state, action) => {
      state.trainingDetail = action.payload.payload;
    })
    .addCase(setTrainingDetailHistory, (state, action) => {
      state.trainingDetailHistory = action.payload.payload.history;
    })
    .addCase(setFunding, (state, action) => {
      state.fundings = action.payload.payload.fundings;
    })
    .addCase(setFundingHistory, (state, action) => {
      state.fundingHistory = action.payload.payload.histories;
    })
    .addCase(setFundingDetail, (state, action) => {
      state.fundingDetail = action.payload.payload;
    })
    .addCase(setFundingDetailHistory, (state, action) => {
      state.fundingDetailHistory = action.payload.payload.history;
    })
    .addCase(setProduct, (state, action) => {
      state.products = action.payload.payload.products;
    })
    .addCase(setProductV1, (state, action) => {
      state.productListV1 = action.payload.payload.products;
    })
    .addCase(setProfile, (state, action) => {
      state.profile = action.payload.payload;
    })
    .addCase(setNotification, (state, action) => {
      state.notification = action.payload.payload;
    });
});

export default userReducer;
