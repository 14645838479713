import { Icon, MainLayout } from "components";
import {
  ActionTypes,
  getConfig,
  getMitra,
  getProduct,
} from "pages/landing/redux";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { logout } from "pages/admin/components/auth/redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { getProfile, updateProfile } from "pages/users/redux";
import { toast } from "react-hot-toast";

const imageToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const DashboardProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state: RootState) => state.user.profile);

  const fileRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: any) => {
    let file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File terlalu besar");
      return;
    }
    if (!file) return;
    const loadingPromise = new Promise((resolve, reject) => {
      imageToBase64(file).then((base64) => {
        dispatch(
          updateProfile({
            params: {
              fotoProfil: base64,
            },
            onSuccess(e) {
              dispatch(getProfile());
              resolve("Berhasil mengganti Foto Profile");
              // toast.success("Berhasil mengganti Foto Profile");
            },
            onFailure(e) {
              reject("Gagal mengganti Foto Profile");
              // toast.error("Gagal mengganti Foto Profile");
            },
          })
        );
      });
    });

    toast.promise(loadingPromise, {
      loading: "Sedang Mengupload Foto Profile",
      success: "Berhasil mengganti Foto Profile",
      error: "Gagal mengganti Foto Profile",
    });
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <BottomNav />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative p-6 pb-20">
          <div className="flex flex-col items-center py-8">
            <div className="relative">
              <img
                src={profile.foto || "/assets/images/no-avatar.webp"}
                alt="avatar"
                className="w-28 h-28 rounded-full object-cover border-2 border-red-600"
              />
              <div
                className="absolute bottom-1 right-0 bg-white w-8 h-8 rounded-full border-[#CB043F] border flex items-center justify-center cursor-pointer"
                onClick={() => {
                  if (fileRef.current !== null) {
                    fileRef.current.click();
                  }
                }}
              >
                <Icon name="Camera" size="small" type="bold" color="#CB043F" />
              </div>
              <input
                type="file"
                className="hidden"
                onInput={handleFileInput}
                ref={fileRef}
              />
            </div>
            <div className="flex items-center mt-4">
              <div className="text-xl font-semibold mr-2">{profile.nama}</div>
              {profile.statusVerify === "A" && (
                <img
                  src="/assets/images/verify.svg"
                  alt="verify"
                  className="w-6"
                />
              )}
            </div>
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={() => {
              navigate(PATH.USER_PROFILE_INFO);
            }}
          >
            <div>Data Diri</div>
            <Icon name="EditSquare" size="small" />
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={() => {
              navigate(PATH.USER_PROFILE_ACCOUNT);
            }}
          >
            <div>Pengaturan Akun</div>
            <Icon name="Setting" size="small" />
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={() => {
              navigate(PATH.USER_PROFILE_CHANGE_PASSWORD);
            }}
          >
            <div>Ganti Password</div>
            <Icon name="ShieldDone" size="small" />
          </div>
          <div className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer">
            <div>Bantuan</div>
            <Icon name="Calling" size="small" />
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={() => {
              navigate(PATH.USER_NOTIFICATION);
            }}
          >
            <div>Notifikasi</div>
            <Icon name="Notification" size="small" />
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={() => {
              navigate(PATH.USER_ASPIRATION);
            }}
          >
            <div>Aspriasi</div>
            <Icon name="Message" size="small" />
          </div>
          <div
            className="flex justify-between p-4 bg-white rounded-lg mb-4 cursor-pointer"
            onClick={handleLogout}
          >
            <div>Keluar Aplikasi</div>
            <Icon name="Logout" size="small" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardProfile };
