import { MainLayout, TableComponent, TextField } from "components";
import { ActionTypes, IUser, exportUser, getUser } from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import moment from "moment";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { toast } from "react-hot-toast";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

export const DashboardUser: FC<{
  isVerify?: boolean;
}> = ({ isVerify }) => {
  const dispatch = useDispatch();

  const limit = 20;
  const [page, setPage] = useState<number>(1);

  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_USER));
  const columns: any = [
    {
      header: "Nama",
      field: "nama",
      exportAs: "Nama",
      rowRender: (row: IUser) => {
        return (
          <div className="flex items-center">
            <div className="mr-3 w-12 flex-shrink-0">
              <img
                src={row?.foto?.url ?? "/assets/images/no-avatar.webp"}
                alt="avatar"
                className="w-12 h-12 rounded-full object-cover"
              />
            </div>
            <div>
              <div className="font-medium text-gray-800">{row.nama}</div>
              <div className="text-xs">{row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      header: "NIK",
      field: "nik",
    },
    {
      header: "Status",
      field: "status",
      exportRender: (item: any) =>
        item.statusVerify === "A" ? "Active" : "Waiting",
      rowRender: (row: IUser) => {
        return (
          <div className="whitespace-nowrap">
            {row.statusVerify === "A" ? "✓ Active" : "Waiting"}
          </div>
        );
      },
    },
  ];
  const [search, setSearch] = useState<string>("");

  const users = useSelector((state: RootState) => state.admin.users);
  const meta = useSelector((state: RootState) => state.admin.userMeta);

  useEffect(() => {
    dispatch(
      getUser({
        params: {
          search: search,
          limit: limit,
          page: page,
          status: isVerify ? "A" : "S",
        },
      })
    );
  }, [isVerify, limit, page, search]);

  const handleSearch = useCallback((e: any) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  }, []);

  const handleExport = useCallback(() => {
    dispatch(
      exportUser({
        params: {},
        onSuccess(e: IExportExcel) {
          exportBase64ToExcel(e.payload.base64, e.payload.fileName);
          toast.success("Berhasil Export Excel");
        },
        onFailure(e) {
          toast.error("Gagal Export Excel");
        },
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <TableComponent
          withSearch={false}
          header={
            <div className="flex p-4 w-full justify-end">
              <div className="w-full max-w-xs">
                <TextField placeholder="Search" onChange={handleSearch} />
              </div>
            </div>
          }
          columns={columns}
          data={users}
          isLoading={isLoading}
          pagination={{
            currentPage: meta.pageNumber,
            totalPages: meta.pageTotal,
            totalCount: meta.count,
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              setPage((prev) => prev + 1);
            },
            onChange(page) {
              setPage(page);
            },
          }}
          isDownload
          onExport={handleExport}
        />
      </>
    </MainLayout>
  );
};

export default DashboardUser;
