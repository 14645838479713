import { Icon } from "components";
import { PATH } from "config";
import moment from "moment";
import { IFundings } from "pages/users/redux";
import { FC } from "react";
import { useNavigate } from "react-router";

const STATUS: any = {
  A: {
    name: "Diterima",
    color: "bg-[#CB043F]",
  },
  R: {
    name: "Ditolak",
    color: "bg-[#3D3D3D]",
  },
  S: {
    name: "Diproses",
    color: "bg-[#F2C94C]",
  },
};

const Status = (status: string) => {
  const value = STATUS[status];

  return (
    <div
      className={
        "text-white text-sm py-1.5 px-5 text-xs rounded w-auto " + value.color
      }
    >
      {value.name}
    </div>
  );
};

const CardFunding: FC<{
  funding: any;
  status?: string;
  history?: boolean;
}> = ({ funding, status, history }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (history) {
      navigate(`${PATH.USER_FUNDING}/${funding.id}/history`);
    } else {
      navigate(`${PATH.USER_FUNDING}/${funding.id}`);
    }
  };

  return (
    <>
      <div
        className="bg-white p-4 cursor-pointer relative"
        onClick={handleNavigate}
      >
        <div className="relative">
          <img
            src={funding.banner ?? "/assets/img/avatar-person.svg"}
            alt="foto"
            className="w-full h-48 object-cover rounded-lg"
          />
          {status && (
            <div className="absolute top-4 right-4">
              <div>{Status(status ?? "S")}</div>
            </div>
          )}
        </div>
        <div className="mt-2 text-[#3D3D3D] font-medium">{funding.title}</div>
        <div className="flex iems-center mt-2 gap-3">
          <div>
            <Icon name="Calendar" type="bold" size="small" color="#3D3D3D" />
          </div>
          <span className="text-sm my-auto">
            Deadline : {moment(funding.deadline).format("DD MMMM YYYY")}
          </span>
        </div>
      </div>
    </>
  );
};
export { CardFunding };
