import { Icon, MainLayout, TableComponent } from "components";
import { ActionTypes, deleteArticle, getArticle } from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { PATH } from "config";
import { useNavigate } from "react-router-dom";
import DialogDelete from "components/DialogDelete/DialogDelete";
import { toast } from "react-hot-toast";

export const DashboardArticle: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_ARTICLE));
  const columns: any = [
    {
      header: "Thumbnail",
      field: "preview",
      rowRender: (row: any) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row.preview.image ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Judul",
      field: "title",
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: any) => {
        return (
          <div className="flex">
            <span
              className="px-2 cursor-pointer"
              onClick={() =>
                navigate(`${PATH.DASHBOARD_ARTICLE}/edit/${row.id}`)
              }
            >
              <Icon name="Edit" />
            </span>
            <span
              className="px-2 cursor-pointer"
              onClick={() => setDialogDelete({ show: true, id: row.id })}
            >
              <Icon name="Delete" />
            </span>
          </div>
        );
      },
    },
  ];
  const articles = useSelector((state: RootState) => state.admin.articles);

  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [dialogDelete, setDialogDelete] = useState<{
    show: boolean;
    id: string;
  }>({
    show: false,
    id: "",
  });

  const hadleDelete = useCallback(() => {
    dispatch(
      deleteArticle({
        params: {
          articleId: dialogDelete.id,
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus artikel");
          setDialogDelete({ show: false, id: "" });
        },
        onFailure(e) {
          toast.error("Gagal menghapus artikel");
        },
      })
    );
  }, [dialogDelete]);

  useEffect(() => {
    dispatch(
      getArticle({
        params: {},
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <DialogDelete
          show={dialogDelete.show}
          onClose={() => {
            setDialogDelete({ show: false, id: "" });
          }}
          onConfirm={hadleDelete}
        />
        <TableComponent
          columns={columns}
          data={articles}
          isLoading={isLoading}
          pagination={{
            currentPage: page,
            totalPages: Math.ceil(articles.length / limit),
            totalCount: articles.length,
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              if (page < Math.ceil(articles.length / limit)) {
                setPage((prev) => prev + 1);
              }
            },
          }}
          // isDownload
          localPagination
        />
      </>
    </MainLayout>
  );
};

export default DashboardArticle;
