import React, { useEffect, useState } from "react";
import { Table } from "flowbite-react";
import Skeleton from "react-loading-skeleton";
import { IPagination, Pagination, TextField } from "..";
// @ts-ignore
import * as XLSX from "xlsx/xlsx.mjs";

export interface ITableColumn {
  header: string;
  field: string;
  rowRender?: (row: any, index: number) => React.ReactNode;
  exportRender?: (row: any, index: number) => any;
  exportAs?: string;
  isShow?: boolean;
}

export interface ITableRow {
  [key: string]: any;
}

export interface ITableProps {
  columns: ITableColumn[];
  data: ITableRow[];
  isLoading: boolean;
  isWithCheckbox?: boolean;
  pagination?: IPagination;
  isDownload?: boolean;
  localPagination?: boolean;
  limit?: number;
  downloadName?: string;
  header?: any;
  withSearch?: boolean;
  onExport?: () => void;
}

const TableComponent: React.FC<ITableProps> = ({
  withSearch = true,
  header,
  columns,
  data,
  isLoading,
  isWithCheckbox = false,
  pagination = {
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
    onPrev: () => {},
    onNext: () => {},
    onChange: (page: any) => {},
  },
  limit,
  isDownload,
  localPagination,
  downloadName,
  onExport,
}) => {
  const [generateExcel, setGenerateExcel] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const exportToExcel = (data: any[], filename: string) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  };

  const paginateData = (data: any[], limit: number, pagination: any) => {
    const { currentPage, totalPages, totalCount, onPrev, onNext } = pagination;

    // Calculate the starting index and ending index of the data to be displayed
    const startIndex = (currentPage - 1) * limit;
    const endIndex = Math.min(startIndex + limit, totalCount);

    // Get the current page data
    const rowData = data.slice(startIndex, endIndex);

    // Update the pagination information
    const updatedPagination = {
      ...pagination,
      onPrev: () => {
        if (currentPage > 1) {
          onPrev();
        }
      },
      onNext: () => {
        if (currentPage < totalPages) {
          onNext();
        }
      },
    };

    return { rowData, updatedPagination };
  };

  const [row, setRow] = useState<any[]>([]);
  const [paginations, setPaginations] = useState({
    currentPage: 0,
    totalPages: 0,
    totalCount: 0,
    onPrev: () => {},
    onNext: () => {},
  });

  useEffect(() => {
    if (withSearch) {
      setPaginations(pagination);
      const filter = data.filter(
        (e) =>
          Object.values(e).findIndex((f) =>
            typeof f === "string"
              ? f.toLowerCase().indexOf(search.toLowerCase()) > -1
              : false
          ) > -1
      );
      setRow(filter);
    } else {
      setRow(data);
    }
  }, [pagination, data, search]);

  useEffect(() => {
    let exportData: any[] = [];
    data.forEach((row, rowIndex) => {
      let data: any = {};
      columns.map((column: any, columnIndex) => {
        if (column.exportAs !== undefined) {
          data[column.exportAs] = column.exportRender
            ? column.exportRender(row, rowIndex)
            : row[column.field];
        }
      });
      exportData.push(data);
    });
    setGenerateExcel(exportData);
  }, [data]);

  useEffect(() => {
    if (localPagination) {
      const { rowData, updatedPagination } = paginateData(
        data,
        limit ?? 20,
        pagination
      );
      setRow(rowData);
      setPaginations(updatedPagination);
    } else {
      const { updatedPagination } = paginateData(data, limit ?? 20, pagination);
      setPaginations(updatedPagination);
    }
  }, [localPagination, pagination, limit, data]);

  return (
    <>
      <div className="flex flex-col shadow bg-white rounded-lg">
        <div
          className={[
            "flex p-4 w-full",
            header === undefined ? "justify-end" : "justify-between",
          ].join(" ")}
        >
          {header}
          {withSearch && (
            <TextField
              placeholder="Search"
              parentClassName="max-w-xs"
              onChange={(e) => setSearch(e.target.value)}
              propsInput={{ value: search }}
              padingBottom={0}
            />
          )}
        </div>
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow">
              <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                <Table.Head className="bg-gray-100 dark:bg-gray-700">
                  {isWithCheckbox && (
                    <Table.HeadCell>
                      <label htmlFor="select-all" className="sr-only">
                        Select all
                      </label>
                      <input
                        type="checkbox"
                        id="select-all"
                        name="select-all"
                      />
                    </Table.HeadCell>
                  )}
                  {columns.map(
                    (column, index) =>
                      column.isShow !== false && (
                        <Table.HeadCell key={index}>
                          {column.header}
                        </Table.HeadCell>
                      )
                  )}
                </Table.Head>
                <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-800">
                  {isLoading ? (
                    [1, 2, 3, 4].map((_, index) => (
                      <Table.Row key={index}>
                        <Table.Cell
                          colSpan={
                            isWithCheckbox ? columns.length + 1 : columns.length
                          }
                        >
                          <Skeleton
                            containerClassName="flex space-x-4"
                            count={columns.length}
                            height={40}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : row.length === 0 ? (
                    <Table.Row>
                      <Table.Cell
                        colSpan={
                          isWithCheckbox ? columns.length + 1 : columns.length
                        }
                      >
                        <div className="h-96 flex flex-col justify-center items-center p-8">
                          <img
                            src="/assets/images/bloom-a-man-looks-at-a-blank-sheet-of-paper-in-puzzlement.png"
                            alt="empty"
                          />
                          <div className="text-xl font-medium text-gray-800">
                            Tidak Menemukan Apapun :(
                          </div>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    row.map((row, rowIndex) => (
                      <Table.Row
                        key={rowIndex}
                        className="hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        {isWithCheckbox && (
                          <Table.Cell className="w-4 p-4">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                aria-describedby={`checkbox-${rowIndex}`}
                                id={`checkbox-${rowIndex}`}
                              />
                              <label
                                htmlFor={`checkbox-${rowIndex}`}
                                className="sr-only"
                              >
                                checkbox
                              </label>
                            </div>
                          </Table.Cell>
                        )}
                        {columns.map(
                          (column, columnIndex) =>
                            column.isShow !== false && (
                              <Table.Cell key={columnIndex}>
                                {column.rowRender
                                  ? column.rowRender(row, rowIndex)
                                  : row[column.field]}
                              </Table.Cell>
                            )
                        )}
                      </Table.Row>
                    ))
                  )}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
        {pagination && (
          <Pagination
            currentPage={paginations.currentPage}
            totalPages={paginations.totalPages}
            totalCount={paginations.totalCount}
            onPrev={paginations.onPrev}
            onNext={paginations.onNext}
            onChange={(currentPage) => {
              pagination.onChange && pagination.onChange(currentPage ?? 1);
              const _pagination = {
                ...pagination,
                currentPage: currentPage,
              };

              if (localPagination) {
                const { rowData, updatedPagination } = paginateData(
                  data,
                  limit ?? 20,
                  _pagination
                );
                setRow(rowData);
                setPaginations(updatedPagination);
              }
            }}
            isDownload={isDownload}
            onExport={() => {
              if (onExport !== undefined) {
                onExport();
              } else {
                var d = new Date();
                if (generateExcel.length > 0) {
                  exportToExcel(
                    generateExcel,
                    `${downloadName ?? ""}-${d.getTime()}.xlsx`
                  );
                }
              }
            }}
          />
        )}
      </div>
    </>
  );
};

export { TableComponent };
