import axios, { AxiosResponse } from "axios";

type Upload = {
  id: string;
  url: string;
};

type UploadResponse = {
  payload: Upload;
};

const uploadFile = async (file: File): Promise<Upload> => {
  try {
    const formData = new FormData();
    formData.append("image", file);

    const baseURL = process.env.REACT_APP_API_URL || "";

    const response: AxiosResponse<UploadResponse> = await axios.post(
      baseURL + "article/management/preview/image",
      formData,
      {
        headers: {
          Authorization: localStorage.getItem(
            process.env.REACT_APP_TOKEN_NAME ?? ""
          ),
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data.payload;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw new Error("Failed to upload file");
  }
};

export { uploadFile };
