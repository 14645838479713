import { Icon, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunding, getFundingHistory } from "pages/users/redux";
import { RootState } from "setup";
import { CardFunding } from "./CardFunding";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";

const DashboardFunding: FC<{
  history?: boolean;
}> = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fundingList = useSelector((state: RootState) => state.user.fundings);
  const fundingHistory = useSelector(
    (state: RootState) => state.user.fundingHistory
  );

  useEffect(() => {
    if (history) {
      dispatch(getFundingHistory());
    } else {
      dispatch(getFunding());
    }
  }, [history]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Bantuan Pendanaan" />
          {!history && (
            <div className="p-6 pb-0 flex items-center justify-between">
              <div className="font-semibold">List Pendanaan</div>
              <div
                className="font-semibold text-[#CB043F] flex items-center cursor-pointer"
                onClick={() => {
                  navigate(PATH.USER_FUNDING_HISTORY);
                }}
              >
                <Icon
                  name="Bookmark"
                  type="bold"
                  color="#CB043F"
                  size="small"
                />
                <div className="ml-2">Riwayat</div>
              </div>
            </div>
          )}
          {history ? (
            <div className="p-4">
              {fundingHistory.map((item, index) => (
                <div className="p-2" key={index}>
                  <CardFunding
                    funding={item}
                    status={item.statusBooking}
                    history
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="p-4">
              {fundingList.map((item, index) => (
                <div className="p-2" key={index}>
                  <CardFunding funding={item} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardFunding };
