import { createReducer } from "@reduxjs/toolkit";
import {
  IAdminState,
  setAdmin,
  setAdminDetail,
  setAnalytics,
  setAnalyticsV1,
  setArticle,
  setArticleDetail,
  setAspiration,
  setCategory,
  setCategoryDetail,
  setCity,
  setDistrict,
  setFunding,
  setFundingDetail,
  setGeneralData,
  setLogs,
  setMitra,
  setMitraDetail,
  setMitraMaps,
  setProductDetail,
  setProfileInfo,
  setTraining,
  setTrainingDetail,
  setUser,
  setUserDetail,
  setVillage,
} from "./";

export const initialState: IAdminState = {
  users: [],
  category: [],
  mitra: [],
  mitraMaps: [],
  course: [],
  fundings: [],
  aspiration: [],
  city: [],
  district: [],
  village: [],
  articles: [],
  mitraMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
  },
  userMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
  },
  courseMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
  },
  fundingMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
  },
  categoryDetail: {
    name: "",
    _id: "",
  },
  analyticsv1: {
    mitra: [],
    omset: [],
    products: [],
    summary: {
      mitra: 0,
      omset: 0,
      produk: 0,
      user: 0,
      nib: {
        exist: 0,
        nonExist: 0,
      },
    },
    meta: {
      tahun: 0,
    },
    role: "",
  },
  analytics: {
    mitra: [],
    omset: [],
    produk: [],
    summary: {
      mitra: 0,
      omset: 0,
      produk: 0,
      user: 0,
    },
  },
  courseDetail: {
    _id: "",
    title: "",
    penyelenggara: "",
    catatanPenyelenggara: "",
    deadline: "",
    waktu: "",
    alamat: "",
    image: "",
    kuota: 0,
    status: "",
    createdAt: "",
    updatedAt: "",
    book: [],
  },
  fundingDetail: {
    _id: "",
    title: "",
    banner: "",
    penyelenggara: "",
    deadline: "",
    kriteria: "",
    persyaratan: [],
    status: "",
    createdAt: "",
    updatedAt: "",
    booked: [],
  },
  userDetail: {
    _id: "",
    nama: "",
    nik: "",
    tempatLahir: "",
    tanggalLahir: "",
    email: "",
    foto: "",
    noHandphone: "",
    statusVerify: "",
    gender: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
  },
  mitraDetail: {
    _id: "",
    userId: "",
    namaMitra: "",
    namaBadanUsaha: "",
    links: {},
    waktuOperasional: {},
    foto: "",
    alamat: "",
    kota: "",
    kecamatan: "",
    kelurahan: "",
    npwp: "",
    nib: "",
    berkasIzinUsaha: "",
    deskripsi: "",
    omset: 0,
    klasifikasiEkraf: [],
    status: "",
    location: {
      lat: 0,
      long: 0,
    },
    rejectReason: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
    pengizinUsaha: "",
    addressName: {},
    user: "",
    products: [],
    addressId: {},
  },
  articleDetail: {
    title: "",
    content: "",
    preview: {
      image: "",
    },
    id: "",
  },
  productDetail: {
    _id: "",
    mitraId: "",
    namaProduct: "",
    fotoProduct: [],
    harga: 0,
    nominalSatuan: 0,
    beratSatuan: "",
    deskripsi: "",
    category: [],
    status: "",
    createdAt: "",
    updatedAt: "",
    __v: 0,
    mitra: {
      userId: "",
    },
  },
  profileInfo: {
    email: "",
    role: "",
    regencies: [],
  },
  generalData: {
    hero: "",
    heroLink: "",
    banners: [],
    contacts: [],
  },
  admin: [],
  adminDetail: {
    id: "",
    role: "",
    regionals: [],
    email: "",
  },
  logs: [],
};

const adminReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogs, (state, action) => {
      state.logs = action.payload.payload.logs;
    })
    .addCase(setAnalytics, (state, action) => {
      state.analytics = action.payload.payload;
    })
    .addCase(setAnalyticsV1, (state, action) => {
      state.analyticsv1 = action.payload.payload;
    })
    .addCase(setUser, (state, action) => {
      state.users = action.payload.payload.users;
      state.userMeta = action.payload.payload.metadata;
    })
    .addCase(setUserDetail, (state, action) => {
      state.userDetail = action.payload.payload.user;
    })
    .addCase(setCategory, (state, action) => {
      state.category = action.payload.payload.classifications;
    })
    .addCase(setCategoryDetail, (state, action) => {
      state.categoryDetail = action.payload.payload.classification;
    })
    .addCase(setMitra, (state, action) => {
      state.mitra = action.payload.payload.mitra;
      state.mitraMeta = action.payload.payload.metadata;
    })
    .addCase(setMitraMaps, (state, action) => {
      state.mitraMaps = action.payload.payload.results;
    })
    .addCase(setMitraDetail, (state, action) => {
      state.mitraDetail = action.payload.payload;
    })
    .addCase(setTraining, (state, action) => {
      state.course = action.payload.payload.courses;
      state.courseMeta = action.payload.payload.metadata;
    })
    .addCase(setFunding, (state, action) => {
      state.fundings = action.payload.payload.fundings;
      state.fundingMeta = action.payload.payload.metadata;
    })
    .addCase(setFundingDetail, (state, action) => {
      state.fundingDetail = action.payload.payload.funding;
    })
    .addCase(setAspiration, (state, action) => {
      state.aspiration = action.payload.payload.aspiration;
    })
    .addCase(setTrainingDetail, (state, action) => {
      state.courseDetail = action.payload.payload.course;
    })
    .addCase(setCity, (state, action) => {
      state.city = action.payload.payload.regency;
    })
    .addCase(setDistrict, (state, action) => {
      state.district = action.payload.payload.district;
    })
    .addCase(setVillage, (state, action) => {
      state.village = action.payload.payload.villages;
    })
    .addCase(setArticle, (state, action) => {
      state.articles = action.payload.payload.articles;
    })
    .addCase(setArticleDetail, (state, action) => {
      state.articleDetail = action.payload.payload.article;
    })
    .addCase(setProductDetail, (state, action) => {
      state.productDetail = action.payload.payload.product;
    })
    .addCase(setProfileInfo, (state, action) => {
      state.profileInfo = action.payload.payload;
    })
    .addCase(setGeneralData, (state, action) => {
      state.generalData = action.payload.payload.incoming;
    })
    .addCase(setAdmin, (state, action) => {
      state.admin = action.payload.payload.accounts;
    })
    .addCase(setAdminDetail, (state, action) => {
      state.adminDetail = action.payload.payload.admin;
    });
});

export default adminReducer;
