import { useParams } from "react-router-dom";
import { Header } from "../landing-page/componets";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  IProduct,
  getMitraDetail,
  getMitraDetailV1,
  getProduct,
} from "pages/landing/redux";
import { getInfo } from "pages/admin/components/auth/redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { RootState } from "setup";
import { Icon } from "components";
import { convertToWhatsAppURL } from "utils/convertWhatsappUrl";
import { CardProduct } from "./CardProduct";

const DetailMitra = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    mitraId: string;
  }>();

  const loading = useSelector(createLoadingSelector(ActionTypes.GET_PRODUCT));

  const { mitraDetailV1, products } = useSelector(
    (state: RootState) => state.landing
  );

  useEffect(() => {
    dispatch(
      getMitraDetailV1({
        params: {
          mitraId: params.mitraId,
        },
      })
    );
    dispatch(
      getProduct({
        params: {
          nama: "",
          limit: 12,
          mitraId: params.mitraId,
        },
      })
    );
    dispatch(getInfo());
  }, [params]);

  return (
    <div className="bg-[#F6F7FB] min-h-screen">
      <Header
        handleScroll={() => {}}
        handleClick={() => {}}
        setOpenSide={() => {}}
      />
      <div className="mx-auto max-w-5xl pt-24 p-6">
        <div className="bg-white rounded-lg p-6 md:p-8">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <img
                  src={
                    mitraDetailV1.foto.url ??
                    "/assets/images/placeholder-image.png"
                  }
                  alt="avatar"
                  className="w-16 h-16 rounded-full object-cover"
                />
                <div className="ml-4 flex-1">
                  <div className="flex">
                    <div className="text-lg md:text-xl font-semibold text-[#3D3D3D]">
                      {mitraDetailV1.namaMitra}
                    </div>
                    {mitraDetailV1.isVerified && (
                      <img
                        src="/assets/icon/verify.svg"
                        alt="verift"
                        className="w-4 ml-2"
                      />
                    )}
                  </div>
                  <div className="text-sm md:text-md text-[#3D3D3D]">
                    {mitraDetailV1.addressName.kelurahan},{" "}
                    {mitraDetailV1.addressName.kecamatan},{" "}
                    {mitraDetailV1.addressName.kota}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-wrap md:justify-end mt-4 md:mt-0">
              <div className="pb-2">
                <a
                  rel="noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&query=${mitraDetailV1.location.coordinates[1]}%2C${mitraDetailV1.location.coordinates[0]}`}
                  target="_blank"
                  className="whitespace-nowrap items-center border border-[#CB043F] text-[#CB043F] py-4 md:py-2 px-2 md:px-4 rounded flex"
                >
                  <Icon
                    name="Send"
                    type="bold"
                    size="medium"
                    color="#CB043F"
                    className="h-5 md:h-8"
                  />
                  <span className="ml-0 md:ml-2 text-sm md:text-md">
                    Menuju Lokasi
                  </span>
                </a>
              </div>
              <div className="pb-2">
                <a
                  rel="noreferrer"
                  href={convertToWhatsAppURL(
                    mitraDetailV1.user.noHandphone || ""
                  )}
                  target="_blank"
                  className="whitespace-nowrap items-center ml-1 md:ml-3 bg-[#CB043F] text-white py-4 md:py-2 px-2 md:px-4 rounded flex border border-[#CB043F]"
                >
                  <Icon
                    name="Chat"
                    type="bold"
                    size="medium"
                    color="#FFFFFF"
                    className="h-5 md:h-8"
                  />
                  <span className="ml-0 md:ml-2 text-sm md:text-md">
                    Chat Penjual
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-md font-semibold text-gray-800">
              Link Marketplace
            </div>
            <div className="flex flex-wrap">
              {mitraDetailV1.links !== undefined
                ? Object.keys(mitraDetailV1.links)?.map((item, index) => {
                    return mitraDetailV1.links[item] !== null ? (
                      <div className="p-2" key={index}>
                        <img
                          onClick={() => {
                            if (mitraDetailV1.links[item] !== "") {
                              window.location.href = mitraDetailV1.links[item];
                            }
                          }}
                          src={`/assets/marketplace/${item}.png`}
                          alt="marketplace"
                          className="w-12 cursor-pointer"
                        />
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-5xl mt-6">
        <div className="text-xl font-bold text-gray-800 px-6 md:px-4">
          Produk Mitra
        </div>
        <div className="flex flex-wrap">
          {!loading
            ? products?.map((product: IProduct, index) => (
                <div className="w-1/2 md:w-1/3 p-4" key={index}>
                  <CardProduct product={product} />
                </div>
              ))
            : [1, 2, 3, 4, 5, 6].map((e, i) => (
                <div className="w-1/2 md:w-1/3 p-4" key={i}>
                  <div className="bg-slate-200 animate-pulse w-full h-48 md:h-72 rounded"></div>
                  <div className="bg-slate-200 animate-pulse w-full h-6 mt-2 rounded"></div>
                  <div className="bg-slate-200 animate-pulse w-1/2 h-6 mt-2 rounded"></div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
export { DetailMitra };
