import { takeLatest } from "redux-saga/effects";
import sagaRequest from "setup/saga/sagaRequest";
import {
  ActionTypes,
  IInputPayload,
  IInputPayloadCallback,
} from "./admin.model";
import { IActionWithPayload } from "setup/redux/redux.model";
import {
  addAdmin,
  addArticle,
  addCategory,
  addFunding,
  addMitra,
  addTraining,
  addUser,
  approveFunding,
  approveMitra,
  approveTraining,
  approveUser,
  changePassword,
  deleteAdmin,
  deleteArticle,
  deleteCategory,
  deleteSlider,
  deleteUser,
  editArticle,
  editCategory,
  editFunding,
  editMitra,
  editProduct,
  editProfile,
  editTraining,
  editUser,
  exportFunding,
  exportMitra,
  exportTraining,
  exportUser,
  getAdmin,
  getAdminDetail,
  getAnalytics,
  getAnalyticsV1,
  getArticle,
  getArticleDetail,
  getAspiration,
  getCategory,
  getCategoryDetail,
  getCity,
  getDistrict,
  getFunding,
  getFundingDetail,
  getGeneralData,
  getLogs,
  getMitra,
  getMitraDetail,
  getMitraMaps,
  getProductDetail,
  getProfileInfo,
  getTraining,
  getTrainingDetail,
  getUser,
  getUserDetail,
  getVillage,
  sendNotifBroadcast,
  sendNotifFunding,
  sendNotifTraining,
  sendNotifUser,
  updateAdmin,
  updateBanner,
  uploadSlider,
} from "./admin.action";

function* getLogsSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/activity/logs`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_LOGS,
  });
}

const prefixAnalytics = "/dashboard";
function* getAnalyticsSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAnalytics}/admin/analytics`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_ANALYTICS,
  });
}
function* getAnalyticsV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAnalytics}/v1/management/analytics`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_ANALYTICS_V1,
  });
}

const prefixUser = "/user";
function* getUserSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_USER,
  });
}
function* getUserDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/details/${action.payload.params.userId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_USER_DETAIL,
  });
}
function* addUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/register`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.ADD_USER,
  });
}
function* editUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/update-verify-status`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EDIT_USER,
  });
}
function* deleteUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/update-verify-status`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.DELETE_USER,
  });
}
function* approveUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/update-verify-status`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.APPROVE_USER,
  });
}
function* exportUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixUser}/admin/export`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EXPORT_USER,
  });
}

const prefixCategory = "/classification";
function* getCategorySaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCategory}/v1/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_CATEGORY,
  });
}
function* getCategoryDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCategory}/admin/details/${action.payload.params.id}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_CATEGORY_DETAIL,
  });
}
function* addCategorySaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCategory}/admin/create`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_CATEGORY,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* editCategorySaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCategory}/admin/edit`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.EDIT_CATEGORY,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* deleteCategorySaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCategory}/admin/delete`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.DELETE_CATEGORY,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}

const prefixMitra = "/mitra";
function* getMitraSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_MITRA,
  });
}
function* getMitraMapsSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/map`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_MITRA_MAPS,
  });
}
function* getMitraDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/details/${action.payload.params.id}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_MITRA_DETAIL,
  });
}
function* addMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/create`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.ADD_MITRA,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* editMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.EDIT_MITRA,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* approveMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.APPROVE_MITRA,
  });
}
function* exportMitraSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/admin/export`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.APPROVE_MITRA,
  });
}

// product
const prefixProduct = "/product";
function* getProductDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/admin/details/${action.payload.params.productId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PRODUCT_DETAIL,
  });
}
function* editProductSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/admin/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EDIT_PRODUCT,
  });
}

const prefixCourse = "/course";
function* getTrainingSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_TRAINING,
  });
}
function* getTrainingDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/details`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_TRAINING_DETAIL,
  });
}
function* addTrainingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/create-program`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.ADD_TRAINING,
  });
}
function* editTrainingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/edit`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EDIT_TRAINING,
  });
}
function* approveTrainingSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/status-booking`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.APPROVE_TRAINING,
  });
}
function* exportTrainingSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/export/${action.payload.params.trainingId}`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EXPORT_TRAINING,
  });
}

const prefixFunding = "/funding";
function* getFundingSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/list`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_FUNDING,
  });
}
function* addFundingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/new`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.ADD_FUNDING,
  });
}
function* editFundingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/edit/${action.payload.params.fundingId}`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EDIT_FUNDING,
  });
}
function* getFundingDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/details/${action.payload.params.fundingId}`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_FUNDING_DETAIL,
  });
}
function* approveFundingSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/status-booking`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.APPROVE_FUNDING,
  });
}
function* exportFundingSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/export/${action.payload.params.fundingId}`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EXPORT_FUNDING,
  });
}

const prefixAspiration = "/aspiration";
function* getAspirationSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAspiration}/admin`,
      method: "GET",
    },
    actionType: ActionTypes.GET_ASPIRATION,
  });
}

const prefixAddress = "/address";
function* getCitySaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAddress}/v1/regency?provinceId=62`,
      method: "GET",
    },
    actionType: ActionTypes.GET_CITY,
  });
}
function* getDistrictSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAddress}/v1/district`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_DISTRICT,
  });
}
function* getVillageSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAddress}/v1/village`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_VILLAGE,
  });
}

// artikel
const prefixArticle = "/article";
function* getArticleSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixArticle}/management`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_ARTICLE,
  });
}
function* getArticleDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixArticle}/management/${action.payload.params.articleId}`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_ARTICLE_DETAIL,
  });
}
function* addArticleSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixArticle}/management/record`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.ADD_ARTICLE,
  });
}
function* editArticleSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixArticle}/management/update/${action.payload.params.articleId}`,
      method: "PUT",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.EDIT_ARTICLE,
  });
}
function* deleteArticleSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixArticle}/management/remove/${action.payload.params.articleId}`,
      method: "DELETE",
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.DELETE_ARTICLE,
  });
}
const prefixAdmin = "/admin";
function* getProfileInfoSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdmin}/general/data`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PROFILE_INFO,
  });
}
function* editProfileSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdmin}/general/update`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SETTING_EDIT_PROFILE,
  });
}
function* changePasswordSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdmin}/general/change-password`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SETTING_CHANGE_PASSWORD,
  });
}

const prefixDashboard = "/dashboard";
function* updateBannerSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixDashboard}/admin/edit-hero`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.UPDATE_BANNER,
  });
}
function* getGeneralDataSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixDashboard}/general/data`,
      method: "GET",
    },
    actionType: ActionTypes.GET_GENERAL_DATA,
  });
}
function* uploadSliderSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixDashboard}/admin/add-banner`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.UPLOAD_SLIDER,
  });
}
function* deleteSliderSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixDashboard}/admin/remove-banner/`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.DELETE_SLIDER,
  });
}
const prefixAdminV1 = "/admin/v1";
function* getAdminSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdminV1}/management/list`,
      method: "GET",
    },
    actionType: ActionTypes.GET_ADMIN_LIST,
  });
}
function* getAdminDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdminV1}/management/details/${action.payload.params.accountId}`,
      method: "GET",
      data: action.payload.params ?? {},
    },
    actionType: ActionTypes.GET_ADMIN_DETAIL,
  });
}
function* addAdminSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdminV1}/management/record`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.ADD_ADMIN,
  });
}
function* updateAdminSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdminV1}/management/update/${action.payload.params.accountId}`,
      method: "PUT",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.UPDATE_ADMIN,
  });
}
function* deleteAdminSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixAdminV1}/management/remove/${action.payload.params.id}`,
      method: "DELETE",
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.DELETE_ADMIN,
  });
}
const prefixNotification = "/notification/admin";
function* sendNotifUserSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixNotification}/send`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SEND_NOTIFICATION_USER,
  });
}
function* sendNotifBroadcastSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixNotification}/send-broadcast`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SEND_NOTIFICATION_BROADCAST,
  });
}
function* sendNotifTrainingSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixCourse}/admin/send-notification`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SEND_NOTIFICATION_TRAINING,
  });
}
function* sendNotifFundingSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixFunding}/admin/send-notification`,
      method: "POST",
      data: action.payload.params ?? {},
    },
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
    actionType: ActionTypes.SEND_NOTIFICATION_FUNDING,
  });
}

export default function* adminSaga() {
  yield takeLatest(getLogs.type, getLogsSaga);
  yield takeLatest(getAnalytics.type, getAnalyticsSaga);
  yield takeLatest(getAnalyticsV1.type, getAnalyticsV1Saga);
  yield takeLatest(getUser.type, getUserSaga);
  yield takeLatest(getUserDetail.type, getUserDetailSaga);
  yield takeLatest(addUser.type, addUserSaga);
  yield takeLatest(editUser.type, editUserSaga);
  yield takeLatest(deleteUser.type, deleteUserSaga);
  yield takeLatest(approveUser.type, approveUserSaga);
  yield takeLatest(exportUser.type, exportUserSaga);
  yield takeLatest(getCategory.type, getCategorySaga);
  yield takeLatest(getCategoryDetail.type, getCategoryDetailSaga);
  yield takeLatest(addCategory.type, addCategorySaga);
  yield takeLatest(editCategory.type, editCategorySaga);
  yield takeLatest(deleteCategory.type, deleteCategorySaga);
  yield takeLatest(getMitra.type, getMitraSaga);
  yield takeLatest(getMitraMaps.type, getMitraMapsSaga);
  yield takeLatest(getMitraDetail.type, getMitraDetailSaga);
  yield takeLatest(addMitra.type, addMitraSaga);
  yield takeLatest(editMitra.type, editMitraSaga);
  yield takeLatest(approveMitra.type, approveMitraSaga);
  yield takeLatest(exportMitra.type, exportMitraSaga);
  yield takeLatest(getProductDetail.type, getProductDetailSaga);
  yield takeLatest(editProduct.type, editProductSaga);
  yield takeLatest(getTraining.type, getTrainingSaga);
  yield takeLatest(getTrainingDetail.type, getTrainingDetailSaga);
  yield takeLatest(addTraining.type, addTrainingSaga);
  yield takeLatest(editTraining.type, editTrainingSaga);
  yield takeLatest(approveTraining.type, approveTrainingSaga);
  yield takeLatest(exportTraining.type, exportTrainingSaga);
  yield takeLatest(getFunding.type, getFundingSaga);
  yield takeLatest(getFundingDetail.type, getFundingDetailSaga);
  yield takeLatest(addFunding.type, addFundingSaga);
  yield takeLatest(editFunding.type, editFundingSaga);
  yield takeLatest(approveFunding.type, approveFundingSaga);
  yield takeLatest(exportFunding.type, exportFundingSaga);
  yield takeLatest(getAspiration.type, getAspirationSaga);
  yield takeLatest(getCity.type, getCitySaga);
  yield takeLatest(getDistrict.type, getDistrictSaga);
  yield takeLatest(getVillage.type, getVillageSaga);
  yield takeLatest(getArticle.type, getArticleSaga);
  yield takeLatest(getArticleDetail.type, getArticleDetailSaga);
  yield takeLatest(addArticle.type, addArticleSaga);
  yield takeLatest(editArticle.type, editArticleSaga);
  yield takeLatest(deleteArticle.type, deleteArticleSaga);
  yield takeLatest(getProfileInfo.type, getProfileInfoSaga);
  yield takeLatest(editProfile.type, editProfileSaga);
  yield takeLatest(changePassword.type, changePasswordSaga);
  yield takeLatest(updateBanner.type, updateBannerSaga);
  yield takeLatest(getGeneralData.type, getGeneralDataSaga);
  yield takeLatest(uploadSlider.type, uploadSliderSaga);
  yield takeLatest(deleteSlider.type, deleteSliderSaga);
  yield takeLatest(getAdmin.type, getAdminSaga);
  yield takeLatest(getAdminDetail.type, getAdminDetailSaga);
  yield takeLatest(addAdmin.type, addAdminSaga);
  yield takeLatest(updateAdmin.type, updateAdminSaga);
  yield takeLatest(deleteAdmin.type, deleteAdminSaga);
  yield takeLatest(sendNotifUser.type, sendNotifUserSaga);
  yield takeLatest(sendNotifBroadcast.type, sendNotifBroadcastSaga);
  yield takeLatest(sendNotifTraining.type, sendNotifTrainingSaga);
  yield takeLatest(sendNotifFunding.type, sendNotifFundingSaga);
}
