import { Icon } from "components";
import { PATH } from "config";
import { ItrainingHistory } from "pages/users/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const STATUS: any = {
  A: {
    name: "Diterima",
    color: "bg-[#CB043F]",
  },
  R: {
    name: "Ditolak",
    color: "bg-[#3D3D3D]",
  },
  S: {
    name: "Diproses",
    color: "bg-[#F2C94C]",
  },
};

const Status = (status: string) => {
  const value = STATUS[status];

  return (
    <div
      className={
        "text-white text-sm py-1.5 px-5 text-xs rounded w-auto " + value.color
      }
    >
      {value.name}
    </div>
  );
};

const CardTrainingHistory: FC<{
  training: ItrainingHistory;
}> = ({ training }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${PATH.USER_TRAINING}/${training.id}/history`);
  };

  return (
    <>
      <div
        className="bg-white p-4 cursor-pointer relative rounded-lg"
        onClick={handleNavigate}
      >
        <div className="relative">
          <img
            src={training.banner ?? "/assets/images/placeholder-image.png"}
            alt="foto"
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute top-4 right-4">
            <div>{Status(training.statusBooking ?? "S")}</div>
          </div>
        </div>
        <div className="mt-2 text-[#3D3D3D] font-medium ellipsis-2">
          {training.title}
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="TicketStar" type="bold" size="small" color="#CB043F" />
          </div>
          <span className="ml-2 text-sm">{training.penyelenggara}</span>
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="Location" type="bold" size="small" color="#F2994A" />
          </div>
          <span className="ml-2 text-sm">{training.alamat}</span>
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="Calendar" type="bold" size="small" color="#3D3D3D" />
          </div>
          <span className="ml-2 text-sm">{training.waktu}</span>
        </div>
      </div>
    </>
  );
};
export { CardTrainingHistory };
