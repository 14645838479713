import { Icon, MainLayout, TableComponent } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMitraDetail } from "pages/admin/redux";
import { RootState } from "setup";
import { DashboardMitraData } from "./DashboardMitraData";
import { PATH } from "config";

const DashboardMitraDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{
    partnerId: string;
  }>();
  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [active, setActive] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isLoading = false;

  const columns: any = [
    {
      header: "Foto",
      field: "foto",
      rowRender: (row: any) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row.photo ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Nama Produk",
      field: "namaProduct",
    },
    {
      header: "Harga",
      field: "harga",
    },
  ];

  const mitra = useSelector((state: RootState) => state.admin.mitraDetail);

  useEffect(() => {
    if (params.partnerId) {
      dispatch(
        getMitraDetail({
          params: {
            id: params.partnerId,
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout>
      <>
        <div className="bg-white shadow rounded">
          <div className="text-lg p-8 font-semibold text-gray-800 border-b">
            Detail Mitra
          </div>
          <div className="flex px-8">
            <div
              onClick={() => setActive(0)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 0
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Detail
            </div>
            <div
              onClick={() => setActive(1)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 1
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Produk
            </div>
          </div>
        </div>
        {active === 0 ? (
          <>
            <div className="bg-white shadow rounded p-8 mt-4">
              <div className="w-full max-w-3xl mx-auto">
                <div className="flex justify-between mb-8">
                  <div className="text-lg font-semibold text-gray-800">
                    Data Pelatihan
                  </div>
                </div>
                <DashboardMitraData mitra={mitra} />
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <TableComponent
              columns={columns}
              data={mitra.products ?? []}
              isLoading={isLoading}
              limit={limit}
              pagination={{
                currentPage: page,
                totalPages: Math.ceil(mitra.products.length / limit),
                totalCount: mitra.products.length,
                onPrev: () => {
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                  }
                },
                onNext: () => {
                  if (page < Math.ceil(mitra.products.length / limit)) {
                    setPage((prev) => prev + 1);
                  }
                },
              }}
              isDownload
              localPagination
            />
          </div>
        )}
      </>
    </MainLayout>
  );
};
export default DashboardMitraDetail;
