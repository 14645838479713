import { Icon } from "components";
import { PATH } from "config";
import { ActionTypes, getMitraDetail } from "pages/admin/redux";
import { FC, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
// import { convertPhoneNumber } from "utils/convertPhoneNumber"

const DashboardMapDetail: FC<{
  id: string;
  setDetailMitra: React.Dispatch<
    React.SetStateAction<{
      id: string;
      show: boolean;
    }>
  >;
}> = ({ id, setDetailMitra }) => {
  const navigate = useNavigate();
  const loading = useSelector(
    createLoadingSelector(ActionTypes.GET_MITRA_DETAIL)
  );
  const mitra = useSelector((state: RootState) => state.admin.mitraDetail);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) {
      dispatch(
        getMitraDetail({
          params: {
            id: id,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [id]);

  const waktuBuka = useMemo(() => {
    let operasional = Object.entries(mitra.waktuOperasional);
    const first: any = operasional.find((e: any) => e[1].buka !== "");
    if (first) {
      return `Buka ${first[0]} Pukul ${first[1].buka}`;
    }
    return undefined;
  }, [mitra]);

  const [tab, setTab] = useState<number>(3);
  const [openSchedule, setOpenSchedule] = useState<boolean>(false);
  return (
    <div
      className="fixed top-0 left-0 w-full md:w-96 shadow md:relative bg-white"
      style={{ zIndex: 999999 }}
    >
      <div
        className="absolute md:bg-white md:hover:bg-blue-500 hover:text-white text-white md:text-gray-800 cursor-pointer right-4 md:right-0 transform md:translate-x-full full py-6 px-2 rounded-r z-10"
        onClick={() => {
          setDetailMitra({ id: "", show: false });
        }}
      >
        <i className="fa fa-chevron-left md:block hidden"></i>
        <i className="fa fa-times block md:hidden"></i>
      </div>
      <div className="h-screen overflow-y-auto">
        <div className="relative">
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-25 md:hidden"></div>
          <img
            src={mitra.foto || "/assets/images/placeholder-image.png"}
            className="w-full border-b"
            alt="mitra cover"
          />
        </div>
        {loading ? (
          <div className="p-6">
            <div className="mb-2">
              <Skeleton height={25} width={200} />
            </div>
            <div className="mb-2">
              <Skeleton />
            </div>
            <div className="mb-2">
              <Skeleton />
            </div>
            <div className="mb-2">
              <Skeleton />
            </div>
            <div className="mb-2">
              <Skeleton />
            </div>
          </div>
        ) : (
          <div className="p-6">
            <div className="flex items-center">
              <div className="text-xl font-semibold capitalize">
                {mitra.namaMitra}
              </div>
              {mitra.status === "A" && (
                <img
                  src="/assets/icon/verify.svg"
                  alt="verift"
                  className="w-4 ml-2"
                />
              )}
            </div>
            <div className="mt-5">
              <div className="flex items-start mb-4">
                <div className="w-10 flex-shrink-0">
                  <Icon name="Location" stroke="#f05252" size="small" />
                </div>
                <div className="text-sm text-gray-600 capitalize">
                  {[
                    mitra.alamat || "",
                    mitra.kelurahan || "",
                    mitra.kecamatan || "",
                    mitra.kota || "",
                  ].join(", ")}
                </div>
              </div>
              {waktuBuka && (
                <>
                  <div className="flex items-start mb-4">
                    <div className="w-10 flex-shrink-0">
                      <Icon name="TimeCircle" stroke="#f05252" size="small" />
                    </div>
                    <div className="text-sm text-gray-600">
                      {waktuBuka}
                      <i
                        className="fa fa-chevron-down ml-2 cursor-pointer"
                        onClick={() => setOpenSchedule(!openSchedule)}
                      ></i>
                    </div>
                  </div>
                  <div
                    className={["mb-4", !openSchedule && "hidden"].join(" ")}
                  >
                    <table className="min-w-full border-collapse border border-gray-200 rounded">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b text-sm font-semibold">
                            Hari
                          </th>
                          <th className="py-2 px-4 border-b text-sm font-semibold">
                            Waktu Buka
                          </th>
                          <th className="py-2 px-4 border-b text-sm font-semibold">
                            Waktu Tutup
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(mitra.waktuOperasional).map(
                          (item: any, index) => (
                            <tr key={index}>
                              <td className="py-2 px-4 border-b text-sm capitalize">
                                {item[0]}
                              </td>
                              <td className="py-2 px-4 border-b text-sm">
                                {item[1].buka || "-"}
                              </td>
                              <td className="py-2 px-4 border-b text-sm">
                                {item[1].tutup || "-"}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="flex items-start mb-4">
                <div className="w-10 flex-shrink-0">
                  <Icon name="User" stroke="#f05252" size="small" />
                </div>
                <div className="text-sm text-gray-600 cursor-pointer">
                  {mitra.user}
                  {/* <i className="fa fa-external-link ml-2 cursor-pointer"></i> */}
                </div>
              </div>
              <div className="flex items-start mb-4">
                <div className="w-10 flex-shrink-0">
                  <Icon name="ShieldDone" stroke="#f05252" size="small" />
                </div>
                <div className="text-sm text-gray-600 font-semibold">
                  {mitra.status === "A" ? "Status Aktif" : "Status Non Aktif"}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex border-b">
          <div
            className={[
              "px-4 py-3 cursor-pointer text-sm",
              tab === 3
                ? "font-semibold text-red-500 border-b-2 border-red-500"
                : "text-gray-800",
            ].join(" ")}
            onClick={() => setTab(3)}
          >
            Produk
          </div>
          <div
            className={[
              "px-4 py-3 cursor-pointer text-sm",
              tab === 1
                ? "font-semibold text-red-500 border-b-2 border-red-500"
                : "text-gray-800",
            ].join(" ")}
            onClick={() => setTab(1)}
          >
            Marketplace
          </div>
          {/* <div
            className={[
              "px-4 py-3 cursor-pointer text-sm",
              tab === 2
                ? "font-semibold text-red-500 border-b-2 border-red-500"
                : "text-gray-800",
            ].join(" ")}
            onClick={() => setTab(2)}
          >
            Kontak
          </div> */}
        </div>
        {tab === 1 ? (
          <>
            <div>
              {loading
                ? [1, 2, 3, 4, 5, 6].map((e, i) => (
                    <div className="flex items-center px-6 py-4" key={i}>
                      <Skeleton width={48} height={48} />
                      <div className="flex-1 ml-2">
                        <Skeleton />
                        <Skeleton width={100} />
                      </div>
                    </div>
                  ))
                : mitra.links &&
                  Object.entries(mitra.links).map(
                    (item: any, index: number) => (
                      <div
                        className="px-6 py-4 hover:bg-gray-100 flex items-center text-sm cursor-pointer"
                        key={index}
                        onClick={() => {
                          window.location.href = item[1];
                        }}
                      >
                        <img
                          src={`/assets/marketplace/${item[0]}.png`}
                          alt="marketplace"
                          className="w-12"
                        />
                        <div className="underline ml-4">
                          {item[1]} <i className="fa fa-external-link"></i>
                        </div>
                      </div>
                    )
                  )}
            </div>
          </>
        ) : tab === 2 ? (
          <div className="p-4">
            {/* <div className="p-2 flex items-center text-sm text-gray-700">
              <i className="fa fa-phone"></i>
              <div className="ml-2">
                {convertPhoneNumber(mitra.phoneNumber)}
              </div>
            </div> */}
            {/* <div className="p-2 flex items-center text-sm text-gray-700">
              <i className="fa fa-envelope"></i>
              <div className="ml-2">{mitra.user.email}</div>
            </div> */}
          </div>
        ) : (
          <>
            <div className="flex flex-col">
              {loading
                ? [1, 2, 3, 4, 5, 6].map((e, i) => (
                    <div className="flex px-6 py-4" key={i}>
                      <div className="flex-1">
                        <Skeleton />
                        <Skeleton width={100} />
                      </div>
                      <div className="ml-4">
                        <Skeleton width={128} height={96} />
                      </div>
                    </div>
                  ))
                : mitra.products.map((product, index) => (
                    <div
                      className="flex flex-row hover:bg-gray-100 cursor-pointer px-6 py-4"
                      key={index}
                      onClick={() => {
                        navigate(`${PATH.LANDING_PAGE_PRODUCT}/${product._id}`);
                      }}
                    >
                      <div className="flex-1">
                        <div className="font-medium text-lg">
                          {product.namaProduct}
                        </div>
                        <div className="text-sm">
                          {new Intl.NumberFormat("id-ID", {
                            style: "currency",
                            currency: "IDR",
                            minimumFractionDigits: 0,
                          }).format(product.harga)}
                        </div>
                      </div>
                      <div>
                        <img
                          src={
                            product.fotoProduct ||
                            "/assets/images/placeholder-image.png"
                          }
                          className="w-32 h-24 object-cover rounded"
                          alt="product cover"
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { DashboardMapDetail };
