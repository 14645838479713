import { Dropdown } from "components";
import { PATH } from "config";
import { IMitraList } from "pages/users/redux";
import { FC } from "react";
import { useNavigate } from "react-router";

const CardUsaha: FC<{
  mitra: IMitraList;
  setDialogDelete: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      id: string;
    }>
  >;
}> = ({ mitra, setDialogDelete }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="bg-white p-4 rounded-lg relative cursor-pointer"
        onClick={() => {
          navigate(`${PATH.USER_USAHA}/edit/${mitra._id}`);
        }}
      >
        <span className="absolute top-0 right-0 p-6 text-gray-300">
          <Dropdown>
            <div>
              <i className="fa fa-ellipsis-h"></i>
            </div>
            <div className="text-gray-600 p-2 text-xs w-24">
              {/* <div
                className="cursor-pointer"
                onClick={() => {
                  navigate(`${PATH.USER_USAHA}/edit/${mitra._id}`);
                }}
              >
                Edit
              </div> */}
              <div
                className="cursor-pointer"
                onClick={() => {
                  setDialogDelete({ id: mitra._id, show: true });
                }}
              >
                Delete
              </div>
            </div>
          </Dropdown>
        </span>
        <img
          src={mitra.foto ?? "/assets/img/avatar-person.svg"}
          alt="foto"
          className="w-full h-48 object-cover rounded-lg"
        />
        <div>
          <div className="mt-2 text-[#3D3D3D] text-lg font-medium">
            {mitra.namaMitra}
          </div>
          <div className="text-gray-400 text-sm">
            <i className="fa fa-map-marker-alt"></i>
            <span className="ml-2">{mitra.direction}</span>
          </div>
          {mitra.status === "S" ? (
            <div className="bg-[#FF5400] rounded-lg p-2 text-center text-white mt-2 text-sm">
              Direview
            </div>
          ) : mitra.status === "R" ? (
            <div className="bg-[#200E32] rounded-lg p-2 text-center text-white mt-2 text-sm">
              Ditolak
            </div>
          ) : mitra.status === "A" ? (
            <div className="bg-[#FCF0F3] rounded-lg p-2 text-center text-[#CB043F] mt-2 text-sm">
              Diterima
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export { CardUsaha };
