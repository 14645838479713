import { MainLayout, TableComponent, TextField } from "components";
import { ActionTypes, getAspiration, getLogs } from "pages/admin/redux";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import moment from "moment";

export const DashboardLog: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_LOGS));

  const [startValue, setStartValue] = useState("");
  const [endValue, setEndValue] = useState("");

  useEffect(() => {
    const defaultStartValue = moment()
      .subtract(1, "month")
      .format("YYYY-MM-DDTHH:mm");
    const defaultEndValue = moment().format("YYYY-MM-DDTHH:mm");

    setStartValue(defaultStartValue);
    setEndValue(defaultEndValue);
  }, []);

  const columns: any = [
    {
      header: "User",
      field: "name",
    },
    {
      header: "Keterangan",
      field: "log",
    },
    {
      header: "Waktu",
      field: "timestamp",
      rowRender: (row: any) => {
        return moment(row.timestamp).format("DD MMMM YYYY HH:mm");
      },
    },
  ];
  const logs = useSelector((state: RootState) => state.admin.logs);

  const limit = 20;
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (startValue !== "" && endValue !== "") {
      dispatch(
        getLogs({
          params: {
            start: moment(startValue).format("YYYY-MM-DDTHH:mm:ss.SSS\\Z"),
            end: moment(endValue).format("YYYY-MM-DDTHH:mm:ss.SSS\\Z"),
            sort: "desc",
            // userId: true,
          },
        })
      );
    }
  }, [startValue, endValue]);

  return (
    <MainLayout>
      <TableComponent
        header={
          <div className="flex">
            <div className="px-4">
              <TextField
                type="datetime-local"
                label="Start"
                propsInput={{ value: startValue }}
                onChange={(e) => setStartValue(e.target.value)}
              />
            </div>
            <div className="px-4">
              <TextField
                type="datetime-local"
                label="End"
                propsInput={{ value: endValue }}
                onChange={(e) => setEndValue(e.target.value)}
              />
            </div>
          </div>
        }
        columns={columns}
        data={logs}
        isLoading={isLoading}
        pagination={{
          currentPage: page,
          totalPages: Math.ceil(logs.length / limit),
          totalCount: logs.length,
          onPrev: () => {
            if (page > 1) {
              setPage((prev) => prev - 1);
            }
          },
          onNext: () => {
            if (page < Math.ceil(logs.length / limit)) {
              setPage((prev) => prev + 1);
            }
          },
        }}
        // isDownload
        localPagination
      />
    </MainLayout>
  );
};

export default DashboardLog;
