import { Button, MainLayout, TextField } from "components";
import { useFormik } from "formik";
import {
  ActionTypes,
  addCategory,
  editCategory,
  getCategoryDetail,
} from "pages/admin/redux";
import { FC, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

export const DashboardCategoryInput: FC<{
  isEdit: boolean;
}> = ({ isEdit }) => {
  const dispatch = useDispatch();
  const params = useParams<{
    categoryId: string;
  }>();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_CATEGORY)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_CATEGORY)
  );

  const categoryDetail = useSelector(
    (state: RootState) => state.admin.categoryDetail
  );

  const initialValues = useMemo(() => {
    return {
      name: isEdit ? categoryDetail.name : "",
    };
  }, [isEdit, categoryDetail]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      if (isEdit) {
        dispatch(
          editCategory({
            params: { ...values, classificationId: params.categoryId },
            onSuccess(e) {
              toast.success("Berhasil mengedit kategori");
              dispatch(
                getCategoryDetail({
                  params: {
                    id: params.categoryId,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Gagal mengedit kategori");
            },
          })
        );
      } else {
        dispatch(
          addCategory({
            params: values,
            onSuccess(e) {
              toast.success("Berhasil menambah kategori");
            },
            onFailure(e) {
              toast.error("Gagal menambah kategori");
            },
          })
        );
      }
    },
  });

  useEffect(() => {
    dispatch(
      getCategoryDetail({
        params: {
          id: params.categoryId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Data Kategori</div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                label="name"
                propsInput={{ ...formHandler.getFieldProps("name") }}
                error={formHandler.touched.name && !!formHandler.errors.name}
                errorText={`${formHandler.errors.name}`}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start flex-1 pr-12">
              <Button
                color="failure"
                loading={isLoadingAdd || isLoadingEdit}
                disabled={isLoadingAdd || isLoadingEdit}
                onClick={() => formHandler.submitForm()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardCategoryInput;
