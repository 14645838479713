import { createReducer } from "@reduxjs/toolkit";
import {
  IlandingState,
  setArticle,
  setArticleDetail,
  setConfig,
  setMitra,
  setMitraDetail,
  setMitraDetailV1,
  setMitraV1,
  setProduct,
  setProductDetail,
  setProductV1,
  setSummary,
} from "./";

export const initialState: IlandingState = {
  summary: {
    mitra: 0,
    product: 0,
    omset: 0,
    user: 0,
  },
  config: {
    hero: "",
    heroLink: "",
    banners: [],
    contacts: [],
  },
  products: [],
  mitras: [],
  product: {
    _id: "",
    namaProduct: "",
    harga: 0,
    nominalSatuan: 0,
    beratSatuan: "",
    deskripsi: "",
    mitra: {
      namaMitra: "",
      id: "",
      foto: "",
      kota: "",
      kecamatan: "",
      kelurahan: "",
      location: {
        type: "",
        coordinates: [],
      },
      phoneNumber: "",
      userId: "",
      isVerified: false,
    },
    fotoProduk: [],
    isFavorite: false,
  },
  mitra: {
    _id: "",
    namaMitra: "",
    links: {},
    waktuOperasional: {
      senin: { buka: "", tutup: "" },
      selasa: { buka: "", tutup: "" },
      rabu: { buka: "", tutup: "" },
      kamis: { buka: "", tutup: "" },
      jumat: { buka: "", tutup: "" },
      sabtu: { buka: "", tutup: "" },
      minggu: { buka: "", tutup: "" },
    },
    foto: "",
    kelurahan: "",
    pengizinUsaha: "",
    location: {
      type: "",
      coordinates: [],
    },
    rejectReason: null,
    addressName: {
      kota: "",
      kecamatan: "",
      kelurahan: "",
      _id: "",
    },
    phoneNumber: "",
    isVerified: false,
    kota: "",
    kecamatan: "",
  },
  articles: [],
  article: {
    title: "",
    content: "",
    preview: {
      image: "",
    },
    id: "",
  },
  mitraDetailV1: {
    namaMitra: "",
    namaBadanUsaha: "",
    links: {},
    waktuOperasional: {
      senin: { buka: "", tutup: "" },
      selasa: { buka: "", tutup: "" },
      rabu: { buka: "", tutup: "" },
      kamis: { buka: "", tutup: "" },
      jumat: { buka: "", tutup: "" },
      sabtu: { buka: "", tutup: "" },
      minggu: { buka: "", tutup: "" },
    },
    foto: {
      id: "",
      url: "",
    },
    alamat: "",
    kota: 0,
    kecamatan: 0,
    kelurahan: 0,
    deskripsi: "",
    klasifikasiEkraf: [],
    location: {
      type: "",
      coordinates: [0, 0],
    },
    addressName: {
      kota: "",
      kecamatan: "",
      kelurahan: "",
    },
    user: {
      nama: "",
      foto: "",
      noHandphone: "",
      id: "",
    },
    id: "",
    isVerified: false,
  },
  mitraListV1: [],
  mitraMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
  },
  productMeta: {
    count: 0,
    pageNumber: 0,
    limit: 0,
    pageTotal: 0,
    filter: {},
    sort: {},
    location: {
      lat: 0,
      long: 0,
    },
  },
  productListV1: [],
};

const landingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSummary, (state, action) => {
      state.summary = action.payload.payload.summary;
    })
    .addCase(setConfig, (state, action) => {
      state.config = action.payload.payload.incoming;
    })
    .addCase(setProduct, (state, action) => {
      state.products = action.payload.payload;
    })
    .addCase(setMitra, (state, action) => {
      state.mitras = action.payload.payload;
    })
    .addCase(setProductDetail, (state, action) => {
      state.product = action.payload.payload;
    })
    .addCase(setMitraDetail, (state, action) => {
      state.mitra = action.payload.payload;
    })
    .addCase(setArticle, (state, action) => {
      state.articles = action.payload.payload.articles;
    })
    .addCase(setArticleDetail, (state, action) => {
      state.article = action.payload.payload.article;
    })
    .addCase(setMitraV1, (state, action) => {
      state.mitraListV1 = action.payload.payload.mitra;
      state.mitraMeta = action.payload.payload.metadata;
    })
    .addCase(setProductV1, (state, action) => {
      state.productMeta = action.payload.payload.metadata;
      state.productListV1 = action.payload.payload.products;
    })
    .addCase(setMitraDetailV1, (state, action) => {
      state.mitraDetailV1 = action.payload.payload.mitra;
    });
});

export default landingReducer;
