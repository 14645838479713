import { Button, MainLayout, TextField } from "components";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getInfo } from "../auth/redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import {
  ActionTypes,
  changePassword,
  editProfile,
  getProfileInfo,
} from "pages/admin/redux";
import { toast } from "react-hot-toast";

const DashboardSettingProfile = () => {
  const dispatch = useDispatch();

  const authInfo = useSelector((state: RootState) => state.admin.profileInfo);

  const loadingEdit = useSelector(
    createLoadingSelector(ActionTypes.SETTING_EDIT_PROFILE)
  );
  const loadingChangePassword = useSelector(
    createLoadingSelector(ActionTypes.SETTING_CHANGE_PASSWORD)
  );

  const formHandlerEditProfile = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: authInfo.email ?? "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email tidak valid")
        .required("Email wajib diisi"),
    }),
    onSubmit(values) {
      dispatch(
        editProfile({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil edit profile");
            dispatch(getProfileInfo({ params: {} }));
          },
          onFailure(e) {
            toast.success("Gagal edit profile");
          },
        })
      );
    },
  });

  const formHandlerChangePassword = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old password wajib diisi"),
      newPassword: Yup.string().required("New password wajib diisi"),
      confirmPassword: Yup.string()
        .required("Confirm password wajib diisi")
        .oneOf(
          [Yup.ref("newPassword")],
          "Password konfirmasi harus sama dengan passwird baru"
        ),
    }),
    onSubmit(values) {
      dispatch(
        changePassword({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil ganti password");
            dispatch(getProfileInfo({ params: {} }));
          },
          onFailure(e) {
            toast.success("Gagal ganti password");
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getProfileInfo({ params: {} }));
  }, []);

  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Edit Profil</div>
            </div>
            <div className="w-full max-w-[300px]">
              <TextField
                type="email"
                label="Email"
                propsInput={{
                  ...formHandlerEditProfile.getFieldProps("email"),
                  autoComplete: "off",
                }}
                error={
                  formHandlerEditProfile.touched.email &&
                  !!formHandlerEditProfile.errors.email
                }
                errorText={formHandlerEditProfile.errors.email}
              />
              <Button
                color="failure"
                loading={loadingEdit}
                disabled={loadingEdit}
                onClick={() => formHandlerEditProfile.submitForm()}
              >
                Simpan
              </Button>
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Ganti Password</div>
            </div>
            <div className="w-full max-w-[300px]">
              <TextField
                type="password"
                label="Password Lama"
                propsInput={{
                  ...formHandlerChangePassword.getFieldProps("oldPassword"),
                }}
                error={
                  formHandlerChangePassword.touched.oldPassword &&
                  !!formHandlerChangePassword.errors.oldPassword
                }
                errorText={`${formHandlerChangePassword.errors.oldPassword}`}
              />
              <TextField
                type="password"
                label="Password Baru"
                propsInput={{
                  ...formHandlerChangePassword.getFieldProps("newPassword"),
                }}
                error={
                  formHandlerChangePassword.touched.newPassword &&
                  !!formHandlerChangePassword.errors.newPassword
                }
                errorText={`${formHandlerChangePassword.errors.newPassword}`}
              />
              <TextField
                type="password"
                label="Konfirmasi Password Baru"
                propsInput={{
                  ...formHandlerChangePassword.getFieldProps("confirmPassword"),
                }}
                error={
                  formHandlerChangePassword.touched.confirmPassword &&
                  !!formHandlerChangePassword.errors.confirmPassword
                }
                errorText={`${formHandlerChangePassword.errors.confirmPassword}`}
              />
              <Button
                color="failure"
                onClick={() => {
                  formHandlerChangePassword.submitForm();
                }}
                disabled={loadingChangePassword}
                loading={loadingChangePassword}
              >
                Simpan
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardSettingProfile };
