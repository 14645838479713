import { MainLayout } from "components";
import { DashboardMitraInput } from "./DashboardMitraInput";

export const DashboardMitraAdd = () => {
  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <DashboardMitraInput isEdit={false} />
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardMitraAdd;
