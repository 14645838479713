import moment from "moment";
import { FC } from "react";

const DashboardFundingData: FC<{
  funding: any;
}> = ({ funding }) => {
  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Foto Pendanaan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Foto akan di tampilkan sebagai thumbnail untuk detail pendanaan
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <img
            className="w-full max-w-[300px] rounded"
            src={funding.banner || "/assets/images/placeholder-image.png"}
            alt="image thumbnail"
          />
        </div>
      </div>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Detail Pendanaan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan detail pendanaan seperti waktu penyelenggara kriteria dan
            lain lain.
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Judul Pendanaan</div>
              <div className="text-md">{funding.title || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Penyelenggara</div>
              <div className="text-md">{funding.penyelenggara || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Deadline</div>
              <div className="text-md">
                {moment(funding.deadline || "0000-00-00").format(
                  "DD MMM YYYY HH:mm"
                )}
              </div>
            </div>
            <i className="fa fa-calendar text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kriteria</div>
              <div className="text-md">{funding.kriteria || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Persyaratan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan persyaratan untuk ikut pelatihan
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <div className="flex justify-between text-gray-600 items-center mb-8">
            <div>
              <div className="text-xs">Persyaratan</div>
              <div className="text-md">
                <ul className="list-decimal ml-3">
                  {(funding.persyaratan || []).map(
                    (item: any, index: number) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div className="text-md">
              {funding.status === "O" ? "Aktif" : "Non Aktif"}
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export { DashboardFundingData };
