import { FC, Fragment, useState } from "react";
import { ISidebarProps } from "./Sidebar.model";
import { Dialog, Icon } from "components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarMenu } from "config";
import { Button } from "flowbite-react";
import { logout } from "pages/admin/components/auth/redux";

const Sidebar: FC<ISidebarProps> = ({ onHide, hide }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [show, setShow] = useState<boolean>(false);
  const [active, setActive] = useState<number>(0);

  const authInfo = useSelector((state: RootState) => state.auth.authInfo);

  const handleClick = () => {
    setShow(true);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Dialog
        onClose={() => setShow(false)}
        size="sm"
        show={show}
        title="Keluar"
      >
        <div className="flex flex-col items-center w-full">
          <img
            src="/assets/animation/91964-question.gif"
            alt="illustration"
            className="w-48"
          />
          <div className="text-center ">Anda Yakin Ingin Keluar?</div>
          <div className="mt-4 flex justify-center">
            <div>
              <Button
                color="light"
                onClick={() => setShow(false)}
                className="mr-2"
              >
                Batalkan
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                onClick={() => handleLogout()}
                className="ml-2 whitespace-nowrap"
              >
                Ya, saya ingin
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      {!hide && (
        <aside
          className={
            "max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 w-full -translate-x-0 flex-wrap items-center justify-between overflow-y-auto p-0 antialiased shadow-none transition-transform duration-200 left-0 bg-white " +
            (hide ? "hidden w-full" : "block w-[300px]")
          }
        >
          <div className="h-19.5">
            <i
              className="absolute top-0 right-0 hidden p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden bg-white"
              onClick={() => onHide()}
            ></i>
            <div className="block px-8 py-6 m-0 text-sm whitespace-nowrap text-slate-700">
              <img
                src="/assets/icon/logo-ct.png"
                className="inline h-full max-w-full transition-all duration-200 ease-nav-brand max-h-12"
                alt="main_logo"
              />
              <span className="ml-1 font-semibold transition-all duration-200 ease-nav-brand">
                Dashboard
              </span>
            </div>
          </div>

          <div className="items-center block w-auto grow basis-full">
            <ul className="flex flex-col pl-0 mb-0">
              {SidebarMenu[authInfo.role ?? ""].map((item, index) => (
                <Fragment key={index}>
                  {!!item.path ? (
                    <li className="mt-2 w-full">
                      <div
                        onClick={() => {
                          if (item.sub === undefined) {
                            navigate(item?.path || "");
                          } else {
                            setActive(index);
                          }
                        }}
                        className={
                          "cursor-pointer py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors " +
                          (location.pathname.startsWith(item.path)
                            ? "shadow-soft-xl bg-"
                            : "")
                        }
                      >
                        <div
                          className={
                            "shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-center stroke-0 text-center p-2.5 " +
                            (location.pathname.startsWith(item.path)
                              ? "shadow-lg bg-gradient-to-r from-[#CB043F] to-[#90022D]"
                              : "bg-gray-50")
                          }
                        >
                          <Icon
                            name={item.icon ?? "InfoCircle"}
                            type="two-tone"
                            size="medium"
                            className={
                              location.pathname.startsWith(item.path)
                                ? "invert"
                                : ""
                            }
                          />
                        </div>
                        <span
                          className={`ml-1 duration-300 opacity-100 pointer-events-none ease-soft ${
                            location.pathname.startsWith(item.path)
                              ? "text-[#CB043F] font-bold"
                              : "text-gray-800 font-medium"
                          }`}
                        >
                          {item.title}
                        </span>
                      </div>
                      {item?.sub && (
                        <ul
                          className={[
                            "pt-2 text-sm border-l-2 ml-12 sidebar-item",
                            active !== index && "hidden",
                          ].join(" ")}
                        >
                          {item?.sub?.map((sub, i) => (
                            <li
                              key={i}
                              onClick={() => navigate(sub.path ?? "")}
                              className={[
                                "pl-4 py-1 cursor-pointer hover:text-gray-800 hover:font-semibold",
                                location.pathname === sub.path
                                  ? "text-gray-800 font-bold border-l-2 border-gray-800 -ml-0.5"
                                  : "text-gray-500 font-medium",
                              ].join(" ")}
                            >
                              {sub.title}
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ) : (
                    <li key={index} className="w-full mt-4">
                      <h6 className="pl-6 ml-2 font-bold leading-tight uppercase text-xs">
                        {item.title}
                      </h6>
                    </li>
                  )}
                </Fragment>
              ))}
              <li className="mt-2 w-full" onClick={() => handleClick()}>
                <div className="cursor-pointer py-2.7 text-sm ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors">
                  <div className="mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50 bg-center stroke-0 text-center p-2.5">
                    <Icon name="Logout" type="two-tone" size="medium" />
                  </div>
                  <div className="ml-1 duration-300 text-gray-800 pointer-events-none ease-soft">
                    Logout
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      )}
    </>
  );
};

export { Sidebar };
