import { Button, MainLayout, SelectField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useParams } from "react-router-dom";
import { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  applyTraining,
  getTrainingDetail,
  getTrainingDetailHistory,
} from "pages/users/redux";
import { RootState } from "setup";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

interface Itraining {
  image: string;
  title: string;
  penyelenggara: string;
  waktu: string;
  alamat: string;
  kuota: number;
}

const DashboardTrainingDetail: FC<{
  history?: boolean;
}> = ({ history }) => {
  const dispatch = useDispatch();
  const params = useParams<{
    trainingId: string;
  }>();

  const training = useSelector((state: RootState) => state.user.trainingDetail);
  const trainingHistory = useSelector(
    (state: RootState) => state.user.trainingDetailHistory
  );

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.APPLY_TRAINING)
  );

  const data = useMemo<Itraining>(() => {
    if (history) {
      return {
        image: trainingHistory.image,
        title: trainingHistory.title,
        penyelenggara: trainingHistory.penyelenggara,
        waktu: trainingHistory.waktu,
        alamat: trainingHistory.alamat,
        kuota: trainingHistory.kuota,
      };
    } else {
      return {
        image: training.program.image,
        title: training.program.title,
        penyelenggara: training.program.penyelenggara,
        waktu: training.program.waktu,
        alamat: training.program.alamat,
        kuota: training.program.kuota,
      };
    }
  }, [training, trainingHistory]);

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      mitraId: "",
    },
    validationSchema: Yup.object({
      mitraId: Yup.string().required("Anda harus memilih mitra"),
    }),
    onSubmit(values) {
      dispatch(
        applyTraining({
          params: {
            mitraId: values.mitraId,
            courseId: params.trainingId,
          },
          onSuccess(e) {
            toast.success("Berhasil Daftar Pendanaan");
          },
          onFailure(e) {
            toast.error("Gagal Daftar Pendanaan");
          },
        })
      );
    },
  });

  useEffect(() => {
    if (history) {
      dispatch(
        getTrainingDetailHistory({
          params: {
            trainingId: params.trainingId,
          },
        })
      );
    } else {
      dispatch(
        getTrainingDetail({
          params: {
            trainingId: params.trainingId,
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Detail Pelatihan" />
          <img
            src={data.image || "/assets/images/placeholder-image.png"}
            alt="pelatihan"
            className="w-full"
          />
          <div className="p-6 transform -translate-y-24">
            <div className="mt-6 bg-white p-4 rounded-lg text-gray-800">
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>Pelatihan</td>
                    <td>:&nbsp;{data.title}</td>
                  </tr>
                  <tr>
                    <td>Penyelenggara</td>
                    <td>:&nbsp;{data.penyelenggara}</td>
                  </tr>
                  <tr>
                    <td>Waktu</td>
                    <td>:&nbsp;{data.waktu}</td>
                  </tr>
                  <tr>
                    <td>Tempat</td>
                    <td>:&nbsp;{data.alamat}</td>
                  </tr>
                  <tr>
                    <td>Kuota</td>
                    <td>:&nbsp;{training.program.kuota} Peserta</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="my-6 rounded-lg">
              {history ? (
                <>
                  {trainingHistory.statusBooking === "R" ? (
                    <div className="font-medium text-[#CB043F]">
                      Mohon maaf Anda belum bisa diikutkan dalam program ini
                    </div>
                  ) : (
                    <>
                      <div className="font-medium text-[#CB043F]">
                        {trainingHistory.statusBooking === "A"
                          ? "Selamat Pelatihan Anda diterima!"
                          : "Pelatihan sedang menunggu persetujuan"}
                      </div>
                      <div className="font-semibold mt-4">
                        Usaha yang didaftarkan
                      </div>
                      <div className="mt-4 bg-white p-4 rounded-lg text-[#CB043F] font-semibold">
                        {trainingHistory.mitra}
                      </div>
                    </>
                  )}
                  <div className="font-semibold mt-6">
                    Catatan dari penyelenggara
                  </div>
                  <div className="mt-4 bg-white p-4 rounded-lg text-gray-800">
                    {trainingHistory.message || "Belum ada catatan"}
                  </div>
                </>
              ) : (
                <SelectField
                  className="bg-white rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800"
                  placeholder="Pilih Usaha Saya"
                  value={formHandler.values.mitraId}
                  onChange={(e) => {
                    formHandler.setFieldTouched("mitraId");
                    formHandler.setFieldValue("mitraId", e.target.value);
                  }}
                  options={training.available.map((e) => ({
                    label: e.namaMitra,
                    value: e.id,
                  }))}
                  error={
                    formHandler.touched.mitraId && !!formHandler.errors.mitraId
                  }
                  errorText={formHandler.errors.mitraId}
                />
              )}
            </div>
            {!history && (
              <Button
                size="xl"
                full
                color="theme"
                onClick={() => formHandler.submitForm()}
                loading={isLoading}
                disabled={isLoading}
              >
                Daftar
              </Button>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardTrainingDetail };
