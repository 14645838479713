import { Icon, MainLayout, TableComponent } from "components";
import { ActionTypes, deleteCategory, getCategory } from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import moment from "moment";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import DialogDelete from "components/DialogDelete/DialogDelete";
import { toast } from "react-hot-toast";

export const DashboardCategory: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_CATEGORY)
  );
  const [dialogDelete, setDialogDelete] = useState<{
    show: boolean;
    id: string;
  }>({
    show: false,
    id: "",
  });
  const columns: any = [
    {
      header: "Nama",
      field: "name",
    },
  ];
  const categories = useSelector((state: RootState) => state.admin.category);

  const limit = 20;
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getCategory({}));
  }, []);

  const hadleDelete = useCallback(() => {
    dispatch(
      deleteCategory({
        params: {
          classificationId: dialogDelete.id,
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus kategori");
          dispatch(getCategory({}));
          setDialogDelete({ show: false, id: "" });
        },
        onFailure(e) {
          toast.success("Gagal menghapus kategori");
        },
      })
    );
  }, [dialogDelete]);

  return (
    <MainLayout>
      <>
        <TableComponent
          columns={columns}
          data={categories}
          isLoading={isLoading}
          pagination={{
            currentPage: page,
            totalPages: Math.ceil(categories.length / limit),
            totalCount: categories.length,
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              if (page < Math.ceil(categories.length / limit)) {
                setPage((prev) => prev + 1);
              }
            },
            onChange(page) {
              setPage(page);
            },
          }}
          // isDownload
          localPagination
        />
        <DialogDelete
          show={dialogDelete.show}
          onClose={() => {
            setDialogDelete({ show: false, id: "" });
          }}
          onConfirm={hadleDelete}
        />
      </>
    </MainLayout>
  );
};

export default DashboardCategory;
