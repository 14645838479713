import { Icon, MainLayout, TableComponent, TextField } from "components";
import {
  ActionTypes,
  IUser,
  deleteUser,
  exportUser,
  getUser,
} from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import moment from "moment";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "config";
import DialogDelete from "components/DialogDelete/DialogDelete";
import { toast } from "react-hot-toast";
import { DialogApprovalUser } from "./DialogApprovalUser";
import { Button } from "flowbite-react";
import { DialogRejectUser } from "./DialogRejectUser";
import { DialogNotification } from "./DialogNotification";
import { DialogNotificationPersonal } from "./DialogNotificationPersonal";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

export const DashboardUser: FC<{
  isVerify?: boolean;
}> = ({ isVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [showBroadcast, setShowBroadcast] = useState<boolean>(false);
  const [showNotif, setShowNotif] = useState<{
    show: boolean;
    user?: IUser;
  }>({
    show: false,
  });
  const [approveDialog, setApproveDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });
  const [rejectDialog, setRejectDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });
  const [dialogDelete, setDialogDelete] = useState<{
    show: boolean;
    id: string;
  }>({
    show: false,
    id: "",
  });

  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_USER));
  const columns: any = [
    {
      header: "Nama",
      field: "nama",
      exportAs: "Nama",
      rowRender: (row: IUser) => {
        return (
          <div className="flex items-center">
            <div className="mr-3 w-12 flex-shrink-0">
              <img
                src={row?.foto?.url ?? "/assets/images/no-avatar.webp"}
                alt="avatar"
                className="w-12 h-12 rounded-full object-cover"
              />
            </div>
            <div>
              <div className="font-medium text-gray-800">{row.nama}</div>
              <div className="text-xs">{row.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      header: "NIK",
      field: "nik",
    },
    {
      header: "Status",
      field: "status",
      exportRender: (item: any) =>
        item.statusVerify === "A" ? "Active" : "Waiting",
      rowRender: (row: IUser) => {
        return (
          <div className="whitespace-nowrap">
            {row.statusVerify === "A" ? "✓ Active" : "Waiting"}
          </div>
        );
      },
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: any) => {
        return (
          <>
            {isVerify ? (
              <div className="flex">
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => setShowNotif({ show: true, user: row })}
                >
                  <Icon name="Send" />
                </span>
                <span
                  className="px-2 cursor-pointer"
                  onClick={() =>
                    navigate(`${PATH.DASHBOARD_REGIONAL_USER}/edit/${row.id}`)
                  }
                >
                  <Icon name="Edit" />
                </span>
                <span
                  className="px-2 cursor-pointer"
                  onClick={() => setDialogDelete({ show: true, id: row.id })}
                >
                  <Icon name="Delete" />
                </span>
              </div>
            ) : (
              <div className="flex">
                <Button
                  color="light"
                  className="mr-2"
                  onClick={() => {
                    setRejectDialog({ show: true, data: row });
                  }}
                >
                  Tolak
                  <i className="cursor-pointer px-2 fa fa-times"></i>
                </Button>
                <Button
                  color="light"
                  onClick={() => {
                    setApproveDialog({ show: true, data: row });
                  }}
                >
                  Setujui
                  <i className="cursor-pointer px-2 fa fa-check"></i>
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const [search, setSearch] = useState<string>("");

  const users = useSelector((state: RootState) => state.admin.users);

  const hadleDelete = useCallback(() => {
    dispatch(
      deleteUser({
        params: {
          userId: dialogDelete.id,
          status: "D",
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus user");
          dispatch(
            getUser({
              params: {
                nama: "",
                email: "",
                status: [isVerify ? "A" : "S"],
              },
            })
          );
          setDialogDelete({ show: false, id: "" });
        },
        onFailure(e) {
          toast.error("Gagal menghapus user");
        },
      })
    );
  }, [dialogDelete]);

  useEffect(() => {
    dispatch(
      getUser({
        params: {
          nama: "",
          email: "",
          status: isVerify ? "A" : "S",
        },
      })
    );
  }, [isVerify]);

  const handleSearch = useCallback((e: any) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  }, []);

  const handleExport = useCallback(() => {
    dispatch(
      exportUser({
        params: {},
        onSuccess(e: IExportExcel) {
          exportBase64ToExcel(e.payload.base64, e.payload.fileName);
          toast.success("Berhasil Export Excel");
        },
        onFailure(e) {
          toast.error("Gagal Export Excel");
        },
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <TableComponent
          withSearch={false}
          columns={columns}
          data={users}
          isLoading={isLoading}
          header={
            <div className="flex p-4 w-full justify-between">
              <Button color="failure" onClick={() => setShowBroadcast(true)}>
                Broadcast
              </Button>
              <div className="w-full max-w-xs">
                <TextField placeholder="Search" onChange={handleSearch} />
              </div>
            </div>
          }
          pagination={{
            currentPage: page,
            totalPages: Math.ceil(users.length / limit),
            totalCount: users.length,
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              if (page < Math.ceil(users.length / limit)) {
                setPage((prev) => prev + 1);
              }
            },
          }}
          isDownload
          onExport={handleExport}
        />
        <DialogNotification
          show={showBroadcast}
          onClose={() => setShowBroadcast(false)}
        />
        <DialogNotificationPersonal
          show={showNotif.show}
          user={showNotif.user}
          onClose={() => setShowNotif({ show: false })}
        />
        <DialogDelete
          show={dialogDelete.show}
          onClose={() => {
            setDialogDelete({ show: false, id: "" });
          }}
          onConfirm={hadleDelete}
        />
        <DialogApprovalUser
          show={approveDialog.show}
          data={approveDialog.data}
          onClose={() => {
            setApproveDialog({ show: false });
          }}
        />
        <DialogRejectUser
          show={rejectDialog.show}
          data={rejectDialog.data}
          onClose={() => {
            setRejectDialog({ show: false });
          }}
        />
      </>
    </MainLayout>
  );
};

export default DashboardUser;
