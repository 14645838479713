import { Button, Dialog, TextField } from "components";
import {
  ActionTypes,
  approveFunding,
  approveMitra,
  approveTraining,
  getFundingDetail,
  getTraining,
  getTrainingDetail,
  sendNotifUser,
} from "pages/admin/redux";
import { FC, useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DialogRejectFunding: FC<{
  show: boolean;
  onClose: () => void;
  data?: any;
}> = ({ show, data, onClose }) => {
  const dispatch = useDispatch();
  const loading = useSelector(
    createLoadingSelector(ActionTypes.APPROVE_FUNDING)
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const params = useParams<{
    fundingId: string;
  }>();

  const funding = useSelector((state: RootState) => state.admin.fundingDetail);

  const handleReject = useCallback(() => {
    dispatch(
      approveFunding({
        params: {
          bookingId: data._id,
          status: "R",
        },
        onSuccess(e) {
          setSuccess(true);
          toast.success("Berhasil menolak mitra");
          if (message !== "") {
            dispatch(
              sendNotifUser({
                params: {
                  target: [data.userId],
                  title: `Persetujuan untuk pendanaan ${funding.title} ditolak`,
                  body: message,
                },
              })
            );
          }
          dispatch(
            getFundingDetail({
              params: {
                fundingId: params.fundingId,
              },
            })
          );
        },
        onFailure(e) {
          setSuccess(false);
          toast.error("Berhasil menolak mitra");
        },
      })
    );
  }, [data, message]);

  return (
    <Dialog title="Tolak Mitra" show={show} onClose={onClose} size="md">
      <div className="flex flex-col items-center">
        {success ? (
          <>
            <img
              src="/assets/images/134875-failed-status.gif"
              alt="apprive gif"
              className="w-32 my-4"
            />
            <div>Berhasil Menolak Mitra</div>
            <Button color="light" onClick={onClose} className="mt-4">
              Tutup
            </Button>
          </>
        ) : (
          <>
            <img
              src="/assets/images/undraw_confirmation_re_b6q5.svg"
              alt="need approve"
              className="w-32 my-8"
            />
            <div>Tindakan ini perlu persetujuan</div>
            <div className="text-sm">Anda yakin ingin menolak mitra ini?</div>
            <TextField
              placeholder="Alasan ditolak"
              className="mt-4"
              multiLine={4}
              padingBottom={0}
              propsInput={{ value: message }}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div className="flex justify-center mt-4">
              <Button color="light" className="mr-2" onClick={onClose}>
                Batalkan
              </Button>
              <Button
                color="failure"
                className="ml-2"
                loading={loading}
                disabled={loading}
                onClick={handleReject}
              >
                Tolak mitra
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
export { DialogRejectFunding };
