import { FC, useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import useDebounce from "utils/useDebounce";
import { Button, Dialog, TextField } from "components";

export interface ILatLng {
  lat: number;
  lng: number;
}

const RecenterAutomatically: FC<{ lat: any; lng: any }> = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
    // eslint-disable-next-line
  }, [lat, lng]);
  return null;
};

export interface IDialogSelectLocationProps {
  show: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const DialogSelectLocation: FC<IDialogSelectLocationProps> = ({
  show,
  onClose,
  onSubmit,
}) => {
  const icon = L.icon({
    iconUrl: "/assets/icon/marker.png",
    iconSize: [32, 32],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any[]>([]);
  const [search, setSearch] = useState<string>("");
  const [center, setCenter] = useState<ILatLng>({
    lat: 1.4297828,
    lng: 114.74104130383714,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        lat: +position.coords.latitude,
        lng: +position.coords.longitude,
      });
      setLatLng({
        lat: +position.coords.latitude,
        lng: +position.coords.longitude,
      });
    });
  }, []);

  const [latLng, setLatLng] = useState<ILatLng>(center);

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        let latLng = e.target.getLatLng();
        setLatLng(latLng);
      },
    }),
    []
  );

  useDebounce(
    () => {
      if (search !== "") {
        setResult([]);
        setLoading(true);
        fetch(
          "https://nominatim.openstreetmap.org/search?q=" +
            search +
            "&format=json&limit=6"
        ).then(async (e) => {
          let response = await e.json();
          setLoading(false);
          setResult(response);
        });
      } else {
        setResult([]);
      }
    },
    [search],
    1000
  );

  const handleSubmit = () => {
    onSubmit(latLng);
  };

  return (
    <Dialog show={show} onClose={onClose} size="xl" title="Pilih Titik Lokasi">
      <>
        <div className="relative">
          <div className="flex">
            <TextField
              placeholder="Cari Lokasi"
              type="search"
              onChange={(e) => setSearch(e.target.value)}
              className="mt-4"
            />
          </div>
          {search !== "" ? (
            loading ? (
              <div className="absolute top-full z-50 w-full bg-white shadow-lg rounded overflow-hidden py-3 px-4">
                Loading...
              </div>
            ) : result.length > 0 ? (
              <div className="absolute top-full z-50 w-full">
                <div className="bg-white shadow-lg rounded overflow-hidden w-full">
                  {result.map((e, i) => (
                    <div
                      key={i}
                      className="hover:bg-gray-200 py-3 px-4 cursor-pointer flex justify-between items-center"
                      onClick={() => {
                        setCenter({ lat: +e.lat, lng: +e.lon });
                        setLatLng({ lat: +e.lat, lng: +e.lon });
                        setSearch("");
                      }}
                    >
                      <span>{e.display_name}</span>
                      <i className="fa fa-map-marker-alt"></i>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="absolute top-full z-50 w-full bg-white shadow-lg rounded overflow-hidden py-3 px-4">
                Location Not Found
              </div>
            )
          ) : (
            <></>
          )}
        </div>
        {show ? (
          <MapContainer
            className="z-0"
            style={{ height: "400px", width: "100%" }}
            center={center}
            zoom={15}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <RecenterAutomatically lat={center.lat} lng={center.lng} />
            <Marker
              position={latLng}
              icon={icon}
              draggable={true}
              autoPan={true}
              eventHandlers={eventHandlers}
            >
              <Popup>
                {latLng.lat},{latLng.lng}
              </Popup>
            </Marker>
          </MapContainer>
        ) : (
          <></>
        )}
        <Button color="primary" onClick={handleSubmit} className="mt-4">
          Selesai
        </Button>
      </>
    </Dialog>
  );
};

export { DialogSelectLocation };
