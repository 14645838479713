import { Button, FileSelect, Icon, SelectField, TextField } from "components";
import { ToggleSwitch } from "flowbite-react";
import { getIn, useFormik } from "formik";
import moment from "moment";
import {
  ActionTypes,
  addFunding,
  editFunding,
  getFundingDetail,
} from "pages/admin/redux";
import { FC, useMemo } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DashboardFundingInput: FC<{
  isEdit: boolean;
  funding?: any;
}> = ({ funding, isEdit }) => {
  const dispatch = useDispatch();
  const kriteria = [
    { label: "Pilih Kriteria", value: "" },
    { label: "Usaha Mikro", value: "mikro" },
    { label: "Usaha Kecil", value: "kecil" },
    { label: "Usaha Menengah", value: "menengah" },
  ];

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_FUNDING)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_FUNDING)
  );

  const initialValues = useMemo(() => {
    return {
      title: isEdit ? funding?.title ?? "" : "",
      penyelenggara: isEdit ? funding?.penyelenggara ?? "" : "",
      deadline: isEdit
        ? moment(funding?.deadline).format("YYYY-MM-DD HH:mm") ?? ""
        : "",
      kriteria: isEdit ? funding?.kriteria ?? "" : "",
      banner: isEdit ? funding?.banner ?? "" : "",
      persyaratan: isEdit ? funding?.persyaratan ?? [""] : [""],
      status: isEdit ? funding?.status ?? "O" : "O",
    };
  }, [isEdit, funding]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Judul Pelatihan is required"),
    penyelenggara: Yup.string().required("Penyelenggara is required"),
    deadline: Yup.string().required("Deadline is required"),
    kriteria: Yup.string().required("Kriteria is required"),
    persyaratan: Yup.array().of(
      Yup.string().required("Persyaratan is required")
    ),
    banner: Yup.string().optional().required("Foto is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (isEdit) {
        dispatch(
          editFunding({
            params: { ...values, fundingId: funding?._id },
            onSuccess(e) {
              toast.success("Berhasil mengedit pendanaan");
              dispatch(
                getFundingDetail({
                  params: {
                    fundingId: funding._id,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Gagal mengedit pendanaan");
            },
          })
        );
      } else {
        dispatch(
          addFunding({
            params: values,
            onSuccess(e) {
              toast.success("Berhasil membuat pendanaan");
            },
            onFailure(e) {
              toast.error("Gagal membuat pendanaan");
            },
          })
        );
      }
    },
  });

  return (
    <>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Foto Pendanaan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Foto akan di tampilkan sebagai thumbnail untuk detail pendanaan
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <FileSelect
            imageUrl={formHandler.values.banner}
            onChange={(value) => {
              formHandler.setFieldTouched("banner");
              formHandler.setFieldValue("banner", value);
            }}
            error={formHandler.touched.banner && !!formHandler.errors.banner}
            errorText={`${formHandler.errors.banner ?? ""}`}
          />
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Detail Pendanaan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan detail pendanaan seperti waktu penyelenggara kriteria dan
            lain lain.
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <TextField
            label="Judul Pendanaan"
            propsInput={{ ...formHandler.getFieldProps("title") }}
            error={!!formHandler.touched.title && !!formHandler.errors.title}
            errorText={`${
              formHandler.touched.title && formHandler.errors.title
            }`}
          />
          <TextField
            label="Penyelenggara"
            propsInput={{ ...formHandler.getFieldProps("penyelenggara") }}
            error={
              !!formHandler.touched.penyelenggara &&
              !!formHandler.errors.penyelenggara
            }
            errorText={`${
              formHandler.touched.penyelenggara &&
              formHandler.errors.penyelenggara
            }`}
          />
          <TextField
            type="datetime-local"
            label="Deadline"
            propsInput={{ ...formHandler.getFieldProps("deadline") }}
            error={
              !!formHandler.touched.deadline && !!formHandler.errors.deadline
            }
            errorText={`${
              formHandler.touched.deadline && formHandler.errors.deadline
            }`}
          />
          <SelectField
            label="Nama Badan Usaha"
            options={kriteria}
            value={formHandler.values.kriteria}
            onChange={(e) => {
              formHandler.setFieldTouched("kriteria");
              formHandler.setFieldValue("kriteria", e.target.value ?? "");
            }}
            error={
              !!formHandler.touched.kriteria && !!formHandler.errors.kriteria
            }
            errorText={`${formHandler.errors.kriteria}`}
            required
          />
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Persyaratan Pendanaan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan persyaratan untuk ikut pelatihan
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          {formHandler.values.persyaratan.map((item: any, index: number) => {
            const handleChangePersyaratan = (e: any) => {
              const updatedPersyaratans = [...formHandler.values.persyaratan];
              updatedPersyaratans[index] = e.target.value;
              formHandler.setFieldValue("persyaratan", updatedPersyaratans);
              formHandler.setFieldTouched("persyaratan");
            };

            const handleDeletePersyaratan = () => {
              const updatedPersyaratans = formHandler.values.persyaratan.filter(
                (_: any, i: number) => i !== index
              );
              formHandler.setFieldValue("persyaratan", updatedPersyaratans);
            };

            return (
              <div className="flex items-center mb-2" key={index}>
                <TextField
                  placeholder="Persyaratan"
                  padingBottom={0}
                  propsInput={{
                    value: item,
                  }}
                  onChange={handleChangePersyaratan}
                  error={
                    !!getIn(formHandler.touched, `persyaratan[${index}]`) &&
                    !!getIn(formHandler.errors, `persyaratan[${index}]`)
                  }
                  errorText={
                    formHandler.errors?.persyaratan &&
                    (formHandler.errors.persyaratan as any)[index]
                  }
                />
                {formHandler.values.persyaratan.length > 1 && (
                  <span
                    className="ml-2 cursor-pointer"
                    onClick={handleDeletePersyaratan}
                  >
                    <Icon name="Delete" />
                  </span>
                )}
              </div>
            );
          })}
          <Button
            color="light"
            className="mt-2"
            size="xs"
            onClick={() => {
              formHandler.setFieldValue("persyaratan", [
                ...formHandler.values.persyaratan,
                "",
              ]);
            }}
          >
            Tambah <i className="fa fa-plus"></i>
          </Button>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <Button
            color="failure"
            onClick={() => formHandler.submitForm()}
            loading={isLoadingAdd || isLoadingEdit}
            disabled={isLoadingAdd || isLoadingEdit}
          >
            Submit
          </Button>
        </div>
        <div className="w-full max-w-[300px] relative">
          <ToggleSwitch
            label="Status Pendanaan"
            checked={formHandler.values.status === "O"}
            onChange={(e) => {
              formHandler.setFieldValue("status", e ? "O" : "C");
            }}
          />
        </div>
      </div>
    </>
  );
};
export { DashboardFundingInput };
