import { FC } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { uploadFile } from "utils/uploadFile";

interface CkeditorProps {
  error?: boolean;
  errorText?: string;
  onChange: (value: string) => void;
  value?: string;
}

function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        const body = new FormData();
        loader.file.then((file: any) => {
          uploadFile(file)
            .then((res) => {
              resolve({
                default: res.url,
              });
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },
  };
}

function uploadPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}

const Ckeditor: FC<CkeditorProps> = ({ error, errorText, onChange, value }) => {
  return (
    <>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor: any) => {
          const data = editor.getData();
          onChange(data);
        }}
        config={{
          extraPlugins: [uploadPlugin],
        }}
      />
      {error && <div className="text-sm mt-2 text-red-500">{errorText}</div>}
    </>
  );
};

export { Ckeditor };
