import { Icon, MainLayout, TableComponent } from "components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMitraDetail } from "pages/admin/redux";
import { RootState } from "setup";
import { DashboardMitraInput } from "./DashboardMitraInput";
import { DashboardMitraData } from "./DashboardMitraData";
import { PATH } from "config";

const DashboardMitraDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<{
    partnerId: string;
  }>();
  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [active, setActive] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const isLoading = false;

  const columns: any = [
    {
      header: "Foto",
      field: "foto",
      rowRender: (row: any) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row.fotoProduct ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Nama Produk",
      field: "namaProduct",
    },
    {
      header: "Harga",
      field: "harga",
    },
    // {
    //   header: "Tanggal",
    //   field: "updatedAt",
    // },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: any) => {
        return (
          <div className="flex">
            <span
              className="px-2 cursor-pointer"
              onClick={() =>
                navigate(`${PATH.DASHBOARD_PRODUCT}/edit/${row._id}`)
              }
            >
              <Icon name="Edit" />
            </span>
            {/* <span
              className="px-2 cursor-pointer"
              // onClick={() => setDialogDelete({ show: true, id: row._id })}
            >
              <Icon name="Delete" />
            </span> */}
          </div>
        );
      },
    },
  ];

  const mitra = useSelector((state: RootState) => state.admin.mitraDetail);

  useEffect(() => {
    if (params.partnerId) {
      dispatch(
        getMitraDetail({
          params: {
            id: params.partnerId,
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout>
      <>
        <div className="bg-white shadow rounded">
          <div className="text-lg p-8 font-semibold text-gray-800 border-b">
            Detail Mitra
          </div>
          <div className="flex px-8">
            <div
              onClick={() => setActive(0)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 0
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Detail
            </div>
            <div
              onClick={() => setActive(1)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 1
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Produk
            </div>
          </div>
        </div>
        {active === 0 ? (
          <>
            <div className="bg-white shadow rounded p-8 mt-4">
              <div className="w-full max-w-3xl mx-auto">
                <div className="flex justify-between mb-8">
                  <div className="text-lg font-semibold text-gray-800">
                    Data Pelatihan
                  </div>
                  {!isEdit ? (
                    <div
                      className="cursor-pointer text-blue-500"
                      onClick={() => setIsEdit(true)}
                    >
                      <i className="fa fa-edit mr-2"></i>Edit
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer text-red-500"
                      onClick={() => setIsEdit(false)}
                    >
                      Cancel
                    </div>
                  )}
                </div>
                {isEdit ? (
                  <DashboardMitraInput mitra={mitra} isEdit={true} />
                ) : (
                  <DashboardMitraData mitra={mitra} />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <TableComponent
              columns={columns}
              data={mitra.products ?? []}
              isLoading={isLoading}
              limit={limit}
              pagination={{
                currentPage: page,
                totalPages: Math.ceil(mitra.products.length / limit),
                totalCount: mitra.products.length,
                onPrev: () => {
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                  }
                },
                onNext: () => {
                  if (page < Math.ceil(mitra.products.length / limit)) {
                    setPage((prev) => prev + 1);
                  }
                },
              }}
              isDownload
              localPagination
            />
          </div>
        )}
      </>
    </MainLayout>
  );
};
export default DashboardMitraDetail;
