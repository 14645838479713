import {
  Button,
  FileSelect,
  MainLayout,
  SelectField,
  TextField,
} from "components";
import { FileSelectMulti } from "components/FileSelectMulti/FileSelectMulti";
import { ToggleSwitch } from "flowbite-react";
import { useFormik } from "formik";
import {
  ActionTypes,
  editProduct,
  getCategory,
  getProductDetail,
} from "pages/admin/redux";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DashboardProductInput: FC<{
  isEdit: boolean;
}> = ({ isEdit }) => {
  const dispatch = useDispatch();

  const params = useParams<{
    productId: string;
  }>();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_PRODUCT)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_PRODUCT)
  );

  const { category, productDetail } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteIndex, setDeleteIndex] = useState<number>(0);

  const initialValues = useMemo(() => {
    return {
      namaProduct: isEdit ? productDetail.namaProduct : "",
      deskripsi: isEdit ? productDetail.deskripsi : "",
      harga: isEdit ? productDetail.harga : 0,
      nominalSatuan: isEdit ? productDetail.nominalSatuan : "",
      beratSatuan: isEdit ? productDetail.beratSatuan : "",
      category: isEdit
        ? productDetail.category[0]
          ? productDetail.category[0]._id
          : ""
        : "",
      foto: isEdit ? productDetail.fotoProduct.map((e) => e.image) : [],
      images: {
        submit: [],
        remove: [],
      },
      status: isEdit ? productDetail.status : "A",
    };
  }, [isEdit, productDetail]);

  const validationSchema = Yup.object({
    namaProduct: Yup.string().min(5, "Nama Produk minimum 5 huruf").required(),
    category: Yup.string().required("Kategori wajib dipilih"),
    harga: Yup.number()
      .min(1, "Harga tidak boleh kosong")
      .required("Harga wajib diisi"),
    beratSatuan: Yup.string().required("Berat satuan wajib diisi"),
    nominalSatuan: Yup.number()
      .min(0.1, "Nominal Satuan tidak boleh kosong")
      .required("Nominal satuan wajib diisi"),
    deskripsi: Yup.string().required(),
    foto: Yup.array()
      .of(Yup.string().required("Foto is required"))
      .min(1, "At least one foto is required")
      .required("Foto is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      if (isEdit) {
        const foto = values.foto
          .filter((e) => e.indexOf("data:image") > -1)
          .map((e) => e);

        let updateData: any = {
          productId: productDetail._id,
          namaProduct: values.namaProduct,
          category: [values.category],
          harga: values.harga,
          beratSatuan: values.beratSatuan,
          nominalSatuan: values.nominalSatuan,
          deskripsi: values.deskripsi,
          userId: productDetail.mitra.userId,
          foto: values.foto,
        };

        if (foto.length > 0) {
          updateData.images = {
            submit: values.foto
              .filter((e) => e.indexOf("data:image") > -1)
              .map((e) => e),
            remove: values.images.remove,
          };
        }

        dispatch(
          editProduct({
            params: updateData,
            onSuccess(e) {
              toast.success("Berhasil mengedit produk");
              dispatch(
                getProductDetail({
                  params: {
                    productId: params.productId,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Gagal mengedit produk");
            },
          })
        );
      }
    },
  });

  const handleRemove = useCallback(
    (index: number) => {
      formHandler.setFieldValue(
        "foto",
        formHandler.values.foto.filter((_, i) => i !== index)
      );
    },
    [formHandler]
  );

  useEffect(() => {
    dispatch(getCategory({}));
    dispatch(
      getProductDetail({
        params: {
          productId: params.productId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Data Wajib</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan data wajib untuk keperluan verifikasi.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                label="Nama"
                propsInput={{ ...formHandler.getFieldProps("namaProduct") }}
                error={
                  formHandler.touched.namaProduct &&
                  !!formHandler.errors.namaProduct
                }
                errorText={formHandler.errors.namaProduct}
              />
              <TextField
                label="Deskripsi"
                propsInput={{ ...formHandler.getFieldProps("deskripsi") }}
                error={
                  formHandler.touched.deskripsi &&
                  !!formHandler.errors.deskripsi
                }
                errorText={formHandler.errors.deskripsi}
              />
              <TextField
                type="number"
                label="Harga"
                propsInput={{ ...formHandler.getFieldProps("harga") }}
                error={formHandler.touched.harga && !!formHandler.errors.harga}
                errorText={formHandler.errors.harga}
              />
              <TextField
                type="number"
                label="Nominal Satuan"
                propsInput={{ ...formHandler.getFieldProps("nominalSatuan") }}
                error={
                  formHandler.touched.nominalSatuan &&
                  !!formHandler.errors.nominalSatuan
                }
                errorText={formHandler.errors.nominalSatuan}
              />
              <TextField
                label="Berat Satuan"
                propsInput={{ ...formHandler.getFieldProps("beratSatuan") }}
                error={
                  formHandler.touched.beratSatuan &&
                  !!formHandler.errors.beratSatuan
                }
                errorText={formHandler.errors.beratSatuan}
              />
              <SelectField
                label="Kategori"
                options={[
                  { label: "Pilih Kategori", value: "" },
                  ...category.map((e) => ({
                    label: e.name,
                    value: e.id,
                  })),
                ]}
                value={formHandler.values.category as string}
                onChange={(e) => {
                  formHandler.setFieldTouched("category");
                  formHandler.setFieldValue("category", e?.target?.value ?? "");
                }}
                error={
                  !!formHandler.touched.category &&
                  !!formHandler.errors.category
                }
                errorText={`${formHandler.errors.category}`}
                required
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Foto Produk</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Foto produk akan di tampilkan ketika mengeklik detail produk
                atau di list all produk
              </div>
            </div>
            <div className="w-full max-w-[300px]">
              <FileSelectMulti
                formHandler={formHandler}
                name="foto"
                error={formHandler.touched.foto && !!formHandler.errors.foto}
                errorText={`${formHandler.errors.foto ?? ""}`}
                onDelete={(index) => {
                  if (productDetail.fotoProduct[index]?._id !== undefined) {
                    formHandler.setFieldValue(
                      `images.remove[${deleteIndex}]`,
                      productDetail.fotoProduct[index]._id
                    );
                  }
                  handleRemove(index);
                  setDeleteIndex(deleteIndex + 1);
                }}
              />
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <Button
                color="failure"
                loading={isLoadingAdd || isLoadingEdit}
                disabled={isLoadingAdd || isLoadingEdit}
                onClick={() => formHandler.submitForm()}
              >
                Submit
              </Button>
            </div>
            <div className="w-full max-w-[300px] relative">
              <ToggleSwitch
                label="Status Produk"
                checked={formHandler.values.status === "A"}
                onChange={(e) => {
                  formHandler.setFieldValue("status", e ? "A" : "N");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardProductInput };
