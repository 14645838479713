import {
  Button,
  FileSelect,
  Icon,
  MainLayout,
  SelectField,
  TextField,
} from "components";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "setup";

interface IPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

export interface ILatLng {
  lat: number;
  lng: number;
}

const DashboardDataUmum: FC<{
  isEdit?: boolean;
  formHandler: any;
  onSubmit: () => void;
  setDialog: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isEdit, formHandler, setDialog, onSubmit }) => {
  const mitra = useSelector((state: RootState) => state.user.mitra);

  const badanUsaha = [
    { label: "Personal", value: "Personal" },
    { label: "PT. Perorangan", value: "PT. Perorangan" },
    { label: "CV", value: "CV" },
    { label: "Firma", value: "Firma" },
    { label: "PT. (Perseroan Terbatas)", value: "PT. (Perseroan Terbatas)" },
    { label: "Persero Milik Negara", value: "Persero Milik Negara" },
    { label: "Perusahaan Daerah", value: "Perusahaan Daerah" },
    { label: "Koperasi", value: "Koperasi" },
  ];

  const { categoryList } = useSelector((state: RootState) => state.user);
  const { city, district, village } = useSelector(
    (state: RootState) => state.admin
  );

  return (
    <div className="p-6 pb-20">
      <div className="text-gray-800 font-semibold mb-2">Data Umum</div>
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Nama Badan Usaha"
        propsInput={{ ...formHandler.getFieldProps("namaMitra") }}
        onChange={(e) => {
          formHandler.setFieldTouched("namaMitra");
          formHandler.setFieldValue("namaMitra", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.namaMitra && !!formHandler.errors.namaMitra
        }
        errorText={`${formHandler.errors.namaMitra}`}
      />
      <div className="text-gray-800 font-semibold mb-2">Upload Foto Usaha</div>
      <FileSelect
        full
        imageUrl={formHandler.values.foto}
        config={{
          className: "w-130px h-130px object-cover",
          placeholder: (
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="130" height="130" rx="4" fill="white" />
              <rect
                x="15"
                y="15"
                width="100"
                height="100"
                rx="4"
                fill="#F6F7FB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.6662 75H69.3328C72.7231 75 75 72.6219 75 69.0833V60.9167C75 57.3781 72.7231 55 69.3338 55H60.6662C57.2769 55 55 57.3781 55 60.9167V69.0833C55 72.6219 57.2769 75 60.6662 75ZM61.4989 64C60.1202 64 59 62.8783 59 61.5C59 60.1217 60.1202 59 61.4989 59C62.8765 59 63.9977 60.1217 63.9977 61.5C63.9977 62.8783 62.8765 64 61.4989 64ZM72.8208 67.934C73.1557 68.7926 72.9817 69.8246 72.6237 70.6749C72.1994 71.6863 71.3869 72.452 70.3632 72.7864C69.9087 72.935 69.432 73 68.9564 73H60.5286C59.69 73 58.9479 72.7988 58.3395 72.4241C57.9584 72.1889 57.891 71.646 58.1736 71.2941C58.6462 70.7059 59.1128 70.1156 59.5834 69.5201C60.4804 68.3808 61.0847 68.0506 61.7565 68.3406C62.029 68.4603 62.3025 68.6398 62.584 68.8297C63.3342 69.3395 64.377 70.0402 65.7506 69.2797C66.6906 68.7532 67.2358 67.8501 67.7106 67.0637L67.7185 67.0506C67.7522 66.9954 67.7855 66.9402 67.8187 66.8852C67.9783 66.6212 68.1357 66.3607 68.3138 66.1207C68.5371 65.8204 69.3646 64.8813 70.4366 65.5501C71.1194 65.9711 71.6936 66.5408 72.308 67.1507C72.5423 67.3839 72.7092 67.6491 72.8208 67.934Z"
                fill="#CB043F"
              />
            </svg>
          ),
        }}
        //   imageUrl={formHandler.values.foto}
        onChange={(value) => {
          formHandler.setFieldValue("foto", value);
          formHandler.setFieldTouched("foto");
        }}
        error={formHandler.touched.foto && !!formHandler.errors.foto}
        errorText={`${formHandler.errors.foto ?? ""}`}
      />
      <SelectField
        options={badanUsaha}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Bentuk Badan Usaha"
        onChange={(e) => {
          formHandler.setFieldTouched("namaBadanUsaha");
          formHandler.setFieldValue("namaBadanUsaha", e.target.value ?? "");
        }}
        value={formHandler.values.namaBadanUsaha}
        error={
          !!formHandler.touched.namaBadanUsaha &&
          !!formHandler.errors.namaBadanUsaha
        }
        errorText={`${formHandler.errors.namaBadanUsaha}`}
      />
      <TextField
        type="number"
        className="rounded-lg p-4"
        variant="solid"
        placeholder="NPWP (optional)"
        propsInput={{ ...formHandler.getFieldProps("npwp") }}
        onChange={(e) => {
          formHandler.setFieldTouched("npwp");
          formHandler.setFieldValue("npwp", e.target.value ?? "");
        }}
        error={!!formHandler.touched.npwp && !!formHandler.errors.npwp}
        errorText={`${formHandler.errors.npwp}`}
      />
      <TextField
        type="number"
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Nomor Induk Berusaha/NIB (Optional)"
        propsInput={{ ...formHandler.getFieldProps("nib") }}
        onChange={(e) => {
          formHandler.setFieldTouched("nib");
          formHandler.setFieldValue("nib", e.target.value ?? "");
        }}
        error={!!formHandler.touched.nib && !!formHandler.errors.nib}
        errorText={`${formHandler.errors.nib}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Pejabat yang mensahkan Ijin Usaha"
        propsInput={{ ...formHandler.getFieldProps("pengizinUsaha") }}
        onChange={(e) => {
          formHandler.setFieldTouched("pengizinUsaha");
          formHandler.setFieldValue("pengizinUsaha", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.pengizinUsaha &&
          !!formHandler.errors.pengizinUsaha
        }
        errorText={`${formHandler.errors.pengizinUsaha}`}
      />
      <TextField
        type="file"
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Upload berkas ijin usaha"
        propsInput={{
          ...formHandler.getFieldProps("berkasIzinUsaha"),
          accept: "application/pdf",
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("berkasIzinUsaha");
          formHandler.setFieldValue("berkasIzinUsaha", e ?? "");
        }}
        error={
          !!formHandler.touched.berkasIzinUsaha &&
          !!formHandler.errors.berkasIzinUsaha
        }
        errorText={`${formHandler.errors.berkasIzinUsaha}`}
      />
      <SelectField
        options={categoryList.map((e) => ({
          label: e.name,
          value: e._id,
        }))}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Pilih Klasifikasi UMKM & Ekraf"
        onChange={(e) => {
          formHandler.setFieldTouched("klasifikasiEkraf");
          formHandler.setFieldValue("klasifikasiEkraf", e.target.value ?? "");
        }}
        value={formHandler.values.klasifikasiEkraf}
        error={
          !!formHandler.touched.klasifikasiEkraf &&
          !!formHandler.errors.klasifikasiEkraf
        }
        errorText={`${formHandler.errors.klasifikasiEkraf}`}
      />
      <TextField
        multiLine={3}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Deskripsi Usaha"
        propsInput={{ ...formHandler.getFieldProps("deskripsi") }}
        onChange={(e) => {
          formHandler.setFieldTouched("deskripsi");
          formHandler.setFieldValue("deskripsi", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.deskripsi && !!formHandler.errors.deskripsi
        }
        errorText={`${formHandler.errors.deskripsi}`}
      />
      <TextField
        type="number"
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Omzet Tahunan"
        propsInput={{ ...formHandler.getFieldProps("omset") }}
        onChange={(e) => {
          formHandler.setFieldTouched("omset");
          formHandler.setFieldValue("omset", e.target.value ?? "");
        }}
        error={!!formHandler.touched.omset && !!formHandler.errors.omset}
        errorText={`${formHandler.errors.omset}`}
      />
      <div className="text-gray-800 font-semibold mb-2">Alamat Usaha</div>
      <div className="mb-6">
        <div
          className={[
            "rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer",
            formHandler.touched.lat && !!formHandler.errors.lat
              ? "bg-red-50 border border-red-500"
              : "bg-white",
          ].join(" ")}
          onClick={() => setDialog(true)}
        >
          <div>
            {(formHandler.values.lat &&
              formHandler.values.long &&
              `${formHandler.values.lat}, ${formHandler.values.long}`) ||
              "Alamat Usaha"}
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.23926 10.3913C4.25367 6.15071 7.70302 2.72471 11.9436 2.73912C16.1842 2.75353 19.6102 6.20288 19.5958 10.4435V10.5304C19.5436 13.2869 18.0045 15.8348 16.1175 17.8261C15.0384 18.9467 13.8333 19.9388 12.5262 20.7826C12.1767 21.0849 11.6583 21.0849 11.3088 20.7826C9.36033 19.5143 7.65019 17.9131 6.25665 16.0522C5.01461 14.4294 4.30942 12.4597 4.23926 10.4174L4.23926 10.3913Z"
              stroke="#200E32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <circle
              cx="11.9179"
              cy="10.5391"
              r="2.46087"
              stroke="#200E32"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {formHandler.touched.lat && !!formHandler.errors.lat && (
          <div className="text-red-500 text-sm mt-2">{`${formHandler.errors.lat}`}</div>
        )}
        {formHandler.touched.alamat && !!formHandler.errors.alamat && (
          <div className="text-red-500 text-sm mt-2">{`${formHandler.errors.alamat}`}</div>
        )}
      </div>
      {/* <SelectField
      options={[]}
      className="rounded-lg p-4"
      variant="solid"
      placeholder="Provinsi"
      onChange={(e) => {
        formHandler.setFieldTouched("namaBadanUsaha");
        formHandler.setFieldValue(
          "namaBadanUsaha",
          e.target.value ?? ""
        );
      }}
      value={formHandler.values.namaBadanUsaha}
      error={
        !!formHandler.touched.namaBadanUsaha &&
        !!formHandler.errors.namaBadanUsaha
      }
      errorText={`${formHandler.errors.namaBadanUsaha}`}
    /> */}
      <SelectField
        options={city.map((e) => ({ label: e.name, value: e.id }))}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Kabupaten/Kota"
        onChange={(e) => {
          formHandler.setFieldTouched("kota");
          formHandler.setFieldValue("kota", e.target.value ?? "");
        }}
        value={formHandler.values.kota}
        error={!!formHandler.touched.kota && !!formHandler.errors.kota}
        errorText={`${formHandler.errors.kota}`}
      />
      <SelectField
        options={district.map((e) => ({ label: e.name, value: e.id }))}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Kecamatan"
        onChange={(e) => {
          formHandler.setFieldTouched("kecamatan");
          formHandler.setFieldValue("kecamatan", e.target.value ?? "");
        }}
        value={formHandler.values.kecamatan}
        error={
          !!formHandler.touched.kecamatan && !!formHandler.errors.kecamatan
        }
        errorText={`${formHandler.errors.kecamatan}`}
      />
      <SelectField
        options={village.map((e) => ({ label: e.name, value: e.id }))}
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Kelurahan"
        onChange={(e) => {
          formHandler.setFieldTouched("kelurahan");
          formHandler.setFieldValue("kelurahan", e.target.value ?? "");
        }}
        value={formHandler.values.kelurahan}
        error={
          !!formHandler.touched.kelurahan && !!formHandler.errors.kelurahan
        }
        errorText={`${formHandler.errors.kelurahan}`}
      />
      {
        isEdit && (
          // mitra.status !== "R" ? (
          //   <>
          //     <div className="text-gray-800 font-semibold mb-2">Keterangan</div>
          //   </>
          // ) : (
          <>
            <div className="text-gray-800 font-semibold mb-2">Status Usaha</div>
            <div className="bg-white p-4 rounded-lg mb-4">
              <div className="flex justify-between items-center mb-2">
                <div className="text-sm text-gray-600">Aktif</div>
                <input
                  type="radio"
                  name="status"
                  value="A"
                  checked={formHandler.values.status === "A"}
                  onChange={() => {
                    formHandler.setFieldValue("status", "A");
                    formHandler.setFieldTouched("status");
                  }}
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-600">Habis/Sembunyikan</div>
                <input
                  type="radio"
                  name="status"
                  value="N"
                  checked={formHandler.values.status === "N"}
                  onChange={() => {
                    formHandler.setFieldValue("status", "N");
                    formHandler.setFieldTouched("status");
                  }}
                />
              </div>
            </div>
          </>
        )
        // )}
      }
      <Button size="xl" full color="theme" onClick={onSubmit}>
        Selanjutnya
      </Button>
    </div>
  );
};

export { DashboardDataUmum };
