import { Button, FileSelect, Icon, TextField, SelectField } from "components";
import { getIn, useFormik } from "formik";
import {
  ActionTypes,
  IMitraDetail,
  addMitra,
  editMitra,
  getCategory,
  getCity,
  getDistrict,
  getMitraDetail,
  getUser,
  getVillage,
} from "pages/admin/redux";
import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import * as Yup from "yup";
import { DialogSelectLocation } from "./DialogSelectLocation";
import { Toaster, toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import moment from "moment";
import { ToggleSwitch } from "flowbite-react";

const DashboardMitraInput: FC<{
  isEdit: boolean;
  mitra?: IMitraDetail;
}> = ({ isEdit, mitra }) => {
  const dispatch = useDispatch();

  const params = useParams<{
    partnerId: string;
  }>();

  const initialValues = useMemo(() => {
    return {
      userId: isEdit ? mitra?.userId ?? "" : "",
      namaMitra: isEdit ? mitra?.namaMitra ?? "" : "",
      namaBadanUsaha: isEdit ? mitra?.namaBadanUsaha ?? "" : "",
      deskripsi: isEdit ? mitra?.deskripsi ?? "" : "",
      omset: isEdit ? mitra?.omset ?? "" : "",
      // category
      klasifikasiEkraf: isEdit ? mitra?.klasifikasiEkraf[0]?.id ?? "" : "",
      npwp: isEdit ? mitra?.npwp ?? "" : "",
      nib: isEdit ? mitra?.nib ?? "" : "",
      pengizinUsaha: isEdit ? mitra?.pengizinUsaha ?? "" : "",
      berkasIzinUsaha: isEdit ? mitra?.berkasIzinUsaha ?? "" : "",
      lat: isEdit ? mitra?.location?.lat ?? "" : "",
      long: isEdit ? mitra?.location?.long ?? "" : "",
      alamat: isEdit ? mitra?.alamat ?? "" : "",
      kota: isEdit ? mitra?.addressId?.kota : "",
      kecamatan: isEdit ? mitra?.addressId?.kecamatan : "",
      kelurahan: isEdit ? mitra?.addressId?.kelurahan : "",
      foto: isEdit ? mitra?.foto ?? "" : "",
      waktuOperasional: isEdit
        ? mitra?.waktuOperasional
        : {
            senin: {
              buka: "",
              tutup: "",
            },
            selasa: {
              buka: "",
              tutup: "",
            },
            rabu: {
              buka: "",
              tutup: "",
            },
            kamis: {
              buka: "",
              tutup: "",
            },
            jumat: {
              buka: "",
              tutup: "",
            },
            sabtu: {
              buka: "",
              tutup: "",
            },
            minggu: {
              buka: "",
              tutup: "",
            },
          },
      links: [""],
      status: isEdit ? mitra?.status : "A",
    };
  }, [isEdit]);

  const marketplaces = [
    { label: "Tokopedia", value: "tokopedia" },
    { label: "Shopee", value: "shopee" },
    { label: "Bukalapak", value: "bukalapak" },
    { label: "Twitter", value: "twitter" },
    { label: "Facebook", value: "facebook" },
    { label: "Instagram", value: "instagram" },
    { label: "Telegram", value: "telegram" },
    { label: "Youtube", value: "youtube" },
    { label: "Web", value: "web" },
  ];

  const PDFToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileInput = async (e: any) => {
    try {
      let file = e.target.files[0];
      if (file?.size > 2 * 1024 * 1024) {
        toast.error("File Terlalu Besar ");
        return;
      }
      if (!file) return;
      const base64: any = await PDFToBase64(file);
      formHandler.setFieldValue("berkasIzinUsaha", base64);
      formHandler.setFieldTouched("berkasIzinUsaha");
    } catch (error) {
      console.log(error);
      toast.error("Error Ketika Memilih Berkas Izin Usaha");
    }
  };

  function getNameValueFromURL(url: string, marketplaces: any[]) {
    const matchedMarketplace = marketplaces.find((marketplace) => {
      const pattern = new RegExp(
        `^https?:\/\/(.+\.)?${marketplace.value}`,
        "i"
      );
      return pattern.test(url);
    });

    if (matchedMarketplace) {
      return { name: matchedMarketplace.label, value: url };
    } else {
      return null; // Return null if no match is found
    }
  }

  const validationSchema = Yup.object({
    userId: Yup.string().required("User is required"),
    namaMitra: Yup.string().required("Nama Usaha is required"),
    deskripsi: Yup.string().required("Deskripsi Usaha is required"),
    omset: Yup.number().required("Omzet Tahunan is required"),
    klasifikasiEkraf: Yup.string().required("Kategori is required"),
    foto: Yup.string().required("Foto is reqiured"),
    // npwp: Yup.string().required("NPWP is required"),
    // nib: Yup.string().required("NIB is required"),
    // pengizinUsaha: Yup.string().required(
    //   "Pejabat yang mensahkan izin usaha is required"
    // ),
    // berkasIzinUsaha: isEdit
    //   ? Yup.string().nullable().optional()
    //   : Yup.string().required("Berkas Izin Usaha is required"),
    alamat: Yup.string().required("Alamat is required"),
    lat: Yup.string().required("Location is required"),
    long: Yup.string().required("Location is required"),
    kota: Yup.string().required("Kota is required"),
    kecamatan: Yup.string().required("Kecamatan is required"),
    kelurahan: Yup.string().required("Kelurahan is required"),
    links: Yup.array()
      .of(
        Yup.string().url("Invalid URL format")
        // .required("URL is required")
      )
      .nullable()
      .optional(),
    // .required("At least one URL is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      const links: any[] = values.links
        .map((url) => getNameValueFromURL(url, marketplaces))
        .filter((result) => result !== null);

      const convertedLink = Object.fromEntries(
        links.map(({ name, value }) => [name, value])
      );

      let valuesData: any = {
        userId: values.userId,
        namaMitra: values.namaMitra,
        namaBadanUsaha: values.namaBadanUsaha,
        deskripsi: values.deskripsi,
        omset: values.omset,
        npwp: values.npwp.toString(),
        nib: values.nib.toString(),
        pengizinUsaha: values.pengizinUsaha,
        alamat: values.alamat,
        kota: values.kota,
        kecamatan: values.kecamatan,
        kelurahan: values.kelurahan,
        waktuOperasional: values.waktuOperasional,
        links: convertedLink,
        klasifikasiEkraf: [values.klasifikasiEkraf],
        foto: values.foto,
        location: {
          lat: values.lat,
          long: values.long,
        },
        status: values.status,
      };

      if (values.berkasIzinUsaha !== "") {
        valuesData.berkasIzinUsaha = values.berkasIzinUsaha;
      }

      if (isEdit) {
        dispatch(
          editMitra({
            params: {
              ...valuesData,
              mitraId: mitra?._id,
            },
            onSuccess(e) {
              toast.success("Berhasil Mengedit Mitra");
              dispatch(
                getMitraDetail({
                  params: {
                    id: mitra?._id,
                  },
                })
              );
            },
            onFailure(e) {
              if (e.msg) {
                toast.error(e.msg);
              } else {
                toast.error("Gagal Mengedit Mitra");
              }
            },
          })
        );
      } else {
        dispatch(
          addMitra({
            params: valuesData,
            onSuccess(e) {
              toast.success("Berhasil Membuat Mitra");
            },
            onFailure(e) {
              if (e.msg) {
                toast.error(e.msg);
              } else {
                toast.error("Gagal Membuat Mitra");
              }
            },
          })
        );
      }
    },
  });

  const [showSelectLocation, setShowSelectLocation] = useState<boolean>(false);

  const { city, district, village, users, category } = useSelector(
    (state: RootState) => state.admin
  );
  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_MITRA)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_MITRA)
  );

  const TYPE_BUSSINESS = [
    { label: "Personal", value: "Personal" },
    { label: "PT. Perorangan", value: "PT. Perorangan" },
    { label: "CV", value: "CV" },
    { label: "Firma", value: "Firma" },
    { label: "PT. (Perseroan Terbatas)", value: "PT. (Perseroan Terbatas)" },
    { label: "Persero Milik Negara", value: "Persero Milik Negara" },
    { label: "Perusahaan Daerah", value: "Perusahaan Daerah" },
    { label: "Koperasi", value: "Koperasi" },
  ];

  useEffect(() => {
    dispatch(
      getUser({
        params: {
          nama: "",
          email: "",
          status: ["A"],
        },
      })
    );
    dispatch(getCategory({}));
    dispatch(getCity());
  }, []);

  useEffect(() => {
    if (formHandler.values.kota !== "") {
      dispatch(
        getDistrict({
          params: {
            regencyId: formHandler.values.kota,
          },
        })
      );
    }
  }, [formHandler.values.kota]);

  useEffect(() => {
    if (formHandler.values.kecamatan !== "") {
      dispatch(
        getVillage({
          params: {
            districtId: formHandler.values.kecamatan,
          },
        })
      );
    }
  }, [formHandler.values.kecamatan]);

  return (
    <>
      <DialogSelectLocation
        show={showSelectLocation}
        onClose={() => {
          setShowSelectLocation(false);
        }}
        onSubmit={(e: any) => {
          formHandler.setFieldTouched("lat");
          formHandler.setFieldTouched("long");
          formHandler.setFieldValue("lat", e.lat);
          formHandler.setFieldValue("long", e.lng);
          setShowSelectLocation(false);
        }}
      />
      <div className="bg-white rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Foto Usaha</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Foto akan di tampilkan di dashboard akun pengguna.
              </div>
            </div>
            <div className="w-full max-w-[300px]">
              <FileSelect
                imageUrl={formHandler.values.foto}
                onChange={(value) => {
                  formHandler.setFieldTouched("foto");
                  formHandler.setFieldValue("foto", value);
                }}
                error={formHandler.touched.foto && !!formHandler.errors.foto}
                errorText={`${formHandler.errors.foto ?? ""}`}
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Data Wajib</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan data wajib untuk keperluan verifikasi.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <SelectField
                label="Pemilik Usaha"
                onChange={(e) => {
                  formHandler.setFieldTouched("userId");
                  formHandler.setFieldValue("userId", e.target.value ?? "");
                }}
                value={formHandler.values.userId}
                options={[
                  { label: "Pilih Pengguna", value: "" },
                  ...users.map((e) => ({
                    label: e.nama,
                    value: e.id,
                  })),
                ]}
                error={
                  !!formHandler.touched.userId && !!formHandler.errors.userId
                }
                errorText={`${formHandler.errors.userId}`}
                required
              />
              <TextField
                label="Nama Usaha"
                propsInput={{ ...formHandler.getFieldProps("namaMitra") }}
                onChange={(e) => {
                  formHandler.setFieldTouched("namaMitra");
                  formHandler.setFieldValue("namaMitra", e.target.value ?? "");
                }}
                error={
                  !!formHandler.touched.namaMitra &&
                  !!formHandler.errors.namaMitra
                }
                errorText={`${formHandler.errors.namaMitra}`}
                required
              />
              <SelectField
                label="Nama Badan Usaha"
                placeholder="Pilih Badan Usaha"
                options={TYPE_BUSSINESS}
                value={formHandler.values.namaBadanUsaha}
                onChange={(e) => {
                  formHandler.setFieldTouched("namaBadanUsaha");
                  formHandler.setFieldValue(
                    "namaBadanUsaha",
                    e.target.value ?? ""
                  );
                }}
                error={
                  !!formHandler.touched.namaBadanUsaha &&
                  !!formHandler.errors.namaBadanUsaha
                }
                errorText={`${formHandler.errors.namaBadanUsaha}`}
                required
              />
              <TextField
                label="Deskripsi Usaha"
                propsInput={{ ...formHandler.getFieldProps("deskripsi") }}
                error={
                  !!formHandler.touched.deskripsi &&
                  !!formHandler.errors.deskripsi
                }
                errorText={`${formHandler.errors.deskripsi}`}
                required
              />
              <TextField
                type="number"
                label="Omzet Tahunan"
                propsInput={{ ...formHandler.getFieldProps("omset") }}
                error={
                  !!formHandler.touched.omset && !!formHandler.errors.omset
                }
                errorText={`${formHandler.errors.omset}`}
                required
              />
              <SelectField
                label="Kategori"
                options={[
                  { label: "Pilih Kategori", value: "" },
                  ...category.map((e) => ({
                    label: e.name,
                    value: e.id,
                  })),
                ]}
                value={formHandler.values.klasifikasiEkraf}
                onChange={(e) => {
                  formHandler.setFieldTouched("klasifikasiEkraf");
                  formHandler.setFieldValue(
                    "klasifikasiEkraf",
                    e?.target?.value ?? ""
                  );
                }}
                error={
                  !!formHandler.touched.klasifikasiEkraf &&
                  !!formHandler.errors.klasifikasiEkraf
                }
                errorText={`${formHandler.errors.klasifikasiEkraf}`}
                required
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Berkas</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan berkas terkait izin usaha.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                type="number"
                label="NPWP"
                propsInput={{ ...formHandler.getFieldProps("npwp") }}
                error={!!formHandler.touched.npwp && !!formHandler.errors.npwp}
                errorText={`${formHandler.errors.npwp}`}
              />
              <TextField
                type="number"
                label="NIB"
                propsInput={{ ...formHandler.getFieldProps("nib") }}
                error={!!formHandler.touched.nib && !!formHandler.errors.nib}
                errorText={`${formHandler.errors.nib}`}
              />
              <TextField
                label="Pejabat yang mensahkan izin usaha"
                propsInput={{ ...formHandler.getFieldProps("pengizinUsaha") }}
                error={
                  !!formHandler.touched.pengizinUsaha &&
                  !!formHandler.errors.pengizinUsaha
                }
                errorText={`${formHandler.errors.pengizinUsaha}`}
              />
              <TextField
                type="file"
                label="berkas Izin Usaha"
                propsInput={{
                  ...formHandler.getFieldProps("berkasIzinUsaha"),
                  accept: "application/pdf",
                }}
                onChange={(e) => {
                  formHandler.setFieldTouched("berkasIzinUsaha");
                  formHandler.setFieldValue("berkasIzinUsaha", e ?? "");
                }}
                error={
                  !!formHandler.touched.berkasIzinUsaha &&
                  !!formHandler.errors.berkasIzinUsaha
                }
                errorText={`${formHandler.errors.berkasIzinUsaha}`}
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Alamat</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan detail alamat usaha.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                label="Pilih Lokasi"
                placeholder="-6.2318152,106.6881042"
                propsInput={{
                  value: `${formHandler.values.lat},${formHandler.values.long}`,
                  onClick: () => setShowSelectLocation(true),
                  readOnly: true,
                }}
                error={!!formHandler.touched.lat && !!formHandler.errors.lat}
                errorText={`${formHandler.errors.lat}`}
                required
              />
              <TextField
                label="Alamat"
                propsInput={{ ...formHandler.getFieldProps("alamat") }}
                error={
                  !!formHandler.touched.alamat && !!formHandler.errors.alamat
                }
                errorText={`${formHandler.errors.alamat}`}
                required
              />
              <SelectField
                options={[
                  { label: "Pilih Kota", value: "" },
                  ...city.map((e) => ({ label: e.name, value: e.id })),
                ]}
                label="Kabupaten/Kota"
                value={formHandler.values.kota}
                onChange={(e) => {
                  formHandler.setFieldTouched("kota");
                  formHandler.setFieldValue("kota", e.target.value ?? "");
                }}
                error={formHandler.touched.kota && !!formHandler.errors.kota}
                errorText={`${formHandler.errors.kota}`}
                required
              />
              <SelectField
                options={[
                  {
                    label: "Pilih Kecamatan",
                    value: "",
                  },
                  ...district.map((e) => ({ label: e.name, value: e.id })),
                ]}
                label="Kecamatan"
                value={formHandler.values.kecamatan}
                onChange={(e) => {
                  formHandler.setFieldTouched("kecamatan");
                  formHandler.setFieldValue("kecamatan", e.target.value ?? "");
                }}
                error={
                  formHandler.touched.kecamatan &&
                  !!formHandler.errors.kecamatan
                }
                errorText={`${formHandler.errors.kecamatan}`}
                required
              />
              <SelectField
                options={[
                  {
                    label: "Pilih Kelurahan",
                    value: "",
                  },
                  ...village.map((e) => ({ label: e.name, value: e.id })),
                ]}
                label="Kelurahan"
                value={formHandler.values.kelurahan}
                onChange={(e) => {
                  formHandler.setFieldTouched("kelurahan");
                  formHandler.setFieldValue("kelurahan", e.target.value ?? "");
                }}
                error={
                  formHandler.touched.kelurahan &&
                  !!formHandler.errors.kelurahan
                }
                errorText={`${formHandler.errors.kelurahan}`}
                required
              />
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Waktu Operasional</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Jika Waktu di reset menjadi kosong maka dianggap tutup
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <div className="container mx-auto">
                <table className="min-w-full border-collapse border border-gray-200 rounded">
                  <thead>
                    <tr>
                      <th className="py-2 px-4 border-b text-sm font-semibold">
                        Hari
                      </th>
                      <th className="py-2 px-4 border-b text-sm font-semibold">
                        Waktu Buka
                      </th>
                      <th className="py-2 px-4 border-b text-sm font-semibold">
                        Waktu Tutup
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      "senin",
                      "selasa",
                      "rabu",
                      "kamis",
                      "jumat",
                      "sabtu",
                      "minggu",
                    ].map((item, index) => (
                      <tr key={index}>
                        <td className="py-2 px-4 border-b text-sm capitalize">
                          {item}
                        </td>
                        <td className="py-2 px-4 border-b text-sm">
                          <TextField
                            type="time"
                            className="border border-gray-300 rounded p-2"
                            propsInput={{
                              ...formHandler.getFieldProps(
                                `waktuOperasional.${item}.buka`
                              ),
                              value: `${
                                formHandler.values.waktuOperasional[item]
                                  ?.buka &&
                                moment(
                                  formHandler.values.waktuOperasional[item]
                                    ?.buka,
                                  "HH.mm"
                                ).format("HH:mm")
                              }`,
                            }}
                            error={
                              !!getIn(
                                formHandler.touched,
                                `waktuOperasional[${item}].buka`
                              ) &&
                              !!getIn(
                                formHandler.errors,
                                `waktuOperasional[${item}].buka`
                              )
                            }
                            errorText={
                              formHandler.errors?.waktuOperasional &&
                              (formHandler.errors.waktuOperasional as any)[item]
                                ?.buka
                            }
                          />
                        </td>
                        <td className="py-2 px-4 border-b text-sm">
                          <TextField
                            type="time"
                            className="border border-gray-300 rounded p-2"
                            propsInput={{
                              ...formHandler.getFieldProps(
                                `waktuOperasional[${item}].tutup`
                              ),
                              value: `${
                                formHandler.values.waktuOperasional[item]
                                  ?.tutup &&
                                moment(
                                  formHandler.values.waktuOperasional[item]
                                    ?.tutup,
                                  "HH.mm"
                                ).format("HH:mm")
                              }`,
                            }}
                            error={
                              !!getIn(
                                formHandler.touched,
                                `waktuOperasional[${item}].tutup`
                              ) &&
                              !!getIn(
                                formHandler.errors,
                                `waktuOperasional[${item}].tutup`
                              )
                            }
                            errorText={
                              formHandler.errors?.waktuOperasional &&
                              (formHandler.errors.waktuOperasional as any)[item]
                                ?.tutup
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Link Marketplace</div>
              <div className="text-sm text-gray-600 max-w-[400px]">
                Masukan link marketplace.
              </div>
            </div>
            <div className="w-full max-w-[300px] relative">
              {formHandler.values.links.map((item, index) => {
                const handleChangeLink = (e: any) => {
                  const updatedLinks = [...formHandler.values.links];
                  updatedLinks[index] = e.target.value;
                  formHandler.setFieldValue("links", updatedLinks);
                  formHandler.setFieldTouched("links");
                };

                const handleDeleteLink = () => {
                  const updatedLinks = formHandler.values.links.filter(
                    (_, i) => i !== index
                  );
                  formHandler.setFieldValue("links", updatedLinks);
                };

                return (
                  <div className="flex items-center mb-2" key={index}>
                    <TextField
                      placeholder="Masukan Link Marketplace"
                      padingBottom={0}
                      propsInput={{
                        value: item,
                      }}
                      onChange={handleChangeLink}
                      error={
                        !!getIn(formHandler.touched, `links[${index}]`) &&
                        !!getIn(formHandler.errors, `links[${index}]`)
                      }
                      errorText={
                        formHandler.errors?.links &&
                        (formHandler.errors.links as any)[index]
                      }
                    />
                    {formHandler.values.links.length > 1 && (
                      <span
                        className="ml-2 cursor-pointer"
                        onClick={handleDeleteLink}
                      >
                        <Icon name="Delete" />
                      </span>
                    )}
                  </div>
                );
              })}
              <button
                className="rounded bg-gray-200 py-2 px-4 text-xs mt-2"
                onClick={() =>
                  formHandler.setFieldValue("links", [
                    ...formHandler.values.links,
                    "",
                  ])
                }
              >
                Tambah <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <Button
                color="failure"
                onClick={() => formHandler.submitForm()}
                loading={isLoadingAdd || isLoadingEdit}
                disabled={isLoadingAdd || isLoadingEdit}
              >
                Submit
              </Button>
            </div>
            <div className="w-full max-w-[300px] relative">
              <ToggleSwitch
                label="Status Mitra"
                checked={formHandler.values.status === "A"}
                onChange={(e) => {
                  formHandler.setFieldValue("status", e ? "A" : "D");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export { DashboardMitraInput };
