import { ActionTypes } from "pages/landing/redux";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const SectionBanner = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const { summary, config } = useSelector((state: RootState) => state.landing);

  const loading = useSelector(createLoadingSelector(ActionTypes.GET_CONFIG));

  function closePopup(e: any) {
    if (e.target.className.indexOf("mask") > -1) {
      setOpenPopup(false);
    }
  }

  return (
    <>
      <div
        className={
          "fixed right-0 top-0 w-full bg-black bg-opacity-50 h-screen justify-center p-8 flex items-center mask " +
          (openPopup ? "block" : "hidden")
        }
        onClick={(e) => closePopup(e)}
        style={{ zIndex: 9999 }}
      >
        {!loading ? (
          <iframe
            width="560"
            height="315"
            src={config.heroLink ?? "https://youtube.com"}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <div className="w-[560px] h-[315px] bg-slate-200 rounded-lg"></div>
        )}
      </div>
      <section className="mx-auto w-full max-w-5xl mt-28">
        <div className="flex flex-col">
          {!loading ? (
            <img
              src={config.hero ?? "/assets/images/placeholder-image.png"}
              onError={(event: any) => {
                event.target.src = "/assets/images/placeholder-image.png";
              }}
              alt="banner"
              className="w-full md:rounded-lg z-10"
            />
          ) : (
            <div className="bg-slate-200 w-full h-[480px] rounded-lg z-10"></div>
          )}
          <button
            className="bg-[#CB043F] text-sm md:text-base text-white px-8 md::px-14 py-2 mx-auto -mt-8 z-20 shadow-lg shadow-white border-2 border-white rounded-full flex items-center"
            onClick={() => setOpenPopup(true)}
          >
            <svg
              className="mr-3 w-6 md:w-8"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.66666 16.0077C2.66666 8.64834 8.65122 2.66667 16 2.66667C23.3488 2.66667 29.3333 8.64834 29.3333 16.0077C29.3333 23.3517 23.3488 29.3333 16 29.3333C8.65122 29.3333 2.66666 23.3517 2.66666 16.0077ZM20.8906 17.3571C21.0322 17.2156 21.2124 16.997 21.251 16.9455C21.4569 16.6754 21.5598 16.3409 21.5598 16.0077C21.5598 15.6334 21.444 15.2861 21.2252 15.0031C21.2073 14.9851 21.1722 14.9469 21.1264 14.8971C21.0408 14.8039 20.9179 14.6702 20.8005 14.5528C19.7452 13.4208 16.991 11.5684 15.5495 11.0024C15.3308 10.9137 14.7773 10.7194 14.4813 10.7065C14.1982 10.7065 13.9279 10.7709 13.6705 10.8995C13.3488 11.0796 13.0914 11.3626 12.9498 11.6971C12.8597 11.9286 12.7181 12.6233 12.7181 12.6361C12.5766 13.3951 12.4994 14.63 12.4994 15.9936C12.4994 17.2941 12.5766 18.4763 12.6924 19.2481C12.6956 19.2513 12.7067 19.3065 12.7239 19.3923C12.7761 19.653 12.8851 20.1961 13.0013 20.4187C13.2844 20.959 13.8378 21.2935 14.4299 21.2935H14.4813C14.8674 21.2806 15.6782 20.9461 15.6782 20.9333C17.0425 20.3673 19.7323 18.6049 20.8134 17.4343L20.8906 17.3571Z"
                fill="white"
              />
            </svg>
            Putar Video
          </button>
        </div>
        <div className="md:relative md:h-72">
          <div className="md:absolute bg-[#CB043F] w-full md:h-[580px] left-48 -top-72"></div>
          <div className="md:absolute top-1/2 left-0 md:transform md:-translate-y-1/2 bg-white z-10 md:w-1/2 p-6 md:rounded-lg md:shadow-lg text-[#3D3D3D]">
            <b>Tabe</b> (Tangan Berkah) hadir untuk mengoptimalkan potensi alam
            dan potensi sumberdaya manusia melalui pemberdayaan UMKM
            se-Kalimantan Tengah, untuk mempercepat pertumbuhan ekonomi
            kerakyatan dan kesejahteraan masyarakat Kalimantan Tengah.
          </div>
          <div className="md:absolute top-1/2 right-0 md:transform md:-translate-y-1/2 z-10 md:w-1/2 p-6 text-white bg-[#CB043F] md:bg-transparent flex justify-end">
            <div className="flex flex-wrap max-w-sm">
              <div className="w-1/2">
                <div className="text-5xl font-bold text-center">
                  {summary.mitra}
                </div>
                <div className="text-center mt-3 text-lg font-light">
                  UMKM & Ekraf
                </div>
              </div>
              <div className="w-1/2">
                <div className="text-5xl font-bold text-center">
                  {summary.product}
                </div>
                <div className="text-center mt-3 text-lg font-light">
                  Produk & Jasa
                </div>
              </div>
              <div className="w-full text-center text-lg font-light mt-4">
                Telah bergabung di <b>Tabe</b>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { SectionBanner };
