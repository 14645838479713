import { Button, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { ActionTypes, getProfile, updateProfile } from "pages/users/redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardEditProfile = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.user.profile);

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.UPDATE_PROFILE)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      nik: profile.nik ?? "",
      nama: profile.nama ?? "",
      tempatLahir: profile.tempatLahir ?? "",
      tanggalLahir: profile.tanggalLahir ?? "",
    },
    validationSchema: Yup.object({
      nik: Yup.string().required("Nik required"),
      nama: Yup.string().required("Nama required"),
      tempatLahir: Yup.string().required("Tempat Lahir required"),
      tanggalLahir: Yup.string().required("Tanggal Lahir required"),
    }),
    onSubmit(values) {
      dispatch(
        updateProfile({
          params: values,
          onSuccess(e) {
            toast.success("Success to edit profile");
            dispatch(getProfile());
          },
          onFailure(e) {
            if (e?.context?.nama) {
              toast.error(e?.context?.nama);
            } else {
              toast.error("Failed to edit profile");
            }
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Data Diri" />
          <div className="p-6 pb-4">
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="NIK"
              propsInput={{ ...formHandler.getFieldProps("nik") }}
              error={!!formHandler.touched.nik && !!formHandler.errors.nik}
              errorText={`${formHandler.errors.nik}`}
            />
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Nama"
              propsInput={{ ...formHandler.getFieldProps("nama") }}
              error={!!formHandler.touched.nama && !!formHandler.errors.nama}
              errorText={`${formHandler.errors.nama}`}
            />
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Tempat Lahir"
              propsInput={{ ...formHandler.getFieldProps("tempatLahir") }}
              error={
                !!formHandler.touched.tempatLahir &&
                !!formHandler.errors.tempatLahir
              }
              errorText={`${formHandler.errors.tempatLahir}`}
            />
            <TextField
              type="date"
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Tanggal Lahir"
              propsInput={{
                value: moment(formHandler.values.tanggalLahir).format(
                  "YYYY-MM-DD"
                ),
              }}
              onChange={(e) => {
                formHandler.setFieldTouched("tanggalLahir");
                formHandler.setFieldValue("tanggalLahir", e.target.value ?? "");
              }}
              error={
                !!formHandler.touched.tanggalLahir &&
                !!formHandler.errors.tanggalLahir
              }
              errorText={`${formHandler.errors.tanggalLahir}`}
            />
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoading}
              disabled={isLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardEditProfile };
