import { Icon, MainLayout, TextField } from "components";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { useEffect, useState } from "react";
import { IFavorite, getFavorite } from "pages/users/redux";
import { RootState } from "setup";
import { CardProduct } from "./CardProduct";

const DashboardFavorit = () => {
  const dispatch = useDispatch();

  const favorit = useSelector((state: RootState) => state.user.favorite);
  const [filtered, setFilterd] = useState<IFavorite[]>([]);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    setFilterd(
      favorit.filter(
        (e) => e.namaProduk.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
    );
  }, [search, favorit]);

  useEffect(() => {
    dispatch(getFavorite());
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <BottomNav />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <div className="p-6 pb-4">
            <TextField
              variant="solid"
              className="p-3 rounded-lg"
              placeholder="Pencarian"
              suffix="Search"
              padingBottom={0}
              propsInput={{ value: search }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="flex flex-wrap px-4">
            {filtered.map((item, index) => (
              <div className="w-1/2 p-2" key={index}>
                <CardProduct product={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardFavorit };
