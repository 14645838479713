import { Button, MainLayout, SelectField, TextField } from "components";
import { useFormik } from "formik";
import {
  ActionTypes,
  addAdmin,
  getAdminDetail,
  getCity,
  updateAdmin,
} from "pages/admin/redux";
import { FC, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

export const DashboardSettingAdminInput: FC<{
  isEdit: boolean;
}> = ({ isEdit }) => {
  const dispatch = useDispatch();
  const params = useParams<{
    accountId: string;
  }>();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_ADMIN)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.UPDATE_ADMIN)
  );

  const { city, adminDetail } = useSelector((state: RootState) => state.admin);

  const initialValues = useMemo(() => {
    return {
      email: isEdit ? adminDetail.email : "",
      password: "",
      role: isEdit ? adminDetail.role : "",
      regional: isEdit
        ? adminDetail?.regionals
          ? adminDetail?.regionals?.[0]
          : "0"
        : "0",
    };
  }, [isEdit, adminDetail]);

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email is required"),
    password: isEdit
      ? Yup.string().nullable().optional()
      : Yup.string()
          .min(5, "Minimal 5 character")
          .required("Password is required"),
    role: Yup.string().required("Role is required"),
    regional: Yup.string().test({
      name: "regional",
      test: function (value) {
        const { role } = this.parent;
        if (role === "adminRegional") {
          return (
            !!value || this.createError({ message: "Regional is required" })
          );
        }
        return true;
      },
    }),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      if (isEdit) {
        dispatch(
          updateAdmin({
            params: {
              ...values,
              accountId: params.accountId,
              regionals: [+values.regional],
            },
            onSuccess(e) {
              toast.success("Berhasil mengedit admin");
            },
            onFailure(e) {
              toast.error("Gagal mengedit admin");
            },
          })
        );
      } else {
        dispatch(
          addAdmin({
            params: {
              ...values,
              regionals: [+values.regional],
            },
            onSuccess(e) {
              toast.success("Berhasil menambah admin");
            },
            onFailure(e) {
              if (e.msg === "Email already registered") {
                toast.error("Email sudah ada");
              } else {
                toast.error("Gagal menambah admin");
              }
            },
          })
        );
      }
    },
  });

  useEffect(() => {
    dispatch(getCity());
    dispatch(
      getAdminDetail({
        params: {
          accountId: params.accountId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <div className="md:flex justify-between mb-8">
            <div className="flex flex-col items-start flex-1 pr-12 mb-4">
              <div className="font-medium">Data Admin</div>
            </div>
            <div className="w-full max-w-[300px] relative">
              <TextField
                label="Email"
                propsInput={{ ...formHandler.getFieldProps("email") }}
                error={formHandler.touched.email && !!formHandler.errors.email}
                errorText={`${formHandler.errors.email}`}
                required
              />
              <TextField
                type="password"
                label="Password"
                propsInput={{ ...formHandler.getFieldProps("password") }}
                error={
                  formHandler.touched.password && !!formHandler.errors.password
                }
                errorText={`${formHandler.errors.password}`}
                required
              />
              <SelectField
                label="Role"
                options={[
                  { label: "Pilih Role", value: "" },
                  { label: "Admin", value: "admin" },
                  { label: "Admin Reginonal", value: "adminRegional" },
                  { label: "Pimpinan", value: "root" },
                ]}
                value={formHandler.values.role}
                onChange={(e) => {
                  formHandler.setFieldTouched("role");
                  formHandler.setFieldValue("role", e?.target?.value ?? "");
                }}
                error={!!formHandler.touched.role && !!formHandler.errors.role}
                errorText={`${formHandler.errors.role}`}
                required
              />
              {formHandler.values.role === "adminRegional" && (
                <SelectField
                  options={[
                    { label: "Pilih Kota", value: "" },
                    ...city.map((e) => ({ label: e.name, value: e.id })),
                  ]}
                  label="Kabupaten/Kota"
                  value={formHandler.values.regional}
                  onChange={(e) => {
                    formHandler.setFieldTouched("regional");
                    formHandler.setFieldValue("regional", e.target.value ?? "");
                  }}
                  error={
                    formHandler.touched.regional &&
                    !!formHandler.errors.regional
                  }
                  errorText={`${formHandler.errors.regional}`}
                  required
                />
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col items-start flex-1 pr-12">
              <Button
                color="failure"
                loading={isLoadingAdd || isLoadingEdit}
                disabled={isLoadingAdd || isLoadingEdit}
                onClick={() => formHandler.submitForm()}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default DashboardSettingAdminInput;
