import { FC, useRef, useState } from "react";
import { Icon } from "../Icon/Icon";
import { ITextFieldProps } from "./TextField.model";
import { toast } from "react-hot-toast";

const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TextField: FC<ITextFieldProps> = ({
  required,
  type,
  placeholder,
  variant = "normal",
  className,
  prefix,
  suffix,
  parentClassName,
  errorText,
  error,
  size,
  label,
  onChange,
  propsInput,
  padingBottom,
  multiLine,
  toggle,
}) => {
  const sizeObject = {
    small: "rounded p-2 placeholder:text-sm text-sm",
    medium: "rounded p-3 placeholder:text-md text-md",
    large: "rounded-xl p-4 placeholder:text-lg text-lg",
  };
  const fileInput = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [show, setShow] = useState<boolean>(false);

  const handleFileInput = (e: any) => {
    let file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File terlalu besar");
      return;
    }
    if (!file) return;
    fileToBase64(file).then((base64) => {
      if (onChange) {
        setSelected(file.name || "");
        onChange(base64);
      }
    });
  };

  return (
    <>
      {!!label && (
        <div className="text-sm text-gray-700 font-medium mb-1">
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </div>
      )}
      <div
        className={[
          "w-full",
          parentClassName ?? "",
          padingBottom != undefined ? `mb-${padingBottom}` : "mb-6",
        ].join(" ")}
      >
        <div className="w-full relative">
          {prefix !== undefined ? (
            <Icon
              name={prefix}
              type="light"
              size="x-small"
              className="absolute top-1/2 transform -translate-y-1/2 left-4"
            />
          ) : null}
          {suffix !== undefined ? (
            <Icon
              name={suffix}
              type="light"
              size="x-small"
              className="absolute top-1/2 transform -translate-y-1/2 right-4 cursor-pointer"
            />
          ) : null}
          {toggle && (
            <Icon
              name={show ? "Hide" : "Show"}
              type="light"
              size="x-small"
              className="absolute top-1/2 transform -translate-y-1/2 right-4"
              onClick={() => setShow(!show)}
            />
          )}
          {multiLine ? (
            <textarea
              className={[
                `w-full`,
                sizeObject[size ?? "small"],
                prefix !== undefined ? "pl-12" : "",
                variant === "light"
                  ? "border"
                  : variant === "solid"
                  ? "border-0 bg-white"
                  : "border border-gray-200",
                error && "!border !border-red-500 !bg-red-50",
                className,
              ].join(" ")}
              {...{ onChange }}
              placeholder={placeholder}
              {...(propsInput !== undefined ? propsInput : {})}
              rows={multiLine}
            ></textarea>
          ) : type === "file" ? (
            <>
              <div
                className={[
                  `w-full text-gray-500 cursor-pointer flex items-center justify-between`,
                  sizeObject[size ?? "small"],
                  prefix !== undefined ? "pl-12" : "",
                  variant === "light"
                    ? "border"
                    : variant === "solid"
                    ? "border-0 bg-white"
                    : "border border-gray-200",
                  error && "!border !border-red-500 !bg-red-50",
                  className,
                ].join(" ")}
                onClick={() => {
                  if (fileInput.current !== null) {
                    fileInput.current.click();
                  }
                }}
              >
                <div>{selected || placeholder || "Upload File"}</div>
                <Icon name="Upload" size="x-small" />
              </div>
              <input
                ref={fileInput}
                type="file"
                className="hidden"
                accept={propsInput?.accept || "image/*"}
                onInput={handleFileInput}
              />
            </>
          ) : (
            <input
              type={
                (type === "password" ? (show ? "text" : "password") : type) ??
                "text"
              }
              className={[
                `w-full`,
                sizeObject[size ?? "small"],
                prefix !== undefined ? "pl-12" : "",
                variant === "light"
                  ? "border"
                  : variant === "solid"
                  ? "border-0 bg-white"
                  : "border border-gray-200",
                error && "!border !border-red-500 !bg-red-50",
                className,
              ].join(" ")}
              {...{ onChange }}
              placeholder={placeholder}
              {...(propsInput !== undefined ? propsInput : {})}
            />
          )}
        </div>
        {error && <div className="text-sm mt-1 text-red-500">{errorText}</div>}
      </div>
    </>
  );
};

export { TextField };
