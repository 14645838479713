import { createAction } from "@reduxjs/toolkit";
import { actionRequest, actionSuccess } from "setup/redux/loading.toolkit";
import { ActionTypes, ILoginAction, ILoginInfoResponse } from "./";

export const login = createAction<ILoginAction>(
  actionRequest(ActionTypes.LOGIN)
);
export const setLogin = createAction<ILoginInfoResponse>(
  actionSuccess(ActionTypes.LOGIN)
);
export const getInfo = createAction(ActionTypes.GETINFO);
export const logout = createAction(actionRequest(ActionTypes.LOGOUT));
