import { Button, FileSelect, TextField } from "components";
import { ToggleSwitch } from "flowbite-react";
import { useFormik } from "formik";
import moment from "moment";
import {
  ActionTypes,
  ITrainingDetail,
  addTraining,
  editTraining,
  getTrainingDetail,
} from "pages/admin/redux";
import { FC, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DashboardTrainingInput: FC<{
  training?: ITrainingDetail;
  isEdit: boolean;
}> = ({ training, isEdit }) => {
  const dispatch = useDispatch();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_TRAINING)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_TRAINING)
  );

  const initialValues = useMemo(() => {
    return {
      title: isEdit ? training?.title ?? "" : "",
      penyelenggara: isEdit ? training?.penyelenggara ?? "" : "",
      catatanPenyelenggara: isEdit ? training?.catatanPenyelenggara ?? "" : "",
      deadline: isEdit
        ? moment(training?.deadline).format("YYYY-MM-DD HH:mm") ?? ""
        : "",
      alamat: isEdit ? training?.alamat ?? "" : "",
      waktu: isEdit ? training?.waktu ?? "" : "",
      kuota: isEdit ? training?.kuota ?? 0 : 0,
      image: isEdit ? training?.image ?? "" : "",
      status: isEdit ? training?.status ?? "O" : "O",
    };
  }, [isEdit, training]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Judul Pelatihan is required"),
    penyelenggara: Yup.string().required("Penyelenggara is required"),
    catatanPenyelenggara: Yup.string().required(
      "Catatan penyelenggara is required"
    ),
    deadline: Yup.string().required("Deadline is required"),
    alamat: Yup.string().required("Alamat is required"),
    waktu: Yup.string().required("Waktu is required"),
    kuota: Yup.number().required("Kuota is required"),
    image: Yup.string().optional().required("Foto is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        deadline: moment(values.deadline).valueOf(),
        waktu: moment(values.waktu).format("YYYY-MM-DD HH:mm"),
      };
      if (isEdit) {
        dispatch(
          editTraining({
            params: { ...data, courseId: training?._id },
            onSuccess(e) {
              toast.success("Berhasil mengedit pelatihan");
              dispatch(
                getTrainingDetail({
                  params: {
                    courseId: training?._id,
                  },
                })
              );
            },
            onFailure(e) {
              toast.error("Gagal mengedit pelatihan");
            },
          })
        );
      } else {
        dispatch(
          addTraining({
            params: data,
            onSuccess(e) {
              toast.success("Berhasil membuat pelatihan");
            },
            onFailure(e) {
              toast.error("Gagal membuat pelatihan");
            },
          })
        );
      }
    },
  });

  return (
    <>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12  mb-4">
          <div className="font-medium">Foto Pelatihan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Foto akan di tampilkan sebagai thumbnail untuk detail pelatihan
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <FileSelect
            imageUrl={formHandler.values.image}
            onChange={(value) => {
              formHandler.setFieldTouched("image");
              formHandler.setFieldValue("image", value);
            }}
            error={formHandler.touched.image && !!formHandler.errors.image}
            errorText={`${formHandler.errors.image ?? ""}`}
          />
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Detail Pelatihan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan detail pelatihan seperti waktu alamat penyelenggara dan lain
            lain.
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <TextField
            label="Judul Pelatihan"
            propsInput={{ ...formHandler.getFieldProps("title") }}
            error={formHandler.touched.title && !!formHandler.errors.title}
            errorText={formHandler.errors.title}
          />
          <TextField
            label="Penyelenggara"
            propsInput={{
              ...formHandler.getFieldProps("penyelenggara"),
            }}
            error={
              formHandler.touched.penyelenggara &&
              !!formHandler.errors.penyelenggara
            }
            errorText={formHandler.errors.penyelenggara}
          />
          <TextField
            label="Catatan Penyelenggara"
            propsInput={{
              ...formHandler.getFieldProps("catatanPenyelenggara"),
            }}
            error={
              formHandler.touched.catatanPenyelenggara &&
              !!formHandler.errors.catatanPenyelenggara
            }
            errorText={formHandler.errors.catatanPenyelenggara}
          />
          <TextField
            type="datetime-local"
            label="Deadline"
            propsInput={{
              ...formHandler.getFieldProps("deadline"),
            }}
            error={
              formHandler.touched.deadline && !!formHandler.errors.deadline
            }
            errorText={formHandler.errors.deadline}
          />
          <TextField
            label="Tempat"
            propsInput={{
              ...formHandler.getFieldProps("alamat"),
            }}
            error={formHandler.touched.alamat && !!formHandler.errors.alamat}
            errorText={formHandler.errors.alamat}
          />
          <TextField
            type="datetime-local"
            label="Waktu"
            propsInput={{
              ...formHandler.getFieldProps("waktu"),
            }}
            error={formHandler.touched.waktu && !!formHandler.errors.waktu}
            errorText={formHandler.errors.waktu}
          />
          <TextField
            type="number"
            label="Kuota"
            propsInput={{
              ...formHandler.getFieldProps("kuota"),
            }}
            error={formHandler.touched.kuota && !!formHandler.errors.kuota}
            errorText={formHandler.errors.kuota}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col items-start flex-1 pr-12">
          <Button
            color="primary"
            onClick={() => formHandler.submitForm()}
            loading={isLoadingAdd || isLoadingEdit}
            disabled={isLoadingAdd || isLoadingEdit}
          >
            Submit
          </Button>
        </div>
        <div className="w-full max-w-[300px] relative">
          <ToggleSwitch
            label="Status Pelatihan"
            checked={formHandler.values.status === "O"}
            onChange={(e) => {
              formHandler.setFieldValue("status", e ? "O" : "C");
            }}
          />
        </div>
      </div>
    </>
  );
};
export { DashboardTrainingInput };
