import { FC, useRef, useState } from "react";
import { toast } from "react-hot-toast";

const imageToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileSelect: FC<{
  error?: boolean;
  errorText?: string;
  padingBottom?: number;
  imageUrl?: string;
  full?: boolean;
  config?: {
    placeholder: JSX.Element;
    className: string;
  };
  onChange?: (base64: any, file?: any) => void;
}> = ({
  config,
  error,
  errorText,
  imageUrl,
  padingBottom,
  onChange,
  full = false,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const imagePreview = useRef<HTMLImageElement>(null);
  const [preview, setPreview] = useState("");

  const handleFileInput = (e: any) => {
    let file = e.target.files[0];
    if (file?.size > 5 * 1024 * 1024) {
      toast.error("File terlalu besar");
      return;
    }
    if (!file) return;
    imageToBase64(file).then((x) => {
      if (onChange) {
        onChange(x, file);
      }
      if (imagePreview.current !== null) {
        imagePreview.current.src = `${x}`;
      }
      setPreview(`${x}`);
    });
  };

  return (
    <div
      className={[
        padingBottom != undefined ? `mb-${padingBottom}` : "mb-6",
        full && "w-full",
      ].join(" ")}
    >
      <div
        className={[
          "relative rounded overflow-hidden",
          error && "border border-red-500",
          !full && "max-w-[300px]",
        ].join(" ")}
      >
        {config?.placeholder ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              if (fileInput.current !== null && full) {
                fileInput.current.click();
              }
            }}
          >
            {imageUrl || preview !== "" ? (
              <img
                src={imageUrl || preview}
                className={config?.className || ""}
              />
            ) : (
              config?.placeholder
            )}
          </div>
        ) : (
          <img
            className={[
              "w-full object-cover",
              full ? "cursor-pointer" : "max-w-[300px]",
            ].join(" ")}
            ref={imagePreview}
            src={imageUrl || "/assets/images/placeholder-image.png"}
            alt="image thumbnail"
            onClick={() => {
              if (fileInput.current !== null && full) {
                fileInput.current.click();
              }
            }}
          />
        )}
        <div
          className={[
            "cursor-pointer px-4 py-3 text-gray-600 text-xs border flex justify-between absolute w-full bottom-0",
            error ? "bg-red-100" : "bg-gray-50",
            full && "hidden",
          ].join(" ")}
          onClick={() => {
            if (fileInput.current !== null) {
              fileInput.current.click();
            }
          }}
        >
          Edit Foto
          <i className="fa fa-upload"></i>
        </div>
        <input
          ref={fileInput}
          type="file"
          className="hidden"
          accept="image/*"
          onInput={handleFileInput}
        />
      </div>
      {error && <div className="text-sm mt-1 text-red-500">{errorText}</div>}
    </div>
  );
};
export { FileSelect };
