import { put } from "redux-saga/effects";
import { IActionRequestServiceProps } from "./sagaRequest.model";
import { actionSuccess, actionFailure } from "setup/redux/loading.toolkit";
import { requestPublic } from "setup/axios/requestPublic";
import { requestPrivate } from "setup/axios/requestPrivate";

function* sagaRequest({
  isPublic = false,
  requestPayload,
  actionType,
  onSuccess,
  onFailure,
  actionPayload = {},
}: IActionRequestServiceProps) {
  try {
    let requests = {
      public: requestPublic,
      private: requestPrivate,
    };
    const { data } = yield requests[isPublic ? "public" : "private"]({
      ...requestPayload,
      actionPage: actionType,
    });
    if (data) {
      if (onSuccess) onSuccess({ ...(data || {}), ...actionPayload });
      yield put({
        type: actionSuccess(actionType),
        payload: { ...(data || {}), ...actionPayload },
      });
      yield { ...(data || {}), ...actionPayload };
    } else {
      if (onFailure) onFailure({});
      yield put({ type: actionFailure(actionType) });
      yield undefined;
    }
  } catch (err: any) {
    if (onFailure) onFailure(err?.response?.data);
    yield put({ type: actionFailure(actionType) });
    yield undefined;
  }
}
export default sagaRequest;
