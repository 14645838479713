const DASHBOARD = "/admin";

const DASHBOARD_ANALYTICS = `${DASHBOARD}/analytics`;

const DASHBOARD_USER = `${DASHBOARD}/pengguna`;
const DASHBOARD_USER_ADD = `${DASHBOARD_USER}/tambah`;
const DASHBOARD_USER_EDIT = `${DASHBOARD_USER}/edit/:userId`;
const DASHBOARD_USER_VERIFY = `${DASHBOARD_USER}/verifikasi`;

const DASHBOARD_CATEGORY = `${DASHBOARD}/kategori`;
const DASHBOARD_CATEGORY_ADD = `${DASHBOARD_CATEGORY}/tambah`;
const DASHBOARD_CATEGORY_EDIT = `${DASHBOARD_CATEGORY}/edit/:categoryId`;

const DASHBOARD_PARTNER = `${DASHBOARD}/mitra`;
const DASHBOARD_PARTNER_ADD = `${DASHBOARD_PARTNER}/tambah`;
const DASHBOARD_PARTNER_EDIT = `${DASHBOARD_PARTNER}/edit/:partnerId`;
const DASHBOARD_PARTNER_DETAIL = `${DASHBOARD_PARTNER}/:partnerId`;
const DASHBOARD_PARTNER_VERIFY = `${DASHBOARD_PARTNER}/verifikasi`;

const DASHBOARD_PRODUCT = `${DASHBOARD}/product`;
const DASHBOARD_PRODUCT_ADD = `${DASHBOARD_PRODUCT}/tambah`;
const DASHBOARD_PRODUCT_EDIT = `${DASHBOARD_PRODUCT}/edit/:productId`;

const DASHBOARD_TRAINING = `${DASHBOARD}/pelatihan`;
const DASHBOARD_TRAINING_ADD = `${DASHBOARD_TRAINING}/tambah`;
const DASHBOARD_TRAINING_EDIT = `${DASHBOARD_TRAINING}/edit/:trainingId`;
const DASHBOARD_TRAINING_DETAIL = `${DASHBOARD_TRAINING}/:trainingId`;
const DASHBOARD_TRAINING_VERIFY = `${DASHBOARD_TRAINING}/verifikasi`;

const DASHBOARD_FUNDING = `${DASHBOARD}/pendanaan`;
const DASHBOARD_FUNDING_ADD = `${DASHBOARD_FUNDING}/tambah`;
const DASHBOARD_FUNDING_EDIT = `${DASHBOARD_FUNDING}/edit/:fundingId`;
const DASHBOARD_FUNDING_DETAIL = `${DASHBOARD_FUNDING}/:fundingId`;
const DASHBOARD_FUNDING_VERIFY = `${DASHBOARD_FUNDING}/verifikasi`;

const DASHBOARD_ASPIRATION = `${DASHBOARD}/aspirasi`;

const DASHBOARD_ARTICLE = `${DASHBOARD}/artikel`;
const DASHBOARD_ARTICLE_ADD = `${DASHBOARD_ARTICLE}/tambah`;
const DASHBOARD_ARTICLE_EDIT = `${DASHBOARD_ARTICLE}/edit/:articleId`;

const DASHBOARD_SETTINGS = `${DASHBOARD}/pengaturan`;
const DASHBOARD_SETTINGS_GENERAL = `${DASHBOARD}/pengaturan/general`;
const DASHBOARD_SETTINGS_PROFILE = `${DASHBOARD}/pengaturan/profil`;

const DASHBOARD_ADMIN_ACCOUNT = `${DASHBOARD}/pengaturan/akun-admin`;
const DASHBOARD_ADMIN_ACCOUNT_ADD = `${DASHBOARD_ADMIN_ACCOUNT}/tambah`;
const DASHBOARD_ADMIN_ACCOUNT_EDIT = `${DASHBOARD_ADMIN_ACCOUNT}/edit/:accountId`;

const DASHBOARD_PIMPINAN = "/pimpinan";
const DASHBOARD_PIMPINAN_ANALYTICS = `${DASHBOARD_PIMPINAN}/analytics`;
const DASHBOARD_PIMPINAN_MAPS = `${DASHBOARD_PIMPINAN}/maps`;
const DASHBOARD_PIMPINAN_USER = `${DASHBOARD_PIMPINAN}/pengguna`;
const DASHBOARD_PIMPINAN_USER_VERIFY = `${DASHBOARD_PIMPINAN_USER}/verifikasi`;
const DASHBOARD_PIMPINAN_CATEGORY = `${DASHBOARD_PIMPINAN}/kategori`;
const DASHBOARD_PIMPINAN_PARTNER = `${DASHBOARD_PIMPINAN}/mitra`;
const DASHBOARD_PIMPINAN_PARTNER_DETAIL = `${DASHBOARD_PIMPINAN_PARTNER}/:partnerId`;
const DASHBOARD_PIMPINAN_PARTNER_VERIFY = `${DASHBOARD_PIMPINAN_PARTNER}/verifikasi`;
const DASHBOARD_PIMPINAN_PRODUCT = `${DASHBOARD_PIMPINAN}/product`;
const DASHBOARD_PIMPINAN_TRAINING = `${DASHBOARD_PIMPINAN}/pelatihan`;
const DASHBOARD_PIMPINAN_TRAINING_DETAIL = `${DASHBOARD_PIMPINAN_TRAINING}/:trainingId`;
const DASHBOARD_PIMPINAN_TRAINING_VERIFY = `${DASHBOARD_PIMPINAN_TRAINING}/verifikasi`;
const DASHBOARD_PIMPINAN_FUNDING = `${DASHBOARD_PIMPINAN}/pendanaan`;
const DASHBOARD_PIMPINAN_FUNDING_DETAIL = `${DASHBOARD_PIMPINAN_FUNDING}/:fundingId`;
const DASHBOARD_PIMPINAN_FUNDING_VERIFY = `${DASHBOARD_PIMPINAN_FUNDING}/verifikasi`;
const DASHBOARD_PIMPINAN_ASPIRATION = `${DASHBOARD_PIMPINAN}/aspirasi`;
const DASHBOARD_PIMPINAN_LOG = `${DASHBOARD_PIMPINAN}/log`;
const DASHBOARD_PIMPINAN_ARTICLE = `${DASHBOARD_PIMPINAN}/artikel`;
const DASHBOARD_PIMPINAN_SETTINGS_GENERAL = `${DASHBOARD_PIMPINAN}/pengaturan/general`;
const DASHBOARD_PIMPINAN_SETTINGS_PROFILE = `${DASHBOARD_PIMPINAN}/pengaturan/profil`;
const DASHBOARD_PIMPINAN_ADMIN_ACCOUNT = `${DASHBOARD_PIMPINAN}/pengaturan/akun-admin`;

const DASHBOARD_REGIONAL = "/admin-regional";
const DASHBOARD_REGIONAL_ANALYTICS = `${DASHBOARD_REGIONAL}/analytics`;
const DASHBOARD_REGIONAL_MAPS = `${DASHBOARD_REGIONAL}/maps`;
const DASHBOARD_REGIONAL_USER = `${DASHBOARD_REGIONAL}/pengguna`;
const DASHBOARD_REGIONAL_USER_ADD = `${DASHBOARD_REGIONAL_USER}/tambah`;
const DASHBOARD_REGIONAL_USER_EDIT = `${DASHBOARD_REGIONAL_USER}/edit/:userId`;
const DASHBOARD_REGIONAL_USER_VERIFY = `${DASHBOARD_REGIONAL_USER}/verifikasi`;
const DASHBOARD_REGIONAL_PARTNER = `${DASHBOARD_REGIONAL}/mitra`;
const DASHBOARD_REGIONAL_PARTNER_ADD = `${DASHBOARD_REGIONAL_PARTNER}/tambah`;
const DASHBOARD_REGIONAL_PARTNER_EDIT = `${DASHBOARD_REGIONAL_PARTNER}/edit/:partnerId`;
const DASHBOARD_REGIONAL_PARTNER_DETAIL = `${DASHBOARD_REGIONAL_PARTNER}/:partnerId`;
const DASHBOARD_REGIONAL_PARTNER_VERIFY = `${DASHBOARD_REGIONAL_PARTNER}/verifikasi`;
const DASHBOARD_REGIONAL_PRODUCT = `${DASHBOARD_REGIONAL}/product`;
const DASHBOARD_REGIONAL_PRODUCT_ADD = `${DASHBOARD_REGIONAL_PRODUCT}/tambah`;
const DASHBOARD_REGIONAL_PRODUCT_EDIT = `${DASHBOARD_REGIONAL_PRODUCT}/edit/:productId`;
const DASHBOARD_REGIONAL_SETTINGS_PROFILE = `${DASHBOARD_REGIONAL}/pengaturan/profil`;

const LOGIN = "/login";
const REGISTER = "/register";
const FORGOT_PASSWORD = "/forgot-password";
const RESET_PASSWORD = `/new-password/:token`;
const LANDING_PAGE = "/";
const LANDING_PAGE_ARTICLE = `/article`;
const LANDING_PAGE_ARTICLE_DETAIL = `${LANDING_PAGE_ARTICLE}/:articleId/:slug`;
const LANDING_PAGE_PRODUCT = `/product`;
const LANDING_PAGE_MITRA = `/mitra`;
const LANDING_PAGE_PRODUCT_DETAIL = `/product/:productId`;
const LANDING_PAGE_MITRA_DETAIL = `/mitra/:mitraId`;

const USER = `/user`;
const USER_USAHA = `${USER}/usaha`;
const USER_USAHA_LIST = `${USER}/usaha/list`;
const USER_USAHA_DETAIL = `${USER}/usaha/:businessId`;
const USER_USAHA_EDIT = `${USER}/usaha/edit/:businessId`;
const USER_USAHA_ADD = `${USER}/usaha/tambah`;
const USER_PRODUCT = `${USER}/product`;
const USER_PRODUCT_LIST = `${USER}/product/list`;
const USER_PRODUCT_DETAIL = `${USER}/product/:productId`;
const USER_PRODUCT_EDIT = `${USER}/usaha/edit/:businessId/edit/:productId`;
const USER_PRODUCT_ADD = `${USER}/usaha/edit/:businessId/tambah`;
const USER_FAVORIT = `${USER}/favorit`;
const USER_PROFILE = `${USER}/profile`;
const USER_PROFILE_ACCOUNT = `${USER}/profile/account`;
const USER_PROFILE_INFO = `${USER}/profile/profile-info`;
const USER_PROFILE_CHANGE_PASSWORD = `${USER}/profile/change-password`;
const USER_TRAINING = `${USER}/training`;
const USER_TRAINING_HISTORY = `${USER}/training/riwayat`;
const USER_TRAINING_DETAIL = `${USER}/training/:trainingId`;
const USER_TRAINING_DETAIL_HISTORY = `${USER}/training/:trainingId/history`;
const USER_FUNDING = `${USER}/funding`;
const USER_FUNDING_HISTORY = `${USER}/funding/riwayat`;
const USER_FUNDING_DETAIL = `${USER}/funding/:fundingId`;
const USER_FUNDING_DETAIL_HISTORY = `${USER}/funding/:fundingId/history`;
const USER_NOTIFICATION = `${USER}/notification`;
const USER_ASPIRATION = `${USER}/aspiration`;

export const PATH = {
  USER,
  USER_USAHA,
  USER_USAHA_LIST,
  USER_USAHA_DETAIL,
  USER_USAHA_EDIT,
  USER_USAHA_ADD,
  USER_PRODUCT,
  USER_PRODUCT_LIST,
  USER_PRODUCT_DETAIL,
  USER_PRODUCT_EDIT,
  USER_PRODUCT_ADD,
  USER_FAVORIT,
  USER_PROFILE,
  USER_PROFILE_ACCOUNT,
  USER_PROFILE_INFO,
  USER_PROFILE_CHANGE_PASSWORD,
  USER_TRAINING,
  USER_TRAINING_HISTORY,
  USER_TRAINING_DETAIL,
  USER_TRAINING_DETAIL_HISTORY,
  USER_FUNDING,
  USER_FUNDING_HISTORY,
  USER_FUNDING_DETAIL,
  USER_FUNDING_DETAIL_HISTORY,
  USER_NOTIFICATION,
  USER_ASPIRATION,

  DASHBOARD_REGIONAL,
  DASHBOARD_REGIONAL_ANALYTICS,
  DASHBOARD_REGIONAL_MAPS,
  DASHBOARD_REGIONAL_USER,
  DASHBOARD_REGIONAL_USER_ADD,
  DASHBOARD_REGIONAL_USER_EDIT,
  DASHBOARD_REGIONAL_USER_VERIFY,
  DASHBOARD_REGIONAL_PARTNER,
  DASHBOARD_REGIONAL_PARTNER_ADD,
  DASHBOARD_REGIONAL_PARTNER_DETAIL,
  DASHBOARD_REGIONAL_PARTNER_EDIT,
  DASHBOARD_REGIONAL_PARTNER_VERIFY,
  DASHBOARD_REGIONAL_PRODUCT,
  DASHBOARD_REGIONAL_PRODUCT_ADD,
  DASHBOARD_REGIONAL_PRODUCT_EDIT,
  DASHBOARD_REGIONAL_SETTINGS_PROFILE,

  DASHBOARD_PIMPINAN,
  DASHBOARD_PIMPINAN_ANALYTICS,
  DASHBOARD_PIMPINAN_MAPS,
  DASHBOARD_PIMPINAN_USER,
  DASHBOARD_PIMPINAN_USER_VERIFY,
  DASHBOARD_PIMPINAN_CATEGORY,
  DASHBOARD_PIMPINAN_PRODUCT,
  DASHBOARD_PIMPINAN_PARTNER,
  DASHBOARD_PIMPINAN_PARTNER_DETAIL,
  DASHBOARD_PIMPINAN_PARTNER_VERIFY,
  DASHBOARD_PIMPINAN_TRAINING,
  DASHBOARD_PIMPINAN_TRAINING_DETAIL,
  DASHBOARD_PIMPINAN_TRAINING_VERIFY,
  DASHBOARD_PIMPINAN_FUNDING,
  DASHBOARD_PIMPINAN_FUNDING_DETAIL,
  DASHBOARD_PIMPINAN_FUNDING_VERIFY,
  DASHBOARD_PIMPINAN_ASPIRATION,
  DASHBOARD_PIMPINAN_LOG,
  DASHBOARD_PIMPINAN_ARTICLE,
  DASHBOARD_PIMPINAN_SETTINGS_GENERAL,
  DASHBOARD_PIMPINAN_SETTINGS_PROFILE,
  DASHBOARD_PIMPINAN_ADMIN_ACCOUNT,

  LOGIN,
  REGISTER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LANDING_PAGE,
  LANDING_PAGE_ARTICLE,
  LANDING_PAGE_ARTICLE_DETAIL,
  LANDING_PAGE_PRODUCT,
  LANDING_PAGE_MITRA,
  LANDING_PAGE_PRODUCT_DETAIL,
  LANDING_PAGE_MITRA_DETAIL,
  DASHBOARD,
  DASHBOARD_ANALYTICS,
  DASHBOARD_USER,
  DASHBOARD_USER_ADD,
  DASHBOARD_USER_EDIT,
  DASHBOARD_USER_VERIFY,
  DASHBOARD_CATEGORY,
  DASHBOARD_CATEGORY_ADD,
  DASHBOARD_CATEGORY_EDIT,
  DASHBOARD_PRODUCT,
  DASHBOARD_PRODUCT_ADD,
  DASHBOARD_PRODUCT_EDIT,
  DASHBOARD_PARTNER,
  DASHBOARD_PARTNER_ADD,
  DASHBOARD_PARTNER_EDIT,
  DASHBOARD_PARTNER_DETAIL,
  DASHBOARD_PARTNER_VERIFY,
  DASHBOARD_TRAINING,
  DASHBOARD_TRAINING_DETAIL,
  DASHBOARD_TRAINING_ADD,
  DASHBOARD_TRAINING_EDIT,
  DASHBOARD_TRAINING_VERIFY,
  DASHBOARD_FUNDING,
  DASHBOARD_FUNDING_DETAIL,
  DASHBOARD_FUNDING_ADD,
  DASHBOARD_FUNDING_EDIT,
  DASHBOARD_FUNDING_VERIFY,
  DASHBOARD_ASPIRATION,
  DASHBOARD_ARTICLE,
  DASHBOARD_ARTICLE_ADD,
  DASHBOARD_ARTICLE_EDIT,
  DASHBOARD_SETTINGS,
  DASHBOARD_SETTINGS_GENERAL,
  DASHBOARD_SETTINGS_PROFILE,
  DASHBOARD_ADMIN_ACCOUNT,
  DASHBOARD_ADMIN_ACCOUNT_ADD,
  DASHBOARD_ADMIN_ACCOUNT_EDIT,
};
