const prefixAction = `ADMIN`;

export const ActionTypes = {
  // analytics
  GET_ANALYTICS: `${prefixAction}_GET_ANALYTICS`,
  GET_ANALYTICS_V1: `${prefixAction}_GET_ANALYTICS_V1`,
  GET_LOGS: `${prefixAction}_GET_LOGS`,

  // user
  GET_USER: `${prefixAction}_GET_USER`,
  GET_USER_DETAIL: `${prefixAction}_GET_USER_DETAIL`,
  ADD_USER: `${prefixAction}_ADD_USER`,
  EDIT_USER: `${prefixAction}_EDIT_USER`,
  DELETE_USER: `${prefixAction}_DELETE_USER`,
  APPROVE_USER: `${prefixAction}_APPROVE_USER`,
  EXPORT_USER: `${prefixAction}_EXPORT_USER`,

  // category
  GET_CATEGORY: `${prefixAction}_GET_CATEGORY`,
  GET_CATEGORY_DETAIL: `${prefixAction}_GET_CATEGORY_DETAIL`,
  ADD_CATEGORY: `${prefixAction}_ADD_CATEGORY`,
  EDIT_CATEGORY: `${prefixAction}_EDIT_CATEGORY`,
  DELETE_CATEGORY: `${prefixAction}_DELETE_CATEGORY`,

  // mitra
  GET_MITRA: `${prefixAction}_GET_MITRA`,
  GET_MITRA_MAPS: `${prefixAction}_GET_MITRA_MAPS`,
  GET_MITRA_DETAIL: `${prefixAction}_GET_MITRA_DETAIL`,
  ADD_MITRA: `${prefixAction}_ADD_MITRA`,
  EDIT_MITRA: `${prefixAction}_EDIT_MITRA`,
  DELETE_MITRA: `${prefixAction}_DELETE_MITRA`,
  APPROVE_MITRA: `${prefixAction}_APPROVE_MITRA`,
  EXPORT_MITRA: `${prefixAction}_EXPORT_MITRA`,

  // product
  GET_PRODUCT_DETAIL: `${prefixAction}_GET_PRODUCT_DETAIL`,
  ADD_PRODUCT: `${prefixAction}_ADD_PRODUCT`,
  EDIT_PRODUCT: `${prefixAction}_EDIT_PRODUCT`,
  DELETE_PRODUCT: `${prefixAction}_DELETE_PRODUCT`,

  // training
  GET_TRAINING: `${prefixAction}_GET_TRAINING`,
  GET_TRAINING_DETAIL: `${prefixAction}_GET_TRAINING_DETAIL`,
  ADD_TRAINING: `${prefixAction}_ADD_TRAINING`,
  EDIT_TRAINING: `${prefixAction}_EDIT_TRAINING`,
  DELETE_TRAINING: `${prefixAction}_DELETE_TRAINING`,
  APPROVE_TRAINING: `${prefixAction}_APPROVE_TRAINING`,
  EXPORT_TRAINING: `${prefixAction}_EXPORT_TRAINING`,

  // funding
  GET_FUNDING: `${prefixAction}_GET_FUNDING`,
  GET_FUNDING_DETAIL: `${prefixAction}_GET_FUNDING_DETAIL`,
  ADD_FUNDING: `${prefixAction}_ADD_FUNDING`,
  EDIT_FUNDING: `${prefixAction}_EDIT_FUNDING`,
  DELETE_FUNDING: `${prefixAction}_DELETE_FUNDING`,
  APPROVE_FUNDING: `${prefixAction}_APPROVE_FUNDING`,
  EXPORT_FUNDING: `${prefixAction}_EXPORT_FUNDING`,

  //article
  GET_ARTICLE: `${prefixAction}_GET_ARTICLE`,
  GET_ARTICLE_DETAIL: `${prefixAction}_GET_ARTICLE_DETAIL`,
  ADD_ARTICLE: `${prefixAction}_ADD_ARTICLE`,
  EDIT_ARTICLE: `${prefixAction}_EDIT_ARTICLE`,
  DELETE_ARTICLE: `${prefixAction}_DELETE_ARTICLE`,

  // aspirasi
  GET_ASPIRATION: `${prefixAction}_GET_ASPIRATION`,

  //general
  GET_CITY: `${prefixAction}_GET_CITY`,
  GET_DISTRICT: `${prefixAction}_GET_DISTRICT`,
  GET_VILLAGE: `${prefixAction}_GET_VILLAGE`,

  GET_PROFILE_INFO: `${prefixAction}_GET_PROFILE_INFO`,
  SETTING_EDIT_PROFILE: `${prefixAction}_SETTING_EDIT_PROFILE`,
  SETTING_CHANGE_PASSWORD: `${prefixAction}_SETTING_CHANGE_PASSWORD`,

  GET_GENERAL_DATA: `${prefixAction}_GET_GENERAL_DATA`,
  UPDATE_BANNER: `${prefixAction}_UPDATE_BANNER`,
  UPLOAD_SLIDER: `${prefixAction}_UPLOAD_SLIDER`,
  DELETE_SLIDER: `${prefixAction}_DELETE_SLIDER`,

  GET_ADMIN_LIST: `${prefixAction}_GET_ADMIN_LIST`,
  GET_ADMIN_DETAIL: `${prefixAction}_GET_ADMIN_DETAIL`,
  ADD_ADMIN: `${prefixAction}_ADD_ADMIN`,
  UPDATE_ADMIN: `${prefixAction}_UPDATE_ADMIN`,
  DELETE_ADMIN: `${prefixAction}_DELETE_ADMIN`,

  SEND_NOTIFICATION_USER: `${prefixAction}_SEND_NOTIFICATION_USER`,
  SEND_NOTIFICATION_BROADCAST: `${prefixAction}_SEND_NOTIFICATION_BROADCAST`,
  SEND_NOTIFICATION_TRAINING: `${prefixAction}_SEND_NOTIFICATION_TRAINING`,
  SEND_NOTIFICATION_FUNDING: `${prefixAction}_SEND_NOTIFICATION_FUNDING`,
};

export interface ILogsResponse {
  message: string;
  payload: {
    logs: Ilogs[];
  };
}

export interface Ilogs {
  name: string;
  role: string;
  regional: string[];
  userId: string;
  log: string;
  timestamp: number;
}

export interface IAnalyticsV1Response {
  message: string;
  payload: IAnalyticsV1;
}

export interface IAnalyticsV1 {
  mitra: number[];
  omset: number[];
  products: number[];
  summary: IAnalyticsV1Summary;
  meta: Meta;
  role: string;
}

export interface IAnalyticsV1Summary {
  mitra: number;
  omset: number;
  produk: number;
  user: number;
  nib: Nib;
}

export interface Nib {
  exist: number;
  nonExist: number;
}

export interface Meta {
  tahun: number;
}

export interface IAnalytics {
  mitra: number[];
  omset: number[];
  produk: number[];
  summary: Summary;
}

export interface Summary {
  mitra: number;
  omset: number;
  produk: number;
  user: number;
}

export interface IUser {
  id: string;
  nama: string;
  nik: string;
  tempatLahir: string;
  tanggalLahir: string;
  foto?: {
    id: string;
    url: string;
  };
  email: string;
  noHandphone: any;
  statusVerify: string;
  gender: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IUserDetail {
  _id: string;
  nama: string;
  nik: string;
  tempatLahir: string;
  tanggalLahir: string;
  foto: string;
  email: string;
  noHandphone: any;
  statusVerify: string;
  gender: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface ICategory {
  id: string;
  name: string;
}

export interface ICategoryDetail {
  _id: string;
  name: string;
}

export interface IMitra {
  id: string;
  namaMitra: string;
  namaBadanUsaha: string;
  links: Links;
  alamat: string;
  kota: number;
  kecamatan: number;
  kelurahan: number;
  addressName: AddressName;
  npwp: string;
  nib: string;
  pengizinUsaha: string;
  berkasIzinUsaha?: string;
  deskripsi: string;
  omset: number;
  klasifikasiEkraf: string[];
  status: string;
  location: ILocation;
  foto?: Foto;
  user: User;
  createdAt: string;
}

export interface IMetadata {
  count: number;
  pageNumber: number;
  limit: number;
  pageTotal: number;
  filter: {
    [key: string]: any;
  };
  sort: any;
}

export interface Links {
  bukalapak?: string;
  tokopedia?: string;
}

export interface AddressName {
  kota: string;
  kecamatan: string;
  kelurahan: string;
}

export interface ILocation {
  type: string;
  coordinates: number[];
}

export interface Foto {
  id: string;
  url: string;
}

export interface User {
  nama: string;
  email: string;
  statusVerify: string;
  id: string;
}

export interface Location {
  long: number;
  lat: number;
}

export interface ICourse {
  id: string;
  title: string;
  penyelenggara: string;
  kuota: number;
  foto?: {
    url: string;
    id: string;
  };
  waktu: string;
}

export interface IFunding {
  id: string;
  title: string;
  banner?: {
    url: string;
    id: string;
  };
  penyelenggara: string;
  deadline: string;
  kriteria: string;
  persyaratan: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface IAspiration {
  _id: string;
  userId: string;
  message: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  user: string;
}

export interface ITrainingDetail {
  _id: string;
  title: string;
  penyelenggara: string;
  catatanPenyelenggara: string;
  deadline: string;
  waktu: string;
  alamat: string;
  image: any;
  kuota: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  book: ITrainingBook[];
}

export interface IFundingDetail {
  _id: string;
  title: string;
  banner: string;
  penyelenggara: string;
  deadline: string;
  kriteria: string;
  persyaratan: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  booked: any[];
}

export interface IMitraDetail {
  _id: string;
  userId: string;
  namaMitra: string;
  namaBadanUsaha: string;
  links: any;
  waktuOperasional: any;
  foto: any;
  alamat: string;
  kota: string;
  kecamatan: string;
  kelurahan: string;
  npwp: string;
  nib: string;
  berkasIzinUsaha: any;
  deskripsi: string;
  omset: number;
  klasifikasiEkraf: {
    id: string;
    name: string;
  }[];
  status: string;
  location: Location;
  rejectReason: any;
  createdAt: string;
  updatedAt: string;
  __v: number;
  pengizinUsaha: string;
  addressName: any;
  user: string;
  products: Product[];
  addressId: any;
}

export interface IArticleDetail {
  title: string;
  content: string;
  preview: {
    image: any;
  };
  id: string;
}

export interface Product {
  _id: string;
  namaProduct: string;
  harga: number;
  updatedAt: string;
  fotoProduct: any;
}

export interface ITrainingBook {
  _id: string;
  userId: string;
  mitraId: string;
  courseId: string;
  status: string;
  message: any;
  createdAt: string;
  updatedAt: string;
  user: string;
  email: string;
  phoneNumber: string;
  mitra: string;
}

export interface IInputPayload {
  params: {
    [key: string]: any;
  };
}
export interface IInputPayloadCallback {
  params: {
    [key: string]: any;
  };
  onSuccess?: (e: any) => void;
  onFailure?: (e: any) => void;
}

export interface IUserResponse {
  payload: {
    metadata: IMetadata;
    users: IUser[];
  };
  // pagination: {
  //   currentPage: number;
  //   totalPages: number;
  //   totalCount: number;
  // };
}

export interface ICategoryResponse {
  payload: {
    classifications: ICategory[];
  };
}

export interface IMitraResponse {
  payload: {
    metadata: IMetadata;
    mitra: IMitra[];
  };
}

export interface ITrainingResponse {
  payload: {
    metadata: IMetadata;
    courses: ICourse[];
  };
}

export interface IFundingResponse {
  payload: {
    metadata: IMetadata;
    fundings: IFunding[];
  };
}

export interface IAspirationResponse {
  payload: {
    aspiration: IAspiration[];
  };
}

export interface ITrainingDetailResponse {
  payload: {
    course: ITrainingDetail;
  };
}

export interface IFundingDetailResponse {
  payload: {
    funding: IFundingDetail;
  };
}

export interface IUserDetailResponse {
  payload: {
    user: IUserDetail;
  };
}

export interface IAnalyticsResponse {
  payload: IAnalytics;
}

export interface ICategoryDetailResponse {
  payload: {
    classification: ICategoryDetail;
  };
}

export interface IMitraDetailResponse {
  payload: IMitraDetail;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IArticle {
  title: string;
  preview: Preview;
  id: string;
}

export interface Preview {
  image: any;
}

export interface ICityResponse {
  payload: {
    regency: ICity[];
  };
}

export interface IDistrict {
  id: number;
  name: string;
}

export interface IDistrictResponse {
  payload: {
    district: IDistrict[];
  };
}

export interface IVillage {
  id: number;
  name: string;
}

export interface IProductDetail {
  _id: string;
  mitraId: string;
  namaProduct: string;
  fotoProduct: FotoProduct[];
  harga: number;
  nominalSatuan: number;
  beratSatuan: string;
  deskripsi: string;
  category: ICategories[];
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  mitra: {
    userId: string;
  };
}

export interface ICategories {
  _id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface FotoProduct {
  _id: string;
  image: any;
}

export interface IVillageResponse {
  payload: {
    villages: IVillage[];
  };
}

export interface IArticleResponse {
  payload: {
    articles: IArticle[];
  };
}

export interface IArticleDetailResponse {
  payload: {
    article: IArticleDetail;
  };
}

export interface IProductDetailResponse {
  payload: {
    product: IProductDetail;
  };
}

export interface IProfileInfo {
  email: string;
  role: string;
  regencies: any[];
}

export interface IProfileInfoResponse {
  payload: IProfileInfo;
}

export interface IGeneralData {
  hero: string;
  heroLink: string;
  banners: Banner[];
  contacts: Contact[];
}

export interface Banner {
  _id: string;
  foto: string;
}

export interface Contact {
  name: string;
  phone: string;
  _id: string;
}

export interface IGeneralDataResponse {
  payload: {
    incoming: IGeneralData;
  };
}

export interface IAdmin {
  id: string;
  regionals?: any[];
  email: string;
  role: string;
}

export interface IAdminResponse {
  payload: {
    accounts: IAdmin[];
  };
}

export interface IAdminDetailResponse {
  payload: {
    admin: IAdmin;
  };
}

export interface IMitraMaps {
  namaMitra: string;
  namaBadanUsaha: string;
  alamat: string;
  nib: string;
  deskripsi: string;
  klasifikasiEkraf: string[];
  status: string;
  location: MapsLocation;
  id: string;
  foto: Foto;
  kota: string;
  kecamatan: string;
  kelurahan: string;
}

export interface MapsLocation {
  type: string;
  coordinates: number[];
}

export interface Foto {
  id: string;
  url: string;
}

export interface IMitraMapsResponse {
  payload: {
    meta: any;
    results: IMitraMaps[];
  };
}

export interface IAdminState {
  logs: Ilogs[];
  analytics: IAnalytics;
  analyticsv1: IAnalyticsV1;
  users: IUser[];
  userMeta: IMetadata;
  userDetail: IUserDetail;
  category: ICategory[];
  categoryDetail: ICategoryDetail;
  mitra: IMitra[];
  mitraMeta: IMetadata;
  mitraMaps: IMitraMaps[];
  mitraDetail: IMitraDetail;
  productDetail: IProductDetail;
  course: ICourse[];
  courseMeta: IMetadata;
  courseDetail: ITrainingDetail;
  fundings: IFunding[];
  fundingMeta: IMetadata;
  fundingDetail: IFundingDetail;
  aspiration: IAspiration[];
  city: ICity[];
  district: IDistrict[];
  village: IVillage[];
  articles: IArticle[];
  articleDetail: IArticleDetail;
  profileInfo: IProfileInfo;
  generalData: IGeneralData;
  admin: IAdmin[];
  adminDetail: IAdmin;
}
