import {
  Button,
  Icon,
  MainLayout,
  SelectField,
  TableComponent,
  TextField,
} from "components";
import {
  ActionTypes,
  IMitra,
  exportMitra,
  getCategory,
  getMitra,
} from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";
import { toast } from "react-hot-toast";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

export const DashboardMitra: FC<{
  isVerify?: boolean;
}> = ({ isVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_MITRA));

  const columns: any = [
    {
      header: "Foto",
      field: "foto",
      rowRender: (row: IMitra) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row?.foto?.url ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Nama Mitra",
      field: "namaMitra",
    },
    {
      header: "Pemilik Usaha",
      field: "namaMitra",
      rowRender: (row: IMitra) => {
        return <>{row.user.nama}</>;
      },
    },
    {
      header: "Status",
      field: "status",
      exportRender: (item: any) => (item.status === "A" ? "Active" : "Waiting"),
      rowRender: (row: IMitra) => {
        return (
          <div className="whitespace-nowrap">
            {row.status === "A" ? "✓ Active" : "Waiting"}
          </div>
        );
      },
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: IMitra) => {
        return (
          <>
            <Button
              color="light"
              onClick={() => {
                navigate(`${PATH.DASHBOARD_PIMPINAN_PARTNER}/${row.id}`);
              }}
            >
              Detail
              <span className="ml-2">
                <Icon name="Show" />
              </span>
            </Button>
          </>
        );
      },
    },
  ];
  // const mitra = useSelector((state: RootState) => state.admin.mitra);
  const mitra = useSelector((state: RootState) => state.admin.mitra);
  const meta = useSelector((state: RootState) => state.admin.mitraMeta);

  const category = useSelector((state: RootState) => state.admin.category);

  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<string>("");

  useEffect(() => {
    dispatch(getCategory({}));
  }, []);

  useEffect(() => {
    dispatch(
      getMitra({
        params: {
          search: search,
          limit: limit,
          page: page,
          status: isVerify ? "A" : "S",
          classification: categories,
        },
      })
    );
  }, [isVerify, limit, page, search, categories]);

  const handleSearch = useCallback((e: any) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  }, []);

  const handleExport = useCallback(() => {
    dispatch(
      exportMitra({
        params: {},
        onSuccess(e: IExportExcel) {
          exportBase64ToExcel(e.payload.base64, e.payload.fileName);
          toast.success("Berhasil Export Excel");
        },
        onFailure(e) {
          toast.error("Gagal Export Excel");
        },
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <TableComponent
          withSearch={false}
          columns={columns}
          data={mitra}
          isLoading={isLoading}
          header={
            <div className="flex p-4 w-full justify-between">
              <div className="w-48">
                <SelectField
                  className="w-full"
                  options={[
                    { label: "Pilih Kategori", value: "" },
                    ...category.map((e) => ({
                      label: e.name,
                      value: e.id,
                    })),
                  ]}
                  value={categories}
                  onChange={(e) => setCategories(e.target.value)}
                />
              </div>
              <div className="w-full max-w-xs">
                <TextField placeholder="Search" onChange={handleSearch} />
              </div>
            </div>
          }
          pagination={{
            currentPage: meta.pageNumber,
            totalPages: meta.pageTotal,
            totalCount: meta.count,
            onChange(page) {
              setPage(page);
            },
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              setPage((prev) => prev + 1);
            },
          }}
          isDownload
          onExport={handleExport}
        />
      </>
    </MainLayout>
  );
};

export default DashboardMitra;
