import { Button, Icon, TextField } from "components";
import { FC, useState } from "react";

const DashboardDataInfo: FC<{
  formHandler: any;
  loading: boolean;
  onSubmit: () => void;
}> = ({ loading, formHandler, onSubmit }) => {
  return (
    <div className="p-6 pb-20">
      <div className="text-gray-800 font-semibold mb-2">Waktu Operasinal</div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Senin
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.senin.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.senin.buka");
              formHandler.setFieldValue(
                "waktuOperasional.senin.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.senin?.buka &&
              !!formHandler.errors.waktuOperasional?.senin?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.senin?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.senin.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.senin.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.senin.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.senin?.tutup &&
              !!formHandler.errors.waktuOperasional?.senin?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.senin?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Selasa
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.selasa.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.selasa.buka");
              formHandler.setFieldValue(
                "waktuOperasional.selasa.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.selasa?.buka &&
              !!formHandler.errors.waktuOperasional?.selasa?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.selasa?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.selasa.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.selasa.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.selasa.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.selasa?.tutup &&
              !!formHandler.errors.waktuOperasional?.selasa?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.selasa?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Rabu
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.rabu.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.rabu.buka");
              formHandler.setFieldValue(
                "waktuOperasional.rabu.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.rabu?.buka &&
              !!formHandler.errors.waktuOperasional?.rabu?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.rabu?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.rabu.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.rabu.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.rabu.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.rabu?.tutup &&
              !!formHandler.errors.waktuOperasional?.rabu?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.rabu?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Kamis
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.kamis.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.kamis.buka");
              formHandler.setFieldValue(
                "waktuOperasional.kamis.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.kamis?.buka &&
              !!formHandler.errors.waktuOperasional?.kamis?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.kamis?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.kamis.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.kamis.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.kamis.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.kamis?.tutup &&
              !!formHandler.errors.waktuOperasional?.kamis?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.kamis?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Jumat
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.jumat.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.jumat.buka");
              formHandler.setFieldValue(
                "waktuOperasional.jumat.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.jumat?.buka &&
              !!formHandler.errors.waktuOperasional?.jumat?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.jumat?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.jumat.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.jumat.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.jumat.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.jumat?.tutup &&
              !!formHandler.errors.waktuOperasional?.jumat?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.jumat?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Sabtu
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.sabtu.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.sabtu.buka");
              formHandler.setFieldValue(
                "waktuOperasional.sabtu.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.sabtu?.buka &&
              !!formHandler.errors.waktuOperasional?.sabtu?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.sabtu?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.sabtu.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.sabtu.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.sabtu.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.sabtu?.tutup &&
              !!formHandler.errors.waktuOperasional?.sabtu?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.sabtu?.tutup}`}
          />
        </div>
      </div>
      <div className="flex items-center mb-6">
        <div className="rounded-lg p-4 text-gray-500 text-sm flex items-center justify-between cursor-pointer bg-white mx-1 whitespace-nowrap flex items-center justify-between">
          Minggu
          <svg
            className="hidden md:block"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.745899 15.7589C0.44784 15.4667 0.420743 15.0095 0.66461 14.6873L0.745899 14.595L7.47342 8L0.745899 1.40503C0.44784 1.11283 0.420743 0.655583 0.66461 0.333375L0.745899 0.241064C1.04396 -0.0511349 1.51037 -0.0776984 1.83904 0.161374L1.93321 0.241064L9.2541 7.41802C9.55216 7.71022 9.57926 8.16746 9.33539 8.48967L9.2541 8.58198L1.93321 15.7589C1.60534 16.0804 1.07376 16.0804 0.745899 15.7589Z"
              fill="#3D3D3D"
            />
          </svg>
        </div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.minggu.buka"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.minggu.buka");
              formHandler.setFieldValue(
                "waktuOperasional.minggu.buka",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.minggu?.buka &&
              !!formHandler.errors.waktuOperasional?.minggu?.buka
            }
            errorText={`${formHandler.errors.waktuOperasional?.minggu?.buka}`}
          />
        </div>
        <div className="text-sm text-gray-600 mx-1">s.d</div>
        <div className="mx-1 flex-1">
          <TextField
            padingBottom={0}
            type="time"
            className="rounded-lg p-4"
            variant="solid"
            placeholder="Jam"
            propsInput={{
              ...formHandler.getFieldProps("waktuOperasional.minggu.tutup"),
            }}
            onChange={(e) => {
              formHandler.setFieldTouched("waktuOperasional.minggu.tutup");
              formHandler.setFieldValue(
                "waktuOperasional.minggu.tutup",
                e.target.value ?? ""
              );
            }}
            error={
              !!formHandler.touched.waktuOperasional?.minggu?.tutup &&
              !!formHandler.errors.waktuOperasional?.minggu?.tutup
            }
            errorText={`${formHandler.errors.waktuOperasional?.minggu?.tutup}`}
          />
        </div>
      </div>
      <div className="text-gray-800 font-semibold mb-2">
        Media Sosial dan Marketplace (optional)
      </div>
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Shopee"
        propsInput={{
          ...formHandler.getFieldProps("links.shopee"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.shopee");
          formHandler.setFieldValue("links.shopee", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.shopee &&
          !!formHandler.errors.waktuOperasional?.links?.shopee
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.shopee}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Tokopedia"
        propsInput={{
          ...formHandler.getFieldProps("links.tokopedia"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.tokopedia");
          formHandler.setFieldValue("links.tokopedia", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.tokopedia &&
          !!formHandler.errors.waktuOperasional?.links?.tokopedia
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.tokopedia}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Bukalapak"
        propsInput={{
          ...formHandler.getFieldProps("links.bukalapak"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.bukalapak");
          formHandler.setFieldValue("links.bukalapak", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.bukalapak &&
          !!formHandler.errors.waktuOperasional?.links?.bukalapak
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.bukalapak}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Instagram"
        propsInput={{
          ...formHandler.getFieldProps("links.instagram"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.instagram");
          formHandler.setFieldValue("links.instagram", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.instagram &&
          !!formHandler.errors.waktuOperasional?.links?.instagram
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.instagram}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Facebook"
        propsInput={{
          ...formHandler.getFieldProps("links.facebook"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.facebook");
          formHandler.setFieldValue("links.facebook", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.facebook &&
          !!formHandler.errors.waktuOperasional?.links?.facebook
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.facebook}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Twitter"
        propsInput={{
          ...formHandler.getFieldProps("links.twitter"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.twitter");
          formHandler.setFieldValue("links.twitter", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.twitter &&
          !!formHandler.errors.waktuOperasional?.links?.twitter
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.twitter}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Youtube"
        propsInput={{
          ...formHandler.getFieldProps("links.youtube"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.youtube");
          formHandler.setFieldValue("links.youtube", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.youtube &&
          !!formHandler.errors.waktuOperasional?.links?.youtube
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.youtube}`}
      />
      <TextField
        className="rounded-lg p-4"
        variant="solid"
        placeholder="Link Telegram"
        propsInput={{
          ...formHandler.getFieldProps("links.telegram"),
        }}
        onChange={(e) => {
          formHandler.setFieldTouched("links.telegram");
          formHandler.setFieldValue("links.telegram", e.target.value ?? "");
        }}
        error={
          !!formHandler.touched.waktuOperasional?.links?.telegram &&
          !!formHandler.errors.waktuOperasional?.links?.telegram
        }
        errorText={`${formHandler.errors.waktuOperasional?.links?.telegram}`}
      />

      <Button
        size="xl"
        full
        color="theme"
        onClick={onSubmit}
        loading={loading}
        disabled={loading}
      >
        Simpan
      </Button>
    </div>
  );
};

export { DashboardDataInfo };
