const Footer = () => {
  return (
    <div className="w-full px-6 py-6 mx-auto">
      <footer className="pt-4">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap items-center -mx-3 lg:justify-between">
            <div className="w-full max-w-full px-3 mt-0 mb-6 shrink-0 lg:mb-0 lg:w-1/2 lg:flex-none">
              <div className="leading-normal text-center text-sm text-slate-500 lg:text-left">
                © 2022 made with
                <i className="fa fa-heart"></i> by
                <a
                  href="https://www.creative-tim.com"
                  className="font-semibold text-slate-700 ml-2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  KaltengDev Team
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export { Footer };
