import { createAction } from "@reduxjs/toolkit";
import { actionRequest, actionSuccess } from "setup/redux/loading.toolkit";
import {
  ActionTypes,
  IAdminDetailResponse,
  IAdminResponse,
  IAnalyticsResponse,
  IAnalyticsV1Response,
  IArticleDetailResponse,
  IArticleResponse,
  IAspirationResponse,
  ICategoryDetailResponse,
  ICategoryResponse,
  ICityResponse,
  IDistrictResponse,
  IFundingDetailResponse,
  IFundingResponse,
  IGeneralDataResponse,
  IInputPayload,
  IInputPayloadCallback,
  ILogsResponse,
  IMitraDetailResponse,
  IMitraMapsResponse,
  IMitraResponse,
  IProductDetailResponse,
  IProfileInfoResponse,
  ITrainingDetailResponse,
  ITrainingResponse,
  IUserDetailResponse,
  IUserResponse,
  IVillageResponse,
} from "./";

export const getLogs = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_LOGS)
);
export const setLogs = createAction<ILogsResponse>(
  actionSuccess(ActionTypes.GET_LOGS)
);
export const getAnalytics = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ANALYTICS)
);
export const setAnalytics = createAction<IAnalyticsResponse>(
  actionSuccess(ActionTypes.GET_ANALYTICS)
);
export const getAnalyticsV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ANALYTICS_V1)
);
export const setAnalyticsV1 = createAction<IAnalyticsV1Response>(
  actionSuccess(ActionTypes.GET_ANALYTICS_V1)
);
export const getUser = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_USER)
);
export const setUser = createAction<IUserResponse>(
  actionSuccess(ActionTypes.GET_USER)
);
export const getUserDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_USER_DETAIL)
);
export const setUserDetail = createAction<IUserDetailResponse>(
  actionSuccess(ActionTypes.GET_USER_DETAIL)
);
export const editUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_USER)
);
export const deleteUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_USER)
);
export const addUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_USER)
);
export const approveUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPROVE_USER)
);
export const exportUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EXPORT_USER)
);

export const getCategory = createAction<any>(
  actionRequest(ActionTypes.GET_CATEGORY)
);
export const setCategory = createAction<ICategoryResponse>(
  actionSuccess(ActionTypes.GET_CATEGORY)
);
export const getCategoryDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_CATEGORY_DETAIL)
);
export const setCategoryDetail = createAction<ICategoryDetailResponse>(
  actionSuccess(ActionTypes.GET_CATEGORY_DETAIL)
);
export const addCategory = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_CATEGORY)
);
export const editCategory = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_CATEGORY)
);
export const deleteCategory = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_CATEGORY)
);

export const getMitra = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA)
);
export const setMitra = createAction<IMitraResponse>(
  actionSuccess(ActionTypes.GET_MITRA)
);
export const getMitraMaps = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_MAPS)
);
export const setMitraMaps = createAction<IMitraMapsResponse>(
  actionSuccess(ActionTypes.GET_MITRA_MAPS)
);
export const getMitraDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_DETAIL)
);
export const setMitraDetail = createAction<IMitraDetailResponse>(
  actionSuccess(ActionTypes.GET_MITRA_DETAIL)
);
export const addMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_MITRA)
);
export const editMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_MITRA)
);
export const approveMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPROVE_MITRA)
);
export const exportMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EXPORT_MITRA)
);

// product
export const getProductDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_DETAIL)
);
export const setProductDetail = createAction<IProductDetailResponse>(
  actionSuccess(ActionTypes.GET_PRODUCT_DETAIL)
);
export const editProduct = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_PRODUCT)
);
// export const deleteProduct = createAction<IInputPayloadCallback>(
//   actionRequest(ActionTypes.DELETE_PRODUCT)
// );

// training
export const getTraining = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_TRAINING)
);
export const setTraining = createAction<ITrainingResponse>(
  actionSuccess(ActionTypes.GET_TRAINING)
);
export const getTrainingDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_TRAINING_DETAIL)
);
export const setTrainingDetail = createAction<ITrainingDetailResponse>(
  actionSuccess(ActionTypes.GET_TRAINING_DETAIL)
);
export const addTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_TRAINING)
);
export const editTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_TRAINING)
);
export const approveTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPROVE_TRAINING)
);
export const exportTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EXPORT_TRAINING)
);

export const getFunding = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_FUNDING)
);
export const setFunding = createAction<IFundingResponse>(
  actionSuccess(ActionTypes.GET_FUNDING)
);
export const getFundingDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_FUNDING_DETAIL)
);
export const setFundingDetail = createAction<IFundingDetailResponse>(
  actionSuccess(ActionTypes.GET_FUNDING_DETAIL)
);
export const addFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_FUNDING)
);
export const editFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_FUNDING)
);
export const approveFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPROVE_FUNDING)
);
export const exportFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EXPORT_FUNDING)
);

export const getAspiration = createAction(
  actionRequest(ActionTypes.GET_ASPIRATION)
);
export const setAspiration = createAction<IAspirationResponse>(
  actionSuccess(ActionTypes.GET_ASPIRATION)
);

export const getCity = createAction(actionRequest(ActionTypes.GET_CITY));
export const setCity = createAction<ICityResponse>(
  actionSuccess(ActionTypes.GET_CITY)
);
export const getDistrict = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_DISTRICT)
);
export const setDistrict = createAction<IDistrictResponse>(
  actionSuccess(ActionTypes.GET_DISTRICT)
);
export const getVillage = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_VILLAGE)
);
export const setVillage = createAction<IVillageResponse>(
  actionSuccess(ActionTypes.GET_VILLAGE)
);

export const getArticle = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ARTICLE)
);
export const setArticle = createAction<IArticleResponse>(
  actionSuccess(ActionTypes.GET_ARTICLE)
);
export const getArticleDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ARTICLE_DETAIL)
);
export const setArticleDetail = createAction<IArticleDetailResponse>(
  actionSuccess(ActionTypes.GET_ARTICLE_DETAIL)
);
export const addArticle = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_ARTICLE)
);
export const editArticle = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_ARTICLE)
);
export const deleteArticle = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_ARTICLE)
);
export const getProfileInfo = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PROFILE_INFO)
);
export const setProfileInfo = createAction<IProfileInfoResponse>(
  actionSuccess(ActionTypes.GET_PROFILE_INFO)
);
export const editProfile = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SETTING_EDIT_PROFILE)
);
export const changePassword = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SETTING_CHANGE_PASSWORD)
);
export const updateBanner = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.UPDATE_BANNER)
);
export const getGeneralData = createAction(
  actionRequest(ActionTypes.GET_GENERAL_DATA)
);
export const setGeneralData = createAction<IGeneralDataResponse>(
  actionSuccess(ActionTypes.GET_GENERAL_DATA)
);
export const uploadSlider = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.UPLOAD_SLIDER)
);
export const deleteSlider = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_SLIDER)
);
export const getAdmin = createAction(actionRequest(ActionTypes.GET_ADMIN_LIST));
export const setAdmin = createAction<IAdminResponse>(
  actionSuccess(ActionTypes.GET_ADMIN_LIST)
);
export const getAdminDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_ADMIN_DETAIL)
);
export const setAdminDetail = createAction<IAdminDetailResponse>(
  actionSuccess(ActionTypes.GET_ADMIN_DETAIL)
);
export const addAdmin = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_ADMIN)
);
export const updateAdmin = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.UPDATE_ADMIN)
);
export const deleteAdmin = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_ADMIN)
);

export const sendNotifUser = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SEND_NOTIFICATION_USER)
);
export const sendNotifBroadcast = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SEND_NOTIFICATION_BROADCAST)
);
export const sendNotifTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SEND_NOTIFICATION_TRAINING)
);
export const sendNotifFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.SEND_NOTIFICATION_FUNDING)
);
