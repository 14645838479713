import ReactDOM from "react-dom/client";
import "./index.css";
import "./normalize.scss";
import "tailwindcss/tailwind.css";
import theme from "./flowbite-theme";
import { Flowbite } from "flowbite-react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { setupAxios } from "./setup";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./setup/redux/Store";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

setupAxios(axios);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Flowbite theme={{ theme }}>
      <Toaster />
      <App />
    </Flowbite>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
