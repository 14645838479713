import { MainLayout } from "components";
import { DashboardFundingInput } from "./DashboardFundingInput";

const DashboardFundingAdd = () => {
  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <DashboardFundingInput isEdit={false} />
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardFundingAdd };
