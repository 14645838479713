import React, { FC } from "react";
import ReactApexChart from "react-apexcharts";

interface ChartDataItem {
  date: string;
  omset: number;
}

interface LineChartProps {
  data: ChartDataItem[];
}

const LineChart: FC<LineChartProps> = ({ data }) => {
  // Ekstraksi tanggal dan data omset dari input
  const dates = data.map((item) => item.date);
  const omsetData = data.map((item) => item.omset);

  // Data chart
  const chartData: any = {
    series: [
      {
        name: "Omset",
        data: omsetData,
      },
    ],
    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "line",
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: true,
        },
      },
      xaxis: {
        categories: dates,
      },
      yaxis: {
        title: {
          text: "Omset",
        },
      },
    },
  };

  return (
    <div>
      {omsetData[0] !== undefined && (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        />
      )}
    </div>
  );
};

export default LineChart;
