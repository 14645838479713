import { Button, Dialog, TextField } from "components";
import {
  ActionTypes,
  IMitra,
  approveUser,
  getUser,
  sendNotifUser,
} from "pages/admin/redux";
import { FC, useCallback, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DialogRejectUser: FC<{
  show: boolean;
  onClose: () => void;
  data?: IMitra;
}> = ({ show, data, onClose }) => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(ActionTypes.APPROVE_USER));
  const [success, setSuccess] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleReject = useCallback(() => {
    dispatch(
      approveUser({
        params: {
          userId: data?.id,
          status: "S",
        },
        onSuccess(e) {
          setSuccess(true);
          toast.success("Berhasil menolak pengguna");
          if (message !== "") {
            dispatch(
              sendNotifUser({
                params: {
                  target: [data?.id],
                  title: "Pengguna belum berhasil di verifikasi",
                  body: message,
                },
              })
            );
          }
          dispatch(
            getUser({
              params: {
                nama: "",
                email: "",
                status: ["S"],
              },
            })
          );
        },
        onFailure(e) {
          setSuccess(false);
          toast.error("Berhasil menolak pengguna");
        },
      })
    );
  }, [data, message]);
  return (
    <Dialog title="Tolak Pengguna" show={show} onClose={onClose} size="md">
      <div className="flex flex-col items-center">
        {success ? (
          <>
            <img
              src="/assets/images/134875-failed-status.gif"
              alt="apprive gif"
              className="w-32 my-4"
            />
            <div>Berhasil Menolak Pengguna</div>
            <Button color="light" onClick={onClose} className="mt-4">
              Tutup
            </Button>
          </>
        ) : (
          <>
            <img
              src="/assets/images/undraw_confirmation_re_b6q5.svg"
              alt="need approve"
              className="w-32 my-8"
            />
            <div>Tindakan ini perlu persetujuan</div>
            <div className="text-sm">
              Anda yakin ingin menolak pengguna ini?
            </div>
            <TextField
              placeholder="Alasan ditolak"
              className="mt-4"
              multiLine={4}
              padingBottom={0}
              propsInput={{ value: message }}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div className="flex justify-center mt-4">
              <Button color="light" className="mr-2" onClick={onClose}>
                Batalkan
              </Button>
              <Button
                color="failure"
                className="ml-2"
                loading={loading}
                disabled={loading}
                onClick={handleReject}
              >
                Tolak Pengguna
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
export { DialogRejectUser };
