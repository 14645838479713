import { MainLayout } from "components";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { CardUsaha } from "./CardUsaha";
import {
  ActionTypes,
  addMitra,
  editMitra,
  getCategory,
  getMitra,
  getMitraDetail,
  getProduct,
} from "pages/users/redux";
import { useNavigate, useParams } from "react-router-dom";
import { PATH } from "config";
import { HeaderNav } from "./HeaderNav";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RootState } from "setup";
import { DialogLocation } from "./DialogLocation";
import { getCity, getDistrict, getVillage } from "pages/admin/redux";
import { toast } from "react-hot-toast";
import { DashboardDataUmum } from "./DashboardDataUmum";
import { DashboardDataInfo } from "./DashboardDataInfo";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { DashboardProduct } from "../dashboard-product/DashboardProduct";

export interface ILatLng {
  lat: number;
  lng: number;
}

const DashboardUsahaInput: FC<{
  isEdit?: boolean;
}> = ({ isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    businessId: string;
  }>();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_MITRA)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_MITRA)
  );

  const [dialog, setDialog] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [tab, setTab] = useState<number>(1);

  const mitra = useSelector((state: RootState) => state.user.mitra);

  const initialValues = useMemo(() => {
    return {
      mitraId: isEdit ? mitra?._id ?? "" : "",
      namaMitra: isEdit ? mitra?.namaMitra ?? "" : "",
      namaBadanUsaha: isEdit ? mitra?.namaBadanUsaha ?? "" : "",
      deskripsi: isEdit ? mitra?.deskripsi ?? "" : "",
      omset: isEdit ? mitra?.omset ?? "" : "",
      klasifikasiEkraf: isEdit ? mitra?.klasifikasiEkraf[0] : "",
      npwp: isEdit ? mitra?.npwp ?? "" : "",
      nib: isEdit ? mitra?.nib ?? "" : "",
      pengizinUsaha: isEdit ? mitra?.pengizinUsaha ?? "" : "",
      berkasIzinUsaha: isEdit ? mitra?.berkasIzinUsaha ?? "" : "",
      lat: isEdit ? mitra?.location?.coordinates[1] ?? "" : "",
      long: isEdit ? mitra?.location?.coordinates[0] ?? "" : "",
      alamat: isEdit ? mitra?.alamat ?? "" : "",
      kota: isEdit ? mitra?.kotaId : 0,
      kecamatan: isEdit ? mitra?.kecamatanId : 0,
      kelurahan: isEdit ? mitra?.kelurahanId : 0,
      foto: isEdit ? mitra?.foto ?? "" : "",
      waktuOperasional: isEdit
        ? mitra?.waktuOperasional
        : {
            senin: {
              buka: "",
              tutup: "",
            },
            selasa: {
              buka: "",
              tutup: "",
            },
            rabu: {
              buka: "",
              tutup: "",
            },
            kamis: {
              buka: "",
              tutup: "",
            },
            jumat: {
              buka: "",
              tutup: "",
            },
            sabtu: {
              buka: "",
              tutup: "",
            },
            minggu: {
              buka: "",
              tutup: "",
            },
          },
      links: isEdit ? mitra?.links : {},
      status: isEdit ? mitra?.status : "A",
    };
  }, [isEdit, mitra]);

  const validationSchema = Yup.object({
    namaMitra: Yup.string().required("Nama Usaha is required"),
    namaBadanUsaha: Yup.string().required("Nama Badan Usaha is required"),
    deskripsi: Yup.string().required("Deskripsi Usaha is required"),
    omset: Yup.number().required("Omzet Tahunan is required"),
    klasifikasiEkraf: Yup.string().required("Kategori is required"),
    npwp: Yup.string().nullable().optional(),
    nib: Yup.string().nullable().optional(),
    pengizinUsaha: Yup.string().required(
      "Pejabat yang mensahkan izin usaha is required"
    ),
    foto: isEdit
      ? Yup.string().nullable().optional()
      : Yup.string().required("Foto is required"),
    berkasIzinUsaha: Yup.string().nullable().optional(),
    alamat: Yup.string().required("Alamat is required"),
    lat: Yup.string().required("Location is required"),
    long: Yup.string().required("Location is required"),
    kota: Yup.number().min(1, "Kota is required").required("Kota is required"),
    kecamatan: Yup.number()
      .min(1, "Kecamatan is required")
      .required("Kecamatan is required"),
    kelurahan: Yup.number()
      .min(1, "Kelurahan is required")
      .required("Kelurahan is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      let submitValue: any = {
        ...values,
        klasifikasiEkraf: [values.klasifikasiEkraf],
        location: {
          lat: values.lat,
          long: values.long,
        },
      };

      if (values.berkasIzinUsaha === "") {
        var { berkasIzinUsaha, ...newSubmitValue } = submitValue;
        submitValue = newSubmitValue;
      }

      if (isEdit) {
        dispatch(
          editMitra({
            params: submitValue,
            onSuccess(e) {
              toast.success("Berhail mengedit Usaha");
              navigate(PATH.USER_USAHA);
            },
            onFailure(e) {
              toast.error("Gagal mengedit Usaha");
            },
          })
        );
      } else {
        dispatch(
          addMitra({
            params: submitValue,
            onSuccess(e) {
              toast.success("Berhail mendaftarkan Usaha");
              navigate(PATH.USER_USAHA);
            },
            onFailure(e) {
              toast.error("Gagal mendaftarkan Usaha");
            },
          })
        );
      }
    },
  });

  useEffect(() => {
    if (formHandler.values.kota !== 0) {
      dispatch(
        getDistrict({
          params: {
            regencyId: formHandler.values.kota,
          },
        })
      );
    }
  }, [formHandler.values.kota]);

  useEffect(() => {
    if (formHandler.values.kecamatan !== 0) {
      dispatch(
        getVillage({
          params: {
            districtId: formHandler.values.kecamatan,
          },
        })
      );
    }
  }, [formHandler.values.kecamatan]);

  const validateForm = useCallback(
    (fieldToCheck: string[], callBack: (isValid: boolean) => void) => {
      formHandler.validateForm().then((err: any) => {
        let valid = true;
        const errorField = Object.keys(err);
        fieldToCheck.forEach((key) => {
          formHandler.setFieldTouched(key);
          if (errorField.includes(key)) {
            formHandler.setFieldError(key, err[key]);
            valid = false;
          }
        });
        callBack(valid);
      });
    },
    [formHandler]
  );

  const fieldFormByTabId = useMemo<{ [key: string]: string[] }>(
    () => ({
      1: [
        "namaMitra",
        "namaBadanUsaha",
        "deskripsi",
        "omset",
        "klasifikasiEkraf",
        "npwp",
        "nib",
        "pengizinUsaha",
        "berkasIzinUsaha",
        "lat",
        "long",
        "alamat",
        "kota",
        "kecamatan",
        "kelurahan",
        "foto",
      ],
      2: ["links"],
    }),
    []
  );

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getCity());
    if (isEdit) {
      dispatch(
        getMitraDetail({
          params: {
            businessId: params.businessId,
          },
        })
      );
      dispatch(
        getProduct({
          params: {
            mitraId: params.businessId,
          },
        })
      );
    }
  }, [isEdit, params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <BottomNav />
        <DialogLocation
          show={dialog}
          onClose={() => {
            setDialog(false);
          }}
          onSubmit={(value) => {
            formHandler.setFieldValue("lat", value.lat);
            formHandler.setFieldValue("long", value.lng);
            formHandler.setFieldValue("alamat", value.alamat);
            formHandler.setFieldTouched("lat");
            formHandler.setFieldTouched("long");
            formHandler.setFieldTouched("alamat");
            setDialog(false);
          }}
        />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Daftar Usaha" />
          {mitra?.status === "A" && isEdit && (
            <div className="flex p-6 pb-0">
              <div className="flex-1 pr-2">
                <div
                  className={[
                    "bg-white rounded-lg p-3 text-center text-sm cursor-pointer",
                    tab === 1
                      ? "text-[#CB043F] font-semibold"
                      : "text-[#AFAEAE]",
                  ].join(" ")}
                  onClick={() => setTab(1)}
                >
                  Data Usaha
                </div>
              </div>
              <div className="flex-1 pl-2">
                <div
                  className={[
                    "bg-white rounded-lg p-3 text-center text-sm cursor-pointer",
                    tab === 2
                      ? "text-[#CB043F] font-semibold"
                      : "text-[#AFAEAE]",
                  ].join(" ")}
                  onClick={() => setTab(2)}
                >
                  Data Produk
                </div>
              </div>
            </div>
          )}
          {tab === 1 ? (
            <>
              {step === 1 ? (
                <DashboardDataUmum
                  isEdit={isEdit}
                  formHandler={formHandler}
                  setDialog={setDialog}
                  onSubmit={() => {
                    validateForm(fieldFormByTabId["1"], (isValid: boolean) => {
                      if (isValid) setStep(2);
                    });
                  }}
                />
              ) : (
                <DashboardDataInfo
                  formHandler={formHandler}
                  onSubmit={() => {
                    formHandler.submitForm();
                  }}
                  loading={isLoadingAdd || isLoadingEdit}
                />
              )}
            </>
          ) : (
            <DashboardProduct />
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardUsahaInput };
