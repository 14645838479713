import { Button, SelectField, TextField } from "components";
import { PATH } from "config";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ActionTypes, register } from "../redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = useSelector(createLoadingSelector(ActionTypes.REGISTER));

  const initialValues = {
    nik: "",
    nama: "",
    gambarProfil: "",
    gender: "",
    tempatLahir: "",
    tanggalLahir: "",
    email: "",
    noHandphone: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    nik: Yup.string().required("NIK is Required"),
    nama: Yup.string().required("Nama is Required"),
    gambarProfil: Yup.string().required("Foto Profile is Required"),
    gender: Yup.string().required("Jenis Kelamin is required").matches(/(M|F)/),
    tempatLahir: Yup.string().required("Tempat Lahir is Required"),
    tanggalLahir: Yup.string().required("Tanggal Lahir is Required"),
    email: Yup.string().required("Email is Required"),
    noHandphone: Yup.string()
      .matches(/^[0-9]+$/, "Can only numbers")
      .min(9, "Phone number at least 9 digits")
      .max(20, "Phone number up to 20 digits")
      .required(),
    password: Yup.string()
      .min(6, "Password minimum 6 Characters")
      .required("Password is Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formHandler = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      dispatch(
        register({
          params: {
            dataDiri: {
              nik: values.nik,
              gender: values.gender,
              nama: values.nama,
              tempatLahir: values.tempatLahir,
              tanggalLahir: values.tanggalLahir,
              gambarProfil: values.gambarProfil,
            },
            dataAkun: {
              email: values.email,
              password: values.password,
              noHandphone: values.noHandphone,
            },
          },
          onSuccess(e) {
            toast.success("Berhasil membuat akun");
            navigate(PATH.LOGIN);
          },
          onFailure(e) {
            toast.error("Gagal membuat akun");
          },
        })
      );
    },
  });

  return (
    <div className="bg-[#F6F7FB]">
      <div className="w-full mx-auto md:max-w-md flex flex-col px-8 py-8 items-center justify-center bg-white min-h-screen">
        <div className="text-xl mt-4 mb-8 font-bold">Data Diri</div>

        <TextField
          type="text"
          parentClassName="mb-6"
          placeholder="Nomor NIK"
          prefix="ShieldDone"
          propsInput={{ ...formHandler.getFieldProps("nik") }}
          error={formHandler.touched.nik && !!formHandler.errors.nik}
          errorText={`${formHandler.errors.nik}`}
        />
        <TextField
          type="text"
          parentClassName="mb-6"
          placeholder="Nama"
          prefix="User"
          propsInput={{ ...formHandler.getFieldProps("nama") }}
          error={formHandler.touched.nama && !!formHandler.errors.nama}
          errorText={`${formHandler.errors.nama}`}
        />
        <TextField
          type="text"
          parentClassName="mb-6"
          placeholder="Tempat Lahir"
          prefix="Location"
          propsInput={{ ...formHandler.getFieldProps("tempatLahir") }}
          error={
            formHandler.touched.tempatLahir && !!formHandler.errors.tempatLahir
          }
          errorText={`${formHandler.errors.tempatLahir}`}
        />
        <TextField
          type="date"
          parentClassName="mb-6"
          placeholder="Tanggal Lahir"
          prefix="Calendar"
          propsInput={{ ...formHandler.getFieldProps("tanggalLahir") }}
          error={
            formHandler.touched.tanggalLahir &&
            !!formHandler.errors.tanggalLahir
          }
          errorText={`${formHandler.errors.tanggalLahir}`}
        />
        <TextField
          parentClassName="mb-6"
          className="hide-button-file"
          placeholder="Foto Profil"
          prefix="Image"
          type="file"
          propsInput={{
            ...formHandler.getFieldProps("gambarProfil"),
            accept: "image/*",
          }}
          onChange={(e) => {
            formHandler.setFieldTouched("gambarProfil");
            formHandler.setFieldValue("gambarProfil", e ?? "");
          }}
          error={
            formHandler.touched.gambarProfil &&
            !!formHandler.errors.gambarProfil
          }
          errorText={`${formHandler.errors.gambarProfil}`}
        />
        <SelectField
          placeholder="Pilih Jenis Kelamin"
          options={[
            { label: "Laki-laki", value: "M" },
            { label: "Perempuan", value: "F" },
          ]}
          value={formHandler.values.gender}
          onChange={(e) => {
            formHandler.setFieldValue("gender", e.target.value);
            formHandler.setFieldTouched("gender");
          }}
          error={formHandler.touched.gender && !!formHandler.errors.gender}
          errorText={`${formHandler.errors.gender}`}
        />
        <TextField
          type="email"
          parentClassName="mb-6"
          placeholder="Email"
          prefix="Message"
          propsInput={{ ...formHandler.getFieldProps("email") }}
          error={formHandler.touched.email && !!formHandler.errors.email}
          errorText={`${formHandler.errors.email}`}
        />
        <TextField
          type="tel"
          parentClassName="mb-6"
          placeholder="No Whatsapp"
          prefix="Call"
          propsInput={{ ...formHandler.getFieldProps("noHandphone") }}
          error={
            formHandler.touched.noHandphone && !!formHandler.errors.noHandphone
          }
          errorText={`${formHandler.errors.noHandphone}`}
        />
        <TextField
          type="password"
          parentClassName="mb-6"
          placeholder="Password"
          prefix="Lock"
          propsInput={{ ...formHandler.getFieldProps("password") }}
          error={formHandler.touched.password && !!formHandler.errors.password}
          errorText={`${formHandler.errors.password}`}
        />
        <TextField
          type="password"
          parentClassName="mb-6"
          placeholder="Ulangi Password"
          prefix="Lock"
          propsInput={{ ...formHandler.getFieldProps("confirmPassword") }}
          error={
            formHandler.touched.confirmPassword &&
            !!formHandler.errors.confirmPassword
          }
          errorText={`${formHandler.errors.confirmPassword}`}
        />
        <Button
          color="failure"
          type="submit"
          disabled={isLoading}
          loading={isLoading}
          size="sm"
          full
          className="mb-4"
          onClick={() => formHandler.submitForm()}
        >
          Simpan
        </Button>

        <div className="text-center mt-4 mb-8 text-sm">
          Sudah punya akun?{" "}
          <span
            className="text-[#CB043F] font-bold cursor-pointer"
            onClick={() => navigate(PATH.LOGIN)}
          >
            Login Disini
          </span>
        </div>
      </div>
    </div>
  );
};

export default Register;
