import { Button, FileSelect, MainLayout, TextField } from "components";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import {
  ActionTypes,
  deleteSlider,
  getGeneralData,
  updateBanner,
} from "pages/admin/redux";
import { toast } from "react-hot-toast";
import { DialogUplaodSlider } from "./DialogUploadSlider";

const DashboardSettingGeneral = () => {
  const dispatch = useDispatch();

  const loading = useSelector(createLoadingSelector(ActionTypes.UPDATE_BANNER));
  const loadingDelete = useSelector(
    createLoadingSelector(ActionTypes.DELETE_SLIDER)
  );
  const generalData = useSelector(
    (state: RootState) => state.admin.generalData
  );

  const [deleteId, setDeleteId] = useState<string>("");
  const [active, setActive] = useState<number>(0);
  const [dialogUpload, setDialogUpload] = useState<boolean>(false);

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      link: generalData.heroLink,
      image: generalData.hero,
    },
    validationSchema: Yup.object({
      link: Yup.string().required("Link wajib diisi"),
      image: Yup.string().required("Gambar Banner wajib diisi"),
    }),
    onSubmit(values) {
      dispatch(
        updateBanner({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mengganti banner");
            dispatch(getGeneralData());
          },
          onFailure(e) {
            toast.error("Gagal mengganti banner");
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getGeneralData());
  }, []);

  const handleDelete = useCallback((id: string) => {
    setDeleteId(id);
    dispatch(
      deleteSlider({
        params: {
          bannerId: id,
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus slider image");
          dispatch(getGeneralData());
        },
        onFailure(e) {
          toast.success("Gagal menghapus slider image");
        },
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <DialogUplaodSlider
          show={dialogUpload}
          onClose={() => {
            setDialogUpload(false);
          }}
        />
        <div className="bg-white shadow rounded">
          <div className="p-8 flex items-center justify-between border-b">
            <div className="text-lg font-semibold text-gray-800">
              General Setting
            </div>
            {active === 1 && (
              <Button color="failure" onClick={() => setDialogUpload(true)}>
                Tambah Slider
              </Button>
            )}
          </div>
          <div className="flex px-8">
            <div
              onClick={() => setActive(0)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 0
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Banner
            </div>
            <div
              onClick={() => setActive(1)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 1
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Slider
            </div>
          </div>
        </div>
        {active === 0 ? (
          <div className="bg-white shadow rounded p-8 mt-4">
            <div className="w-full max-w-3xl mx-auto">
              <div className="md:flex justify-between mb-8">
                <div className="flex flex-col items-start flex-1 pr-12 mb-4">
                  <div className="font-medium">Edit Banner</div>
                </div>
                <div className="w-full max-w-[300px]">
                  <FileSelect
                    imageUrl={formHandler.values.image}
                    onChange={(value) => {
                      formHandler.setFieldValue("image", value);
                      formHandler.setFieldTouched("image");
                    }}
                    error={
                      formHandler.touched.image && !!formHandler.errors.image
                    }
                    errorText={`${formHandler.errors.image ?? ""}`}
                  />
                  <TextField
                    label="Link Banner"
                    propsInput={{
                      ...formHandler.getFieldProps("link"),
                      autoComplete: "off",
                    }}
                    error={
                      formHandler.touched.link && !!formHandler.errors.link
                    }
                    errorText={formHandler.errors.link}
                  />
                  <Button
                    color="failure"
                    loading={loading}
                    disabled={loading}
                    onClick={() => formHandler.submitForm()}
                  >
                    Simpan
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4 flex flex-wrap">
            {generalData.banners.map((item, index) => (
              <div className="w-1/2 md:w-1/3 lg:1/4 p-3" key={index}>
                <div className="rounded overflow-hidden shadow">
                  <img
                    src={item.foto || "/assets/images/placeholder-image.png"}
                    className="w-full h-32 object-cover"
                  />
                  <div className="p-4 bg-white">
                    <Button
                      size="xs"
                      color="dark"
                      full
                      onClick={() => {
                        handleDelete(item._id);
                      }}
                      loading={deleteId === item._id && loadingDelete}
                      disabled={deleteId === item._id && loadingDelete}
                    >
                      Hapus
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    </MainLayout>
  );
};
export { DashboardSettingGeneral };
