import { Icon, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTraining, getTrainingHistory } from "pages/users/redux";
import { RootState } from "setup";
import { CardTraining } from "./CardTraining";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { CardTrainingHistory } from "./CardTrainingHistory";

const DashboardTraining: FC<{
  history?: boolean;
}> = ({ history }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { trainings, trainingHistory } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (history) {
      dispatch(getTrainingHistory());
    } else {
      dispatch(getTraining());
    }
  }, [history]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Info Pelatihan" />
          {!history && (
            <div className="p-6 pb-0 flex items-center justify-between">
              <div className="font-semibold">List Pelatihan</div>
              <div
                className="font-semibold text-[#CB043F] flex items-center cursor-pointer"
                onClick={() => navigate(PATH.USER_TRAINING_HISTORY)}
              >
                <Icon
                  name="Bookmark"
                  type="bold"
                  color="#CB043F"
                  size="small"
                />
                <div className="ml-2">Riwayat</div>
              </div>
            </div>
          )}
          {history ? (
            <div className="p-4">
              {trainingHistory.map((item, index) => (
                <div className="p-2" key={index}>
                  <CardTrainingHistory training={item} />
                </div>
              ))}
            </div>
          ) : (
            <div className="p-4">
              {trainings.map((item, index) => (
                <div className="p-2" key={index}>
                  <CardTraining training={item} />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardTraining };
