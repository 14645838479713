import { Button, TextField } from "components";
import { FC, useEffect, useMemo, useState } from "react";
import { HeaderNav } from "./HeaderNav";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const RecenterAutomatically: FC<{ lat: any; lng: any }> = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
    // eslint-disable-next-line
  }, [lat, lng]);
  return null;
};

export interface ILatLng {
  lat: number;
  lng: number;
}

const icon = L.icon({
  iconUrl: "/assets/icon/marker.png",
  iconSize: [32, 32],
});

const DialogLocation: FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
}> = ({ show, onClose, onSubmit }) => {
  const [center, setCenter] = useState<ILatLng>({
    lat: 1.4297828,
    lng: 114.74104130383714,
  });
  const [latLng, setLatLng] = useState<ILatLng>(center);
  const [alamat, setAlamat] = useState<string>("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        lat: +position.coords.latitude,
        lng: +position.coords.longitude,
      });
      setLatLng({
        lat: +position.coords.latitude,
        lng: +position.coords.longitude,
      });
    });
  }, []);

  const eventHandlers = useMemo(
    () => ({
      dragend(e: any) {
        let latLng = e.target.getLatLng();
        setLatLng(latLng);
      },
    }),
    []
  );

  return (
    <div
      className={[
        "fixed top-0 left-0 w-full h-screen overflow-y-auto z-50",
        !show && "hidden",
      ].join(" ")}
    >
      <div className="w-full mx-auto md:max-w-md bg-[#F6F7FB] min-h-screen relative">
        <HeaderNav title="Alamat" onClose={onClose} />
        <div className="p-4">
          {show && (
            <MapContainer
              className="z-0"
              style={{ height: "400px", width: "100%" }}
              center={center}
              zoom={15}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <RecenterAutomatically lat={center.lat} lng={center.lng} />
              <Marker
                position={latLng}
                icon={icon}
                draggable={true}
                autoPan={true}
                eventHandlers={eventHandlers}
              >
                <Popup>
                  {latLng.lat},{latLng.lng}
                </Popup>
              </Marker>
            </MapContainer>
          )}
          <TextField
            variant="solid"
            className="mt-4"
            multiLine={3}
            placeholder="Tulis Alamat"
            propsInput={{ value: alamat }}
            onChange={(e) => {
              setAlamat(e.target.value || "");
            }}
          />
          <Button
            className="mt-6"
            size="xl"
            full
            color="theme"
            onClick={() => onSubmit({ ...latLng, alamat: alamat })}
          >
            Simpan
          </Button>
        </div>
      </div>
    </div>
  );
};
export { DialogLocation };
