import { useDispatch, useSelector } from "react-redux";
import { Header } from "../landing-page/componets";
import { RootState } from "setup";
import { useEffect, useState } from "react";
import { getArticle } from "pages/landing/redux";
import { Pagination } from "components";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";

const Article = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const articles = useSelector((state: RootState) => state.landing.articles);

  useEffect(() => {
    dispatch(getArticle());
  }, []);

  return (
    <>
      <div className="bg-[#F6F7FB] min-h-screen">
        <Header
          handleScroll={() => {}}
          handleClick={() => {}}
          setOpenSide={() => {}}
        />
        <div className="flex flex-wrap mt-20">
          {articles.map((article, index) => (
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-4" key={index}>
              <div
                className="bg-white rounded-lg border p-4 cursor-pointer"
                onClick={() => {
                  navigate(
                    `${PATH.LANDING_PAGE_ARTICLE}/${article.id}/${article.title
                      .replace(/\s+/g, "-")
                      .toLowerCase()}`
                  );
                }}
              >
                <img
                  src={
                    article.preview.image ||
                    "/assets/images/placeholder-image.png"
                  }
                  alt="cover mitra"
                  className="w-full h-48 md:h-72 object-cover border"
                />
                <div className="text-[#3D3D3D] text-lg font-semibold my-1.5">
                  {article.title}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export { Article };
