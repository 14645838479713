import { useDispatch, useSelector } from "react-redux";
import { Header } from "../landing-page/componets";
import { RootState } from "setup";
import { useEffect, useState } from "react";
import { getProduct, getProductV1 } from "pages/landing/redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATH } from "config";
import { Pagination } from "components";

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector(
    (state: RootState) => state.landing.productListV1
  );
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");

  const meta = useSelector((state: RootState) => state.landing.productMeta);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(
      getProductV1({
        params: {
          search: search || "",
          page: page,
          limit: 12,
        },
      })
    );
  }, [page, search]);

  return (
    <>
      <div className="bg-[#F6F7FB] min-h-screen">
        <Header
          handleScroll={() => {}}
          handleClick={() => {}}
          setOpenSide={() => {}}
        />
        <div className="flex flex-wrap mt-20">
          {products.map((product, index) => (
            <div className="w-1/2 md:w-1/3 lg:w-1/4 p-4" key={index}>
              <div
                className="bg-white rounded-lg border p-4 cursor-pointer"
                onClick={() => {
                  navigate(`${PATH.LANDING_PAGE_PRODUCT}/${product.id}`);
                }}
              >
                <img
                  src={
                    product.fotoProduk || "/assets/images/placeholder-image.png"
                  }
                  alt="cover product"
                  className="w-full h-48 md:h-72 object-cover border"
                />
                <div className="text-[#3D3D3D] text-lg font-semibold my-1.5">
                  {product.namaProduk}
                </div>
                <div className="flex items-center text-[#AFAEAE] mb-2.5">
                  <i className="fa fa-map-marker-alt mr-2"></i>{" "}
                  {product.namaMitra}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={meta.pageNumber}
          totalPages={meta.pageTotal}
          totalCount={meta.count}
          onPrev={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          onNext={() => {
            setPage(page + 1);
          }}
          onChange={(currentPage) => {
            setPage(currentPage);
          }}
          isDownload={false}
          sticky={false}
        />
      </div>
    </>
  );
};
export { Product };
