import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Footer, Header } from "../landing-page/componets";
import { ActionTypes, getArticleDetail } from "pages/landing/redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DetailArticle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    articleId: string;
  }>();

  const article = useSelector((state: RootState) => state.landing.article);

  const loading = useSelector(
    createLoadingSelector(ActionTypes.GET_ARTICLE_DETAIL)
  );

  useEffect(() => {
    dispatch(
      getArticleDetail({
        params: {
          articleId: params.articleId,
        },
      })
    );
  }, [params]);

  return (
    <>
      <div className="bg-[#F6F7FB] min-h-screen">
        <Header
          handleScroll={() => {}}
          handleClick={() => {}}
          setOpenSide={() => {}}
        />
        {!loading && (
          <div className="mx-auto max-w-5xl pt-24 md:px-4">
            <div className="bg-white">
              <img
                src={
                  article.preview.image ||
                  "/assets/images/placeholder-image.png"
                }
                alt="thumbnail"
                className="w-full h-96 object-cover rounded"
              />
              <div className="w-full max-w-3xl mx-auto p-8 md:py-24">
                <h1 className="text-3xl md:text-4xl font-semibold text-gray-800 text-center">
                  {article.title}
                </h1>
                <div
                  className="mt-24 content-article"
                  dangerouslySetInnerHTML={{ __html: article.content }}
                ></div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
export { DetailArticle };
