/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  DashboardArticle,
  DashboardAspiration,
  DashboardCategory,
  DashboardFunding,
  DashboardMitra,
  DashboardSettingProfile,
  DashboardTraining,
  DashboardUser,
  DashboardMaps,
} from "./components";
import { PATH } from "config";
import DashboardTrainingDetail from "./components/dashboard-training/DashboardTrainingDetail";
import DashboardFundingDetail from "./components/dashboard-funding/DashboardFundingDetail";
import DashboardMitraDetail from "./components/dashboard-mitra/DashboardMitraDetail";
import DashboardLog from "./components/dashboard-log/DashboardLog";
import { NotFound } from "pages/landing/components";

const prefix = "/pimpinan";
const abs = (path: string) => {
  return path.substring(prefix.length);
};

const RoutePimpinan = function () {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={PATH.DASHBOARD_PIMPINAN_ANALYTICS} />}
      />

      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_ANALYTICS)}
        element={<Dashboard />}
      />

      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_MAPS)}
        element={<DashboardMaps />}
      />

      {/* user */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_USER)}
        element={<DashboardUser isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_USER_VERIFY)}
        element={<DashboardUser />}
      />

      {/* category */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_CATEGORY)}
        element={<DashboardCategory />}
      />

      {/* mitra */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_PARTNER)}
        element={<DashboardMitra isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_PARTNER_DETAIL)}
        element={<DashboardMitraDetail />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_PARTNER_VERIFY)}
        element={<DashboardMitra />}
      />

      {/* Dashboard Training Module */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_TRAINING)}
        element={<DashboardTraining />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_TRAINING_DETAIL)}
        element={<DashboardTrainingDetail />}
      />

      {/* Dashboard Funding Module */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_FUNDING)}
        element={<DashboardFunding />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_FUNDING_DETAIL)}
        element={<DashboardFundingDetail />}
      />

      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_ARTICLE)}
        element={<DashboardArticle />}
      />

      {/* Dashboard aspiration Module */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_ASPIRATION)}
        element={<DashboardAspiration />}
      />

      {/* Dashboard log */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_LOG)}
        element={<DashboardLog />}
      />

      {/* Dashboard Setting Module */}
      <Route
        path={abs(PATH.DASHBOARD_PIMPINAN_SETTINGS_PROFILE)}
        element={<DashboardSettingProfile />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { RoutePimpinan };
