import { Button, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { ActionTypes, getProfile, updateProfile } from "pages/users/redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardEditAccount = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: RootState) => state.user.profile);

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.UPDATE_PROFILE)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profile.email ?? "",
      noHandphone: profile.noHandphone ?? "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Email is not valid")
        .required("Email is required"),
      noHandphone: Yup.string()
        .matches(/^[0-9]+$/, "Can only numbers")
        .min(9, "Phone number at least 9 digits")
        .max(20, "Phone number up to 20 digits")
        .required(),
    }),
    onSubmit(values) {
      dispatch(
        updateProfile({
          params: values,
          onSuccess(e) {
            toast.success("Success to edit profile");
            dispatch(getProfile());
          },
          onFailure(e) {
            toast.error("Failed to edit profile");
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(getProfile());
  }, []);
  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Data Akun" />
          <div className="p-6 pb-4">
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Email"
              propsInput={{ ...formHandler.getFieldProps("email") }}
              error={!!formHandler.touched.email && !!formHandler.errors.email}
              errorText={`${formHandler.errors.email}`}
            />
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Nomor Handphone"
              propsInput={{ ...formHandler.getFieldProps("noHandphone") }}
              error={
                !!formHandler.touched.noHandphone &&
                !!formHandler.errors.noHandphone
              }
              errorText={`${formHandler.errors.noHandphone}`}
            />
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoading}
              disabled={isLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardEditAccount };
