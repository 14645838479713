import { FC, useEffect, useState } from "react";
import { Footer, Navbar, Sidebar } from ".";
import { IMainLayoutProps } from "./MainLayout.model";

const MainLayout: FC<IMainLayoutProps> = ({
  navbar = true,
  sidebar = true,
  footer = true,
  mainPadding = 4,
  children,
}) => {
  const [hide, setHide] = useState(window.innerWidth < 720 ? true : false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setHide(true);
    }
  }, []);

  return (
    <div className="m-0 font-sans antialiased font-normal text-base leading-default text-slate-500">
      {sidebar && (
        <Sidebar
          hide={hide}
          onHide={() => {
            setHide(false);
          }}
        />
      )}

      <main
        className={
          "ease-soft-in-out relative overflow-auto h-screen transition-all duration-200 bg-[#F6F7FB] " +
          (hide || !sidebar ? "" : "ml-[300px] w-full md:w-auto")
        }
      >
        {/* Navbar */}
        {navbar && <Navbar onHide={() => setHide(!hide)} />}
        {/* end Navbar */}

        {/* children */}
        <div
          className={[
            "min-h-screen",
            sidebar && "border-l border-gray-100",
            "p-" + mainPadding,
          ].join(" ")}
        >
          {children}
        </div>
        {/* end children */}

        {/* cards */}
        {footer && <Footer />}
        {/* end cards */}
      </main>
    </div>
  );
};

export { MainLayout };
