import { Icon } from "components";
import { PATH } from "config";
import { ITrainings } from "pages/users/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardTraining: FC<{
  training: ITrainings;
}> = ({ training }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${PATH.USER_TRAINING}/${training.id}`);
  };

  return (
    <>
      <div
        className="bg-white p-4 cursor-pointer relative rounded-lg"
        onClick={handleNavigate}
      >
        <img
          src={training.banner ?? "/assets/images/placeholder-image.png"}
          alt="foto"
          className="w-full h-48 object-cover rounded-lg"
        />
        <div className="mt-2 text-[#3D3D3D] font-medium ellipsis-2">
          {training.title}
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="TicketStar" type="bold" size="small" color="#CB043F" />
          </div>
          <span className="ml-2 text-sm">{training.penyelenggara}</span>
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="Location" type="bold" size="small" color="#F2994A" />
          </div>
          <span className="ml-2 text-sm">{training.alamat}</span>
        </div>
        <div className="flex iems-center mt-2">
          <div>
            <Icon name="Calendar" type="bold" size="small" color="#3D3D3D" />
          </div>
          <span className="ml-2 text-sm">{training.waktu}</span>
        </div>
      </div>
    </>
  );
};
export { CardTraining };
