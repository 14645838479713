import { FC } from "react";
import { IIconProps } from "./Icon.model";
import SVG from "react-inlinesvg";

const Icon: FC<IIconProps> = ({
  name,
  type,
  size,
  className,
  onClick,
  color,
  stroke,
}) => {
  const sizeObject = {
    "x-small": "w-4 h-4",
    small: "w-6 h-6",
    medium: "w-8 h-8",
    large: "w-10 h-10",
    xlarge: "w-12 h-12",
    xxlarge: "w-14 h-14",
    xxxlarge: "w-16 h-16",
  };

  return (
    <SVG
      src={`/Iconly/${type ?? "light"}/${name}.svg`}
      className={
        `${sizeObject[size ?? "x-small"]} ${sizeObject[size ?? "x-small"]} ` +
        className
      }
      preProcessor={(code) => {
        if (color !== undefined) {
          code = code.replace(/fill=".*?"/g, `fill="${color}"`);
        }

        if (stroke !== undefined) {
          code = code.replace(/stroke=".*?"/g, `stroke="${stroke}"`);
        }

        return code;
      }}
      onClick={onClick}
    />
  );
};

export { Icon };
