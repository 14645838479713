import { FC, useCallback, useEffect, useState } from "react";
import { HiChevronLeft, HiChevronRight, HiDownload } from "react-icons/hi";

export interface IPagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  onPrev: () => void;
  onNext: () => void;
  onExport?: () => void;
  onChange?: (page: any) => void;
  isDownload?: boolean;
  sticky?: boolean;
}

export const Pagination: FC<IPagination> = function ({
  currentPage,
  totalPages,
  totalCount,
  onPrev,
  onNext,
  onExport,
  onChange,
  isDownload,
  sticky = true,
}) {
  const [currentPageBtn, setCurrentPageBtn] = useState<number>(1);

  useEffect(() => {
    setCurrentPageBtn(currentPage);
  }, [currentPage]);

  const renderPageButtons = useCallback(() => {
    const buttons = [];

    const maxVisibleButtons = 7; // Maximum number of visible buttons
    const sideButtons = Math.floor((maxVisibleButtons - 1) / 2); // Number of buttons on each side of the current page button
    const totalButtons = Math.min(totalPages, maxVisibleButtons); // Total number of buttons to render

    let startButton = currentPageBtn - sideButtons;
    let endButton = currentPageBtn + sideButtons;

    if (startButton <= 0) {
      startButton = 1;
      endButton = totalButtons;
    } else if (endButton > totalPages) {
      startButton = totalPages - totalButtons + 1;
      endButton = totalPages;
    }

    for (let i = startButton; i <= endButton; i++) {
      buttons.push(
        <button
          key={i}
          className={`px-2 py-1 rounded ${
            currentPageBtn === i ? "bg-primary-700 text-white" : "text-gray-500"
          } hover:bg-primary-700 hover:text-white focus:outline-none`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }

    if (startButton > 1) {
      buttons.unshift(
        <button
          key="ellipsis-start"
          className="px-2 py-1 rounded text-gray-500 hover:bg-primary-700 hover:text-white focus:outline-none"
          disabled
        >
          ...
        </button>
      );
    }

    if (endButton < totalPages) {
      buttons.push(
        <button
          key="ellipsis-end"
          className="px-2 py-1 rounded text-gray-500 hover:bg-primary-700 hover:text-white focus:outline-none"
          disabled
        >
          ...
        </button>
      );
    }

    return buttons;
  }, [currentPageBtn, totalPages]);

  const handlePageClick = (pageNumber: number) => {
    setCurrentPageBtn(pageNumber);
    onChange && onChange(pageNumber);
  };

  return (
    <div
      className={[
        sticky && "sticky right-0 bottom-0",
        "w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between",
      ].join(" ")}
    >
      <div className="mb-4 flex items-center sm:mb-0">
        <div
          className="inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={onPrev}
        >
          <span className="sr-only">Previous page</span>
          <HiChevronLeft className="text-2xl" />
        </div>
        {renderPageButtons()}
        <div
          className="mr-2 inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={onNext}
        >
          <span className="sr-only">Next page</span>
          <HiChevronRight className="text-2xl" />
        </div>
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Showing&nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {currentPageBtn}-{totalPages}
          </span>
          &nbsp;of&nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {totalCount}
          </span>
        </span>
      </div>
      <div className="flex items-center space-x-3">
        {isDownload && (
          <div
            className="cursor-pointer inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 py-2 px-3 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            onClick={onExport}
          >
            <HiDownload className="mr-1 text-base" />
            Export
          </div>
        )}
        {/* <div
          className="cursor-pointer inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 py-2 px-3 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={onPrev}
        >
          <HiChevronLeft className="mr-1 text-base" />
          Previous
        </div>
        <div
          className="cursor-pointer inline-flex flex-1 items-center justify-center rounded-lg bg-primary-700 py-2 px-3 text-center text-sm font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          onClick={onNext}
        >
          Next
          <HiChevronRight className="ml-1 text-base" />
        </div> */}
      </div>
    </div>
  );
};
export default Pagination;
