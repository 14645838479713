import { takeLatest } from "redux-saga/effects";
import sagaRequest from "setup/saga/sagaRequest";
import {
  login,
  ActionTypes,
  ILoginAction,
  register,
  forgotPassword,
  resetPassword,
} from ".";
import { IActionWithPayload } from "setup/redux/redux.model";
import { IInputPayloadCallback } from "pages/users/redux";

const prefixEndpoint = "/user/auth";

function* loginSaga(action: IActionWithPayload<ILoginAction>) {
  yield sagaRequest({
    isPublic: true,
    requestPayload: {
      url: `${prefixEndpoint}/login`,
      data: action.payload.data ?? {},
      method: "POST",
    },
    actionType: ActionTypes.LOGIN,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* registerSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    isPublic: true,
    requestPayload: {
      url: `${prefixEndpoint}/register`,
      data: action.payload.params ?? {},
      method: "POST",
    },
    actionType: ActionTypes.REGISTER,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* forgotPasswordSaga(
  action: IActionWithPayload<IInputPayloadCallback>
) {
  yield sagaRequest({
    isPublic: true,
    requestPayload: {
      url: `${prefixEndpoint}/forgot-password`,
      data: action.payload.params ?? {},
      method: "POST",
    },
    actionType: ActionTypes.FORGOT_PASSWORD,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
function* resetPasswordSaga(action: IActionWithPayload<IInputPayloadCallback>) {
  yield sagaRequest({
    isPublic: true,
    requestPayload: {
      url: `${prefixEndpoint}/claim-new-password`,
      data: action.payload.params ?? {},
      method: "POST",
    },
    actionType: ActionTypes.NEW_PASSWORD,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
export default function* authSaga() {
  yield takeLatest(login.type, loginSaga);
  yield takeLatest(register.type, registerSaga);
  yield takeLatest(forgotPassword.type, forgotPasswordSaga);
  yield takeLatest(resetPassword.type, resetPasswordSaga);
}
