import moment from "moment";
import { ITrainingDetail } from "pages/admin/redux";
import { FC } from "react";

const DashboardTrainingData: FC<{
  training: ITrainingDetail;
}> = ({ training }) => {
  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Foto Pelatihan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Foto akan di tampilkan sebagai thumbnail untuk detail pelatihan
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <img
            className="w-full max-w-[300px] rounded"
            src={training.image || "/assets/images/placeholder-image.png"}
            alt="image thumbnail"
          />
        </div>
      </div>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Detail Pelatihan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan detail pelatihan seperti waktu tempat penyelenggara dan lain
            lain.
          </div>
        </div>
        <div className="w-full max-w-[300px] relative">
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Nama Pelatihan</div>
              <div className="text-md">{training.title ?? "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Penyelenggara</div>
              <div className="text-md">{training.penyelenggara ?? "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Catatan Penyelenggara</div>
              <div className="text-md">
                {training.catatanPenyelenggara ?? "-"}
              </div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Deadline</div>
              <div className="text-md">
                {moment(training.deadline ?? "0000-00-00").format(
                  "DD MMMM YYYY HH:mm"
                )}
              </div>
            </div>
            <i className="fa fa-calendar text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Tempat</div>
              <div className="text-md">{training.alamat ?? "-"}</div>
            </div>
            <i className="fa fa-map-marker text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Waktu</div>
              <div className="text-md">
                {moment(training.waktu ?? "0000-00-00").format(
                  "DD MMMM YYYY HH:mm"
                )}
              </div>
            </div>
            <i className="fa fa-calendar text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kuota</div>
              <div className="text-md">{training.kuota ?? 0}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div className="text-md">
              {training.status === "O" ? "Aktif" : "Non Aktif"}
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export { DashboardTrainingData };
