const prefixAction = `USER`;

export const ActionTypes = {
  // category
  GET_CATEGORY: `${prefixAction}_GET_CATEGORY`,

  // user
  UPDATE_PROFILE: `${prefixAction}_UPDATE_PROFILE`,
  CHANGE_PASSWORD: `${prefixAction}_CHANGE_PASSWORD`,

  // mitra
  GET_MITRA: `${prefixAction}_GET_MITRA`,
  GET_MITRA_DETAIL: `${prefixAction}_GET_MITRA_DETAIL`,
  ADD_MITRA: `${prefixAction}_ADD_MITRA`,
  EDIT_MITRA: `${prefixAction}_EDIT_MITRA`,
  DELETE_MITRA: `${prefixAction}_DELETE_MITRA`,

  GET_PRODUCT: `${prefixAction}_GET_PRODUCT`,
  GET_PRODUCT_DETAIL: `${prefixAction}_GET_PRODUCT_DETAIL`,
  ADD_PRODUCT: `${prefixAction}_ADD_PRODUCT`,
  EDIT_PRODUCT: `${prefixAction}_EDIT_PRODUCT`,
  DELETE_PRODUCT: `${prefixAction}_DELETE_PRODUCT`,

  // training
  GET_TRAINING: `${prefixAction}_GET_TRAINING`,
  GET_TRAINING_DETAIL: `${prefixAction}_GET_TRAINING_DETAIL`,
  GET_TRAINING_DETAIL_HISTORY: `${prefixAction}_GET_TRAINING_DETAIL_HISTORY`,
  GET_TRAINING_HISTORY: `${prefixAction}_GET_TRAINING_HISTORY`,
  APPLY_TRAINING: `${prefixAction}_APPLY_TRAINING`,

  // funding
  GET_FUNDING: `${prefixAction}_GET_FUNDING`,
  GET_FUNDING_DETAIL: `${prefixAction}_GET_FUNDING_DETAIL`,
  GET_FUNDING_DETAIL_HISTORY: `${prefixAction}_GET_FUNDING_DETAIL_HISTORY`,
  GET_FUNDING_HISTORY: `${prefixAction}_GET_FUNDING_HISTORY`,
  APPLY_FUNDING: `${prefixAction}_APPLY_FUNDING`,

  GET_FAVORITE: `${prefixAction}_GET_FAVORITE`,
  ADD_FAVORITE: `${prefixAction}_ADD_FAVORITE`,
  REMOVE_FAVORITE: `${prefixAction}_REMOVE_FAVORITE`,

  GET_PROFILE: `${prefixAction}_GET_PROFILE`,

  ADD_ASPIRATION: `${prefixAction}_ADD_ASPIRATION`,
  GET_NOTIFICATION: `${prefixAction}_GET_NOTIFICATION`,
  READ_NOTIFICATION: `${prefixAction}_READ_NOTIFICATION`,

  //v1
  GET_MITRA_V1: `${prefixAction}_GET_MITRA_V1`,
  GET_MITRA_DETAIL_V1: `${prefixAction}_GET_MITRA_DETAIL_V1`,

  GET_PRODUCT_V1: `${prefixAction}_GET_PRODUCT_V1`,
  GET_PRODUCT_DETAIL_V1: `${prefixAction}_GET_PRODUCT_DETAIL_V1`,
};

export interface IInputPayload {
  params: {
    [key: string]: any;
  };
}
export interface IInputPayloadCallback {
  params: {
    [key: string]: any;
  };
  onSuccess?: (e: any) => void;
  onFailure?: (e: any) => void;
}

export interface ICategory {
  name: string;
  _id: string;
}

export interface IMitraList {
  _id: string;
  namaMitra: string;
  foto: string;
  status: string;
  direction: string;
  nib: any;
}

export interface IMitraListResponse {
  payload: {
    mitra: IMitraList[];
  };
}

export interface ICategoryResponse {
  payload: {
    classifications: ICategory[];
  };
}

export interface IProduct {
  _id: string;
  namaProduct: string;
  harga: number;
  nominalSatuan: number;
  beratSatuan: string;
  deskripsi: string;
  mitra: Mitra;
  fotoProduk: string[];
  isFavorite: boolean;
}

export interface Mitra {
  namaMitra: string;
  id: string;
  foto: string;
  kota: string;
  kecamatan: string;
  kelurahan: string;
  location: Location;
  phoneNumber: string;
  userId: string;
  isVerified: boolean;
}

export interface Location {
  type: string;
  coordinates: number[];
}

export interface IMitra {
  _id: string;
  userId: string;
  namaMitra: string;
  namaBadanUsaha: string;
  links: any;
  waktuOperasional: any;
  foto: string;
  alamat: string;
  kota: string;
  kecamatan: string;
  kelurahan: string;
  npwp: any;
  nib: any;
  pengizinUsaha: string;
  berkasIzinUsaha: string;
  deskripsi: string;
  omset: number;
  klasifikasiEkraf: string[];
  status: string;
  location: Location;
  rejectReason: any;
  addressName: AddressName;
  createdAt: string;
  kotaId: number;
  kecamatanId: number;
  kelurahanId: number;
}

export interface Links {
  bukalapak: string;
}

export interface Location {
  type: string;
  coordinates: number[];
}

export interface AddressName {
  kota: string;
  kecamatan: string;
  kelurahan: string;
  _id: string;
}

export interface IMitraResponse {
  payload: {
    mitra: IMitra;
  };
}

export interface IProductResponse {
  payload: IProduct;
}

export interface IFavorite {
  _id: string;
  fotoProduk: string;
  namaProduk: string;
  productId: string;
  mitra: string;
  harga: number;
}

export interface IFavoriteResponse {
  payload: IFavorite[];
}

export interface IProductsResponse {
  payload: {
    products: IProducts[];
  };
}

export interface ITrainings {
  title: string;
  penyelenggara: string;
  waktu: string;
  alamat: string;
  id: string;
  banner: any;
}

export interface ItrainingsResponse {
  payload: {
    courses: ITrainings[];
  };
}

export interface IProfile {
  _id: string;
  nama: string;
  nik: string;
  tempatLahir: string;
  tanggalLahir: string;
  foto: any;
  email: string;
  noHandphone: string;
  statusVerify: string;
  gender: string;
}

export interface IprofileResponse {
  message: string;
  payload: IProfile;
}

export interface IFundingResponse {
  payload: {
    fundings: IFundings[];
  };
}

export interface IFundings {
  title: string;
  penyelenggara: string;
  deadline: string;
  kriteria: string;
  persyaratan: string[];
  status: string;
  id: string;
  banner?: string;
}

export interface IProductListV1Response {
  message: string;
  payload: {
    products: IProductListV1[];
  };
}

export interface IProductListV1 {
  harga: number;
  id: string;
  namaProduk: string;
  statusVerify: string;
  mitraId: string;
  userId: string;
  namaMitra: string;
  fotoProduk: any;
  distance: string;
  favorite: number;
}

export interface ITrainingDetail {
  program: Program;
  available: Available[];
}

export interface Program {
  title: string;
  penyelenggara: string;
  waktu: string;
  alamat: string;
  kuota: number;
  id: string;
  image: string;
}

export interface Available {
  id: string;
  namaMitra: string;
  namaBadanUsaha: string;
  status: string;
  nib: string;
}

export interface ITrainingDetailResponse {
  payload: ITrainingDetail;
}

export interface ItrainingHistory {
  id: string;
  courseId: string;
  mitraId: string;
  message: any;
  statusBooking: string;
  statusProgram: string;
  banner: string;
  title: string;
  alamat: string;
  penyelenggara: string;
  waktu: string;
  status: string;
}

export interface ItrainingHistoryResponse {
  payload: {
    histories: ItrainingHistory[];
  };
}

export interface ItrainingDetailHistoryResponse {
  payload: {
    history: ItrainingDetailHistory;
    userId: string;
  };
}

export interface ItrainingDetailHistory {
  id: string;
  message: any;
  image: string;
  title: string;
  penyelenggara: string;
  waktu: string;
  alamat: string;
  kuota: number;
  mitra: string;
  statusBooking: string;
  statusProgram: string;
  status: string;
}

export interface IFundingDetailResponse {
  payload: {
    program: IFunding;
    available: Available[];
  };
}

export interface IFunding {
  id: string;
  title: string;
  penyelenggara: string;
  deadline: string;
  kriteria: string;
  persyaratan: string[];
  status: string;
  banner: string;
}

export interface Available {
  id: string;
  namaMitra: string;
  namaBadanUsaha: string;
  status: string;
  nib: string;
}

export interface IFundingHistoryResponse {
  payload: {
    histories: IFundingHistory[];
  };
}

export interface IFundingHistory {
  id: string;
  statusBooking: string;
  statusProgram: string;
  title: string;
  deadline: string;
  banner: string;
  status: string;
}

export interface IFundingDetailHistoryResponse {
  payload: {
    history: IFundingDetailHistory;
    userId: string;
  };
}

export interface IFundingDetailHistory {
  id: string;
  fundingId: string;
  title: string;
  sektorUsaha: SektorUsaha[];
  karyawan: Karyawan;
  asset: number;
  omset: number;
  catatan: string;
  kriteria: string;
  banner: string;
  penyelenggara: string;
  deadline: string;
  persyaratan: string[];
  namaMitra: string;
  nib: string;
  statusBook: string;
  statusProgram: string;
  status: string;
}

export interface SektorUsaha {
  id: string;
  name: string;
}

export interface Karyawan {
  pria: number;
  wanita: number;
}

export interface IProductDetailResponse {
  payload: {
    product: IProductDetail;
  };
}

export interface IProductDetail {
  mitraId: string;
  harga: number;
  nominalSatuan: number;
  beratSatuan: string;
  deskripsi: string;
  category: string[];
  status: string;
  id: string;
  namaProduk: string;
  fotoProduk: FotoProduk[];
}

export interface FotoProduk {
  _id: string;
  default: string;
}

export interface IProductDetailV1Response {
  payload: {
    product: IProductDetailV1;
    user: any;
  };
}

export interface IProductDetailV1 {
  harga: number;
  nominalSatuan: number;
  beratSatuan: string;
  deskripsi: string;
  mitra: IproductDetailMitraV1;
  fotoProduk: IFotoProdukV1[];
  id: string;
  namaProduk: string;
  isFavorite: boolean;
}

export interface IFotoProdukV1 {
  id: string;
  url: any;
}

export interface IproductDetailMitraV1 {
  addressName: AddressName;
  id: string;
  namaMitra: string;
  foto: any;
  kota: number;
  kecamatan: number;
  kelurahan: number;
  location: Location;
  phoneNumber: string;
  userId: string;
  isVerified: boolean;
}

export interface INotificationResponse {
  payload: INotification[];
}

export interface INotification {
  id: string;
  title: string;
  body: string;
  createdAt: string;
  isReaded: boolean;
}

export interface IProducts {
  id: string;
  mitraId: string;
  namaProduk: string;
  namaMitra: string;
  status: string;
  harga: number;
  fotoProduk: string;
}

export interface IUserState {
  categoryList: ICategory[];
  mitraList: IMitraList[];
  mitra: IMitra;
  favorite: IFavorite[];
  productDetail: IProductDetail;
  productDetailV1: IProductDetailV1;
  profile: IProfile;
  trainings: ITrainings[];
  products: IProducts[];
  productListV1: IProductListV1[];
  trainingDetail: ITrainingDetail;
  trainingDetailHistory: ItrainingDetailHistory;
  trainingHistory: ItrainingHistory[];
  fundings: IFundings[];
  fundingHistory: IFundingHistory[];
  fundingDetail: {
    program: IFunding;
    available: Available[];
  };
  fundingDetailHistory: IFundingDetailHistory;
  notification: INotification[];
}
