import { Button, Dialog, TextField } from "components";
import { useFormik } from "formik";
import {
  ActionTypes,
  IUser,
  sendNotifBroadcast,
  sendNotifFunding,
  sendNotifUser,
} from "pages/admin/redux";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DialogNotificationPersonal: FC<{
  show: boolean;
  user?: IUser;
  onClose: () => void;
}> = ({ show, user, onClose }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.SEND_NOTIFICATION_USER)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Judul wajib diisi"),
      body: Yup.string().required("Pesan wajib diisi"),
    }),
    onSubmit(values) {
      dispatch(
        sendNotifUser({
          params: {
            target: [user?.id],
            title: formHandler.values.title,
            body: formHandler.values.body,
          },
          onSuccess(e) {
            toast.success("Berhasil mengirim pesan");
          },
          onFailure(e) {
            toast.error("Gagal mengirim pesan");
          },
        })
      );
    },
  });

  return (
    <Dialog show={show} onClose={onClose} title={"Kirim ke " + user?.nama}>
      <>
        <TextField
          placeholder="Judul"
          padingBottom={4}
          propsInput={{ ...formHandler.getFieldProps("title") }}
        />
        <TextField
          placeholder="Pesan"
          multiLine={3}
          padingBottom={4}
          propsInput={{ ...formHandler.getFieldProps("body") }}
        />
        <Button
          color="failure"
          full
          onClick={() => formHandler.submitForm()}
          loading={isLoading}
          disabled={isLoading}
        >
          Kirim
        </Button>
      </>
    </Dialog>
  );
};
export { DialogNotificationPersonal };
