const NotFound = () => {
  return (
    <main className="overflow-x-hidden">
      <section className="mx-auto w-full p-8">
        <img
          src="/assets/img/banner-preview.png"
          alt="banner"
          className="w-full md:rounded-lg"
        />
      </section>
      <section className="md:px-8 md:bg-[#CB043F] md:transform md:-translate-y-12 z-10">
        <div className="flex flex-wrap md:flex-row justify-center items-center">
          <div className="w-full md:w-3/5 flex justify-end">
            <div className="md:transform md:-translate-y-12 bg-gray-100 md:bg-white z-10 max-w-lg p-6 md:rounded-lg md:shadow-lg text-[#3D3D3D]">
              Ada ribuan pelaku usaha mikro kecil dan menengah yang sudah
              bergabung di direktori <b>TABE</b> terbesar se-Indonesia ini.
              Dapatkan pelanggan, penawaran kerjasama, pelatihan, seminar,
              bantuan modal, dan lain sebagainya dari mitra-mitra kami. Gabung
              sekarang!
            </div>
          </div>
          <div className="w-full md:w-2/5 p-4 flex flex-col items-center bg-[#CB043F] md:bg-transparent">
            <h1 className="text-white text-xl md:text-3xl font-bold mb-4">
              DOWNLOAD SEKARANG
            </h1>
            <div className="flex">
              <a
                href="https://play.google.com/store/apps/details?id=id.go.kalteng.tabe.android"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/playstore.png"
                  alt="playstore"
                  className="w-36 mr-4"
                />
              </a>
              <img
                src="/assets/img/appstore.png"
                alt="playstore"
                className="w-36 mr-4"
              />
            </div>
          </div>
        </div>
      </section>
      <footer className="bg-[#F6F7FB] text-center p-4 text-sm text-[#3D3D3D] md:-mt-12">
        © 2022 - TABE Kalimantan Tengah. All rights reserved.
      </footer>
    </main>
  );
};
export { NotFound };
