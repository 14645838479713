/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  DashboardMaps,
  DashboardMitra,
  DashboardMitraAdd,
  DashboardProductInput,
  DashboardSettingProfile,
  DashboardUser,
  DashboardUserInput,
} from "./components";
import { PATH } from "config";
import DashboardMitraDetail from "./components/dashboard-mitra/DashboardMitraDetail";

const prefix = "/admin-regional";
const abs = (path: string) => {
  return path.substring(prefix.length);
};

const RouteAdminRegional = function () {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={PATH.DASHBOARD_REGIONAL_ANALYTICS} />}
      />

      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_ANALYTICS)}
        element={<Dashboard />}
      />

      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_MAPS)}
        element={<DashboardMaps />}
      />

      {/* user */}
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_USER)}
        element={<DashboardUser isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_USER_VERIFY)}
        element={<DashboardUser />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_USER_EDIT)}
        element={<DashboardUserInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_USER_ADD)}
        element={<DashboardUserInput isEdit={false} />}
      />

      {/* mitra */}
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PARTNER)}
        element={<DashboardMitra isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PARTNER_ADD)}
        element={<DashboardMitraAdd />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PARTNER_DETAIL)}
        element={<DashboardMitraDetail />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PARTNER_VERIFY)}
        element={<DashboardMitra />}
      />

      {/* product */}
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PRODUCT_EDIT)}
        element={<DashboardProductInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_PRODUCT)}
        element={<DashboardProductInput isEdit={false} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_REGIONAL_SETTINGS_PROFILE)}
        element={<DashboardSettingProfile />}
      />

      <Route path="*" element={<>not found</>} />
    </Routes>
  );
};

export { RouteAdminRegional };
