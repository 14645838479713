import { Button, Dialog, TextField } from "components";
import { useFormik } from "formik";
import {
  ActionTypes,
  sendNotifBroadcast,
  sendNotifFunding,
} from "pages/admin/redux";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DialogNotification: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.SEND_NOTIFICATION_BROADCAST)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      body: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Judul wajib diisi"),
      body: Yup.string().required("Pesan wajib diisi"),
    }),
    onSubmit(values) {
      dispatch(
        sendNotifBroadcast({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mengirim broadcast");
            onClose();
          },
          onFailure(e) {
            toast.error("Gagal mengirim broadcast");
          },
        })
      );
    },
  });

  return (
    <Dialog show={show} onClose={onClose} title="Kirim Notifikasi">
      <>
        <div className="text-sm text-yellow-600 text-center bg-yellow-100 p-4 rounded mb-4">
          Notifikasi ini akan terkirim ke semua pengguna secara broadcast
        </div>
        <TextField
          placeholder="Judul"
          padingBottom={4}
          propsInput={{ ...formHandler.getFieldProps("title") }}
        />
        <TextField
          placeholder="Pesan"
          multiLine={3}
          padingBottom={4}
          propsInput={{ ...formHandler.getFieldProps("body") }}
        />
        <Button
          color="failure"
          full
          onClick={() => formHandler.submitForm()}
          loading={isLoading}
          disabled={isLoading}
        >
          Kirim
        </Button>
      </>
    </Dialog>
  );
};
export { DialogNotification };
