import { createAction } from "@reduxjs/toolkit";
import { actionRequest, actionSuccess } from "setup/redux/loading.toolkit";
import {
  ActionTypes,
  ICategoryResponse,
  IFavoriteResponse,
  IFundingDetailHistoryResponse,
  IFundingDetailResponse,
  IFundingHistoryResponse,
  IFundingResponse,
  IInputPayload,
  IInputPayloadCallback,
  IMitraListResponse,
  IMitraResponse,
  INotificationResponse,
  IProductDetailResponse,
  IProductDetailV1Response,
  IProductListV1Response,
  IProductResponse,
  IProductsResponse,
  ITrainingDetailResponse,
  IprofileResponse,
  ItrainingDetailHistoryResponse,
  ItrainingHistoryResponse,
  ItrainingsResponse,
} from ".";

// mitra
export const getMitra = createAction(actionRequest(ActionTypes.GET_MITRA));
export const setMitra = createAction<IMitraListResponse>(
  actionSuccess(ActionTypes.GET_MITRA)
);
export const getMitraDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_MITRA_DETAIL)
);
export const setMitraDetail = createAction<IMitraResponse>(
  actionSuccess(ActionTypes.GET_MITRA_DETAIL)
);
export const addMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_MITRA)
);
export const editMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_MITRA)
);
export const deleteMitra = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.DELETE_MITRA)
);
// prodcut
export const getProduct = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT)
);
export const setProduct = createAction<IProductsResponse>(
  actionSuccess(ActionTypes.GET_PRODUCT)
);
export const getProductDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_DETAIL)
);
export const setProductDetail = createAction<IProductDetailResponse>(
  actionSuccess(ActionTypes.GET_PRODUCT_DETAIL)
);
export const addProduct = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_PRODUCT)
);
export const editProduct = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.EDIT_PRODUCT)
);

// training
export const getTraining = createAction(
  actionRequest(ActionTypes.GET_TRAINING)
);
export const setTraining = createAction<ItrainingsResponse>(
  actionSuccess(ActionTypes.GET_TRAINING)
);
export const getTrainingHistory = createAction(
  actionRequest(ActionTypes.GET_TRAINING_HISTORY)
);
export const setTrainingHistory = createAction<ItrainingHistoryResponse>(
  actionSuccess(ActionTypes.GET_TRAINING_HISTORY)
);
export const getTrainingDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_TRAINING_DETAIL)
);
export const setTrainingDetail = createAction<ITrainingDetailResponse>(
  actionSuccess(ActionTypes.GET_TRAINING_DETAIL)
);
export const getTrainingDetailHistory = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_TRAINING_DETAIL_HISTORY)
);
export const setTrainingDetailHistory =
  createAction<ItrainingDetailHistoryResponse>(
    actionSuccess(ActionTypes.GET_TRAINING_DETAIL_HISTORY)
  );
export const applyTraining = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPLY_TRAINING)
);

// funding
export const getFunding = createAction(actionRequest(ActionTypes.GET_FUNDING));
export const setFunding = createAction<IFundingResponse>(
  actionSuccess(ActionTypes.GET_FUNDING)
);
export const getFundingHistory = createAction(
  actionRequest(ActionTypes.GET_FUNDING_HISTORY)
);
export const setFundingHistory = createAction<IFundingHistoryResponse>(
  actionSuccess(ActionTypes.GET_FUNDING_HISTORY)
);
export const getFundingDetail = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_FUNDING_DETAIL)
);
export const setFundingDetail = createAction<IFundingDetailResponse>(
  actionSuccess(ActionTypes.GET_FUNDING_DETAIL)
);
export const getFundingDetailHistory = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_FUNDING_DETAIL_HISTORY)
);
export const setFundingDetailHistory =
  createAction<IFundingDetailHistoryResponse>(
    actionSuccess(ActionTypes.GET_FUNDING_DETAIL_HISTORY)
  );
export const applyFunding = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.APPLY_FUNDING)
);
export const getCategory = createAction(
  actionRequest(ActionTypes.GET_CATEGORY)
);
export const setCategory = createAction<ICategoryResponse>(
  actionSuccess(ActionTypes.GET_CATEGORY)
);

export const addFavorite = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_FAVORITE)
);
export const removeFavorite = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.REMOVE_FAVORITE)
);

export const getFavorite = createAction(
  actionRequest(ActionTypes.GET_FAVORITE)
);
export const setFavorite = createAction<IFavoriteResponse>(
  actionSuccess(ActionTypes.GET_FAVORITE)
);

export const getProfile = createAction(actionRequest(ActionTypes.GET_PROFILE));
export const setProfile = createAction<IprofileResponse>(
  actionSuccess(ActionTypes.GET_PROFILE)
);

export const updateProfile = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.UPDATE_PROFILE)
);
export const changePassword = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.CHANGE_PASSWORD)
);

export const getNotification = createAction(
  actionRequest(ActionTypes.GET_NOTIFICATION)
);
export const setNotification = createAction<INotificationResponse>(
  actionSuccess(ActionTypes.GET_NOTIFICATION)
);
export const readNotification = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.READ_NOTIFICATION)
);
export const addAspiration = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.ADD_ASPIRATION)
);

// general v1
export const getProductV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_V1)
);
export const setProductV1 = createAction<IProductListV1Response>(
  actionSuccess(ActionTypes.GET_PRODUCT_V1)
);
export const getProductDetailV1 = createAction<IInputPayload>(
  actionRequest(ActionTypes.GET_PRODUCT_DETAIL_V1)
);
export const setProductDetailV1 = createAction<IProductDetailV1Response>(
  actionSuccess(ActionTypes.GET_PRODUCT_DETAIL_V1)
);
