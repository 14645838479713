import { Button, MainLayout } from "components";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen flex justify-center items-center p-6">
          <div className="flex flex-col items-center">
            <img
              src="/assets/logo/logo.png"
              alt="logo tabe"
              className="w-32 cursor-pointer"
            />
            <div className="text-lg font-medium text-gray-600 mb-4">
              Halaman Yang anda cari tidak ada
            </div>
            <Button
              color="light"
              onClick={() => {
                navigate(-1);
              }}
            >
              Kembali
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { NotFound };
