import { createAction } from "@reduxjs/toolkit";
import { actionRequest, actionSuccess } from "setup/redux/loading.toolkit";
import { ActionTypes, ILoginAction, ILoginInfoResponse } from "./";
import { IInputPayloadCallback } from "pages/users/redux";

export const login = createAction<ILoginAction>(
  actionRequest(ActionTypes.LOGIN)
);
export const register = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.REGISTER)
);
export const forgotPassword = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.FORGOT_PASSWORD)
);
export const resetPassword = createAction<IInputPayloadCallback>(
  actionRequest(ActionTypes.NEW_PASSWORD)
);
export const setLogin = createAction<ILoginInfoResponse>(
  actionSuccess(ActionTypes.LOGIN)
);
export const getInfo = createAction(actionSuccess(ActionTypes.GET_INFO));
