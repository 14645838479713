import moment from "moment";
import { IMitraDetail } from "pages/admin/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const DashboardMitraData: FC<{
  mitra: IMitraDetail;
}> = ({ mitra }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12">
          <div className="font-medium">Foto Pelatihan</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Foto akan di tampilkan sebagai thumbnail untuk detail pelatihan
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <img
            className="w-full max-w-[300px] rounded"
            src={mitra.foto ?? "/assets/images/placeholder-image.png"}
            alt="image thumbnail"
          />
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Data Wajib</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan data wajib untuk keperluan verifikasi.
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Pemilik Usaha</div>
              <div
                className="text-md text-blue-500 hover:underline cursor-pointer"
                onClick={() => navigate("/admin/pengguna/edit/" + mitra.userId)}
              >
                {mitra.user || "-"}
              </div>
            </div>
            <i className="fa fa-user text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Nama Usaha</div>
              <div className="text-md">{mitra.namaMitra || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Nama Badan Usaha</div>
              <div className="text-md">{mitra.namaBadanUsaha || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Deskripsi Usaha</div>
              <div className="text-md">{mitra.deskripsi || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Omset Tahunan</div>
              <div className="text-md">{mitra.omset || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kategori</div>
              <div className="text-md">
                {mitra.klasifikasiEkraf.map((e) => e.name).join(",") || "-"}
              </div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Berkas</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan berkas terkait izin usaha.
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">NIB</div>
              <div className="text-md">{mitra.nib || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">NPWP</div>
              <div className="text-md">{mitra.npwp || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Pejabat yang mengesahkan izin usaha</div>
              <div className="text-md">{mitra.pengizinUsaha || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Berkas izin usaha</div>
              {mitra.berkasIzinUsaha ? (
                <div
                  className="text-md text-blue-500 cursor-pointer hover:underline"
                  onClick={() => {
                    window.location.href = mitra.berkasIzinUsaha ?? "#";
                  }}
                >
                  Download Berkas <i className="fa fa-download"></i>
                </div>
              ) : (
                "-"
              )}
            </div>
            <i className="fa fa-link text-sm"></i>
          </div>
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Alamat</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan detail alamat usaha.
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Lokasi</div>
              <div
                className="text-md text-blue-500 cursor-pointer hover:underline"
                onClick={() => {
                  mitra.location &&
                    (window.location.href = `https://www.google.com/maps/@${mitra.location.lat},${mitra.location.long},15.18z`);
                }}
              >
                Lihat Peta <i className="fa fa-map-marker-alt"></i>
              </div>
            </div>
            <i className="fa fa-map-marker-alt text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Alamat</div>
              <div className="text-md">{mitra.alamat || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kabupaten/Kota</div>
              <div className="text-md">{mitra.kota || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kecamatan</div>
              <div className="text-md">{mitra.kecamatan || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>

          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div>
              <div className="text-xs">Kelurahan</div>
              <div className="text-md">{mitra.kelurahan || "-"}</div>
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Waktu Operasional</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Jika Waktu di reset menjadi kosong maka dianggap tutup
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <table className="min-w-full border-collapse border border-gray-200 rounded">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-sm font-semibold">
                  Hari
                </th>
                <th className="py-2 px-4 border-b text-sm font-semibold">
                  Waktu Buka
                </th>
                <th className="py-2 px-4 border-b text-sm font-semibold">
                  Waktu Tutup
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(mitra.waktuOperasional).map(
                (item: any, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 border-b text-sm capitalize">
                      {item[0]}
                    </td>
                    <td className="py-2 px-4 border-b text-sm">
                      {item[1].buka || "-"}
                    </td>
                    <td className="py-2 px-4 border-b text-sm">
                      {item[1].tutup || "-"}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="md:flex justify-between mb-8">
        <div className="flex flex-col items-start flex-1 pr-12 mb-4">
          <div className="font-medium">Link Marketplace</div>
          <div className="text-sm text-gray-600 max-w-[400px]">
            Masukan link marketplace.
          </div>
        </div>
        <div className="w-full max-w-[300px]">
          <ul className="ml-3">
            {Object.entries(mitra.links ?? {}).map((item: any, index) => (
              <li className="flex items-center mb-4" key={index}>
                <img
                  src={`/assets/marketplace/${item[0].toLowerCase()}.png`}
                  className="w-8"
                />
                <div
                  className="ml-4 text-gray-600 text-sm cursor-pointer hover:underline hover:text-blue-500"
                  onClick={() => (window.location.href = item[1])}
                >
                  {item[1]}
                </div>
              </li>
            ))}
          </ul>
          <div className="flex justify-between text-gray-600 items-center mb-4">
            <div className="text-md">
              Status Mitra : {mitra.status === "A" ? "Aktif" : "Non Aktif"}
            </div>
            <i className="fa fa-info-circle text-sm"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export { DashboardMitraData };
