import {
  Button,
  FileSelect,
  MainLayout,
  SelectField,
  TextField,
} from "components";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { RootState } from "setup";
import {
  ActionTypes,
  addProduct,
  editProduct,
  getCategory,
  getProductDetail,
} from "pages/users/redux";
import { toast } from "react-hot-toast";
import { PATH } from "config";
import { FileSelectMulti } from "components/FileSelectMulti/FileSelectMulti";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardProductInput: FC<{
  isEdit?: boolean;
}> = ({ isEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    businessId: string;
    productId: string;
  }>();

  const isLoadingAdd = useSelector(
    createLoadingSelector(ActionTypes.ADD_PRODUCT)
  );
  const isLoadingEdit = useSelector(
    createLoadingSelector(ActionTypes.EDIT_PRODUCT)
  );

  const { categoryList } = useSelector((state: RootState) => state.user);
  const productDetail = useSelector(
    (state: RootState) => state.user.productDetail
  );

  const [deleteIndex, setDeleteIndex] = useState<number>(0);

  const initialValues = useMemo(() => {
    return {
      productId: isEdit ? productDetail.id : "",
      mitraId: params.businessId,
      namaProduct: isEdit ? productDetail.namaProduk : "",
      harga: isEdit ? productDetail.harga : 0,
      nominalSatuan: isEdit ? productDetail.nominalSatuan : 0,
      beratSatuan: isEdit ? productDetail.beratSatuan : "",
      deskripsi: isEdit ? productDetail.deskripsi : "",
      category: isEdit ? productDetail.category[0] : "",
      foto: isEdit ? productDetail.fotoProduk.map((e) => e.default) : [],
      images: {
        submit: [],
      },
      status: isEdit ? productDetail.status : "A",
    };
  }, [isEdit, params, productDetail]);

  const validationSchema = Yup.object({
    mitraId: Yup.string().required("Mitra Id is required"),
    namaProduct: Yup.string().required("Nama Product is required"),
    harga: Yup.number()
      .min(1, "Harga is required")
      .required("Harga is required"),
    nominalSatuan: Yup.number()
      .min(1, "Nominal Satuan is required")
      .required("Nominal Satuan is required"),
    beratSatuan: Yup.string().required("Berat Satuan is required"),
    deskripsi: Yup.string().required("Deskripsi is required"),
    category: Yup.string().required("Category is required"),
    foto: Yup.array()
      .of(Yup.string().required("Foto is required"))
      .min(1, "At least one foto is required")
      .required("Foto is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit(values) {
      if (isEdit) {
        dispatch(
          editProduct({
            params: {
              ...values,
              mitraId: params.businessId,
              category: [values.category],
              images: {
                ...values.images,
                submit: values.foto
                  .filter((e: any) => e.indexOf("data:image") > -1)
                  .map((e) => e),
                // remove: values.images.remove,
              },
            },
            onSuccess(e) {
              toast.success("Berhasil mengedit produk");
              navigate(`${PATH.USER_USAHA}/edit/${params.businessId}`);
            },
            onFailure(e) {
              toast.error("Gagal mengedit produk");
            },
          })
        );
      } else {
        dispatch(
          addProduct({
            params: {
              ...values,
              category: [values.category],
              images: {
                ...values.images,
                submit: values.foto
                  .filter((e: any) => e.indexOf("data:image") > -1)
                  .map((e) => e),
                // remove: values.images.remove,
              },
            },
            onSuccess(e) {
              toast.success("Berhasil menambah produk");
              navigate(`${PATH.USER_USAHA}/edit/${params.businessId}`);
            },
            onFailure(e) {
              toast.error("Gagal menambah produk");
            },
          })
        );
      }
    },
  });

  const handleRemove = useCallback(
    (index: number) => {
      formHandler.setFieldValue(
        "foto",
        formHandler.values.foto.filter((_, i) => i !== index)
      );
    },
    [formHandler]
  );

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getProductDetail({
          params: {
            productId: params.productId,
          },
        })
      );
    }
  }, [params, isEdit]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <BottomNav />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative pb-20">
          <HeaderNav title="Tambah Produk" />
          <div className="p-6">
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Nama Produk"
              propsInput={{ ...formHandler.getFieldProps("namaProduct") }}
              onChange={(e) => {
                formHandler.setFieldTouched("namaProduct");
                formHandler.setFieldValue("namaProduct", e.target.value ?? "");
              }}
              error={
                !!formHandler.touched.namaProduct &&
                !!formHandler.errors.namaProduct
              }
              errorText={`${formHandler.errors.namaProduct}`}
            />
            <SelectField
              options={categoryList.map((e) => ({
                label: e.name,
                value: e._id,
              }))}
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Kategori"
              onChange={(e) => {
                formHandler.setFieldTouched("category");
                formHandler.setFieldValue("category", e.target.value ?? "");
              }}
              value={formHandler.values.category}
              error={
                !!formHandler.touched.category && !!formHandler.errors.category
              }
              errorText={`${formHandler.errors.category}`}
            />
            <TextField
              type="number"
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Harga"
              propsInput={{ ...formHandler.getFieldProps("harga") }}
              onChange={(e) => {
                formHandler.setFieldTouched("harga");
                formHandler.setFieldValue("harga", e.target.value ?? "");
              }}
              error={!!formHandler.touched.harga && !!formHandler.errors.harga}
              errorText={`${formHandler.errors.harga}`}
            />
            <div className="flex">
              <TextField
                type="number"
                className="rounded-lg p-4"
                variant="solid"
                placeholder="Nominal"
                propsInput={{ ...formHandler.getFieldProps("nominalSatuan") }}
                onChange={(e) => {
                  formHandler.setFieldTouched("nominalSatuan");
                  formHandler.setFieldValue(
                    "nominalSatuan",
                    e.target.value ?? ""
                  );
                }}
                error={
                  !!formHandler.touched.nominalSatuan &&
                  !!formHandler.errors.nominalSatuan
                }
                errorText={`${formHandler.errors.nominalSatuan}`}
              />
              <TextField
                className="rounded-lg p-4 ml-4"
                variant="solid"
                placeholder="Satuan"
                propsInput={{ ...formHandler.getFieldProps("beratSatuan") }}
                onChange={(e) => {
                  formHandler.setFieldTouched("beratSatuan");
                  formHandler.setFieldValue(
                    "beratSatuan",
                    e.target.value ?? ""
                  );
                }}
                error={
                  !!formHandler.touched.beratSatuan &&
                  !!formHandler.errors.beratSatuan
                }
                errorText={`${formHandler.errors.beratSatuan}`}
              />
            </div>
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Tulis Deskripsi Produk"
              propsInput={{ ...formHandler.getFieldProps("deskripsi") }}
              onChange={(e) => {
                formHandler.setFieldTouched("deskripsi");
                formHandler.setFieldValue("deskripsi", e.target.value ?? "");
              }}
              error={
                !!formHandler.touched.deskripsi &&
                !!formHandler.errors.deskripsi
              }
              errorText={`${formHandler.errors.deskripsi}`}
              multiLine={3}
            />
            <div className="text-gray-800 font-semibold mb-2">
              Upload Foto Produk
            </div>
            <div className="flex flex-wrap">
              <FileSelectMulti
                formHandler={formHandler}
                name="foto"
                onDelete={(index: number) => {
                  handleRemove(index);
                  setDeleteIndex(deleteIndex + 1);
                }}
                error={!!formHandler.touched.foto && !!formHandler.errors.foto}
                errorText={`${formHandler.errors.foto}`}
              />
            </div>
            {isEdit && (
              <>
                <div className="text-gray-800 font-semibold mb-2">
                  Status produk
                </div>
                <div className="bg-white p-4 rounded-lg mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <div className="text-sm text-gray-600">Aktif</div>
                    <input
                      type="radio"
                      name="status"
                      value="A"
                      checked={formHandler.values.status === "A"}
                      onChange={() => {
                        formHandler.setFieldValue("status", "A");
                        formHandler.setFieldTouched("status");
                      }}
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="text-sm text-gray-600">
                      Habis/Sembunyikan
                    </div>
                    <input
                      type="radio"
                      name="status"
                      value="N"
                      checked={formHandler.values.status === "N"}
                      onChange={() => {
                        formHandler.setFieldValue("status", "N");
                        formHandler.setFieldTouched("status");
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoadingAdd || isLoadingEdit}
              disabled={isLoadingAdd || isLoadingEdit}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export { DashboardProductInput };
