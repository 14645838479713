import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { RootState } from "setup";
import { useSelector } from "react-redux";

const SectionFooter = () => {
  const { banners } = useSelector((state: RootState) => state.landing.config);

  return (
    <section className="md:px-8 md:bg-[#CB043F] md:h-48 md:relative md:mt-52 md:mb-52 p-0 mt-6">
      <div className="md:absolute md:top-1/2 md:transform md:-translate-y-1/2 w-full">
        <div className="flex flex-wrap md:flex-row justify-center items-center">
          <div className="w-full md:w-3/5">
            <Swiper
              modules={[Navigation]}
              navigation
              className="w-full footer-slider"
              slidesPerView={1}
              centerInsufficientSlides={true}
              spaceBetween={0}
            >
              {banners.map((e) => (
                <SwiperSlide key={e._id}>
                  <img
                    src={e.foto || "/assets/images/placeholder-image.png"}
                    alt="banner footer"
                    className="w-full"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="w-full md:w-2/5 p-4 flex flex-col items-center bg-[#CB043F] md:bg-transparent">
            <h1 className="text-white text-xl md:text-3xl font-bold mb-4">
              DOWNLOAD SEKARANG
            </h1>
            <div className="flex">
              <a
                href="https://play.google.com/store/apps/details?id=id.go.kalteng.tabe.android"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/img/playstore.png"
                  alt="playstore"
                  className="w-36 mr-4"
                />
              </a>
              <img
                src="/assets/img/appstore.png"
                alt="playstore"
                className="w-36 mr-4"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { SectionFooter };
