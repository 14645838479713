import { MainLayout, TableComponent } from "components";
import { ActionTypes, getAspiration } from "pages/admin/redux";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import moment from "moment";

export const DashboardAspiration: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_ASPIRATION)
  );
  const columns: any = [
    {
      header: "Pengguna",
      field: "user",
    },
    {
      header: "Pesan Aspirasi",
      field: "message",
    },
    {
      header: "Tanggal",
      field: "createdAt",
      rowRender: (row: any) => {
        return moment(row.createdAt ?? "0000-00-00").format("DD MMMM YYYY");
      },
    },
  ];
  const aspiration = useSelector((state: RootState) => state.admin.aspiration);

  const limit = 20;
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getAspiration());
  }, []);

  return (
    <MainLayout>
      <TableComponent
        columns={columns}
        data={aspiration}
        isLoading={isLoading}
        pagination={{
          currentPage: page,
          totalPages: Math.ceil(aspiration.length / limit),
          totalCount: aspiration.length,
          onPrev: () => {
            if (page > 1) {
              setPage((prev) => prev - 1);
            }
          },
          onNext: () => {
            if (page < Math.ceil(aspiration.length / limit)) {
              setPage((prev) => prev + 1);
            }
          },
        }}
        // isDownload
        localPagination
      />
    </MainLayout>
  );
};

export default DashboardAspiration;
