import { MainLayout, TableComponent } from "components";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DashboardTrainingData } from "./DashboardTrainingData";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  ITrainingDetail,
  exportTraining,
  getTrainingDetail,
} from "pages/admin/redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";
import { toast } from "react-hot-toast";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

const DashboardTrainingDetail = () => {
  const dispatch = useDispatch();
  const params = useParams<{
    trainingId: string;
  }>();
  const limit = 20;
  const [page, setPage] = useState<number>(1);

  const [active, setActive] = useState<number>(0);

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_TRAINING_DETAIL)
  );

  const columns: any = [
    {
      header: "Nama Usaha",
      field: "mitra",
      rowRender: (item: any) => item.mitra,
    },
    {
      header: "Nama Pemilik",
      field: "user",
      rowRender: (item: any) => item.user,
    },
    {
      header: "Status",
      field: "status",
      rowRender: (row: any) => {
        return row.status === "S"
          ? "Menunggu"
          : row.status === "A"
          ? "Diterima"
          : "Ditolak";
      },
    },
  ];

  const training: ITrainingDetail = useSelector(
    (state: RootState) => state.admin.courseDetail
  );

  useEffect(() => {
    if (params.trainingId) {
      dispatch(
        getTrainingDetail({
          params: {
            courseId: params.trainingId,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [params]);

  const handleExport = useCallback(() => {
    if (params.trainingId) {
      dispatch(
        exportTraining({
          params: {
            trainingId: params.trainingId,
          },
          onSuccess(e: IExportExcel) {
            exportBase64ToExcel(e.payload.base64, e.payload.fileName);
            toast.success("Berhasil Export Excel");
          },
          onFailure(e) {
            toast.error("Gagal Export Excel");
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout>
      <>
        <div className="bg-white shadow rounded">
          <div className="text-lg p-8 font-semibold text-gray-800 border-b">
            Detail Pelatihan
          </div>
          <div className="flex px-8">
            <div
              onClick={() => setActive(0)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 0
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Detail
            </div>
            <div
              onClick={() => setActive(1)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 1
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Persetujuan
            </div>
          </div>
        </div>
        {active === 0 ? (
          <>
            <div className="bg-white shadow rounded p-8 mt-4">
              <div className="w-full max-w-3xl mx-auto">
                <div className="flex justify-between mb-8">
                  <div className="text-lg font-semibold text-gray-800">
                    Data Pelatihan
                  </div>
                </div>
                <DashboardTrainingData training={training} />
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <TableComponent
              columns={columns}
              data={training.book ?? []}
              isLoading={isLoading}
              limit={limit}
              pagination={{
                currentPage: page,
                totalPages: Math.ceil(training.book.length / limit),
                totalCount: training.book.length,
                onPrev: () => {
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                  }
                },
                onNext: () => {
                  if (page < Math.ceil(training.book.length / limit)) {
                    setPage((prev) => prev + 1);
                  }
                },
              }}
              isDownload
              onExport={handleExport}
              localPagination
            />
          </div>
        )}
      </>
    </MainLayout>
  );
};
export default DashboardTrainingDetail;
