import { Button, MainLayout, TableComponent } from "components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardTrainingInput } from "./DashboardTrainingInput";
import { DashboardTrainingData } from "./DashboardTrainingData";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  ICourse,
  ITrainingDetail,
  exportTraining,
  getTrainingDetail,
} from "pages/admin/redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { DialogRejectTraining } from "./DialogRejectTraining";
import { DialogApprovalTraining } from "./DialogApprovalTraining";
import { DialogNotification } from "./DialogNotification";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";
import { toast } from "react-hot-toast";
import { PATH } from "config";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

const DashboardTrainingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    trainingId: string;
  }>();
  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [approveDialog, setApproveDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });
  const [rejectDialog, setRejectDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });

  const [active, setActive] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showNotif, setShowNotif] = useState<{
    show: boolean;
    id: string;
  }>({
    show: false,
    id: "",
  });

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_TRAINING_DETAIL)
  );

  const columns: any = [
    {
      header: "Nama Usaha",
      field: "mitra",
      rowRender: (item: any) => item.mitra,
    },
    {
      header: "Nama Pemilik",
      field: "user",
      rowRender: (item: any) => item.user,
    },
    {
      header: "Status",
      field: "status",
      rowRender: (row: any) => {
        return row.status === "S"
          ? "Menunggu"
          : row.status === "A"
          ? "Diterima"
          : "Ditolak";
      },
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: any) => {
        return row.status === "S" ? (
          <div className="flex">
            <Button
              color="light"
              className="mr-2"
              onClick={() => {
                setRejectDialog({ show: true, data: row });
              }}
            >
              Tolak
              <i className="cursor-pointer px-2 fa fa-times"></i>
            </Button>
            <Button
              color="light"
              onClick={() => {
                setApproveDialog({ show: true, data: row });
              }}
            >
              Setujui
              <i className="cursor-pointer px-2 fa fa-check"></i>
            </Button>
          </div>
        ) : (
          <Button
            color="light"
            className="mr-2"
            onClick={() => {
              navigate(`${PATH.DASHBOARD_PARTNER}/${row.mitraId}`);
            }}
          >
            Lihat Detail
            <i className="cursor-pointer px-2 fa fa-eye"></i>
          </Button>
        );
      },
    },
  ];

  const training: ITrainingDetail = useSelector(
    (state: RootState) => state.admin.courseDetail
  );

  useEffect(() => {
    if (params.trainingId) {
      dispatch(
        getTrainingDetail({
          params: {
            courseId: params.trainingId,
          },
        })
      );
    }
  }, [params]);

  const handleExport = useCallback(() => {
    if (params.trainingId) {
      dispatch(
        exportTraining({
          params: {
            trainingId: params.trainingId,
          },
          onSuccess(e: IExportExcel) {
            exportBase64ToExcel(e.payload.base64, e.payload.fileName);
            toast.success("Berhasil Export Excel");
          },
          onFailure(e) {
            toast.error("Gagal Export Excel");
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout>
      <>
        <DialogNotification
          show={showNotif.show}
          id={showNotif.id}
          onClose={() => setShowNotif({ show: false, id: "" })}
        />
        <DialogApprovalTraining
          show={approveDialog.show}
          data={approveDialog.data}
          onClose={() => {
            setApproveDialog({ show: false });
          }}
        />
        <DialogRejectTraining
          show={rejectDialog.show}
          data={rejectDialog.data}
          onClose={() => {
            setRejectDialog({ show: false });
          }}
        />
        <div className="bg-white shadow rounded">
          <div className="flex p-8 justify-between items-center border-b">
            <div className="text-lg font-semibold text-gray-800">
              Detail Pelatihan
            </div>
            <Button
              color="failure"
              onClick={() => {
                if (params.trainingId !== "") {
                  setShowNotif({ show: true, id: params.trainingId ?? "" });
                }
              }}
            >
              Buat Notifikasi
            </Button>
          </div>
          <div className="flex px-8">
            <div
              onClick={() => setActive(0)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 0
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Detail
            </div>
            <div
              onClick={() => setActive(1)}
              className={[
                "cursor-pointer text-sm px-6 py-3 table",
                active === 1
                  ? "text-red-500 border-red-500 border-b-2 font-semibold"
                  : "text-gray-600",
              ].join(" ")}
            >
              Persetujuan
            </div>
          </div>
        </div>
        {active === 0 ? (
          <>
            <div className="bg-white shadow rounded p-8 mt-4">
              <div className="w-full max-w-3xl mx-auto">
                <div className="flex justify-between mb-8">
                  <div className="text-lg font-semibold text-gray-800">
                    Data Pelatihan
                  </div>
                  {!isEdit ? (
                    <div
                      className="cursor-pointer text-blue-500"
                      onClick={() => setIsEdit(true)}
                    >
                      <i className="fa fa-edit mr-2"></i>Edit
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer text-red-500"
                      onClick={() => setIsEdit(false)}
                    >
                      Cancel
                    </div>
                  )}
                </div>
                {isEdit ? (
                  <DashboardTrainingInput training={training} isEdit={true} />
                ) : (
                  <DashboardTrainingData training={training} />
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="mt-4">
            <TableComponent
              columns={columns}
              data={training.book ?? []}
              isLoading={isLoading}
              limit={limit}
              pagination={{
                currentPage: page,
                totalPages: Math.ceil(training.book.length / limit),
                totalCount: training.book.length,
                onPrev: () => {
                  if (page > 1) {
                    setPage((prev) => prev - 1);
                  }
                },
                onNext: () => {
                  if (page < Math.ceil(training.book.length / limit)) {
                    setPage((prev) => prev + 1);
                  }
                },
              }}
              isDownload
              onExport={handleExport}
              localPagination
            />
          </div>
        )}
      </>
    </MainLayout>
  );
};
export default DashboardTrainingDetail;
