import { PATH } from "config";
import { IMitraListV1 } from "pages/landing/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardUsahaItem: FC<{
  mitra: IMitraListV1;
}> = ({ mitra }) => {
  const navigate = useNavigate();
  return (
    <div
      className="p-3 rounded-lg bg-white rounded-lg cursor-pointer"
      onClick={() => navigate(`${PATH.USER_USAHA}/${mitra.id}`)}
    >
      <img
        src={mitra?.foto?.url || "/assets/images/placeholder-image.png"}
        alt="card thumbnail"
        className="w-full h-32 object-cover rounded"
      />
      <div className="text-[#3D3D3D] text-lg font-medium my-1.5 ellipsis font-semibold capitalize">
        {mitra.namaMitra}
      </div>
      <div className="flex flex-row items-center mb-2.5">
        <svg
          width="12"
          height="16"
          viewBox="0 0 12 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.99999 15.4315C4.91736 14.5081 3.91387 13.4957 2.99999 12.405C1.62857 10.767 7.53336e-07 8.32756 7.53336e-07 6.00299C-0.000594015 4.81582 0.351007 3.65515 1.01031 2.66788C1.66961 1.68061 2.60697 0.911113 3.70376 0.456777C4.80056 0.00244004 6.00748 -0.116314 7.1718 0.115544C8.33611 0.347402 9.40547 0.919449 10.2445 1.75929C10.8032 2.31545 11.246 2.97689 11.5473 3.70532C11.8486 4.43375 12.0025 5.21471 12 6.00299C12 8.32756 10.3714 10.767 8.99998 12.405C8.0861 13.4957 7.0826 14.5081 5.99999 15.4315ZM5.99999 3.43157C5.318 3.43157 4.66395 3.70249 4.18172 4.18472C3.69948 4.66696 3.42856 5.32101 3.42856 6.00299C3.42856 6.68498 3.69948 7.33903 4.18172 7.82126C4.66395 8.3035 5.318 8.57442 5.99999 8.57442C6.68197 8.57442 7.33602 8.3035 7.81826 7.82126C8.30049 7.33903 8.57141 6.68498 8.57141 6.00299C8.57141 5.32101 8.30049 4.66696 7.81826 4.18472C7.33602 3.70249 6.68197 3.43157 5.99999 3.43157Z"
            fill="black"
          />
        </svg>

        <div className="text-[#AFAEAE] text-sm ellipsis ml-2">
          {mitra.distance} KM
        </div>
      </div>
      <div className="bg-[#FCF0F3] rounded-lg p-2 text-center text-[#CB043F] mt-2 text-sm text-semibold">
        Lihat Detail
      </div>
    </div>
  );
};
export { CardUsahaItem };
