import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "setup";
import { PATH } from "config";

const SectionProduct = () => {
  const navigate = useNavigate();

  const products = useSelector(
    (state: RootState) => state.landing.productListV1
  );

  function handleClick() {
    navigate("/product");
  }

  return (
    <section className="px-8">
      <div className="flex justify-between mt-12 mb-6 items-center">
        <h1 className="text-lg md:text-2xl font-bold">Produk & Jasa Favorit</h1>
        <button
          onClick={() => handleClick()}
          className="flex items-center md:border-2 md:border-[#CB043F] text-[#CB043F] md:px-6 md:py-3 md:rounded-full text-xs md:text-sm font-medium"
        >
          Lihat Semua
          <svg
            className="ml-4 w-6 hidden md:block"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.6668 20C36.6668 29.2 29.1835 36.6666 20.0002 36.6666L19.5339 36.6602C10.5492 36.413 3.3335 29.044 3.3335 20C3.3335 10.8166 10.8002 3.33331 20.0002 3.33331C29.1835 3.33331 36.6668 10.8166 36.6668 20ZM16.7002 13.3333C16.2168 13.8333 16.2168 14.6166 16.7168 15.1L21.6335 20L16.7168 24.9C16.2168 25.3833 16.2168 26.1833 16.7002 26.6666C17.2002 27.1666 17.9835 27.1666 18.4668 26.6666L24.2835 20.8833C24.5168 20.65 24.6502 20.3333 24.6502 20C24.6502 19.6666 24.5168 19.35 24.2835 19.1166L18.4668 13.3333C18.2335 13.0833 17.9168 12.9666 17.6002 12.9666C17.2668 12.9666 16.9502 13.0833 16.7002 13.3333Z"
              fill="#CB043F"
            />
          </svg>
        </button>
      </div>

      <Swiper
        className="w-full"
        breakpoints={{
          0: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 4,
          },
        }}
        centerInsufficientSlides={true}
        spaceBetween={20}
      >
        {products.map((e, i: number) => (
          <SwiperSlide key={i}>
            <div
              className="p-3 rounded-lg bg-[#FCFDFE] border border-[#F2F2F2] cursor-pointer"
              onClick={() => navigate(`${PATH.LANDING_PAGE_PRODUCT}/${e.id}`)}
            >
              <img
                src={e.fotoProduk || "/assets/images/placeholder-image.png"}
                alt="card thumbnail"
                className="w-full h-48 md:h-72 object-cover border"
              />
              <div className="text-[#3D3D3D] text-lg font-semibold my-1.5">
                {e.namaProduk}
              </div>
              <div className="flex items-center text-[#AFAEAE] mb-2.5">
                <i className="fa fa-map-marker-alt mr-2"></i> {e.namaMitra}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export { SectionProduct };
