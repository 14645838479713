import React, { FC, useState } from "react";

export interface IDropdownProps {
  children: React.ReactElement[];
  hideOnclick?: boolean;
}

const Dropdown: FC<IDropdownProps> = ({ children, hideOnclick }) => {
  let [active, setActive] = useState<boolean>(false);

  let handleClick = () => {
    if (!hideOnclick && !active) {
      setActive(true);
    } else {
      setActive(!active);
    }
  };

  window.addEventListener("click", function (e: any) {
    if (e?.target?.id === "dropdown-wrapper") {
      setActive(false);
    }
  });

  return (
    <>
      {active && (
        <div
          className="fixed w-full h-screen top-0 left-0 z-10 bg-black bg-opacity-10"
          id="dropdown-wrapper"
        ></div>
      )}
      <div className="relative">
        <div onClick={handleClick} className="cursor-pointer">
          {children[0]}
        </div>
        <div
          className={
            "absolute top-full right-4 w-auto bg-white rounded-lg overflow-hidden shadow-lg " +
            (active ? "" : "hidden")
          }
          style={{ zIndex: 9999 }}
        >
          {children[1]}
        </div>
      </div>
    </>
  );
};

export { Dropdown };
