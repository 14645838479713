import { takeLatest } from "redux-saga/effects";
import sagaRequest from "setup/saga/sagaRequest";
import {
  getConfig,
  getSummary,
  ActionTypes,
  IInputPayload,
  getProduct,
  getMitra,
  getProductDetail,
  getMitraDetail,
  getArticle,
  getArticleDetail,
  getMitraV1,
  getMitraDetailV1,
  getProductV1,
} from "./";
import { IActionWithPayload } from "setup/redux/redux.model";

const prefixProduct = "/product";
const prefixMitra = "/mitra";
const prefixGeneral = "/dashboard";
function* getSummarySaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixGeneral}/v1/summary`,
      method: "GET",
    },
    actionType: ActionTypes.GET_SUMMARY,
  });
}
function* getConfigSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixGeneral}/general/data`,
      method: "GET",
    },
    actionType: ActionTypes.GET_CONFIG,
  });
}

function* getProductSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/general`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_PRODUCT,
  });
}
function* getProductDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/general/details/${action.payload.params.productId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_PRODUCT_DETAIL,
  });
}
function* getMitraSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/general/list`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_MITRA,
  });
}
function* getMitraDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/general/details`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_MITRA_DETAIL,
  });
}
function* getArticleSaga() {
  yield sagaRequest({
    requestPayload: {
      url: `/article`,
      method: "GET",
    },
    actionType: ActionTypes.GET_ARTICLE,
  });
}
function* getArticleDetailSaga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `/article/${action.payload.params.articleId}`,
      method: "GET",
    },
    actionType: ActionTypes.GET_ARTICLE_DETAIL,
  });
}

// v1
function* getMitraV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/v1/list`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_MITRA_V1,
  });
}
function* getMitraDetailV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixMitra}/v1/${action.payload.params.mitraId}/details`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_MITRA_DETAIL_V1,
  });
}
function* getProductV1Saga(action: IActionWithPayload<IInputPayload>) {
  yield sagaRequest({
    requestPayload: {
      url: `${prefixProduct}/v1/list`,
      method: "GET",
      data: action.payload.params,
    },
    actionType: ActionTypes.GET_PRODUCT_V1,
  });
}

export default function* adminSaga() {
  yield takeLatest(getSummary.type, getSummarySaga);
  yield takeLatest(getConfig.type, getConfigSaga);
  yield takeLatest(getProduct.type, getProductSaga);
  yield takeLatest(getProductDetail.type, getProductDetailSaga);
  yield takeLatest(getMitra.type, getMitraSaga);
  yield takeLatest(getMitraDetail.type, getMitraDetailSaga);
  yield takeLatest(getArticle.type, getArticleSaga);
  yield takeLatest(getArticleDetail.type, getArticleDetailSaga);
  yield takeLatest(getMitraV1.type, getMitraV1Saga);
  yield takeLatest(getProductV1.type, getProductV1Saga);
  yield takeLatest(getMitraDetailV1.type, getMitraDetailV1Saga);
}
