/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  DashboardAspiration,
  DashboardChangePassword,
  DashboardEditAccount,
  DashboardEditProfile,
  DashboardFavorit,
  DashboardFunding,
  DashboardFundingDetail,
  DashboardFundingDetailHistory,
  DashboardNotification,
  DashboardProductDetail,
  DashboardProductInput,
  DashboardProfile,
  DashboardTraining,
  DashboardTrainingDetail,
  DashboardUsaha,
  DashboardUsahaDetail,
  DashboardUsahaInput,
  NotFound,
} from "./components";
import { PATH } from "config";
import { DashboardListProduct } from "./components/dashboard-product/DashboardListProduct";
import { DashboardListUsaha } from "./components/dashboard-usaha/DashboardListUsaha";

const prefix = "/user";
const abs = (path: string) => {
  return path.substring(prefix.length);
};

const RouteUser: FC = function () {
  return (
    <Routes>
      <Route index element={<Dashboard />} />
      <Route
        path={abs(PATH.USER_USAHA_LIST)}
        element={<DashboardListUsaha />}
      />
      <Route
        path={abs(PATH.USER_USAHA_DETAIL)}
        element={<DashboardUsahaDetail />}
      />
      <Route path={abs(PATH.USER_USAHA)} element={<DashboardUsaha />} />
      <Route
        path={abs(PATH.USER_USAHA_ADD)}
        element={<DashboardUsahaInput />}
      />
      <Route
        path={abs(PATH.USER_USAHA_EDIT)}
        element={<DashboardUsahaInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.USER_PRODUCT_LIST)}
        element={<DashboardListProduct />}
      />
      <Route
        path={abs(PATH.USER_PRODUCT_DETAIL)}
        element={<DashboardProductDetail />}
      />

      <Route
        path={abs(PATH.USER_PRODUCT_ADD)}
        element={<DashboardProductInput />}
      />
      <Route
        path={abs(PATH.USER_PRODUCT_EDIT)}
        element={<DashboardProductInput isEdit={true} />}
      />

      <Route path={abs(PATH.USER_FAVORIT)} element={<DashboardFavorit />} />
      <Route path={abs(PATH.USER_PROFILE)} element={<DashboardProfile />} />
      <Route
        path={abs(PATH.USER_PROFILE_INFO)}
        element={<DashboardEditProfile />}
      />
      <Route
        path={abs(PATH.USER_PROFILE_ACCOUNT)}
        element={<DashboardEditAccount />}
      />
      <Route
        path={abs(PATH.USER_PROFILE_CHANGE_PASSWORD)}
        element={<DashboardChangePassword />}
      />

      <Route path={abs(PATH.USER_TRAINING)} element={<DashboardTraining />} />
      <Route
        path={abs(PATH.USER_TRAINING_HISTORY)}
        element={<DashboardTraining history={true} />}
      />
      <Route
        path={abs(PATH.USER_TRAINING_DETAIL)}
        element={<DashboardTrainingDetail />}
      />
      <Route
        path={abs(PATH.USER_TRAINING_DETAIL_HISTORY)}
        element={<DashboardTrainingDetail history={true} />}
      />

      <Route path={abs(PATH.USER_FUNDING)} element={<DashboardFunding />} />
      <Route
        path={abs(PATH.USER_FUNDING_HISTORY)}
        element={<DashboardFunding history={true} />}
      />
      <Route
        path={abs(PATH.USER_FUNDING_DETAIL)}
        element={<DashboardFundingDetail />}
      />
      <Route
        path={abs(PATH.USER_FUNDING_DETAIL_HISTORY)}
        element={<DashboardFundingDetailHistory />}
      />

      <Route
        path={abs(PATH.USER_NOTIFICATION)}
        element={<DashboardNotification />}
      />
      <Route
        path={abs(PATH.USER_ASPIRATION)}
        element={<DashboardAspiration />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { RouteUser };
