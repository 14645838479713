import { Button } from "components";
import { PATH } from "config";
import { getProduct } from "pages/users/redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "setup";
import { CardProduct } from "./CardProduct";

const DashboardProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams<{
    businessId: string;
  }>();

  const productList = useSelector((state: RootState) => state.user.products);

  useEffect(() => {
    dispatch(
      getProduct({
        params: {
          mitraId: params.businessId,
        },
      })
    );
  }, [params]);

  return (
    <div>
      <div className="font-medium p-6 pb-4">Produk Saya</div>
      {productList.length === 0 ? (
        <div className="h-48 text-gray-600 flex justify-center items-center">
          No Produk Found!
        </div>
      ) : (
        <div className="flex flex-wrap">
          {productList.map((product, index) => (
            <div className="w-1/2 p-2" key={index}>
              <CardProduct product={product} />
            </div>
          ))}
        </div>
      )}
      <div className="p-6 pt-4">
        <Button
          size="xl"
          full
          color="theme"
          className="flex items-center"
          onClick={() => {
            navigate(`${PATH.USER_USAHA}/edit/${params.businessId}/tambah`);
          }}
        >
          <img
            src="/assets/icon/paper-plus.svg"
            alt="tambah"
            className="w-4 mr-4"
          />{" "}
          Tambah Produk
        </Button>
      </div>
    </div>
  );
};
export { DashboardProduct };
