import { FC } from "react";
import { Button, Modal } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const DialogDelete: FC<{
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}> = function ({ show, onClose, onConfirm }) {
  return (
    <>
      <Modal onClose={onClose} show={show} size="md">
        <Modal.Header className="px-6 pt-6 pb-0">
          <span className="sr-only">Hapus data</span>
        </Modal.Header>
        <Modal.Body className="px-6 pt-0 pb-6">
          <div className="flex flex-col items-center gap-y-6 text-center">
            <HiOutlineExclamationCircle className="text-7xl text-red-500" />
            <p className="text-xl text-gray-500">
              Anda yakin ingin menghapus ini?
            </p>
            <div className="flex items-center gap-x-3">
              <Button color="gray" onClick={onClose}>
                Tidak, batalkan
              </Button>
              <Button color="failure" onClick={onConfirm}>
                Ya saya yakin
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DialogDelete;
