import { IIcon } from "components/Icon/Icon.model";
import { PATH } from ".";

export interface ISidebarItem {
  title: string;
  path?: string;
  icon?: IIcon;
  sub?: ISidebarItem[];
}

export interface ISidebarMenu {
  [key: string]: ISidebarItem[];
}

const SidebarMenu: ISidebarMenu = {
  admin: [
    {
      title: "Dashboard",
      path: PATH.DASHBOARD_ANALYTICS,
      icon: "Activity",
    },
    {
      title: "Data Pengguna",
      path: PATH.DASHBOARD_USER,
      icon: "People",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_USER,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_USER_ADD,
        },
        {
          title: "Status Verifikasi",
          path: PATH.DASHBOARD_USER_VERIFY,
        },
      ],
    },
    {
      title: "UMKM DAN EKRAF",
    },
    {
      title: "Kategori",
      path: PATH.DASHBOARD_CATEGORY,
      icon: "Filter2",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_CATEGORY,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_CATEGORY_ADD,
        },
      ],
    },
    {
      title: "Data Mitra",
      path: PATH.DASHBOARD_PARTNER,
      icon: "ShieldDone",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PARTNER,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_PARTNER_ADD,
        },
        {
          title: "Status Review",
          path: PATH.DASHBOARD_PARTNER_VERIFY,
        },
      ],
    },
    {
      title: "PELATIHAN DAN PENDANAAN",
    },
    {
      title: "Pelatihan",
      path: PATH.DASHBOARD_TRAINING,
      icon: "Calendar",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_TRAINING,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_TRAINING_ADD,
        },
      ],
    },
    {
      title: "Pendanaan",
      path: PATH.DASHBOARD_FUNDING,
      icon: "Star",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_FUNDING,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_FUNDING_ADD,
        },
      ],
    },
    {
      title: "Lainnya",
    },
    {
      title: "Article",
      path: PATH.DASHBOARD_ARTICLE,
      icon: "Paper",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_ARTICLE,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_ARTICLE_ADD,
        },
      ],
    },
    {
      title: "Aspirasi",
      path: PATH.DASHBOARD_ASPIRATION,
      icon: "Message",
    },
    {
      title: "PENGATURAN",
    },
    {
      title: "Setting",
      path: PATH.DASHBOARD_SETTINGS,
      icon: "Setting",
      sub: [
        {
          title: "Setting Admin",
          path: PATH.DASHBOARD_ADMIN_ACCOUNT,
        },
        {
          title: "Setting General",
          path: PATH.DASHBOARD_SETTINGS_GENERAL,
        },
        {
          title: "Setting Profile",
          path: PATH.DASHBOARD_SETTINGS_PROFILE,
        },
      ],
    },
  ],
  root: [
    {
      title: "Dashboard",
      path: PATH.DASHBOARD_PIMPINAN_ANALYTICS,
      icon: "Activity",
    },
    {
      title: "Maps",
      path: PATH.DASHBOARD_PIMPINAN_MAPS,
      icon: "Location",
    },
    {
      title: "Data Pengguna",
      path: PATH.DASHBOARD_PIMPINAN_USER,
      icon: "People",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_USER,
        },
        {
          title: "Status Verifikasi",
          path: PATH.DASHBOARD_PIMPINAN_USER_VERIFY,
        },
      ],
    },
    {
      title: "UMKM DAN EKRAF",
    },
    {
      title: "Kategori",
      path: PATH.DASHBOARD_PIMPINAN_CATEGORY,
      icon: "Filter2",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_CATEGORY,
        },
      ],
    },
    {
      title: "Data Mitra",
      path: PATH.DASHBOARD_PIMPINAN_PARTNER,
      icon: "ShieldDone",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_PARTNER,
        },
        {
          title: "Status Review",
          path: PATH.DASHBOARD_PIMPINAN_PARTNER_VERIFY,
        },
      ],
    },
    {
      title: "PELATIHAN DAN PENDANAAN",
    },
    {
      title: "Pelatihan",
      path: PATH.DASHBOARD_PIMPINAN_TRAINING,
      icon: "Calendar",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_TRAINING,
        },
      ],
    },
    {
      title: "Pendanaan",
      path: PATH.DASHBOARD_PIMPINAN_FUNDING,
      icon: "Star",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_FUNDING,
        },
      ],
    },
    {
      title: "Lainnya",
    },
    {
      title: "Article",
      path: PATH.DASHBOARD_PIMPINAN_ARTICLE,
      icon: "Paper",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_PIMPINAN_ARTICLE,
        },
      ],
    },
    {
      title: "Aspirasi",
      path: PATH.DASHBOARD_PIMPINAN_ASPIRATION,
      icon: "Message",
    },
    {
      title: "Log",
      path: PATH.DASHBOARD_PIMPINAN_LOG,
      icon: "Paper",
    },
    {
      title: "PENGATURAN",
    },
    {
      title: "Setting",
      path: PATH.DASHBOARD_PIMPINAN_SETTINGS_PROFILE,
      icon: "Setting",
      sub: [
        {
          title: "Setting Profile",
          path: PATH.DASHBOARD_PIMPINAN_SETTINGS_PROFILE,
        },
      ],
    },
  ],
  user: [],
  adminRegional: [
    {
      title: "Dashboard",
      path: PATH.DASHBOARD_REGIONAL_ANALYTICS,
      icon: "Activity",
    },
    {
      title: "Maps",
      path: PATH.DASHBOARD_REGIONAL_MAPS,
      icon: "Location",
    },
    {
      title: "Data Pengguna",
      path: PATH.DASHBOARD_REGIONAL_USER,
      icon: "People",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_REGIONAL_USER,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_REGIONAL_USER_ADD,
        },
        {
          title: "Status Verifikasi",
          path: PATH.DASHBOARD_REGIONAL_USER_VERIFY,
        },
      ],
    },
    {
      title: "UMKM DAN EKRAF",
    },
    {
      title: "Data Mitra",
      path: PATH.DASHBOARD_REGIONAL_PARTNER,
      icon: "ShieldDone",
      sub: [
        {
          title: "Semua Data",
          path: PATH.DASHBOARD_REGIONAL_PARTNER,
        },
        {
          title: "Tambah Data",
          path: PATH.DASHBOARD_REGIONAL_PARTNER_ADD,
        },
        {
          title: "Status Review",
          path: PATH.DASHBOARD_REGIONAL_PARTNER_VERIFY,
        },
      ],
    },
    {
      title: "PENGATURAN",
    },
    {
      title: "Setting",
      path: PATH.DASHBOARD_REGIONAL_SETTINGS_PROFILE,
      icon: "Setting",
      sub: [
        {
          title: "Setting Profile",
          path: PATH.DASHBOARD_REGIONAL_SETTINGS_PROFILE,
        },
      ],
    },
  ],
};

export { SidebarMenu };
