import { Icon } from "components";
import { PATH } from "config";
import { useEffect, useMemo } from "react";
import { useMatch, useNavigate } from "react-router-dom";

const BottomNav = () => {
  const navigate = useNavigate();
  const userMatch = useMatch("/user");
  const usahaMatch = useMatch("/user/usaha");
  const favoritMatch = useMatch("/user/favorit");
  const profileMatch = useMatch("/user/profile");

  const activeTab = useMemo(() => {
    if (userMatch) return 0;
    if (usahaMatch || window.location.pathname.startsWith("/user/usaha"))
      return 1; // Modify this line
    if (favoritMatch) return 2;
    if (profileMatch) return 3;
    return -1; // Set a default value for when no tabs match
  }, [userMatch, usahaMatch, favoritMatch, profileMatch]);

  return (
    <nav className="fixed bottom-0 left-0 right-0 flex justify-center z-20">
      <div className="w-full mx-auto md:max-w-md bg-white flex justify-around shadow">
        <div
          className="px-4 py-2 flex flex-col items-center cursor-pointer"
          onClick={() => navigate(PATH.USER)}
        >
          <Icon
            name="Home"
            size="small"
            type={activeTab === 0 ? "bold" : "light"}
            color={activeTab === 0 ? "#CB043F" : "#ffffff"}
          />
          <div
            className={[
              "text-xs mt-1",
              activeTab === 0 ? "text-black font-semibold" : "text-[#AFAEAE]",
            ].join(" ")}
          >
            Beranda
          </div>
        </div>
        <div
          className="px-4 py-2 flex flex-col items-center cursor-pointer"
          onClick={() => navigate(PATH.USER_USAHA)}
        >
          <Icon
            name="Work"
            size="small"
            type={activeTab === 1 ? "bold" : "light"}
            color={activeTab === 1 ? "#CB043F" : "#ffffff"}
          />
          <div
            className={[
              "text-xs mt-1",
              activeTab === 1 ? "text-black font-semibold" : "text-[#AFAEAE]",
            ].join(" ")}
          >
            Usaha Saya
          </div>
        </div>
        <div
          className="px-4 py-2 flex flex-col items-center cursor-pointer"
          onClick={() => navigate(PATH.USER_FAVORIT)}
        >
          <Icon
            name="Heart"
            size="small"
            type={activeTab === 2 ? "bold" : "light"}
            color={activeTab === 2 ? "#CB043F" : "#ffffff"}
          />
          <div
            className={[
              "text-xs mt-1",
              activeTab === 2 ? "text-black font-semibold" : "text-[#AFAEAE]",
            ].join(" ")}
          >
            Favorit
          </div>
        </div>
        <div
          className="px-4 py-2 flex flex-col items-center cursor-pointer"
          onClick={() => navigate(PATH.USER_PROFILE)}
        >
          <Icon
            name="User"
            size="small"
            type={activeTab === 3 ? "bold" : "light"}
            color={activeTab === 3 ? "#CB043F" : "#ffffff"}
          />
          <div
            className={[
              "text-xs mt-1",
              activeTab === 3 ? "text-black font-semibold" : "text-[#AFAEAE]",
            ].join(" ")}
          >
            Profil
          </div>
        </div>
      </div>
    </nav>
  );
};
export { BottomNav };
