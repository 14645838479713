/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  DashboardArticle,
  DashboardArticleInput,
  DashboardAspiration,
  DashboardCategory,
  DashboardCategoryInput,
  DashboardFunding,
  DashboardFundingAdd,
  DashboardMitra,
  DashboardMitraAdd,
  DashboardProductInput,
  DashboardSettingAdmin,
  DashboardSettingProfile,
  DashboardSettingGeneral,
  DashboardTraining,
  DashboardTrainingAdd,
  DashboardUser,
  DashboardUserInput,
} from "./components";
import { PATH } from "config";
import DashboardTrainingDetail from "./components/dashboard-training/DashboardTrainingDetail";
import DashboardFundingDetail from "./components/dashboard-funding/DashboardFundingDetail";
import DashboardMitraDetail from "./components/dashboard-mitra/DashboardMitraDetail";
import DashboardSettingAdminInput from "./components/dashboard-setting/DashboardSettingAdminInput";
import { NotFound } from "pages/landing/components";

const prefix = "/admin";
const abs = (path: string) => {
  return path.substring(prefix.length);
};

const RouteAdmin = function () {
  return (
    <Routes>
      <Route index element={<Navigate to={PATH.DASHBOARD_ANALYTICS} />} />

      <Route path={abs(PATH.DASHBOARD_ANALYTICS)} element={<Dashboard />} />

      {/* user */}
      <Route
        path={abs(PATH.DASHBOARD_USER)}
        element={<DashboardUser isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_USER_VERIFY)}
        element={<DashboardUser />}
      />
      <Route
        path={abs(PATH.DASHBOARD_USER_EDIT)}
        element={<DashboardUserInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_USER_ADD)}
        element={<DashboardUserInput isEdit={false} />}
      />

      {/* category */}
      <Route
        path={abs(PATH.DASHBOARD_CATEGORY)}
        element={<DashboardCategory />}
      />
      <Route
        path={abs(PATH.DASHBOARD_CATEGORY_ADD)}
        element={<DashboardCategoryInput isEdit={false} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_CATEGORY_EDIT)}
        element={<DashboardCategoryInput isEdit={true} />}
      />

      {/* mitra */}
      <Route
        path={abs(PATH.DASHBOARD_PARTNER)}
        element={<DashboardMitra isVerify />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PARTNER_ADD)}
        element={<DashboardMitraAdd />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PARTNER_DETAIL)}
        element={<DashboardMitraDetail />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PARTNER_VERIFY)}
        element={<DashboardMitra />}
      />

      {/* product */}
      <Route
        path={abs(PATH.DASHBOARD_PRODUCT_EDIT)}
        element={<DashboardProductInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_PRODUCT)}
        element={<DashboardProductInput isEdit={false} />}
      />

      {/* <Route path={abs(PATH.DASHBOARD_ADMIN)} element={<DashboardAdminRoutes />} /> */}

      {/* Dashboard Training Module */}
      <Route
        path={abs(PATH.DASHBOARD_TRAINING)}
        element={<DashboardTraining />}
      />
      <Route
        path={abs(PATH.DASHBOARD_TRAINING_ADD)}
        element={<DashboardTrainingAdd />}
      />
      <Route
        path={abs(PATH.DASHBOARD_TRAINING_DETAIL)}
        element={<DashboardTrainingDetail />}
      />

      {/* Dashboard Funding Module */}
      <Route
        path={abs(PATH.DASHBOARD_FUNDING)}
        element={<DashboardFunding />}
      />
      <Route
        path={abs(PATH.DASHBOARD_FUNDING_DETAIL)}
        element={<DashboardFundingDetail />}
      />
      <Route
        path={abs(PATH.DASHBOARD_FUNDING_ADD)}
        element={<DashboardFundingAdd />}
      />

      <Route
        path={abs(PATH.DASHBOARD_ARTICLE)}
        element={<DashboardArticle />}
      />
      <Route
        path={abs(PATH.DASHBOARD_ARTICLE_ADD)}
        element={<DashboardArticleInput isEdit={false} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_ARTICLE_EDIT)}
        element={<DashboardArticleInput isEdit={true} />}
      />

      {/* Dashboard aspiration Module */}
      <Route
        path={abs(PATH.DASHBOARD_ASPIRATION)}
        element={<DashboardAspiration />}
      />

      {/* Dashboard Setting Module */}
      <Route
        path={abs(PATH.DASHBOARD_SETTINGS_GENERAL)}
        element={<DashboardSettingGeneral />}
      />
      <Route
        path={abs(PATH.DASHBOARD_SETTINGS_PROFILE)}
        element={<DashboardSettingProfile />}
      />
      <Route
        path={abs(PATH.DASHBOARD_ADMIN_ACCOUNT)}
        element={<DashboardSettingAdmin />}
      />
      <Route
        path={abs(PATH.DASHBOARD_ADMIN_ACCOUNT_EDIT)}
        element={<DashboardSettingAdminInput isEdit={true} />}
      />
      <Route
        path={abs(PATH.DASHBOARD_ADMIN_ACCOUNT_ADD)}
        element={<DashboardSettingAdminInput isEdit={false} />}
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { RouteAdmin };
