import { FC } from "react";
import { IDialogProps } from "./Dialog.model";
import { Modal } from "flowbite-react";

const Dialog: FC<IDialogProps> = ({
  children,
  show,
  onClose,
  title,
  size = "md",
}) => {
  // return (
  // <Modal onClose={onClose} show={show} size={size}>
  //   <Modal.Header className="px-6 pt-6 pb-0">
  //     <span className="text-base">{title}</span>
  //   </Modal.Header>
  //   <Modal.Body className="px-6 pt-0 pb-6">{children}</Modal.Body>
  // </Modal>
  // );

  return show ? (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50	bg-black bg-opacity-10 flex items-center justify-center overflow-auto">
      <div className={`w-full max-w-${size} bg-white rounded-lg`}>
        <div className="p-6 border-b flex justify-between">
          <div>{title}</div>
          <i className="fa fa-times cursor-pointer" onClick={onClose}></i>
        </div>
        <div className="p-6">{children}</div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export { Dialog };
