import { Button, TextField } from "components";
import { PATH } from "config";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ActionTypes, forgotPassword } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.FORGOT_PASSWORD)
  );

  const initialValues = {
    nik: "",
  };

  const validationSchema = Yup.object({
    nik: Yup.string().required("NIK is Required"),
  });

  const formHandler = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      dispatch(
        forgotPassword({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mengirim link reset password");
          },
          onFailure(e) {
            toast.error("Gagal mengirim link reset password");
          },
        })
      );
    },
  });

  return (
    <div className="min-h-screen  bg-[#F6F7FB]">
      <div className="w-full mx-auto md:max-w-md flex flex-col px-8 py-8 items-center justify-center bg-white min-h-screen">
        <img src="/assets/logo/logo.png" alt="logo tabe" className="w-32" />
        <div className="text-xl mt-4 mb-8 font-bold">Reset Password</div>
        <TextField
          parentClassName="mb-6"
          placeholder="Nomor NIK"
          prefix="ShieldDone"
          propsInput={{ ...formHandler.getFieldProps("nik") }}
          error={formHandler.touched.nik && !!formHandler.errors.nik}
          errorText={`${formHandler.errors.nik}`}
        />
        <Button
          color="failure"
          type="submit"
          size="sm"
          full
          loading={isLoading}
          disabled={isLoading}
          onClick={() => formHandler.submitForm()}
        >
          Kirim Link Reset
        </Button>
        <div className="mt-24 text-center w-full text-sm">
          Belum punya akun?{" "}
          <span
            className="text-[#CB043F] font-bold cursor-pointer"
            onClick={() => navigate(PATH.REGISTER)}
          >
            Register
          </span>{" "}
          di sini
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
