import { FC, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";

const imageToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileSelectMulti: FC<{
  formHandler: any;
  name: string;
  error?: boolean;
  errorText?: string;
  onDelete?: (index: number) => void;
  onChange?: (base64: any, file?: any) => void;
}> = ({ formHandler, name, error, errorText, onDelete }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState("");

  const [image, setImage] = useState<string[]>([]);

  const handleFileInput = (e: any) => {
    let file = e.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      toast.error("File terlalu besar");
      return;
    }
    if (!file) return;
    imageToBase64(file).then((x) => {
      formHandler.setFieldValue(name, [...formHandler.values[name], x]);
      formHandler.setFieldTouched(name);
      // setImage((prev: any) => {
      //   return [...prev, x];
      // });
      // setPreview(`${x}`);
    });
  };

  // useEffect(() => {
  //   formHandler.setFieldValue(name, image);
  //   formHandler.setFieldTouched(name);
  // }, [image]);

  return (
    <div className={["mb-6 w-full"].join(" ")}>
      <div
        className={[
          "flex flex-wrap rounded bg-white overflow-hidden",
          error && "border border-red-500",
        ].join(" ")}
      >
        {formHandler.values[name].map((item: any, index: number) => (
          <div className="p-4 relative" key={index}>
            <span
              className="absolute top-4 right-4 p-1 bg-white w-6 h-6 rounded-full flex items-center justify-center cursor-pointer"
              onClick={() => {
                onDelete && onDelete(index);
              }}
            >
              <i className="fa fa-trash text-red-500 text-xs"></i>
            </span>
            <img
              src={item || "/assets/images/placeholder-image.png"}
              alt="preview"
              className="object-cover rounded"
              style={{ width: "100px", height: "100px" }}
            />
          </div>
        ))}
        <div>
          <div
            className="cursor-pointer w-130px h-130px object-cover"
            onClick={() => {
              if (fileInput.current !== null) {
                fileInput.current.click();
              }
            }}
          >
            <svg
              width="130"
              height="130"
              viewBox="0 0 130 130"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="130" height="130" rx="4" fill="white" />
              <rect
                x="15"
                y="15"
                width="100"
                height="100"
                rx="4"
                fill="#F6F7FB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M60.6662 75H69.3328C72.7231 75 75 72.6219 75 69.0833V60.9167C75 57.3781 72.7231 55 69.3338 55H60.6662C57.2769 55 55 57.3781 55 60.9167V69.0833C55 72.6219 57.2769 75 60.6662 75ZM61.4989 64C60.1202 64 59 62.8783 59 61.5C59 60.1217 60.1202 59 61.4989 59C62.8765 59 63.9977 60.1217 63.9977 61.5C63.9977 62.8783 62.8765 64 61.4989 64ZM72.8208 67.934C73.1557 68.7926 72.9817 69.8246 72.6237 70.6749C72.1994 71.6863 71.3869 72.452 70.3632 72.7864C69.9087 72.935 69.432 73 68.9564 73H60.5286C59.69 73 58.9479 72.7988 58.3395 72.4241C57.9584 72.1889 57.891 71.646 58.1736 71.2941C58.6462 70.7059 59.1128 70.1156 59.5834 69.5201C60.4804 68.3808 61.0847 68.0506 61.7565 68.3406C62.029 68.4603 62.3025 68.6398 62.584 68.8297C63.3342 69.3395 64.377 70.0402 65.7506 69.2797C66.6906 68.7532 67.2358 67.8501 67.7106 67.0637L67.7185 67.0506C67.7522 66.9954 67.7855 66.9402 67.8187 66.8852C67.9783 66.6212 68.1357 66.3607 68.3138 66.1207C68.5371 65.8204 69.3646 64.8813 70.4366 65.5501C71.1194 65.9711 71.6936 66.5408 72.308 67.1507C72.5423 67.3839 72.7092 67.6491 72.8208 67.934Z"
                fill="#CB043F"
              />
            </svg>
          </div>
        </div>
        <input
          ref={fileInput}
          type="file"
          className="hidden"
          accept="image/*"
          onInput={handleFileInput}
        />
      </div>
      {error && <div className="text-sm mt-1 text-red-500">{errorText}</div>}
    </div>
  );
};
export { FileSelectMulti };
