import { Button } from "components"
import { getInfo } from "pages/admin/components/auth/redux"
import { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { RootState } from "setup"

const BlockPage = () => (
  <div className="w-full h-screen flex flex-col justify-center items-center">
    <img src="/assets/icon/logo-ct.png" alt="logo" className="w-24" />
    <h1 className="text-xl font-semibold text-gray-600 mt-8">
      Anda Memiliki Akses Ke Halaman Ini
    </h1>
    <Button
      color="light"
      className="mt-4"
      onClick={() => (window.location.href = "/")}
    >
      Kembali
    </Button>
  </div>
)

const Guard: FC<{
  element: React.ReactElement | null
  role: string
}> = ({ element, role }) => {
  const dispatch = useDispatch()
  const isAuthorized = localStorage.getItem("token") ?? false
  const authInfo = useSelector((state: RootState) => state.auth.authInfo)

  useEffect(() => {
    dispatch(getInfo()) // Set isLoading menjadi false setelah permintaan selesai, baik berhasil maupun gagal
  }, [dispatch])

  if (authInfo.role === "" && isAuthorized === false) {
    // Tampilkan halaman loading
    return <BlockPage />
  }

  if (isAuthorized) {
    if (authInfo.role !== "") {
      if (authInfo.role === role) {
        return element
      } else {
        return <BlockPage />
      }
    } else {
      return <></>
    }
  } else {
    return <Navigate to={"/"} />
  }
}

export { Guard }
