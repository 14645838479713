import { Button, Dialog, FileSelect } from "components";
import { useFormik } from "formik";
import { ActionTypes, getGeneralData, uploadSlider } from "pages/admin/redux";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import * as Yup from "yup";

const DialogUplaodSlider: FC<{
  show: boolean;
  onClose: () => void;
}> = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(ActionTypes.UPLOAD_SLIDER));

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      foto: "",
    },
    validationSchema: Yup.object({
      foto: Yup.string().required("Gambar Banner wajib diisi"),
    }),
    onSubmit(values) {
      dispatch(
        uploadSlider({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mengganti banner");
            dispatch(getGeneralData());
            onClose();
          },
          onFailure(e) {
            toast.error("Gagal mengganti banner");
          },
        })
      );
    },
  });

  return (
    <Dialog title="Unggah Slider" show={show} onClose={onClose}>
      <>
        <div className="py-4 flex justify-center">
          <FileSelect
            full
            onChange={(value) => {
              formHandler.setFieldValue("foto", value);
              formHandler.setFieldTouched("foto");
            }}
            error={formHandler.touched.foto && !!formHandler.errors.foto}
            errorText={`${formHandler.errors.foto ?? ""}`}
          />
        </div>
        <div className="flex justify-center">
          <Button className="mx-2" color="light" onClick={onClose}>
            Batal
          </Button>
          <Button
            className="mx-2"
            color="primary"
            onClick={() => formHandler.submitForm()}
            loading={loading}
            disabled={loading}
          >
            Upload
          </Button>
        </div>
      </>
    </Dialog>
  );
};
export { DialogUplaodSlider };
