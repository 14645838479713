const Footer = () => {
  return (
    <footer className="bg-[#F6F7FB] p-4">
      <div className="text-center text-sm text-[#3D3D3D]">
        <div>© 2022 - TABE Kalimantan Tengah. All rights reserved.</div>
        <div className="pt-4 text-gray-500">
          <div>Contact Email : dev.kalteng2203@gmail.com</div>
          <div className="mt-2">Contact Number : (+62) 815 - 2821 - 8324</div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
