const convertToWhatsAppURL = (phoneNumber: string): string => {
  // Remove any non-digit characters from the phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Add the country code prefix if necessary
  const countryCode = "62"; // Replace with the desired country code
  const phoneNumberWithCountryCode = cleanedPhoneNumber.startsWith(countryCode)
    ? cleanedPhoneNumber
    : countryCode + cleanedPhoneNumber;

  // Generate the WhatsApp URL
  const whatsAppURL = `https://api.whatsapp.com/send?phone=${phoneNumberWithCountryCode}`;

  return whatsAppURL;
};

export { convertToWhatsAppURL };
