import { MainLayout, Pagination, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductV1 } from "pages/landing/redux";
import { RootState } from "setup";
import { CardProductItem } from "./CardProductItem";

const DashboardListProduct = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const products = useSelector(
    (state: RootState) => state.landing.productListV1
  );

  const handleSearch = useCallback((e: any) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  }, []);

  const meta = useSelector((state: RootState) => state.landing.productMeta);

  useEffect(() => {
    dispatch(
      getProductV1({
        params: {
          search: search || "",
          page: page,
          limit: 12,
        },
      })
    );
  }, [search, page]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="List Produk dan Jasa" />
          <div className="p-6 pb-4">
            <input
              type="text"
              className="w-full rounded p-2 placeholder:text-sm text-sm  border-0 bg-white  p-3 rounded-lg"
              placeholder="Pencarian"
              onKeyUp={handleSearch}
            />
          </div>
          <div className="flex flex-wrap px-4">
            {products.map((item, index) => (
              <div className="w-1/2 p-2" key={index}>
                <CardProductItem product={item} />
              </div>
            ))}
          </div>
          <Pagination
            currentPage={meta.pageNumber}
            totalPages={meta.pageTotal}
            totalCount={meta.count}
            onPrev={() => {
              if (page > 0) {
                setPage(page - 1);
              }
            }}
            onNext={() => {
              setPage(page + 1);
            }}
            onChange={(currentPage) => {
              setPage(currentPage);
            }}
            isDownload={false}
            sticky={false}
          />
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardListProduct };
