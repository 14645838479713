import { createReducer } from "@reduxjs/toolkit";
import { IAuthState, getInfo, logout, setLogin } from ".";

export const initialState: IAuthState = {
  authInfo: {
    role: "",
    email: "",
    id: "",
    regionals: [],
  },
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogin, (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_NAME ?? "",
        action.payload.payload.token
      );
      state.authInfo = action.payload.payload.user;
    })
    .addCase(logout, (state, action) => {
      localStorage.clear();
      window.location.href = "/";
    })
    .addCase(getInfo, (state, action) => {
      const token = localStorage.getItem(
        process.env.REACT_APP_TOKEN_NAME ?? ""
      );
      try {
        const result = JSON.parse(atob(token?.split(".")[1] ?? "{}"));
        state.authInfo = {
          role: result.role,
          email: result.email,
          id: result.id,
          regionals: [],
        };
      } catch (e) {
        state.authInfo = {
          role: "",
          email: "",
          id: "",
          regionals: [],
        };
      }
    });
});

export default authReducer;
