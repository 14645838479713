import {
  Button,
  Ckeditor,
  FileSelect,
  MainLayout,
  TextField,
} from "components";
import { useFormik } from "formik";
import { addArticle, editArticle, getArticleDetail } from "pages/admin/redux";
import { FC, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "setup";
import { uploadFile } from "utils/uploadFile";
import * as Yup from "yup";

export const DashboardArticleInput: FC<{
  isEdit: boolean;
}> = ({ isEdit }) => {
  const dispatch = useDispatch();
  const params = useParams<{
    articleId: string;
  }>();

  const [loading, setLoading] = useState<boolean>(false);

  const article = useSelector((state: RootState) => state.admin.articleDetail);

  const [imageUpload, setImageUpload] = useState<any>(null);

  const initialValues = useMemo(() => {
    return {
      title: isEdit ? article.title : "",
      content: isEdit ? article.content : "",
      image: isEdit ? article.preview.image : "",
    };
  }, [isEdit, article]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Name is required"),
    content: Yup.string().required("Content is required"),
    image: Yup.string().required("Image is required"),
  });

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    async onSubmit(values) {
      setLoading(true);
      try {
        let res;
        if (imageUpload !== null) {
          res = await uploadFile(imageUpload);
        } else {
          res = { id: 0 };
        }

        if (isEdit) {
          dispatch(
            editArticle({
              params: {
                articleId: article.id,
                title: values.title,
                content: values.content,
                preview: {
                  image: imageUpload !== null && res.id,
                },
              },
              onSuccess(e) {
                toast.success("Berhasil mengedit artikel");
                getArticleDetail({
                  params: {
                    articleId: params.articleId,
                  },
                });
              },
              onFailure(e) {
                toast.success("Gagal mengedit artikel");
              },
            })
          );
        } else {
          dispatch(
            addArticle({
              params: {
                title: values.title,
                content: values.content,
                preview: {
                  image: res.id,
                },
              },
              onSuccess(e) {
                toast.success("Berhasil membuat artikel");
              },
              onFailure(e) {
                toast.success("Gagal membuat artikel");
              },
            })
          );
        }

        setLoading(false);
      } catch (err) {
        console.log(err);
        toast.error("Failed to upload image");
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      dispatch(
        getArticleDetail({
          params: {
            articleId: params.articleId,
          },
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit]);

  return (
    <MainLayout>
      <>
        <div className="bg-white shadow rounded p-8 mt-4">
          <div className="w-full max-w-3xl mx-auto">
            <div className="md:flex justify-between mb-8">
              <div className="flex flex-col items-start flex-1 pr-12 mb-4">
                <div className="font-medium">Detail Artikel</div>
              </div>
              <div className="w-full max-w-[300px] relative">
                <FileSelect
                  imageUrl={formHandler.values.image}
                  onChange={(value, file) => {
                    setImageUpload(file);
                    formHandler.setFieldValue("image", value);
                    formHandler.setFieldTouched("image");
                  }}
                  error={
                    formHandler.touched.image && !!formHandler.errors.image
                  }
                  errorText={`${formHandler.errors.image ?? ""}`}
                />
                <TextField
                  label="title"
                  propsInput={{ ...formHandler.getFieldProps("title") }}
                  error={
                    formHandler.touched.title && !!formHandler.errors.title
                  }
                  errorText={`${formHandler.errors.title}`}
                />
              </div>
            </div>
            <div className="mb-8">
              <Ckeditor
                value={formHandler.values.content}
                onChange={(e) => {
                  if (e !== "") {
                    formHandler.setFieldValue("content", e);
                    formHandler.setFieldTouched("content");
                  }
                }}
                error={
                  formHandler.touched.content && !!formHandler.errors.content
                }
                errorText={`${formHandler.errors.content}`}
              />
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col items-start flex-1 pr-12">
                <Button
                  color="failure"
                  loading={loading}
                  disabled={loading}
                  onClick={() => formHandler.submitForm()}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  );
};

export default DashboardArticleInput;
