const prefixAction = `AUTH`;

export const ActionTypes = {
  LOGIN: `${prefixAction}_LOGIN`,
  LOGOUT: `${prefixAction}_LOGOUT`,
  GETINFO: `${prefixAction}_GETINFO`,
};

export interface LoginFormValues {
  email: string;
  password: string;
}

export interface ILoginAction {
  data: LoginFormValues;
  onSuccess?: (e: any) => void;
  onFailure?: (e: any) => void;
}

export interface Payload {
  user: IUser;
  token: string;
  role: string;
}

export interface IUser {
  role: string;
  email: string;
  id: string;
  regionals: any[];
}

export interface ILoginInfoResponse {
  message: string;
  payload: Payload;
}

export interface IAuthState {
  authInfo: IUser;
}
