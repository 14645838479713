import { RouteUser } from "pages/users";
import { RouteAdmin } from "pages/admin";
import { Auth as AuthAdmin } from "pages/admin/components";
import {
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
} from "pages/users/components";
import {
  Article,
  DetailArticle,
  DetailMitra,
  DetailProduct,
  Mitra,
  Product,
} from "pages/landing/components";
import { RoutePimpinan } from "pages/root";
import { RouteAdminRegional } from "pages/regional";
import { PATH } from "./Path";

export const route = {
  private: [
    {
      path: "/admin/*",
      element: RouteAdmin,
      role: "admin",
    },
    {
      path: "/pimpinan/*",
      element: RoutePimpinan,
      role: "root",
    },
    {
      path: "/admin-regional/*",
      element: RouteAdminRegional,
      role: "adminRegional",
    },
    { path: "/user/*", element: RouteUser, role: "user" },
  ],
  public: [
    { path: PATH.LOGIN, element: Login },
    { path: PATH.REGISTER, element: Register },
    { path: PATH.FORGOT_PASSWORD, element: ForgotPassword },
    { path: PATH.RESET_PASSWORD, element: ResetPassword },
    { path: PATH.LANDING_PAGE_PRODUCT, element: Product },
    { path: PATH.LANDING_PAGE_MITRA, element: Mitra },
    { path: PATH.LANDING_PAGE_ARTICLE, element: Article },
    { path: "/admin/login", element: AuthAdmin },
    { path: "/auth/login", element: AuthAdmin },
    { path: PATH.LANDING_PAGE_PRODUCT_DETAIL, element: DetailProduct },
    { path: PATH.LANDING_PAGE_MITRA_DETAIL, element: DetailMitra },
    { path: PATH.LANDING_PAGE_ARTICLE_DETAIL, element: DetailArticle },
    // { Path: PATH.LANDING_PAGE_ARTICLE, },
  ],
};
