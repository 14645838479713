import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

function exportBase64ToExcel(base64Data: string, fileName: string) {
  const workbook = XLSX.read(base64Data, { type: "base64" });
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const excelData = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(excelData, fileName);
}

export { exportBase64ToExcel };
