import { FC } from "react";
import { useNavigate } from "react-router-dom";

const HeaderNav: FC<{
  title: string;
  onClose?: () => void;
}> = ({ title, onClose }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center bg-white py-3 px-6">
      <svg
        onClick={
          onClose
            ? onClose
            : () => {
                navigate(-1);
              }
        }
        className="cursor-pointer"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0.750244C4.892 0.750244 0.75 4.89124 0.75 10.0002C0.75 15.1082 4.892 19.2502 10 19.2502C15.108 19.2502 19.25 15.1082 19.25 10.0002C19.25 4.89124 15.108 0.750244 10 0.750244Z"
          stroke="#200E32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.4424 6.52905L7.95638 10.0001L11.4424 13.4711"
          stroke="#200E32"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div className="text-lg font-semibold">{title}</div>
      <div className="w-12 h-1"></div>
    </div>
  );
};
export { HeaderNav };
