import { PATH } from "config";
import { IProducts } from "pages/users/redux";
import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CardProduct: FC<{ product: IProducts }> = ({ product }) => {
  const navigate = useNavigate();

  const params = useParams<{
    businessId: string;
  }>();

  return (
    <div
      className="p-3 rounded-lg bg-[#FCFDFE] border border-[#F2F2F2] cursor-pointer"
      onClick={() => {
        navigate(
          `${PATH.USER_USAHA}/edit/${params.businessId}/edit/${product.id}`
        );
      }}
    >
      <img
        src={product.fotoProduk || "/assets/images/placeholder-image.png"}
        alt="card thumbnail"
        className="w-full h-32 object-cover rounded"
      />
      <div className="text-[#3D3D3D] text-md font-semibold my-1.5">
        {product.namaProduk}
      </div>
      <div className="flex items-center mb-2.5">
        <img src="/assets/icon/market.svg" alt="mitra" className="w-5" />
        <div className="text-[#AFAEAE] text-xs ellipsis ml-2">
          {product.namaMitra}
        </div>
      </div>
      <div className="rounded bg-red-100 text-red-500 text-xs p-2 text-center font-semibold">
        {new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(product.harga)}
      </div>
    </div>
  );
};

export { CardProduct };
