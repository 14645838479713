import { Button, Icon, MainLayout, TableComponent } from "components";
import { ActionTypes, IAdmin, deleteAdmin, getAdmin } from "pages/admin/redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import DialogDelete from "components/DialogDelete/DialogDelete";
import { toast } from "react-hot-toast";

export const DashboardSettingAdmin: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogDelete, setDialogDelete] = useState<{
    show: boolean;
    id: string;
  }>({
    show: false,
    id: "",
  });

  const columns = useMemo(
    () => [
      {
        header: "Email",
        field: "email",
      },
      {
        header: "Role",
        field: "role",
      },
      {
        header: "Aksi",
        field: "id",
        rowRender: (row: IAdmin) => {
          return (
            <div className="flex">
              <span
                className="px-2 cursor-pointer"
                onClick={() => {
                  navigate(`${PATH.DASHBOARD_ADMIN_ACCOUNT}/edit/${row.id}`);
                }}
              >
                <Icon name="Edit" />
              </span>
              <span
                className="px-2 cursor-pointer"
                onClick={() => {
                  setDialogDelete({ show: true, id: row.id });
                }}
              >
                <Icon name="Delete" />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const admin = useSelector((state: RootState) => state.admin.admin);

  const limit = 20;
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    dispatch(getAdmin());
  }, []);

  const handleDelete = useCallback(() => {
    dispatch(
      deleteAdmin({
        params: {
          id: dialogDelete.id,
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus admin");
          dispatch(getAdmin());
          setDialogDelete({ show: false, id: "" });
        },
        onFailure(e) {
          toast.error("Gagal menghapus admin");
        },
      })
    );
  }, [dialogDelete]);

  return (
    <MainLayout>
      <>
        <div className="bg-white shadow rounded">
          <div className="p-8 flex items-center justify-between border-b">
            <div className="text-lg font-semibold text-gray-800">
              List Admin
            </div>
            <Button
              color="failure"
              onClick={() => {
                navigate(PATH.DASHBOARD_ADMIN_ACCOUNT_ADD);
              }}
            >
              Tambah Admin
            </Button>
          </div>
        </div>
        <div className="mt-4 table-responve">
          <TableComponent
            columns={columns}
            data={admin}
            isLoading={false}
            pagination={{
              currentPage: page,
              totalPages: Math.ceil(admin.length / limit),
              totalCount: admin.length,
              onPrev: () => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              },
              onNext: () => {
                if (page < Math.ceil(admin.length / limit)) {
                  setPage((prev) => prev + 1);
                }
              },
            }}
            isDownload
            localPagination
          />
        </div>
        <DialogDelete
          show={dialogDelete.show}
          onClose={() => {
            setDialogDelete({ show: false, id: "" });
          }}
          onConfirm={handleDelete}
        />
      </>
    </MainLayout>
  );
};

export default DashboardSettingAdmin;
