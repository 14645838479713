import { FC } from "react";
import ReactApexChart from "react-apexcharts";

interface ChartDataItem {
  date: string;
  produk: number;
  usaha: number;
}

interface MultiBarChartProps {
  data: ChartDataItem[];
}

const MultiBarChart: FC<MultiBarChartProps> = ({ data }) => {
  // Extracting the dates, produk, and usaha data from the input
  const categories = data.map((item) => item.date);
  const produkData = data.map((item) => item.produk);
  const usahaData = data.map((item) => item.usaha);

  // Chart data
  const chartData: any = {
    series: [
      {
        name: "Produk",
        data: produkData,
      },
      {
        name: "Usaha",
        data: usahaData,
      },
    ],
    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: 350,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          endingShape: "flat",
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: categories,
      },
      yaxis: {
        title: {
          text: "Value",
        },
      },
      legend: {
        position: "top",
      },
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <div>
      {produkData[0] !== undefined && (
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
        />
      )}
    </div>
  );
};

export default MultiBarChart;
