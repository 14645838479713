import { MainLayout } from "components";
import { DashboardTrainingInput } from "./DashboardTrainingInput";

const DashboardTrainingAdd = () => {
  return (
    <MainLayout>
      <div className="bg-white shadow rounded p-8 mt-4">
        <div className="w-full max-w-3xl mx-auto">
          <DashboardTrainingInput isEdit={false} />
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardTrainingAdd };
