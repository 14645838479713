import { Button, MainLayout, SelectField, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IFundingDetailHistory,
  applyFunding,
  getCategory,
  getFundingDetail,
  getFundingDetailHistory,
  getMitraDetail,
} from "pages/users/redux";
import { RootState } from "setup";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";

const DashboardFundingDetailHistory = () => {
  const dispatch = useDispatch();

  const params = useParams<{
    fundingId: string;
  }>();

  const funding = useSelector(
    (state: RootState) => state.user.fundingDetailHistory
  );
  const categoryList = useSelector(
    (state: RootState) => state.user.categoryList
  );

  const kriteria = [
    { label: "Usaha Mikro", value: "mikro" },
    { label: "Usaha Kecil", value: "kecil" },
    { label: "Usaha Menengah", value: "menengah" },
  ];

  const initialValues = useMemo(() => {
    return {
      fundingId: params.fundingId,
      nib: funding.nib ?? "",
      sektorUsaha: funding.sektorUsaha?.[0]?.id ?? "",
      karyawan: {
        pria: funding.karyawan.pria ?? 0,
        wanita: funding.karyawan.wanita ?? 0,
      },
      asset: funding.asset ?? 0,
      omset: funding.omset ?? 0,
      kriteria: funding.kriteria ?? "",
    };
  }, [funding]);

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({}),
    onSubmit(values) {},
  });

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    dispatch(
      getFundingDetailHistory({
        params: {
          fundingId: params.fundingId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Detail Penawaran" />
          <img
            src={funding.banner || "/assets/images/placeholder-image.png"}
            alt="pelatihan"
            className="w-full"
          />
          <div className="p-6 transform -translate-y-24">
            <div className="mt-6 bg-white p-4 rounded-lg text-gray-800">
              <div className="mb-4 text-lg text-gray-800 font-medium">
                {funding.title}
              </div>
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>Penyelenggara</td>
                    <td>:&nbsp;{funding.penyelenggara}</td>
                  </tr>
                  <tr>
                    <td>Deadline</td>
                    <td>:&nbsp;{funding.deadline}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Persyaratan</td>
                  </tr>
                  <tr>
                    <td>
                      {funding.persyaratan.map((item, index) => (
                        <div className="pl-4" key={index}>
                          {index + 1}. {item}
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="my-6">
              <div className="bg-white rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800">
                {funding.namaMitra}
              </div>
              <TextField
                className="rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800  my-4"
                type="number"
                variant="solid"
                placeholder="NIB"
                parentClassName="mr-2"
                propsInput={{
                  ...formHandler.getFieldProps("nib"),
                  readOnly: true,
                }}
                error={formHandler.touched.nib && !!formHandler.errors.nib}
                errorText={formHandler.errors.nib}
              />
              <div className="font-semibold mb-2">Pilih Sektor Usaha</div>
              <SelectField
                className="bg-white rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800"
                placeholder="Pilih Sektor Usaha"
                value={`${formHandler.values.sektorUsaha}`}
                onChange={(e) => {
                  formHandler.setFieldTouched("sektorUsaha");
                  formHandler.setFieldValue("sektorUsaha", e.target.value);
                }}
                propsInput={{ readOnly: true }}
                options={categoryList.map((e) => ({
                  label: e.name,
                  value: e._id,
                }))}
                error={
                  formHandler.touched.sektorUsaha &&
                  !!formHandler.errors.sektorUsaha
                }
                errorText={`${formHandler.errors.sektorUsaha}`}
              />
            </div>
            <div className="font-semibold mb-2">Jumlah Karyawan</div>
            <div className="flex">
              <TextField
                type="number"
                variant="solid"
                placeholder="Laki - laki"
                className="bg-white p-4 rounded-lg text-gray-800 text-center"
                parentClassName="mr-2"
                propsInput={{
                  ...formHandler.getFieldProps("karyawan.pria"),
                  readOnly: true,
                }}
                error={
                  formHandler.touched.karyawan?.pria &&
                  !!formHandler.errors.karyawan?.pria
                }
                errorText={formHandler.errors.karyawan?.pria}
              />
              <TextField
                type="number"
                variant="solid"
                placeholder="Perempuan"
                className="bg-white p-4 rounded-lg text-gray-800 text-center"
                parentClassName="ml-2"
                propsInput={{
                  ...formHandler.getFieldProps("karyawan.wanita"),
                  readOnly: true,
                }}
                error={
                  formHandler.touched.karyawan?.wanita &&
                  !!formHandler.errors.karyawan?.wanita
                }
                errorText={formHandler.errors.karyawan?.wanita}
              />
            </div>
            <div className="font-semibold mt-4 mb-2">Aset dan Omzet</div>
            <TextField
              variant="solid"
              placeholder="Jumlah Aset (Rp)"
              className="bg-white p-4 rounded-lg text-gray-800"
              propsInput={{
                ...formHandler.getFieldProps("asset"),
                readOnly: true,
              }}
              error={formHandler.touched.asset && !!formHandler.errors.asset}
              errorText={formHandler.errors.asset}
            />
            <TextField
              variant="solid"
              placeholder="Jumlah Omzet pertahun (Rp)"
              className="bg-white p-4 rounded-lg text-gray-800"
              propsInput={{
                ...formHandler.getFieldProps("omset"),
                readOnly: true,
              }}
              error={formHandler.touched.omset && !!formHandler.errors.omset}
              errorText={formHandler.errors.omset}
            />
            <SelectField
              variant="solid"
              placeholder="Kriteria UMKM & Ekraf"
              className="bg-white p-4 rounded-lg text-gray-800"
              options={kriteria}
              value={formHandler.values.kriteria}
              onChange={(e) => {
                formHandler.setFieldTouched("kriteria");
                formHandler.setFieldValue("kriteria", e.target.value);
              }}
              error={
                formHandler.touched.kriteria && !!formHandler.errors.kriteria
              }
              errorText={formHandler.errors.kriteria}
            />
            <div className="font-medium text-[#CB043F] mb-4">
              {funding.statusBook === "A"
                ? "Selamat pengajuan Anda diterima!"
                : funding.statusBook === "R"
                ? "Mohon maaf Anda belum bisa diikutkan dalam program ini"
                : "Pengajuan pendanaan anda sedang menunggu persetujuan"}
            </div>

            <div className="font-semibold mt-6">Catatan dari penyelenggara</div>
            <div className="mt-4 bg-white p-4 rounded-lg text-gray-800">
              {funding.catatan || "Belum ada catatan"}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardFundingDetailHistory };
