import { Icon, MainLayout, TextField } from "components";
import { HeaderNav } from "./HeaderNav";
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { convertToWhatsAppURL } from "utils/convertWhatsappUrl";
import { getMitraDetailV1, getProduct } from "pages/landing/redux";
import { CardProduct } from "./CardProduct";

const DashboardUsahaDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<{
    businessId: string;
  }>();

  const { mitraDetailV1, products } = useSelector(
    (state: RootState) => state.landing
  );

  useEffect(() => {
    if (params.businessId) {
      dispatch(
        getMitraDetailV1({
          params: {
            mitraId: params.businessId,
          },
        })
      );
      dispatch(
        getProduct({
          params: {
            nama: "",
            limit: 12,
            mitraId: params.businessId,
          },
        })
      );
    }
  }, [params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative pb-10">
          <HeaderNav title="Detail Mitra" />
          <div className="px-6">
            <div className="bg-white rounded-lg p-6 mt-4">
              <div className="flex items-start">
                <img
                  src={
                    mitraDetailV1.foto.url ||
                    "/assets/images/placeholder-image.png"
                  }
                  alt="avatar"
                  className="w-12 h-12 object-cover rounded-full flex-shrink-0"
                />
                <div className="ml-4 flex-1">
                  <div className="font-semibold cursor-pointer">
                    {mitraDetailV1.namaMitra}
                  </div>
                  <div className="text-xs">
                    {mitraDetailV1.addressName.kecamatan},
                    {mitraDetailV1.addressName.kota}
                  </div>
                </div>
                <img
                  src="/assets/icon/shop.svg"
                  alt="shop icon"
                  className="w-5"
                />
              </div>
              <div className="flex flex-wrap my-4">
                {mitraDetailV1.links !== undefined
                  ? Object.keys(mitraDetailV1.links)?.map((item, index) => {
                      return mitraDetailV1.links[item] !== null ? (
                        <div className="p-2" key={index}>
                          <img
                            onClick={() => {
                              if (mitraDetailV1.links[item] !== "") {
                                window.location.href =
                                  mitraDetailV1.links[item];
                              }
                            }}
                            src={`/assets/marketplace/${item}.png`}
                            alt="marketplace"
                            className="w-12 cursor-pointer"
                          />
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
              <div className="flex flex-wrap md:justify-start mt-4">
                <div className="flex-1">
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${mitraDetailV1.location.coordinates[1]}%2C${mitraDetailV1.location?.coordinates[0]}`}
                    target="_blank"
                    className="whitespace-nowrap items-center border border-[#CB043F] text-[#CB043F] text-sm py-2 px-4 rounded flex"
                    rel="noreferrer"
                  >
                    <Icon
                      name="Send"
                      type="bold"
                      size="small"
                      color="#CB043F"
                      className="h-5 md:h-8"
                    />
                    <span className="ml-0 md:ml-2 text-sm md:text-md">
                      Menuju Lokasi
                    </span>
                  </a>
                </div>
                <div className="flex-1">
                  <a
                    href={convertToWhatsAppURL(
                      mitraDetailV1.user.noHandphone || ""
                    )}
                    target="_blank"
                    className="whitespace-nowrap items-center ml-1 md:ml-3 bg-[#CB043F] text-white text-sm py-2 px-4 rounded flex border border-[#CB043F]"
                    rel="noreferrer"
                  >
                    <Icon
                      name="Chat"
                      type="bold"
                      size="small"
                      color="#FFFFFF"
                      className="h-5 md:h-8"
                    />
                    <span className="ml-0 md:ml-2 text-sm md:text-md">
                      Chat Penjual
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="font-semibold cursor-pointer my-4">
              Produk Mitra
            </div>
            <TextField
              variant="solid"
              className="p-3 rounded-lg"
              placeholder="Pencarian"
              suffix="Search"
              padingBottom={0}
            />
          </div>
          <div className="flex flex-wrap px-4">
            {products.map((item, index) => (
              <div className="w-1/2 p-2" key={index}>
                <CardProduct product={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardUsahaDetail };
