import { Button, MainLayout, SelectField, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionTypes,
  applyFunding,
  getCategory,
  getFundingDetail,
  getMitraDetail,
} from "pages/users/redux";
import { RootState } from "setup";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardFundingDetail = () => {
  const dispatch = useDispatch();

  const params = useParams<{
    fundingId: string;
  }>();

  const funding = useSelector((state: RootState) => state.user.fundingDetail);
  const mitra = useSelector((state: RootState) => state.user.mitra);
  const categoryList = useSelector(
    (state: RootState) => state.user.categoryList
  );

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.APPLY_FUNDING)
  );

  const kriteria = [
    { label: "Usaha Mikro", value: "mikro" },
    { label: "Usaha Kecil", value: "kecil" },
    { label: "Usaha Menengah", value: "menengah" },
  ];

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      fundingId: params.fundingId,
      mitraId: "",
      nib: "",
      sektorUsaha: [],
      karyawan: {
        pria: 0,
        wanita: 0,
      },
      asset: 0,
      omset: 0,
      kriteria: "",
    },
    validationSchema: Yup.object({
      mitraId: Yup.string().required("Anda harus memilih mitra"),
      nib: Yup.string(),
      sektorUsaha: Yup.array()
        .of(Yup.string().required("Sektor Usaha wajib diisi"))
        .min(1, "Sektor Usaha todak boleh kosong")
        .required("Sektor Usaha wajib diisi"),
      karyawan: Yup.object().shape({
        pria: Yup.number()
          .min(0, "Jumlah karyawan pria tidak boleh kurang dari 0")
          .required("Jumlah karyawan pria harus diisi"),
        wanita: Yup.number()
          .min(0, "Jumlah karyawan wanita tidak boleh kurang dari 0")
          .required("Jumlah karyawan wanita harus diisi"),
      }),
      asset: Yup.number()
        .min(0, "Nilai asset tidak boleh kurang dari 0")
        .required("Nilai asset harus diisi"),
      omset: Yup.number()
        .min(0, "Nilai omset tidak boleh kurang dari 0")
        .required("Nilai omset harus diisi"),
      kriteria: Yup.string().required("Kriteria harus diisi"),
    }),
    onSubmit(values) {
      dispatch(
        applyFunding({
          params: {
            ...values,
            asset: +values.asset,
            nib: values.nib.toString(),
            omset: +values.omset,
          },
          onSuccess(e) {
            toast.success("Berhasil mengajukan pendanaan");
          },
          onFailure(e) {
            toast.error("Gagal mengajukan pendanaan");
          },
        })
      );
    },
  });

  useEffect(() => {
    formHandler.setFieldTouched("nib");
    formHandler.setFieldValue("nib", mitra.nib);
    formHandler.setFieldTouched("sektorUsaha[0]");
    formHandler.setFieldValue("sektorUsaha[0]", mitra.klasifikasiEkraf[0]);
  }, [mitra]);

  useEffect(() => {
    dispatch(getCategory());
  }, []);

  useEffect(() => {
    dispatch(
      getFundingDetail({
        params: {
          fundingId: params.fundingId,
        },
      })
    );
  }, [params]);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Detail Penawaran" />
          <img
            src={
              funding.program.banner || "/assets/images/placeholder-image.png"
            }
            alt="pelatihan"
            className="w-full"
          />
          <div className="p-6 transform -translate-y-24">
            <div className="mt-6 bg-white p-4 rounded-lg text-gray-800">
              <div className="mb-4 text-lg text-gray-800 font-medium">
                {funding.program.title}
              </div>
              <table className="w-full">
                <tbody>
                  <tr>
                    <td>Penyelenggara</td>
                    <td>:&nbsp;{funding.program.penyelenggara}</td>
                  </tr>
                  <tr>
                    <td>Deadline</td>
                    <td>:&nbsp;{funding.program.deadline}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Persyaratan</td>
                  </tr>
                  <tr>
                    <td>
                      {funding.program.persyaratan.map((item, index) => (
                        <div className="pl-4" key={index}>
                          {index + 1}. {item}
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="my-6">
              <SelectField
                className="bg-white rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800"
                placeholder="Pilih Usaha Saya"
                value={formHandler.values.mitraId}
                onChange={(e) => {
                  formHandler.setFieldTouched("mitraId");
                  formHandler.setFieldValue("mitraId", e.target.value);

                  if (e.target.value !== "") {
                    dispatch(
                      getMitraDetail({
                        params: {
                          businessId: e.target.value,
                        },
                      })
                    );
                  }
                }}
                options={funding.available.map((e) => ({
                  label: e.namaMitra,
                  value: e.id,
                }))}
                error={
                  formHandler.touched.mitraId && !!formHandler.errors.mitraId
                }
                errorText={formHandler.errors.mitraId}
              />
              {formHandler.values.mitraId !== "" && (
                <>
                  <TextField
                    className="rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800  mb-4"
                    type="number"
                    variant="solid"
                    placeholder="NIB"
                    parentClassName="mr-2"
                    propsInput={{ ...formHandler.getFieldProps("nib") }}
                    error={formHandler.touched.nib && !!formHandler.errors.nib}
                    errorText={formHandler.errors.nib}
                  />
                  <div className="font-semibold mb-2">Pilih Sektor Usaha</div>
                  <SelectField
                    className="bg-white rounded-lg p-4 w-full border-0 cursor-pointer text-gray-800"
                    placeholder="Pilih Sektor Usaha"
                    value={formHandler.values.sektorUsaha[0]}
                    onChange={(e) => {
                      formHandler.setFieldTouched("sektorUsaha[0]");
                      formHandler.setFieldValue(
                        "sektorUsaha[0]",
                        e.target.value
                      );
                    }}
                    options={categoryList.map((e) => ({
                      label: e.name,
                      value: e._id,
                    }))}
                    error={
                      formHandler.touched.sektorUsaha &&
                      !!formHandler.errors.sektorUsaha
                    }
                    errorText={`${formHandler.errors.sektorUsaha}`}
                  />
                </>
              )}
            </div>
            <div className="font-semibold mb-2">Jumlah Karyawan</div>
            <div className="flex">
              <TextField
                type="number"
                variant="solid"
                placeholder="Laki - laki"
                className="bg-white p-4 rounded-lg text-gray-800 text-center"
                parentClassName="mr-2"
                propsInput={{ ...formHandler.getFieldProps("karyawan.pria") }}
                error={
                  formHandler.touched.karyawan?.pria &&
                  !!formHandler.errors.karyawan?.pria
                }
                errorText={formHandler.errors.karyawan?.pria}
              />
              <TextField
                type="number"
                variant="solid"
                placeholder="Perempuan"
                className="bg-white p-4 rounded-lg text-gray-800 text-center"
                parentClassName="ml-2"
                propsInput={{ ...formHandler.getFieldProps("karyawan.wanita") }}
                error={
                  formHandler.touched.karyawan?.wanita &&
                  !!formHandler.errors.karyawan?.wanita
                }
                errorText={formHandler.errors.karyawan?.wanita}
              />
            </div>
            <div className="font-semibold mt-4 mb-2">Aset dan Omzet</div>
            <TextField
              variant="solid"
              placeholder="Jumlah Aset (Rp)"
              className="bg-white p-4 rounded-lg text-gray-800"
              propsInput={{ ...formHandler.getFieldProps("asset") }}
              error={formHandler.touched.asset && !!formHandler.errors.asset}
              errorText={formHandler.errors.asset}
            />
            <TextField
              variant="solid"
              placeholder="Jumlah Omzet pertahun (Rp)"
              className="bg-white p-4 rounded-lg text-gray-800"
              propsInput={{ ...formHandler.getFieldProps("omset") }}
              error={formHandler.touched.omset && !!formHandler.errors.omset}
              errorText={formHandler.errors.omset}
            />
            <SelectField
              variant="solid"
              placeholder="Kriteria UMKM & Ekraf"
              className="bg-white p-4 rounded-lg text-gray-800"
              options={kriteria}
              value={formHandler.values.kriteria}
              onChange={(e) => {
                formHandler.setFieldTouched("kriteria");
                formHandler.setFieldValue("kriteria", e.target.value);
              }}
              error={
                formHandler.touched.kriteria && !!formHandler.errors.kriteria
              }
              errorText={formHandler.errors.kriteria}
            />
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoading}
              disabled={isLoading}
            >
              Ajukan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardFundingDetail };
