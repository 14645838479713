const prefixAction = `LANDING`;

export const ActionTypes = {
  GET_SUMMARY: `${prefixAction}_GET_SUMMARY`,
  GET_MITRA: `${prefixAction}_GET_MITRA`,
  GET_MITRA_DETAIL: `${prefixAction}_GET_MITRA_DETAIL`,
  GET_PRODUCT: `${prefixAction}_GET_PRODUCT`,
  GET_PRODUCT_DETAIL: `${prefixAction}_GET_PRODUCT_DETAIL`,
  GET_CONFIG: `${prefixAction}_GET_CONFIG`,
  GET_ARTICLE: `${prefixAction}_GET_ARTICLE`,
  GET_ARTICLE_DETAIL: `${prefixAction}_GET_ARTICLE_DETAIL`,
  // v1
  GET_MITRA_V1: `${prefixAction}_GET_MITRA_V1`,
  GET_MITRA_DETAIL_V1: `${prefixAction}_GET_MITRA_DETAIL_V1`,
  GET_PRODUCT_V1: `${prefixAction}_GET_PRODUCT_V1`,
};

export interface ISummary {
  mitra: number;
  product: number;
  omset: number;
  user: number;
}

export interface IConfig {
  hero: string;
  heroLink: string;
  banners: Banner[];
  contacts: Contact[];
}

export interface Banner {
  _id: string;
  foto: string;
}

export interface Contact {
  name: string;
  phone: string;
  _id: string;
}

export interface IProduct {
  _id: string;
  harga: number;
  mitraId: string;
  userId: string;
  namaMitra: string;
  namaProduk: string;
  fotoProduk: any;
  distance: string;
}

export interface IMitra {
  _id: string;
  namaMitra: string;
  foto: any;
  distance: string;
}

export interface IProductDetail {
  _id: string;
  namaProduct: string;
  harga: number;
  nominalSatuan: number;
  beratSatuan: string;
  deskripsi: string;
  mitra: Mitra;
  fotoProduk: any[];
  isFavorite: boolean;
}

export interface Mitra {
  namaMitra: string;
  id: string;
  foto: any;
  kota: string;
  kecamatan: string;
  kelurahan: string;
  location: Location;
  phoneNumber: string;
  userId: string;
  isVerified: boolean;
}

export interface Location {
  type: string;
  coordinates: number[];
}

export interface IMitraDetail {
  _id: string;
  namaMitra: string;
  links: Links;
  waktuOperasional: WaktuOperasional;
  foto: string;
  kelurahan: string;
  pengizinUsaha: string;
  location: Location;
  rejectReason: any;
  addressName: AddressName;
  phoneNumber: string;
  isVerified: boolean;
  kota: string;
  kecamatan: string;
}

export interface Links {
  [key: string]: any;
}

export interface WaktuOperasional {
  senin: Senin;
  selasa: Selasa;
  rabu: Rabu;
  kamis: Kamis;
  jumat: Jumat;
  sabtu: Sabtu;
  minggu: Minggu;
}

export interface Senin {
  buka: string;
  tutup: string;
}

export interface Selasa {
  buka: string;
  tutup: string;
}

export interface Rabu {
  buka: string;
  tutup: string;
}

export interface Kamis {
  buka: string;
  tutup: string;
}

export interface Jumat {
  buka: string;
  tutup: string;
}

export interface Sabtu {
  buka: string;
  tutup: string;
}

export interface Minggu {
  buka: string;
  tutup: string;
}

export interface AddressName {
  kota: string;
  kecamatan: string;
  kelurahan: string;
  _id: string;
}

export interface IMitraDetailV1 {
  namaMitra: string;
  namaBadanUsaha: string;
  links: {
    [key: string]: string;
  };
  waktuOperasional: WaktuOperasionalV1;
  foto: FotoV1;
  alamat: string;
  kota: number;
  kecamatan: number;
  kelurahan: number;
  deskripsi: string;
  klasifikasiEkraf: KlasifikasiEkrafV1[];
  location: LocationV1;
  addressName: AddressNameV1;
  user: UserV1;
  id: string;
  isVerified: boolean;
}

export interface WaktuOperasionalV1 {
  senin: Schedule;
  selasa: Schedule;
  rabu: Schedule;
  kamis: Schedule;
  jumat: Schedule;
  sabtu: Schedule;
  minggu: Schedule;
}

export interface Schedule {
  buka: string;
  tutup: string;
}

export interface FotoV1 {
  id: string;
  url: any;
}

export interface KlasifikasiEkrafV1 {
  name: string;
  id: string;
}

export interface LocationV1 {
  type: string;
  coordinates: number[];
}

export interface AddressNameV1 {
  kota: string;
  kecamatan: string;
  kelurahan: string;
}

export interface UserV1 {
  nama: string;
  foto: string;
  noHandphone: string;
  id: string;
}

export interface IMitraDetailV1Response {
  payload: {
    mitra: IMitraDetailV1;
  };
}

export interface IGetSummaryResponse {
  payload: {
    summary: ISummary;
  };
}

export interface IGetConfigResponse {
  payload: {
    incoming: IConfig;
  };
}

export interface IGetProductResponse {
  payload: IProduct[];
}
export interface IGetMitraResponse {
  payload: IMitra[];
}

export interface IGetProductDetailResponse {
  payload: IProductDetail;
}

export interface IGetMitraDetailResponse {
  payload: IMitraDetail;
}

export interface IInputPayload {
  params: {
    [key: string]: any;
  };
}

export interface IArticle {
  title: string;
  preview: Preview;
  id: string;
}

export interface Preview {
  image: string;
}

export interface IArticleResponse {
  payload: {
    articles: IArticle[];
  };
}

export interface IArticleDetail {
  title: string;
  content: string;
  preview: Preview;
  id: string;
}

export interface IArticleDetailResponse {
  payload: {
    article: IArticleDetail;
  };
}

export interface IMitraListV1 {
  namaMitra: string;
  foto?: IFoto;
  status: string;
  id: string;
  distance: string;
}

export interface IFoto {
  id: string;
  url: any;
}

export interface IMitraListV1Response {
  payload: {
    metadata: IMitraMetadata;
    mitra: IMitraListV1[];
  };
}

export interface IMitraMetadata {
  count: number;
  pageNumber: number;
  limit: number;
  pageTotal: number;
  filter: any;
  sort: any;
}

export interface IProductV1Response {
  message: string;
  payload: {
    products: IProductV1[];
    metadata: IProductMetadata;
  };
}

export interface IProductV1 {
  harga: number;
  id: string;
  namaProduk: string;
  statusVerify?: string;
  mitraId: string;
  userId: string;
  namaMitra: string;
  fotoProduk?: string;
  distance: string;
  favorite: number;
}

export interface IProductMetadata {
  count: number;
  pageNumber: number;
  limit: number;
  pageTotal: number;
  filter: any;
  sort: any;
  location: ProductV1Location;
}

export interface ProductV1Location {
  long: number;
  lat: number;
}

export interface IlandingState {
  summary: ISummary;
  config: IConfig;
  products: IProduct[];
  mitras: IMitra[];
  product: IProductDetail;
  mitra: IMitraDetail;
  articles: IArticle[];
  article: IArticleDetail;
  // v1
  mitraMeta: IMitraMetadata;
  productMeta: IProductMetadata;
  productListV1: IProductV1[];
  mitraListV1: IMitraListV1[];
  mitraDetailV1: IMitraDetailV1;
}
