import { Button, TextField } from "components";
import { PATH } from "config";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ActionTypes, forgotPassword, resetPassword } from "../redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.NEW_PASSWORD)
  );

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const formHandler = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      dispatch(
        resetPassword({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mereset password");
            navigate(PATH.LOGIN);
          },
          onFailure(e) {
            toast.error("Gagal mereset password");
          },
        })
      );
    },
  });

  return (
    <div className="min-h-screen  bg-[#F6F7FB]">
      <div className="w-full mx-auto md:max-w-md flex flex-col px-8 py-8 items-center justify-center bg-white min-h-screen">
        <img src="/assets/logo/logo.png" alt="logo tabe" className="w-32" />
        <div className="text-xl mt-4 mb-8 font-bold">Reset Password</div>
        <TextField
          type="password"
          parentClassName="mb-6"
          prefix="Lock"
          placeholder="Password Baru"
          propsInput={{ ...formHandler.getFieldProps("newPassword") }}
          error={
            !!formHandler.touched.newPassword &&
            !!formHandler.errors.newPassword
          }
          errorText={`${formHandler.errors.newPassword}`}
        />
        <TextField
          type="password"
          parentClassName="mb-6"
          prefix="Lock"
          placeholder="Ulangi Password Baru"
          propsInput={{ ...formHandler.getFieldProps("confirmPassword") }}
          error={
            !!formHandler.touched.confirmPassword &&
            !!formHandler.errors.confirmPassword
          }
          errorText={`${formHandler.errors.confirmPassword}`}
        />
        <Button
          color="failure"
          type="submit"
          size="sm"
          full
          loading={isLoading}
          disabled={isLoading}
          onClick={() => formHandler.submitForm()}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};
export default ResetPassword;
