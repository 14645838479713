import { createReducer } from "@reduxjs/toolkit";
import { IAuthState, IUser, getInfo, setLogin } from ".";

export const initialState: IAuthState = {
  authInfo: {
    token: "",
    _id: "",
    nama: "",
    nik: "",
    tempatLahir: "",
    tanggalLahir: "",
    foto: "",
    email: "",
    noHandphone: "",
    statusVerify: "",
    gender: "",
    role: "",
  },
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLogin, (state, action) => {
      localStorage.setItem(
        process.env.REACT_APP_TOKEN_NAME ?? "",
        action.payload.payload.token
      );
      state.authInfo = {
        ...action.payload.payload,
        role: "user",
      };
    })
    .addCase(getInfo, (state, action) => {
      const token = localStorage.getItem(
        process.env.REACT_APP_TOKEN_NAME ?? ""
      );
      try {
        const result: IUser = JSON.parse(atob(token?.split(".")[1] ?? "{}"));
        state.authInfo = result;
      } catch (e) {
        state.authInfo = {
          token: "",
          _id: "",
          nama: "",
          nik: "",
          tempatLahir: "",
          tanggalLahir: "",
          foto: "",
          email: "",
          noHandphone: "",
          statusVerify: "",
          gender: "",
          role: "",
        };
      }
    });
});

export default authReducer;
