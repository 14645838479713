import { takeLatest } from "redux-saga/effects";
import sagaRequest from "setup/saga/sagaRequest";
import { login, ActionTypes, ILoginAction } from ".";
import { IActionWithPayload } from "setup/redux/redux.model";

const prefixEndpoint = "/admin/v1/auth";

function* loginSaga(action: IActionWithPayload<ILoginAction>) {
  yield sagaRequest({
    isPublic: true,
    requestPayload: {
      url: `${prefixEndpoint}/login`,
      data: action.payload.data ?? {},
      method: "POST",
    },
    actionType: ActionTypes.LOGIN,
    onSuccess: action.payload.onSuccess,
    onFailure: action.payload.onFailure,
  });
}
export default function* authSaga() {
  yield takeLatest(login.type, loginSaga);
}
