import { Button, MainLayout, TextField } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ActionTypes, addAspiration } from "pages/users/redux";
import { toast } from "react-hot-toast";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const DashboardAspiration = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.ADD_ASPIRATION)
  );

  const formHandler = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Message required"),
    }),
    onSubmit(values) {
      dispatch(
        addAspiration({
          params: values,
          onSuccess(e) {
            toast.success("Berhasil mengirim aspirasi");
          },
          onFailure(e) {
            toast.error("Gagal mengirim aspirasi");
          },
        })
      );
    },
  });

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Jaring Aspirasi" />
          <div className="p-6">
            <div className="font-medium text-center mb-4">
              Beri kami masukan dan sampaikan aspirasi Anda pada kami agar
              membantu kami lebih baik lagi.
            </div>
            <TextField
              className="rounded-lg p-4"
              variant="solid"
              placeholder="Tulis aspirasi Anda"
              multiLine={5}
              propsInput={{ ...formHandler.getFieldProps("message") }}
              error={
                !!formHandler.touched.message && !!formHandler.errors.message
              }
              errorText={`${formHandler.errors.message}`}
            />
            <Button
              size="xl"
              full
              color="theme"
              onClick={() => formHandler.submitForm()}
              loading={isLoading}
              disabled={isLoading}
            >
              Simpan
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardAspiration };
