import axios from "axios";

export interface IApiRequest {
  url: string;
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  data: any;
  isDataPayload?: boolean;
}

const requestPublic = ({
  url,
  method,
  data,
  isDataPayload = false,
}: IApiRequest) => {
  const baseURL = process.env.REACT_APP_API_URL || "";

  const dataOrParams =
    !isDataPayload && ["get", "delete"].includes(method.toLowerCase())
      ? "params"
      : "data";

  return axios.request({
    url,
    baseURL: baseURL,
    method,
    [dataOrParams]: data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export { requestPublic };
