import { Icon, MainLayout } from "components";
import { HeaderNav } from "../dashboard-usaha/HeaderNav";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, readNotification } from "pages/users/redux";
import { RootState } from "setup";
import moment from "moment";
import "moment/locale/id"; // Menggunakan bahasa Indonesia
import { toast } from "react-hot-toast";

const DashboardNotification = () => {
  const dispatch = useDispatch();

  const notification = useSelector(
    (state: RootState) => state.user.notification
  );

  const handleRead = useCallback((id: string) => {
    dispatch(
      readNotification({
        params: {
          notificationId: id,
        },
        onSuccess(e) {
          toast.success("Notifikasi telah di hapus");
          dispatch(getNotification());
        },
        onFailure(e) {
          toast.error("Terjadi Kesalahan");
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <HeaderNav title="Notifikasi" />
          <div className="p-6">
            {notification.map((item, index) => (
              <div
                className={[
                  "p-4 rounded-lg mb-4",
                  item.isReaded ? "bg-gray-200" : "bg-white",
                ].join(" ")}
              >
                <div className="flex justify-between">
                  <div className="text-lg font-medium">{item.title}</div>
                  <span
                    className="cursor-pointer"
                    onClick={() => handleRead(item.id)}
                  >
                    <Icon
                      name="Delete"
                      size="x-small"
                      type="bold"
                      color="#CB043F"
                    />
                  </span>
                </div>
                <div className="text-gray-800">{item.body}</div>
                <div className="flex justify-end text-xs text-gray-400">
                  {moment(item.createdAt).locale("id").fromNow()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardNotification };
