import { PATH } from "config";
import { IProduct } from "pages/landing/redux";
import { IFavorite } from "pages/users/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardProduct: FC<{ product: IFavorite }> = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div
      className="p-3 rounded-lg bg-[#FCFDFE] border border-[#F2F2F2] cursor-pointer"
      onClick={() => navigate(`${PATH.USER_PRODUCT}/${product.productId}`)}
    >
      <img
        src={product.fotoProduk || "/assets/images/placeholder-image.png"}
        alt="card thumbnail"
        className="w-full h-32 object-cover rounded"
      />
      <div className="text-[#3D3D3D] text-md font-semibold my-1.5">
        {product.namaProduk}
      </div>
      <div className="flex items-center mb-2.5">
        <img src="/assets/icon/market.svg" alt="mitra" className="w-5" />
        <div className="text-[#AFAEAE] text-xs ellipsis ml-2">
          {product.mitra}
        </div>
      </div>
    </div>
  );
};

export { CardProduct };
