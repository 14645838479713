import * as Yup from "yup";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes, LoginFormValues, login } from "../redux";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "components";
import { PATH } from "config";
import { useFormik } from "formik";
import { createLoadingSelector } from "setup/redux/loading.toolkit";

const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(createLoadingSelector(ActionTypes.LOGIN));

  const initialValues: LoginFormValues = {
    nik: "",
    password: "",
  };

  const validationSchema = Yup.object({
    nik: Yup.string().required("NIK is Required"),
    password: Yup.string().required("Password is Required"),
  });

  const formHandler = useFormik({
    initialValues,
    validationSchema,
    onSubmit(values) {
      dispatch(
        login({
          data: {
            nik: `${values.nik}`,
            password: values.password,
          },
          onSuccess() {
            toast.success("Login Successfully");
            navigate("/user");
          },
          onFailure(e) {
            if (e?.msg) {
              toast.error(e?.msg);
            } else {
              toast.error("Login Failed");
            }
          },
        })
      );
    },
  });

  return (
    <div className="min-h-screen bg-[#F6F7FB]">
      <div className="w-full mx-auto md:max-w-md flex flex-col px-8 py-8 items-center justify-center bg-white min-h-screen">
        <img
          src="/assets/logo/logo.png"
          alt="logo tabe"
          className="w-32 cursor-pointer"
          onClick={() => navigate("/", { replace: true })}
        />
        <div className="text-xl mt-4 mb-8 font-bold">Login</div>
        <TextField
          type="number"
          parentClassName="mb-6"
          placeholder="Nomor NIK"
          prefix="ShieldDone"
          propsInput={{
            ...formHandler.getFieldProps("nik"),
            onKeyUp(e: any) {
              if (e.keyCode === 13) {
                formHandler.submitForm();
              }
            },
          }}
          error={formHandler.touched.nik && !!formHandler.errors.nik}
          errorText={`${formHandler.errors.nik}`}
        />
        <TextField
          type="password"
          parentClassName="mb-6"
          placeholder="Password"
          prefix="Lock"
          toggle
          propsInput={{
            ...formHandler.getFieldProps("password"),
            onKeyUp(e: any) {
              if (e.keyCode === 13) {
                formHandler.submitForm();
              }
            },
          }}
          error={formHandler.touched.password && !!formHandler.errors.password}
          errorText={`${formHandler.errors.password}`}
        />
        <Button
          color="failure"
          size="sm"
          full
          loading={loading}
          disabled={loading}
          onClick={() => formHandler.submitForm()}
        >
          Masuk
        </Button>
        <div
          className="mt-6 w-full text-[#CB043F] text-right cursor-pointer text-sm"
          onClick={() => navigate(PATH.FORGOT_PASSWORD)}
        >
          Lupa Password
        </div>
        <div className="mt-12 text-center w-full text-sm">
          Belum punya akun?{" "}
          <span
            className="text-[#CB043F] font-bold cursor-pointer "
            onClick={() => navigate(PATH.REGISTER)}
          >
            Register
          </span>{" "}
          di sini
        </div>
      </div>
    </div>
  );
};

export default Login;
