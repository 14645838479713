import { FC, useMemo, useState } from "react";
import { Dialog, Dropdown, Icon } from "components";
import { INavbarProps } from "./Navbar.model";
import { Button } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "pages/admin/components/auth/redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { RootState } from "setup";

const Navbar: FC<INavbarProps> = ({ className, onHide }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);

  const authInfo = useSelector((state: RootState) => state.auth.authInfo);

  const pathDashboard = useMemo(() => {
    if (authInfo.role === "admin") {
      return PATH.DASHBOARD_ANALYTICS;
    } else if (authInfo.role === "root") {
      return PATH.DASHBOARD_PIMPINAN_ANALYTICS;
    }
    return PATH.DASHBOARD_ANALYTICS;
  }, [authInfo]);

  const pathSettingProfile = useMemo(() => {
    if (authInfo.role === "admin") {
      return PATH.DASHBOARD_SETTINGS_PROFILE;
    } else if (authInfo.role === "root") {
      return PATH.DASHBOARD_PIMPINAN_SETTINGS_PROFILE;
    } else if (authInfo.role === "adminRegional") {
      return PATH.DASHBOARD_REGIONAL_SETTINGS_PROFILE;
    }
    return PATH.DASHBOARD_SETTINGS_PROFILE;
  }, [authInfo]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Dialog
        onClose={() => setShow(false)}
        size="sm"
        show={show}
        title="Keluar"
      >
        <div className="flex flex-col items-center w-full">
          <img
            src="/assets/animation/91964-question.gif"
            alt="illustration"
            className="w-48"
          />
          <div className="text-center ">Anda Yakin Ingin Keluar?</div>
          <div className="mt-4 flex justify-center">
            <div>
              <Button
                color="light"
                onClick={() => setShow(false)}
                className="mr-2"
              >
                Batalkan
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                onClick={() => handleLogout()}
                className="ml-2 whitespace-nowrap"
              >
                Ya, saya ingin
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
      <nav
        className={`flex flex-row items-center justify-between p-4 bg-gray-50 border-l border-gray-100 ${className}`}
      >
        <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <i className="fa fa-bars cursor-pointer" onClick={() => onHide()}></i>

          <Dropdown>
            <div className="transition-all text-sm ease-nav-brand text-slate-500 flex items-center hover:bg-gray-100 p-2 pr-4 rounded-full cursor-pointer">
              <div className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-600 text-white text-2xl font-bold">
                A
              </div>
              <div className="flex-1 pl-3">
                <div className="font-bold">Admin Provinsi Kalteng</div>
                <div className="font-light text-xs">
                  {authInfo.email} - {authInfo.role}
                </div>
              </div>
              <div className="pl-4">
                <i className="fa fa-caret-down"></i>
              </div>
            </div>
            <div>
              <div
                className="flex items-center text-sm px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                onClick={() => {
                  navigate(pathDashboard);
                }}
              >
                <Icon
                  name="Graph"
                  type="light"
                  size="x-small"
                  className="mr-2"
                />
                Dashboard
              </div>
              <div
                className="flex items-center text-sm px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                onClick={() => {
                  navigate(pathSettingProfile);
                }}
              >
                <Icon
                  name="Setting"
                  type="light"
                  size="x-small"
                  className="mr-2"
                />
                Profile Setting
              </div>
              <div
                className="flex items-center text-sm px-4 py-3 text-gray-700 hover:bg-gray-100 cursor-pointer whitespace-nowrap"
                onClick={() => setShow(true)}
              >
                <Icon
                  name="Logout"
                  type="light"
                  size="x-small"
                  className="mr-2"
                />
                Logout
              </div>
            </div>
          </Dropdown>
        </div>
      </nav>
    </>
  );
};

export { Navbar };
