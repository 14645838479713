import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMap,
  useMapEvent,
  Rectangle,
  Marker,
  ZoomControl,
} from "react-leaflet";
import { useEventHandlers, useLeafletContext } from "@react-leaflet/core";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { IMitraMaps, getCategory, getMitraMaps } from "pages/admin/redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { DashboardMapDetail } from "./DashboardMapDetail";
import { SelectField } from "components";

export interface ILatLng {
  lat: number;
  lng: number;
}

const PanTo: FC<{
  zoom: number;
  lat: number;
  lng: number;
}> = ({ zoom, lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
    map.setZoom(zoom);
    // eslint-disable-next-line
  }, [lat, lng]);
  return null;
};

// Classes used by Leaflet to position controls
const POSITION_CLASSES: any = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

const BOUNDS_STYLE = { weight: 1 };

const MinimapBounds: FC<{
  parentMap: any;
  zoom: any;
}> = ({ parentMap, zoom }) => {
  const minimap = useMap();
  const context = useLeafletContext();

  // Clicking a point on the minimap sets the parent's map center
  const onClick = useCallback(
    (e: any) => {
      parentMap.setView(e.latlng, parentMap.getZoom());
    },
    [parentMap]
  );
  useMapEvent("click", onClick);

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds());
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds());
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom);
  }, [minimap, parentMap, zoom]);

  // Listen to events on the parent map
  const handlers = useMemo(
    () => ({ move: onChange, zoom: onChange }),
    // eslint-disable-next-line
    []
  );
  useEventHandlers({ instance: parentMap, context }, handlers);

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />;
};

const MinimapControl: FC<{
  position: any;
  zoom: any;
}> = ({ position, zoom }) => {
  const parentMap = useMap();
  const mapZoom = zoom || 0;

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: 80, width: 80 }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    // eslint-disable-next-line
    []
  );

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  );
};

function DashboardMaps() {
  const dispatch = useDispatch();

  const [center, setCenter] = useState<ILatLng>({
    lat: -2.196525,
    lng: 115.6303755,
  });
  const [detailMitra, setDetailMitra] = useState<{
    id: string;
    show: boolean;
  }>({
    id: "",
    show: false,
  });
  const [zoom, setZoom] = useState<number>(8);
  const [show, setShow] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");
  const [categories, setCategories] = useState<string>("");

  const mitra = useSelector((state: RootState) => state.admin.mitraMaps);
  const category = useSelector((state: RootState) => state.admin.category);

  const handleSearch = () => {
    setSearch(input);
    setShow(true);
  };

  const loading = false;
  let markerRef = useRef([]);

  const icon = L.icon({
    iconUrl: "/mapping_icon.png",
    iconSize: [28, 38],
  });

  useEffect(() => {
    dispatch(
      getMitraMaps({
        params: {
          nama: search ?? "",
          classification: categories ?? "",
        },
      })
    );
    // eslint-disable-next-line
  }, [search, categories]);

  useEffect(() => {
    dispatch(getCategory({}));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div
        className={
          "fixed top-0 left-0 w-96 h-screen bg-white shadow " +
          (show ? "" : "hidden")
        }
        style={{ zIndex: 9999999 }}
      >
        <div className="bg-gradient-to-r from-[#CB043F] to-[#90022D] text-white">
          <div className="p-4 flex justify-between">
            <div className="text-white fw-bolder">Hasil ({mitra.length})</div>
            <i
              className="fa fa-times text-white cursor-pointer"
              onClick={() => setShow(false)}
            ></i>
          </div>
        </div>
        <div className="overflow-y-scroll h-full">
          {mitra.map((item, i) => (
            <div
              key={i}
              className="flex items-start cursor-pointer p-3"
              onClick={() => {
                setCenter({
                  lat: item.location.coordinates[1],
                  lng: item.location.coordinates[0],
                });
                setZoom(15);
                // setDetailMitra({ id: item.id, show: true });
                // setShow(false);
              }}
            >
              <div className="flex-1 ml-4 border-b border-gray-50 py-2">
                <div className="text-md font-medium text-gray-700 catpialize">
                  {item.namaMitra}
                </div>
                <div className="text-xs text-gray-400">{item.kota}</div>
              </div>
              <img
                src={item?.foto?.url ?? "/assets/images/placeholder-image.png"}
                alt="preview"
                className="w-32 h-24 object-cover rounded"
              />
            </div>
          ))}
          <div className="fixed bottom-0 left-32 w-32">
            <SelectField
              className="w-full p-3 rounded-full shadow"
              options={[
                { label: "Kategori", value: "" },
                ...category.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
              ]}
              value={categories}
              onChange={(e) => {
                setCategories(e.target.value);
                setShow(true);
              }}
              required
            />
          </div>
        </div>
      </div>
      <div
        className={[
          "fixed top-0 left-0 pl-3 pt-3",
          detailMitra.show && "hidden",
        ].join(" ")}
        style={{ zIndex: 999999 }}
      >
        <div className="flex flex-row">
          <div className="relative mr-4 w-64 flex-shrink-1">
            <input
              className="p-3 pr-12 rounded w-full"
              placeholder="Cari Usaha"
              onChange={(e) => setInput(e.target.value)}
              onKeyUp={(e) => (e.keyCode === 13 ? handleSearch() : null)}
            />
            <i
              onClick={handleSearch}
              className="absolute top-0 p-4 text-gray-600 right-0 fa fa-search cursor-pointer"
            ></i>
          </div>
          <div className="w-32">
            <SelectField
              className="w-full p-3"
              options={[
                { label: "Kategori", value: "" },
                ...category.map((e) => ({
                  label: e.name,
                  value: e.id,
                })),
              ]}
              value={categories}
              onChange={(e) => {
                setCategories(e.target.value);
                setShow(true);
              }}
              required
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row">
        {detailMitra.show && (
          <DashboardMapDetail
            id={detailMitra.id}
            setDetailMitra={setDetailMitra}
          />
        )}
        <div className="flex-1">
          <MapContainer
            className="z-0"
            style={{ height: "100vh", width: "100%" }}
            center={center}
            zoom={zoom}
            zoomControl={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <PanTo zoom={zoom} lat={center.lat} lng={center.lng} />
            {!loading
              ? mitra.map((item: IMitraMaps, i) => (
                  <Marker
                    key={i}
                    ref={(el) => ((markerRef as any)[item.id] = el)}
                    position={{
                      lat: item.location.coordinates[1],
                      lng: item.location.coordinates[0],
                    }}
                    icon={icon}
                    autoPan={true}
                    eventHandlers={{
                      click: (e) => {
                        // console.log("marker clicked", e);
                        setDetailMitra({ id: item.id, show: true });
                        setShow(false);
                      },
                    }}
                  ></Marker>
                ))
              : null}
            <ZoomControl position="bottomright" />
            <MinimapControl zoom={true} position="topright" />
          </MapContainer>
        </div>
      </div>
    </>
  );
}

export { DashboardMaps };
