import {
  Button,
  Icon,
  MainLayout,
  TableComponent,
  TextField,
} from "components";
import { ActionTypes, IMitra, exportMitra, getMitra } from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { DialogRejectMitra } from "./DialogRejectMitra";
import { DialogApprovalMitra } from "./DialogApprovalMitra";
import { toast } from "react-hot-toast";
import { exportBase64ToExcel } from "utils/exportBase64ToExcel";

interface IExportExcel {
  payload: {
    fileName: string;
    base64: string;
  };
}

export const DashboardMitra: FC<{
  isVerify?: boolean;
}> = ({ isVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector(ActionTypes.GET_MITRA));
  const [approveDialog, setApproveDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });
  const [rejectDialog, setRejectDialog] = useState<{
    show: boolean;
    data?: any;
  }>({
    show: false,
  });

  const columns: any = [
    {
      header: "Foto",
      field: "foto",
      rowRender: (row: IMitra) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row?.foto?.url ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Nama Mitra",
      field: "namaMitra",
    },
    {
      header: "Pemilik Usaha",
      field: "namaMitra",
      rowRender: (row: IMitra) => {
        return <>{row.user.nama}</>;
      },
    },
    {
      header: "Status",
      field: "status",
      exportRender: (item: any) => (item.status === "A" ? "Active" : "Waiting"),
      rowRender: (row: IMitra) => {
        return (
          <div className="whitespace-nowrap">
            {row.status === "A" ? "✓ Active" : "Waiting"}
          </div>
        );
      },
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: IMitra) => {
        return (
          <>
            {isVerify ? (
              <Button
                color="light"
                onClick={() => {
                  navigate(`${PATH.DASHBOARD_PARTNER}/${row.id}`);
                }}
              >
                Detail
                <span className="ml-2">
                  <Icon name="Show" />
                </span>
              </Button>
            ) : (
              <div className="flex">
                <Button
                  color="light"
                  className="mr-2"
                  onClick={() => {
                    setRejectDialog({ show: true, data: row });
                  }}
                >
                  Tolak
                  <i className="cursor-pointer px-2 fa fa-times"></i>
                </Button>
                <Button
                  color="light"
                  onClick={() => {
                    setApproveDialog({ show: true, data: row });
                  }}
                >
                  Setujui
                  <i className="cursor-pointer px-2 fa fa-check"></i>
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];
  const mitra = useSelector((state: RootState) => state.admin.mitra);
  const meta = useSelector((state: RootState) => state.admin.mitraMeta);

  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    dispatch(
      getMitra({
        params: {
          limit: limit,
          page: page,
          search: search,
          status: isVerify ? "A" : "S",
        },
      })
    );
    // eslint-disable-next-line
  }, [isVerify, limit, page, search]);

  const handleSearch = useCallback((e: any) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  }, []);

  const handleExport = useCallback(() => {
    dispatch(
      exportMitra({
        params: {},
        onSuccess(e: IExportExcel) {
          exportBase64ToExcel(e.payload.base64, e.payload.fileName);
          toast.success("Berhasil Export Excel");
        },
        onFailure(e) {
          toast.error("Gagal Export Excel");
        },
      })
    );
  }, []);

  return (
    <MainLayout>
      <>
        <TableComponent
          withSearch={false}
          columns={columns}
          data={mitra}
          isLoading={isLoading}
          header={
            <div className="flex p-4 w-full justify-end">
              <div className="w-full max-w-xs">
                <TextField placeholder="Search" onChange={handleSearch} />
              </div>
            </div>
          }
          pagination={{
            currentPage: meta.pageNumber,
            totalPages: meta.pageTotal,
            totalCount: meta.count,
            onChange(page) {
              setPage(page);
            },
            onPrev: () => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            },
            onNext: () => {
              setPage((prev) => prev + 1);
            },
          }}
          isDownload
          onExport={handleExport}
        />
        <DialogApprovalMitra
          show={approveDialog.show}
          data={approveDialog.data}
          onClose={() => {
            setApproveDialog({ show: false });
          }}
        />
        <DialogRejectMitra
          show={rejectDialog.show}
          data={rejectDialog.data}
          onClose={() => {
            setRejectDialog({ show: false });
          }}
        />
      </>
    </MainLayout>
  );
};

export default DashboardMitra;
