import { PATH } from "config";
import { IProduct } from "pages/landing/redux";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

const CardProduct: FC<{ product: IProduct }> = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div
      className="p-3 rounded-lg bg-[#FCFDFE] border border-[#F2F2F2] cursor-pointer"
      onClick={() => navigate(`${PATH.LANDING_PAGE_PRODUCT}/${product._id}`)}
    >
      <img
        src={product.fotoProduk || "/assets/images/placeholder-image.png"}
        alt="card thumbnail"
        className="w-full h-48 md:h-72 object-cover"
      />
      <div className="text-[#3D3D3D] text-lg font-semibold my-1.5">
        {product.namaProduk}
      </div>
      <div className="flex items-center text-[#AFAEAE] mb-2.5">
        <i className="fa fa-map-marker-alt mr-2"></i>
        {product.namaMitra}
      </div>
    </div>
  );
};

export { CardProduct };
