import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import {
  authReducer as authUserReducer,
  authSaga as authUserSaga,
} from "pages/users/components/auth/redux";
import { authReducer, authSaga } from "pages/admin/components/auth/redux";
import { adminReducer, adminSaga } from "pages/admin/redux";
import { loadingReducer } from "./loading.toolkit";
import { landingReducer, landingSaga } from "pages/landing/redux";
import { userReducer, userSaga } from "pages/users/redux";

export const rootReducer = combineReducers({
  // reducer
  loading: loadingReducer,
  auth: authReducer,
  authUser: authUserReducer,
  admin: adminReducer,
  landing: landingReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function* rootSaga() {
  yield all([
    // saga
    authSaga(),
    authUserSaga(),
    adminSaga(),
    landingSaga(),
    userSaga(),
  ]);
}
