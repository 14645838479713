import { Button, Icon, MainLayout } from "components";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { CardUsaha } from "./CardUsaha";
import { deleteMitra, getMitra } from "pages/users/redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { HeaderNav } from "./HeaderNav";
import { RootState } from "setup";
import DialogDelete from "components/DialogDelete/DialogDelete";
import { toast } from "react-hot-toast";

interface IPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

const DashboardUsaha = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogDelete, setDialogDelete] = useState<{
    show: boolean;
    id: string;
  }>({ show: false, id: "" });
  const mitraList = useSelector((state: RootState) => state.user.mitraList);

  const handleDelete = useCallback(() => {
    dispatch(
      deleteMitra({
        params: {
          mitraId: dialogDelete.id,
        },
        onSuccess(e) {
          toast.success("Berhasil menghapus mitra");
          dispatch(getMitra());
        },
        onFailure(e) {
          toast.error("Gagal menghapus mitra");
        },
      })
    );
  }, [dialogDelete]);

  useEffect(() => {
    dispatch(getMitra());
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <DialogDelete
          show={dialogDelete.show}
          onClose={() => {
            setDialogDelete({ id: "", show: false });
          }}
          onConfirm={() => {
            handleDelete();
            setDialogDelete({ id: "", show: false });
          }}
        />
        <BottomNav />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <div className="flex justify-between items-center p-6">
            <div className="text-[#3D3D3D] text-lg font-semibold">
              Usaha Saya
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => {
                navigate(`${PATH.USER_USAHA_ADD}`);
              }}
            >
              <Icon name="PaperPlus" type="bold" size="small" color="#CB043F" />
              <div className="ml-2 font-medium text-md text-[#CB043F]">
                Tambah Usaha
              </div>
            </div>
          </div>
          <div className="flex flex-wrap px-4">
            {mitraList.map((item, index) => (
              <div className="w-1/2 p-2" key={index}>
                <CardUsaha mitra={item} setDialogDelete={setDialogDelete} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { DashboardUsaha };
