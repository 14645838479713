const prefixAction = `AUTH_USER`;

export const ActionTypes = {
  LOGIN: `${prefixAction}_LOGIN`,
  REGISTER: `${prefixAction}_REGISTER`,
  FORGOT_PASSWORD: `${prefixAction}_FORGOT_PASSWORD`,
  NEW_PASSWORD: `${prefixAction}_NEW_PASSWORD`,
  GET_INFO: `${prefixAction}_GET_INFO`,
};

export interface LoginFormValues {
  nik: string;
  password: string;
}

export interface ILoginAction {
  data: LoginFormValues;
  onSuccess?: () => void;
  onFailure?: (e: any) => void;
}
export interface IUser {
  token: string;
  _id: string;
  nama: string;
  nik: string;
  tempatLahir: string;
  tanggalLahir: string;
  foto: any;
  email: string;
  noHandphone: string;
  statusVerify: string;
  gender: string;
  role: string;
}

export interface ILoginInfoResponse {
  message: string;
  payload: IUser;
}

export interface IAuthState {
  authInfo: IUser;
}
