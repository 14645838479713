import { Icon, MainLayout } from "components";
import { ActionTypes, getConfig, getMitraV1 } from "pages/landing/redux";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { BottomNav } from "../bottom-nav/BottomNav";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";
import { getProductV1 } from "pages/users/redux";

interface IPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { config, mitraListV1 } = useSelector(
    (state: RootState) => state.landing
  );

  const products = useSelector((state: RootState) => state.user.productListV1);

  const loading = useSelector(createLoadingSelector(ActionTypes.GET_CONFIG));

  const getLocation = async (): Promise<IPosition> => {
    return new Promise<IPosition>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const currentPosition: IPosition = {
          coords: {
            latitude,
            longitude,
          },
        };
        resolve(currentPosition);
      }, reject);
    });
  };

  useEffect(() => {
    dispatch(getConfig());
    async function getProductByLocation() {
      try {
        const position: IPosition = await getLocation();

        dispatch(
          getProductV1({
            params: {
              nama: "",
              limit: 12,
              mitraId: "",
              lat: position.coords.latitude ?? -7.742100008576515,
              long: position.coords.longitude ?? 110.36552731219834,
            },
          })
        );
        dispatch(
          getMitraV1({
            params: {
              search: "",
              limit: 12,
              lat: position.coords.latitude ?? -7.742100008576515,
              long: position.coords.longitude ?? 110.36552731219834,
            },
          })
        );
      } catch (err) {
        console.log(err);
      }
    }
    getProductByLocation();
  }, []);

  return (
    <MainLayout navbar={false} sidebar={false} footer={false} mainPadding={0}>
      <div className="w-full bg-white">
        <BottomNav />
        <div className="w-full mx-auto md:max-w-md items-center justify-center bg-[#F6F7FB] min-h-screen relative">
          <img
            src="/assets/images/background-header.png"
            className="w-full absolute top-0 left-0 z-0"
            alt="background header"
          />
          <div className="transform -translate-y-0 p-6 pb-20">
            <div className="text-white font-semibold">
              Selamat Datang di Tabe
            </div>
            <img
              src={config.hero || "/assets/images/placeholder-image.png"}
              alt="banner"
              className="w-full mt-4"
            />
            <div className="mt-6 flex flex-row justify-between bg-white w-full rounded-lg p-2">
              <div
                className="w-1/6 flex-shrink-0 flex flex-col items-center p-2 cursor-pointer"
                onClick={() => navigate(`${PATH.USER_USAHA_ADD}`)}
              >
                <img
                  src="/assets/images/menu/daftar-usaha.png"
                  className="w-full max-w-[48px]"
                  alt="menu"
                />
                <div className="mt-2 font-medium text-gray-800 text-center text-xs">
                  Daftar Usaha
                </div>
              </div>
              <div
                className="w-1/6 flex-shrink-0 flex flex-col items-center p-2 cursor-pointer"
                onClick={() => navigate(`${PATH.USER_TRAINING}`)}
              >
                <img
                  src="/assets/images/menu/info-pelatihan.png"
                  className="w-full max-w-[48px]"
                  alt="menu"
                />
                <div className="mt-2 font-medium text-gray-800 text-center text-xs">
                  Info Pelatihan
                </div>
              </div>
              <div
                className="w-1/6 flex-shrink-0 flex flex-col items-center p-2 cursor-pointer"
                onClick={() => navigate(`${PATH.USER_FUNDING}`)}
              >
                <img
                  src="/assets/images/menu/bantuan-pendanaan.png"
                  className="w-full max-w-[48px]"
                  alt="menu"
                />
                <div className="mt-2 font-medium text-gray-800 text-center text-xs">
                  Bantuan Pendanaan
                </div>
              </div>
              <div
                className="w-1/6 flex-shrink-0 flex flex-col items-center p-2 cursor-pointer"
                onClick={() => {
                  navigate(PATH.USER_ASPIRATION);
                }}
              >
                <img
                  src="/assets/images/menu/jaring-aspirasi.png"
                  className="w-full max-w-[48px]"
                  alt="menu"
                />
                <div className="mt-2 font-medium text-gray-800 text-center text-xs">
                  Jaring Aspirasi
                </div>
              </div>
              <a
                className="w-1/6 flex-shrink-0 flex flex-col items-center p-2 cursor-pointer"
                href="https://oss.go.id"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/assets/images/menu/izin-usaha.png"
                  className="w-full max-w-[48px]"
                  alt="menu"
                />
                <div className="mt-2 font-medium text-gray-800 text-center text-xs">
                  Izin Usaha
                </div>
              </a>
            </div>
            <div className="mt-6 flex justify-between">
              <div className="font-semibold text-[#3D3D3D] text-lg">
                Produk & Jasa Terdekat
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => navigate(PATH.USER_PRODUCT_LIST)}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.25 16.3341V7.66512C21.25 4.64512 19.111 2.75012 16.084 2.75012H7.916C4.889 2.75012 2.75 4.63512 2.75 7.66512L2.75 16.3341C2.75 19.3641 4.889 21.2501 7.916 21.2501H16.084C19.111 21.2501 21.25 19.3641 21.25 16.3341Z"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0861 12.0001H7.91406"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.3223 8.25217L16.0863 12.0002L12.3223 15.7482"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <Swiper
              className="w-full mt-2"
              slidesPerView={2.2}
              centerInsufficientSlides={true}
              spaceBetween={12}
            >
              {products.map((e, i: number) => (
                <SwiperSlide key={i}>
                  <div
                    className="p-3 rounded-lg bg-white rounded-lg cursor-pointer"
                    onClick={() => navigate(`${PATH.USER_PRODUCT}/${e.id}`)}
                  >
                    <img
                      src={
                        e.fotoProduk || "/assets/images/placeholder-image.png"
                      }
                      alt="card thumbnail"
                      className="w-full h-32 object-cover rounded"
                    />
                    <div className="text-[#3D3D3D] text-lg font-medium my-1.5 ellipsis font-semibold capitalize">
                      {e.namaProduk}
                    </div>
                    <div className="flex flex-row items-center mb-2.5">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.545 6.24267L13.4157 2.29C13.3918 2.20643 13.3413 2.13292 13.2719 2.0806C13.2025 2.02827 13.1179 1.99998 13.031 2H10.333L10.6497 5.80267C10.6545 5.86381 10.6738 5.92293 10.706 5.97511C10.7382 6.02729 10.7825 6.07102 10.835 6.10267C11.095 6.258 11.603 6.54467 11.9997 6.66667C12.677 6.87533 13.6663 6.8 14.2303 6.73067C14.2852 6.72358 14.3378 6.70461 14.3845 6.67509C14.4313 6.64556 14.471 6.6062 14.501 6.55973C14.5309 6.51327 14.5504 6.46082 14.558 6.40606C14.5656 6.3513 14.5612 6.29554 14.545 6.24267Z"
                          stroke="#3D3D3D"
                          stroke-width="1.5"
                        />
                        <path
                          d="M9.3332 6.66667C9.71187 6.55 10.1919 6.284 10.4599 6.12533C10.5223 6.08815 10.573 6.03405 10.6061 5.96929C10.6391 5.90452 10.6531 5.83174 10.6465 5.75933L10.3332 2H5.66654L5.3532 5.75933C5.34649 5.83184 5.36046 5.90477 5.3935 5.96967C5.42654 6.03456 5.47729 6.08877 5.53987 6.126C5.80787 6.284 6.28787 6.55 6.66654 6.66667C7.66187 6.97333 8.33787 6.97333 9.3332 6.66667V6.66667Z"
                          stroke="#3D3D3D"
                          stroke-width="1.5"
                        />
                        <path
                          d="M2.58403 2.29L1.4547 6.24333C1.43876 6.29612 1.4345 6.35174 1.44221 6.40634C1.44993 6.46094 1.46943 6.51321 1.49937 6.55951C1.52931 6.60582 1.56897 6.64505 1.61559 6.67449C1.66222 6.70393 1.71469 6.72287 1.76937 6.73C2.3327 6.8 3.3227 6.87467 4.00004 6.66667C4.3967 6.54467 4.90537 6.258 5.1647 6.10333C5.21733 6.07162 5.26161 6.02779 5.29385 5.97548C5.32609 5.92318 5.34535 5.86393 5.35003 5.80267L5.6667 2H2.9687C2.88178 1.99998 2.79722 2.02827 2.72782 2.0806C2.65841 2.13292 2.60794 2.20643 2.58403 2.29V2.29Z"
                          stroke="#3D3D3D"
                          stroke-width="1.5"
                        />
                        <path
                          d="M2 6.66663V12.6666C2 13.0202 2.14048 13.3594 2.39052 13.6094C2.64057 13.8595 2.97971 14 3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6094C13.8595 13.3594 14 13.0202 14 12.6666V6.66663"
                          stroke="#3D3D3D"
                          stroke-width="1.5"
                        />
                      </svg>
                      <div className="text-[#AFAEAE] text-sm ellipsis ml-2">
                        {e.namaMitra}
                      </div>
                    </div>
                    <div className="bg-[#FCF0F3] rounded-lg p-2 text-center text-[#CB043F] mt-2 text-sm text-semibold">
                      {new Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      }).format(e.harga)}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              className="w-full footer-slider mt-4"
              pagination
              modules={[Pagination]}
              slidesPerView={1}
              centerInsufficientSlides={true}
              spaceBetween={0}
            >
              {config.banners.map((e) => (
                <SwiperSlide className="pb-8" key={e._id}>
                  <img
                    src={e.foto || "/assets/images/placeholder-image.png"}
                    alt="banner footer"
                    className="w-full"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="mt-6 flex justify-between">
              <div className="font-semibold text-[#3D3D3D] text-lg">
                Mitra Terdekat
              </div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => navigate(PATH.USER_USAHA_LIST)}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.25 16.3341V7.66512C21.25 4.64512 19.111 2.75012 16.084 2.75012H7.916C4.889 2.75012 2.75 4.63512 2.75 7.66512L2.75 16.3341C2.75 19.3641 4.889 21.2501 7.916 21.2501H16.084C19.111 21.2501 21.25 19.3641 21.25 16.3341Z"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0861 12.0001H7.91406"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.3223 8.25217L16.0863 12.0002L12.3223 15.7482"
                  stroke="#CB043F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <Swiper
              className="w-full mt-2"
              slidesPerView={2.2}
              centerInsufficientSlides={true}
              spaceBetween={12}
            >
              {mitraListV1.map((mitra, i: number) => (
                <SwiperSlide key={i}>
                  <div
                    className="p-3 rounded-lg bg-white rounded-lg cursor-pointer"
                    onClick={() => navigate(`${PATH.USER_USAHA}/${mitra.id}`)}
                  >
                    <img
                      src={
                        mitra?.foto?.url ||
                        "/assets/images/placeholder-image.png"
                      }
                      alt="card thumbnail"
                      className="w-full h-32 object-cover rounded"
                    />
                    <div className="text-[#3D3D3D] text-lg font-medium my-1.5 ellipsis font-semibold capitalize">
                      {mitra.namaMitra}
                    </div>
                    <div className="flex flex-row items-center mb-2.5">
                      <svg
                        width="12"
                        height="16"
                        viewBox="0 0 12 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.99999 15.4315C4.91736 14.5081 3.91387 13.4957 2.99999 12.405C1.62857 10.767 7.53336e-07 8.32756 7.53336e-07 6.00299C-0.000594015 4.81582 0.351007 3.65515 1.01031 2.66788C1.66961 1.68061 2.60697 0.911113 3.70376 0.456777C4.80056 0.00244004 6.00748 -0.116314 7.1718 0.115544C8.33611 0.347402 9.40547 0.919449 10.2445 1.75929C10.8032 2.31545 11.246 2.97689 11.5473 3.70532C11.8486 4.43375 12.0025 5.21471 12 6.00299C12 8.32756 10.3714 10.767 8.99998 12.405C8.0861 13.4957 7.0826 14.5081 5.99999 15.4315ZM5.99999 3.43157C5.318 3.43157 4.66395 3.70249 4.18172 4.18472C3.69948 4.66696 3.42856 5.32101 3.42856 6.00299C3.42856 6.68498 3.69948 7.33903 4.18172 7.82126C4.66395 8.3035 5.318 8.57442 5.99999 8.57442C6.68197 8.57442 7.33602 8.3035 7.81826 7.82126C8.30049 7.33903 8.57141 6.68498 8.57141 6.00299C8.57141 5.32101 8.30049 4.66696 7.81826 4.18472C7.33602 3.70249 6.68197 3.43157 5.99999 3.43157Z"
                          fill="black"
                        />
                      </svg>

                      <div className="text-[#AFAEAE] text-sm ellipsis ml-2">
                        {mitra.distance} KM
                      </div>
                    </div>
                    <div className="bg-[#FCF0F3] rounded-lg p-2 text-center text-[#CB043F] mt-2 text-sm text-semibold">
                      Lihat Detail
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
export { Dashboard };
