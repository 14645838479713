import { FC } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "setup";

const Sidebar: FC<{
  handleScroll: (to: number | null) => void;
  handleClick: () => void;
  closeSidebar: (e: any) => void;
  openSide: boolean;
}> = ({ handleScroll, handleClick, closeSidebar, openSide }) => {
  const authInfo = useSelector((state: RootState) => state.auth.authInfo);
  const navigate = useNavigate();

  return (
    <aside
      className={
        "md:hidden fixed right-0 top-0 w-full bg-black bg-opacity-50 h-screen flex justify-end " +
        (openSide ? "block" : "hidden")
      }
      style={{ zIndex: 9999 }}
    >
      <div className="w-2/3 bg-white h-screen p-2">
        <div
          className="flex justify-left items-center p-4"
          onClick={(e) => closeSidebar(e)}
        >
          <i className="fa fa-times cursor-pointer"></i>
          <span className="ml-4">Close</span>
        </div>
        <button
          onClick={() => {
            handleScroll(0);
          }}
          className="text-[#CB043F] table py-4 w-full text-center font-semibold px-4"
        >
          Beranda
        </button>
        <button
          onClick={() => {
            handleScroll(717);
          }}
          className="text-[#3D3D3D] table py-4 w-full text-center font-medium px-4"
        >
          Produk
        </button>
        <button
          onClick={() => {
            handleScroll(1735);
          }}
          className="text-[#3D3D3D] table py-4 w-full text-center font-medium px-4"
        >
          UMKM & Ekraf
        </button>
        <button
          onClick={() => {
            handleScroll(null);
          }}
          className="text-[#3D3D3D] table py-4 w-full text-center font-medium px-4"
        >
          Kontak Kami
        </button>
        {authInfo.role === "" ? (
          <button
            onClick={() => navigate("/register")}
            className="bg-[#CB043F] text-white py-3 table p-4 w-full text-center rounded-lg"
          >
            Gabung Sekarang
          </button>
        ) : null}
      </div>
    </aside>
  );
};

export { Sidebar };
