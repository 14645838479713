import {
  Button,
  Icon,
  MainLayout,
  TableComponent,
  TextField,
} from "components";
import { ActionTypes, ICourse, getTraining } from "pages/admin/redux";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "setup";
import moment from "moment";
import { FC } from "react";
import { createLoadingSelector } from "setup/redux/loading.toolkit";
import { useNavigate } from "react-router-dom";
import { PATH } from "config";

export const DashboardTraining: FC<{
  isVerify?: boolean;
}> = ({ isVerify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    createLoadingSelector(ActionTypes.GET_TRAINING)
  );
  const columns: any = [
    {
      header: "Foto",
      field: "foto",
      rowRender: (row: ICourse) => {
        return (
          <div className="w-32 flex-shrink-0">
            <img
              src={row?.foto?.url ?? "/assets/images/placeholder-image.png"}
              alt="avatar"
              className="w-32 h-20 rounded-lg object-cover"
            />
          </div>
        );
      },
    },
    {
      header: "Nama Pelatihan",
      field: "title",
    },
    {
      header: "Penyelenggara",
      field: "penyelenggara",
    },
    {
      header: "Kuota",
      field: "kuota",
    },
    {
      header: "Waktu",
      field: "waktu",
      rowRender: (row: ICourse) => {
        return moment(row.waktu ?? "0000-00-00").format("DD MMM YYYY");
      },
    },
    {
      header: "Aksi",
      field: "id",
      rowRender: (row: ICourse) => {
        return (
          <Button
            color="light"
            onClick={() => {
              navigate(`${PATH.DASHBOARD_PIMPINAN_TRAINING}/${row.id}`);
            }}
          >
            Detail
            <span className="ml-2">
              <Icon name="Show" />
            </span>
          </Button>
        );
      },
    },
  ];
  const training = useSelector((state: RootState) => state.admin.course);
  const meta = useSelector((state: RootState) => state.admin.courseMeta);

  const limit = 20;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const handleSearch = useCallback((e: any) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  }, []);

  useEffect(() => {
    dispatch(
      getTraining({
        params: {
          title: search,
          limit: limit,
          page: page,
        },
      })
    );
  }, [isVerify, search, page, limit]);

  return (
    <MainLayout>
      <TableComponent
        withSearch={false}
        header={
          <div className="flex p-4 w-full justify-end">
            <div className="w-full max-w-xs">
              <TextField placeholder="Search" onChange={handleSearch} />
            </div>
          </div>
        }
        columns={columns}
        data={training}
        isLoading={isLoading}
        pagination={{
          currentPage: meta.pageNumber,
          totalPages: meta.pageTotal,
          totalCount: meta.count,
          onPrev: () => {
            if (page > 1) {
              setPage((prev) => prev - 1);
            }
          },
          onNext: () => {
            setPage((prev) => prev + 1);
          },
          onChange(page) {
            setPage(page);
          },
        }}
      />
    </MainLayout>
  );
};

export default DashboardTraining;
